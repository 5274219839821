import cx from 'classnames';
import {filter, keyBy, map, sortBy, sumBy, transform} from 'lodash';
import {action, computed, makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import {Component, createRef, Fragment} from 'react';

import {inputConnectorSpace} from './consts';
import Node from './Node';
import GraphConnector from './GraphConnector';
import GraphLink from './GraphLink';

import './GraphCanvas.less';

const getId = (link) => `${link.from} -> ${link.inputProcessor.name}`;

const transformLinkCount = (count) => count > 1 ? '' + count : '';

@observer
export default class GraphCanvas extends Component {
  canvasRef = createRef();

  @observable draggingInputId = null;
  @observable draggingOutputId = null;
  @observable draggingLinkPos = null;
  @observable draggingConnectorData = null;
  hoveredConnectionData = null;
  inputPositions = {};
  outputPositions = {};

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed.struct get nodesById() {
    return keyBy(this.props.nodes, 'id');
  }

  cursorPoint(e) {
    const pt = this.canvasRef.current.createSVGPoint();
    pt.x = e.clientX; pt.y = e.clientY;
    return pt.matrixTransform(this.canvasRef.current.getScreenCTM().inverse());
  }

  @action
  onCanvasMouseMove = (e) => {
    if (this.draggingInputId || this.draggingOutputId) {
      e.preventDefault();
      this.draggingLinkPos = this.cursorPoint(e);
    }
  };

  @action
  onCanvasMouseUp = () => {
    if (this.draggingConnectorData && this.hoveredConnectionData) {
      this.props.onConnectLinks?.(this.draggingConnectorData, this.hoveredConnectionData);
    }

    this.draggingInputId = null;
    this.draggingOutputId = null;
    this.draggingConnectorData = null;
  };

  @action
  onInputConnectorMouseDown = (id, data, e) => {
    if (!this.props.editable) return;
    this.draggingInputId = id;
    this.draggingConnectorData = data;
    this.draggingLinkPos = this.cursorPoint(e);
  };

  @action
  onOutputConnectorMouseDown = (id, data, e) => {
    if (!this.props.editable) return;
    this.draggingOutputId = id;
    this.draggingConnectorData = data;
    this.draggingLinkPos = this.cursorPoint(e);
  };

  @action
  setHoveredConnection = (id, data) => {
    this.hoveredConnectionData = data;
  };

  @action
  resetHoveredConnection = () => {
    this.hoveredConnectionData = null;
  };

  @computed
  get indexedNodes() {
    return keyBy(this.props.nodes, 'id');
  }

  @computed
  get aggregatedLinks() {
    const {indexedNodes} = this;
    const {editable, links} = this.props;
    if (editable) return links;

    const result = transform(links, (acc, link) => {
      const id = getId(link);
      acc[id] ??= {...link, id, links: []};
      acc[id].links.push(link);
    }, {});

    return sortBy(result, [
      (link) => link.from,
      (link) => indexedNodes[link.inputProcessor.name].x,
      (link) => indexedNodes[link.inputProcessor.name].x < indexedNodes[link.outputProcessor.name].x ?
        indexedNodes[link.inputProcessor.name].y : Number.MAX_SAFE_INTEGER - indexedNodes[link.inputProcessor.name].y
    ]);
  }

  renderNodes = () => {
    const {onNodeMouseDown} = this;
    const {nodes} = this.props;
    return (
      <>
        {map(nodes, (node) =>
          <Node
            key={node.id}
            {...node}
            onMouseDown={onNodeMouseDown}
          />
        )}
      </>
    );
  };

  renderEditableConnectors = () => {
    const {draggingConnectorData, inputPositions, outputPositions,
      onInputConnectorMouseDown, onOutputConnectorMouseDown} = this;
    const {nodes} = this.props;
    return (
      <>
        {map(nodes, (node) => {
          const {connectors: {inputs, outputs}} = node;
          return (
            <Fragment key={node.id}>
              {map(inputs, ({x, y, label, data, isButton}, key) => {
                const pos = {
                  x: x + node.x,
                  y: y + node.y,
                };
                inputPositions[key] = pos;
                return <GraphConnector
                  key={'in' + key}
                  id={key}
                  label={label}
                  {...pos}
                  onMouseDown={onInputConnectorMouseDown}
                  data={data}
                  draggingConnectorData={draggingConnectorData}
                  onMouseOver={this.setHoveredConnection}
                  onMouseOut={this.resetHoveredConnection}
                  isButton={isButton}
                />;
              })}
              {map(outputs, ({x, y, label, data}, key) => {
                const pos = {
                  x: x + node.x,
                  y: y + node.y,
                };
                outputPositions[key] = pos;
                return <GraphConnector
                  key={'out' + key}
                  id={key}
                  label={label}
                  {...pos}
                  onMouseDown={onOutputConnectorMouseDown}
                  data={data}
                  draggingConnectorData={draggingConnectorData}
                  onMouseOver={this.setHoveredConnection}
                  onMouseOut={this.resetHoveredConnection}
                />;
              })}
            </Fragment>
          );
        })}
      </>
    );
  };

  renderReadonlyConnectors = () => {
    const {draggingConnectorData, inputPositions, outputPositions, nodesById,
      onInputConnectorMouseDown, onOutputConnectorMouseDown, aggregatedLinks} = this;
    const {nodes} = this.props;
    return (
      <>
        {map(nodes, (node) => {
          const {connectors: {outputs}} = node;
          const linksToNode = filter(aggregatedLinks, (link) => link.inputProcessor.name === node.id);
          const sortedLinks = sortBy(linksToNode, (link) => nodesById[link.outputProcessor.name].x);
          const x0 = 0.5 * (node.size.width - inputConnectorSpace * (linksToNode.length - 1));
          return (
            <Fragment key={node.id}>
              {map(outputs, ({x, y, label, data}, key) => {
                const pos = {
                  x: x + node.x,
                  y: y + node.y,
                };
                outputPositions[key] = pos;
                return <GraphConnector
                  key={'out' + key}
                  output
                  large
                  content={transformLinkCount(sumBy(aggregatedLinks, ({from, links}) => from === key && links.length))}
                  id={key}
                  label={label}
                  {...pos}
                  onMouseDown={onOutputConnectorMouseDown}
                  data={data}
                  draggingConnectorData={draggingConnectorData}
                  onMouseOver={this.setHoveredConnection}
                  onMouseOut={this.resetHoveredConnection}
                />;
              })}
              {map(sortedLinks, ({to, links}, i) => {
                const pos = {
                  x: node.x + x0 + inputConnectorSpace * i,
                  y: node.y,
                };
                inputPositions[to] = pos;
                return <GraphConnector
                  key={'in' + to}
                  large
                  content={transformLinkCount(links.length)}
                  id={to}
                  {...pos}
                  onMouseDown={onInputConnectorMouseDown}
                  draggingConnectorData={draggingConnectorData}
                  onMouseOver={this.setHoveredConnection}
                  onMouseOut={this.resetHoveredConnection}
                />;
              })}
            </Fragment>
          );
        })}
      </>
    );
  };

  renderConnectors = () => this.props.editable ? this.renderEditableConnectors() : this.renderReadonlyConnectors();

  renderLinks = () => {
    const {aggregatedLinks: links, inputPositions, outputPositions} = this;
    const {editable, onLinkClose} = this.props;
    return (
      <>
        {map(links, (link) => {
          const {id, from, to, highlighted, links} = link;
          return <GraphLink
            key={id}
            aggregated={links?.length > 1}
            highlighted={highlighted}
            from={outputPositions[from]}
            to={inputPositions[to]}
            canClose={editable}
            onClose={() => onLinkClose(link)}
          />;
        })}
      </>
    );
  };

  renderDraggingLink = () => {
    const {draggingLinkPos, draggingInputId, draggingOutputId, inputPositions, outputPositions} = this;
    if (draggingInputId) {
      return <GraphLink
        isDragging
        from={draggingLinkPos}
        to={inputPositions[draggingInputId]}
      />;
    }
    if (draggingOutputId) {
      return <GraphLink
        isDragging
        from={outputPositions[draggingOutputId]}
        to={draggingLinkPos}
      />;
    }
  };

  render() {
    const {canvasRef, onCanvasMouseMove, onCanvasMouseUp, draggingConnectorData,
      renderConnectors, renderLinks, renderNodes, renderDraggingLink} = this;
    const {height, width, targetRef} = this.props;
    const nodes = renderNodes();
    const connectors = renderConnectors();
    const links = renderLinks();
    const draggingLink = renderDraggingLink();
    return (
      <div ref={targetRef} className='graph-canvas-container' style={{width: `${width}px`, height: `${height}px`}} >
        <div className='nodes probe-graph' style={{width: `${width}px`, height: `${height}px`}} >
          {nodes}
        </div>
        <svg
          ref={canvasRef}
          className={cx('graph-canvas', {dragging: !!draggingConnectorData})}
          viewBox={`0 0 ${width} ${height}`}
          onMouseMove={onCanvasMouseMove}
          onMouseUp={onCanvasMouseUp}
          width={width}
          height={height}
        >
          {links}
          {connectors}
          {draggingLink}
        </svg>
      </div>
    );
  }
}
