import PrimitiveValue from './PrimitiveValue';

const DiscreteStateValue = ({name, value, stageGraphColors, valueSchemas, defaultColors}) => {
  const {possible_values: possibleValues} = valueSchemas[name];
  const colors = stageGraphColors ?? defaultColors;
  const index = possibleValues.indexOf(value);
  if (index !== -1) {
    const colorName = colors?.[index];
    if (colorName) {
      return <PrimitiveValue value={value} className={`graph-color-${colorName}`} />;
    }
  }
  return <PrimitiveValue value={value} />;
};

export default DiscreteStateValue;
