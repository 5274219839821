import {Table} from 'semantic-ui-react';

export const SpotlightKeysTableRow = ({children, label}) => {
  return (
    <Table.Row role='row'>
      <Table.Cell role='cell' collapsing>
        {label}
      </Table.Cell>
      <Table.Cell role='cell' collapsing>
        {children}
      </Table.Cell>
    </Table.Row>
  );
};
