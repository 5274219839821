import {Component, Fragment} from 'react';
import {Input} from 'semantic-ui-react';
import {computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {flatMap, entries, isEmpty} from 'lodash';
import {Field, MapInput, RadioGroupInput, ValueRenderer} from 'apstra-ui-common';

import './AnomalyPropertiesFilterInput.less';

@observer
export default class AnomalyPropertiesFilterInput extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get value() {
    const value = {properties: {}, strict: true};
    return Object.assign(value, this.props.value);
  }

  onPropertiesChange = (properties) => {
    return this.props.onChange({...this.value, properties});
  };

  onModeChange = (strict) => {
    return this.props.onChange({...this.value, strict});
  };

  render() {
    const {name, schema, required, disabled, errors} = this.props;
    const {onPropertiesChange, onModeChange, value: {properties, strict}} = this;
    return (
      <Field
        className='anomaly-properties-filter-input'
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
        errors={errors}
      >
        <MapInput
          value={properties}
          schema={{}}
          onChange={onPropertiesChange}
          disabled={disabled}
          buttonText='Add Property'
          noItemsMessage='There are no properties to search for.'
        >
          {({key, value, index, errors, setEntryKey, setEntryValue}) =>
            <Fragment>
              <Field key='key' width={8}>
                <Input
                  disabled={disabled}
                  placeholder='Property'
                  value={key}
                  onChange={(e) => setEntryKey(index, e.target.value)}
                />
              </Field>
              <Field key='value' width={8} errors={errors}>
                <Input
                  disabled={disabled}
                  placeholder='Value'
                  value={value}
                  onChange={(e) => setEntryValue(index, e.target.value)}
                />
              </Field>
            </Fragment>
          }
        </MapInput>
        <RadioGroupInput
          value={strict}
          name={name}
          disabled={disabled}
          schema={{
            type: 'boolean',
            oneOf: [
              {const: true, title: 'All'},
              {const: false, title: 'Any'},
            ],
          }}
          onChange={onModeChange}
        />
      </Field>
    );
  }
}

export const anomalyPropertiesFilterRenderer = new ValueRenderer({
  condition: ({name}) => name === 'properties',
  Value({value: {properties, strict}}) {
    if (isEmpty(properties)) return null;
    return (
      <Fragment>
        {'('}
        {flatMap(entries(properties), ([key, value], index) => [
          index ? <Fragment key={`${key} op`}>&nbsp;{strict ? 'and' : 'or'}&nbsp;</Fragment> : null,
          <Fragment key={`${key} ${value} property`}>{key}{' = '}{value}</Fragment>,
        ])}
        {')'}
      </Fragment>
    );
  },
  ValueInput: AnomalyPropertiesFilterInput,
});
