import {isString} from 'lodash';

import PrimitiveValue from './PrimitiveValue';

const TextValue = ({value}) => {
  if (!isString(value)) return <PrimitiveValue value={value} />;
  return (
    <div className='value-container'>
      <div>
        <pre>
          <code>{value}</code>
        </pre>
      </div>
    </div>
  );
};

export default TextValue;
