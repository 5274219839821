import {SchemaTypeName, StageDataSource} from './types';

export const NODE_ROLES = {
  generic: 'Generic',
  spine: 'Spine',
  superspine: 'Superspine',
  leaf: 'Leaf',
  leaf_pair: 'Leaf Pair',
  rack: 'Rack'
};

export const WIDGET_TYPE_STAGE = 'stage';

export const WIDGET_TYPES = {
  [WIDGET_TYPE_STAGE]: 'Stage',
};

export const DEFAULT_WIDGET_TYPE = WIDGET_TYPE_STAGE;

export const SPECIAL_RENDERED_PROPERTIES = {
  SECONDS_OR_EXPRESSION: ['period', 'service_interval', 'time_window', 'total_duration', 'anomaly_retention_duration'],
  SPEED: ['speed', 'link_speed'],
};

export const SPECIAL_NUMBER_PROPERTIES = ['time_window'];

export const SPECIAL_RENDERED_INPUT_PROPERTIES = {
  PYTHON_EXPRESSION: [
    'interface',
    'key',
    'regexp',
    'service_input',
    'state',
    'system_id',
    'value',
    'max_samples',
    'device_profile_label',
    'speed',
  ],
  PYTHON_EXPRESSION_SUGGESTIONS: {
    system_id: ['system', 'system_id'],
    interface: ['interface', 'if_name']
  },
};

export const STAGE_DATA_SOURCE: Record<string, StageDataSource> = {
  real_time: 'real_time',
  time_series: 'time_series',
  telemetry_service_warnings: 'telemetry_service_warnings',
};
export const DEFAULT_STAGE_DATA_SOURCE = STAGE_DATA_SOURCE.real_time;

export const DEFAULT_STAGE_TIME_SERIES_DURATION = 3600; // 1 hour
export const DEFAULT_STAGE_TIME_SERIES_AGGREGATION = 300; // 5 minutes

export const UNSIGNED_INTEGER_TYPES = new Set<SchemaTypeName>(['u8', 'u16', 'u32', 'u64']);
export const SIGNED_INTEGER_TYPES = new Set<SchemaTypeName>(['s8', 's16', 's32', 's64']);
export const FLOAT_TYPES = new Set<SchemaTypeName>(['f32', 'f64']);
export const VALUE_NUMBER_TYPES = new Set<SchemaTypeName>([
  ...UNSIGNED_INTEGER_TYPES, ...SIGNED_INTEGER_TYPES, ...FLOAT_TYPES
]);

export const DEFAULT_AGGREGATION_TYPE_BY_VALUE_TYPE = {
  boolean: 'last',
  string: 'last',
  number: 'average',
};

export const AGGREGATION_TYPE_UNSET = 'unset';

export const AGGREGATION_TYPE_BY_VALUE_TYPE = {
  none: new Set([...VALUE_NUMBER_TYPES, 'bool', 'string']),
  allOf: new Set([...UNSIGNED_INTEGER_TYPES, 'bool']),
  anyOf: new Set([...UNSIGNED_INTEGER_TYPES, 'bool']),
  average: VALUE_NUMBER_TYPES,
  last: new Set([...VALUE_NUMBER_TYPES, 'bool', 'string']),
  max: VALUE_NUMBER_TYPES,
  min: VALUE_NUMBER_TYPES,
};

export const PROCESSOR_DYNAMIC_DATA_TYPES = new Set([
  'dynamic', 'dynamic discrete state', 'dynamic text', 'dynamic number'
]);
export const PROCESSOR_STATIC_DATA_TYPES = new Set(['static', 'discrete state', 'text', 'number']);

export const OPTICAL_PROBE_METRICS = ['temperature', 'voltage', 'tx_power', 'rx_power', 'tx_bias'];

export const STAGE_DYNAMIC_POPUP_MESSAGE = 'This stage contains a variable number of series';
