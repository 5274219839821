import {isNil} from 'lodash';
import {ValueRenderer} from 'apstra-ui-common';
import {useContext} from 'react';

import IBAContext from './IBAContext';

const chartPopupSystemIdLabelRenderer = new ValueRenderer({
  condition: ({name}) => name === 'properties.system_id',
  Value: ({value: systemId}) => {
    const {systemIdMap} = useContext(IBAContext);
    const {hostname, role} = systemIdMap[systemId] ?? {};
    return (
      <>
        {systemId}
        {!(isNil(hostname) && isNil(role)) && ' ('}
        {!isNil(hostname) && hostname}
        {!isNil(role) && (
          <>
            <div className='content-item-divider' />
            {role}
          </>
        )}
        {!(isNil(hostname) && isNil(role)) && ') '}
      </>
    );
  }
});

export default chartPopupSystemIdLabelRenderer;
