import {TimelineGraphContainer} from 'apstra-ui-common';

import EventTimelineGraph from '../../../components/graphs/EventTimeline';

const EventTimeline = ({item, expanded = false, valueColumnName}) => {
  const axes = {
    x: {showGrid: true},
    y: {showGrid: true}
  };
  return (
    <TimelineGraphContainer
      axes={axes}
      samples={item.persisted_samples}
      expanded={expanded}
    >
      <EventTimelineGraph valueKeyName={valueColumnName} />
    </TimelineGraphContainer>
  );
};

export default EventTimeline;
