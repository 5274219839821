import {useMemo} from 'react';
import {DropdownInput} from 'apstra-ui-common';

import {getPossibleValueColumnOptions} from '../stageUtils';

export default function StageWidgetValueColumnNameInput({
  value, selectedStageInfo: {stage}, name, schema, required, disabled, onChange
}) {
  const valueColumnOptions = useMemo(() => getPossibleValueColumnOptions(stage), [stage]);
  return (
    <ValueColumnNameInput
      options={valueColumnOptions}
      value={value}
      onChange={onChange}
      disabled={disabled}
      required={required}
      schema={schema}
      name={name}
    />
  );
}

export function ValueColumnNameInput(props) {
  return (
    <DropdownInput search fieldProps={{className: 'column-value-input'}} {...props} />
  );
}
