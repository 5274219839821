import {useContext} from 'react';
import {Link} from 'react-router-dom';

import {filtersToQueryParam} from '../../../queryParamUtils';
import PrimitiveValue from './PrimitiveValue';
import generateProbeURI from '../../generateProbeURI';
import HorizontalBar from './HorizontalBar';
import IBAContext from '../../IBAContext';

const MissingMacCount = (props) => {
  const {name, value, item, items, stageDataProps: {probe, processor}} = props;
  const {blueprintId} = useContext(IBAContext);

  if (!isFinite(value)) return <PrimitiveValue value={value} />;

  const stageName = processor.outputs.mac;
  const filter = filtersToQueryParam({
    'properties.system_id': item.properties.system_id,
    'properties.vni': item.properties.vni,
    value: 'missing',
  });
  const queryParams = {filters: {filter}};
  const extraContent = (
    <>
      {' '}
      <Link to={generateProbeURI({blueprintId, probeId: probe.id, stageName, queryParams})}>{'(view)'}</Link>
    </>
  );
  return (
    <HorizontalBar {...{...props, name, value, items, extraContent}} />
  );
};

export default MissingMacCount;
