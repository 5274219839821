import {LinePath} from '@visx/shape';
import {curveLinear} from '@visx/curve';
import {useCallback} from 'react';
import {map, isUndefined, forEach, last, first, includes} from 'lodash';
import moment from 'moment';
import {
  CHART_VALUE_CIRCLE_RADIUS, CHART_ELEMENTS_STROKE_WIDTH, NumericChartLayout, ChartTimestampSelector,
} from 'apstra-ui-common';
import cx from 'classnames';
import bounds from 'binary-search-bounds';

import ChartHoverHandler from './ChartHoverHandler';

import './StateLineChart.less';

const defaultDimensions = {
  compact: {
    height: 60 + (CHART_VALUE_CIRCLE_RADIUS + CHART_ELEMENTS_STROKE_WIDTH) * 2,
    numTicksRows: 4,
  },
  expanded: {
    height: 150,
    numTicksRows: 10,
  },
};

const END_OUT_OF_RANGE_MSG = 'The real end date is out of the selected range';

const StateLineChart = ({
  axes,
  samples = [],
  sampleTimes = [],
  mode = 'compact',
  dimensions = defaultDimensions,
  color = 'red',
  maxSamplesDisplayCircles = 150,
  valueKeyName = 'value',
  extraDataKey = 'data',
  periodStartKey = 'detected_at',
  generatePopupContentFn,
  minSpaceBetweenCircles = 0,
  showStandaloneSamples = false,
  chartStart, chartEnd, popupProps,
  useTimestampZoom = false,
  width: chartWidth, showPopup, hidePopup, timelineStartTime, timelineEndTime, numTicksColumns,
  selectedTimestamp, onSelectTimestamp, selectedSampleId, onSelectSample,
}) => {
  const isValueCirclesLayerVisible = !minSpaceBetweenCircles ||
    (samples.length < chartWidth / minSpaceBetweenCircles && samples.length < maxSamplesDisplayCircles);

  const hasArtificialStart = !isUndefined(chartStart) && moment(first(sampleTimes)).isSame(chartStart);
  const hasArtificialEnd = !isUndefined(chartEnd) && moment(last(sampleTimes)).isSame(chartEnd);

  const onSelectSpecificTimestamp = useCallback((x, xScale, chartSamplesData) => {
    const selectedTimestamp = xScale.invert(x);
    const id = bounds.le(map(chartSamplesData, ({timestamp}) => +new Date(timestamp)), +selectedTimestamp);
    onSelectTimestamp({
      timestamp: selectedTimestamp,
      stateData: id !== -1 && chartSamplesData[id][valueKeyName] ? chartSamplesData[id] : null,
    });
  }, [onSelectTimestamp, valueKeyName]);

  const axesAdjusted = {
    x: {
      ...axes?.x,
      axisOnValue: 0,
      ticks: numTicksColumns
    },
    y: {
      ...axes?.y,
      isLinear: true,
      hide: true
    }
  };

  return (
    <NumericChartLayout
      axes={axesAdjusted}
      className='state-line-chart'
      mode={mode}
      dimensions={dimensions}
      minValue={0}
      maxValue={0}
      xAxisOnValue={0}
      width={chartWidth}
      showPopup={showPopup}
      hidePopup={hidePopup}
      timelineStartTime={timelineStartTime}
      timelineEndTime={timelineEndTime}
      useTimestampZoom={useTimestampZoom}
    >
      {({xScale, yScale, yScaleMin, yMax, xMax}) => {
        const linesData = [];
        const chartSamplesData = [];
        const hoverHandlerTimes = [];
        if (chartWidth > 0) {
          const standaloneSamplesIds = [];
          let timePeriodStartDate;
          let samePeriodSamples = [];
          forEach(samples, (sample, sampleIndex) => {
            sample.color = color;
            sample._chartId = sampleIndex;
            sample.hidden = hasArtificialStart && sampleIndex === 0 ||
              hasArtificialEnd && sampleIndex === samples.length - 1;

            if (isUndefined(sample[extraDataKey])) {
              sample[extraDataKey] = {};
            }

            if (
              !(hasArtificialStart && sampleIndex === 0) &&
              !(hasArtificialEnd && sampleIndex === samples.length - 1)
            ) {
              if (
                +new Date(sample[periodStartKey]) === +new Date(sample.timestamp) &&
                !samples[sampleIndex][valueKeyName] &&
                !samples[sampleIndex - 1]?.[valueKeyName]
              ) {
                // standalone sample
                standaloneSamplesIds.push(sampleIndex);
                sample.hidden = !showStandaloneSamples;
                sample.standalone = true;
              } else {
                // if value is equal to
                // 1 => start of period or intermediate sample in period
                // 0 => end of period
                timePeriodStartDate = timePeriodStartDate || new Date(sample[periodStartKey]);
                samePeriodSamples.push(sample);
                if (!sample[valueKeyName]) {
                  forEach(samePeriodSamples, (samePeriodSample) => {
                    samePeriodSample[extraDataKey]._periodStart = timePeriodStartDate;
                    samePeriodSample[extraDataKey]._periodEnd = sampleTimes[sampleIndex];
                  });
                  linesData.push([
                    [
                      xScale(
                        moment(timePeriodStartDate).isSameOrAfter(timelineStartTime)
                          ? timePeriodStartDate
                          : timelineStartTime
                      ),
                      yScale(0)
                    ],
                    [xScale(sampleTimes[sampleIndex]), yScale(0)]
                  ]);

                  timePeriodStartDate = null;
                  samePeriodSamples = [];
                }
              }

              if (sampleIndex === samples.length - (hasArtificialEnd ? 2 : 1) && sample[valueKeyName]) {
                // the last sample value is 1 => the period end is out of range
                forEach(samePeriodSamples, (samePeriodSample) => {
                  samePeriodSample[extraDataKey]._periodStart = timePeriodStartDate;
                  samePeriodSample[extraDataKey]._periodEnd = hasArtificialEnd
                    ? END_OUT_OF_RANGE_MSG
                    : sampleTimes[sampleIndex];
                });
                linesData.push([
                  [
                    xScale(
                      moment(timePeriodStartDate).isSameOrAfter(timelineStartTime)
                        ? timePeriodStartDate
                        : timelineStartTime
                    ),
                    yScale(0)
                  ],
                  [xScale(timelineEndTime), yScale(0)]
                ]);
              }
            } else if (
              // if we only have artificial start & end timestamp, and
              // the state is equal to 1 duting all period
              // => draw line across all chart
              hasArtificialStart && hasArtificialEnd &&
              samples.length === 2 && samples[0][valueKeyName] &&
              (sampleIndex === 0 || sampleIndex === 1)
            ) {
              sample[extraDataKey]._periodStart = new Date(samples[0][periodStartKey]);
              sample[extraDataKey]._periodEnd = END_OUT_OF_RANGE_MSG;
              if (sampleIndex === 1) {
                linesData.push([
                  [xScale(timelineStartTime), yScale(0)],
                  [xScale(timelineEndTime), yScale(0)]
                ]);
              }
            }
          });

          forEach([...samples], (sample, id) => {
            // remove first sample for ChartHoverHandler and circles
            if (id === 0 && hasArtificialStart) {
              return;
            }
            // remove last sample for ChartHoverHandler and circles
            if (id === samples.length - 1 && hasArtificialEnd) {
              return;
            }
            if (
              sample[valueKeyName] ||
              !sample[valueKeyName] && (
                !includes(standaloneSamplesIds, id) ||
                includes(standaloneSamplesIds, id) && showStandaloneSamples
              )
            ) {
              // display circles, allow to hover over and show data in popup in cases when:
              // - sample's value is 1
              // - it's a last sample in period or
              // - it's a standalone sample which is allowed to be shown by "showStandaloneSamples" prop
              chartSamplesData.push(sample);
              hoverHandlerTimes.push(sampleTimes[id]);
            }
          });
        }

        const chartLines = linesData.length > 0 && map(
          linesData,
          (linePathData, id) => (
            <LinePath
              key={`line-normal-${id}`}
              className={`state-line-chart-line graph-color-${color}`}
              data={linePathData}
              curve={curveLinear}
            />
          )
        );

        const chartSamplesCircles = isValueCirclesLayerVisible && samples.length > 0 && map(
          samples,
          ({hidden, standalone}, id) => (
            <circle
              key={`value-circle-${id}`}
              className={cx([
                'state-line-chart-value-circle',
                `graph-color-${color}`,
                {
                  selected: id === selectedSampleId,
                  hidden,
                  standalone
                }
              ])}
              cx={xScale(sampleTimes[id])}
              cy={yScale(0)}
              r={CHART_VALUE_CIRCLE_RADIUS}
              {...onSelectSample && {onClick: () => onSelectSample(samples[id])}}
            />
          )
        );

        return (
          <ChartHoverHandler
            samples={chartSamplesData}
            sampleTimes={hoverHandlerTimes}
            width={Math.abs(xMax)}
            height={yMax}
            yScale={yScale}
            yScaleMin={yScaleMin}
            xScale={xScale}
            valueKeyName={valueKeyName}
            formatValue={() => null}
            showPopup={showPopup}
            hidePopup={hidePopup}
            popupProps={popupProps}
            selectedSampleId={selectedSampleId - (hasArtificialStart ? 1 : 0)}
            generatePopupContentFn={generatePopupContentFn}
          >
            {onSelectTimestamp
              ? (
                <>
                  <ChartTimestampSelector
                    width={Math.abs(xMax)}
                    height={yMax}
                    linePosition={xScale(new Date(selectedTimestamp))}
                    onSelect={(x) => onSelectSpecificTimestamp(x, xScale, samples)}
                  >
                    {chartLines}
                  </ChartTimestampSelector>
                  {chartSamplesCircles}
                </>
              )
              : (
                <>
                  {chartLines}
                  {chartSamplesCircles}
                </>
              )
            }
          </ChartHoverHandler>
        );
      }}
    </NumericChartLayout>
  );
};

export default StateLineChart;
