import {PureComponent} from 'react';
import {Group} from '@visx/group';
import cx from 'classnames';
import {isNil, map, merge} from 'lodash';
import {Axes, shortTickNumber} from 'apstra-ui-common';

import './BulletChart.less';

export default class BulletChart extends PureComponent {
  static defaultProps = {
    width: 220,
    height: 80
  };

  render() {
    const {
      props: {
        axes: axesProp, headroomValue: value, speed, active,
        width, height, colors, ranges
      }
    } = this;

    if (!active) {
      return 'Data not available';
    }
    if (isNil(value) || isNil(speed)) return null;

    const axes = {
      width,
      height,
      x: merge(
        {
          isLinear: true,
          unitsInline: true,
          unitsSpacer: '',
          formatLabel: shortTickNumber,
          values: [0, speed]
        },
        axesProp?.x
      ),
      y: {
        hide: true,
        values: [0, 1]
      }
    };

    const alignRight = value > speed / 4;

    return (
      <svg className='iba-bullet-chart' width={width} height={height}>
        <Axes {...axes}>
          {
            (xScale, yScale) => {
              const [xMin, xMax] = xScale.range();
              const [yMax, yMin] = yScale.range();
              const scaleHeight = yMax - yMin;

              return (
                <>
                  <Group>
                    <defs>
                      <linearGradient id='bullet-chart-gradient'>
                        {map(colors, (color, index) =>
                          <stop key={index} offset={`${ranges[index] * 100}%`} stopColor={color} />
                        )}
                      </linearGradient>
                    </defs>
                    <rect
                      x={xMin - 1}
                      y={yMin}
                      width={xMax - xMin + 1}
                      height={scaleHeight}
                      fill={"url('#bullet-chart-gradient')"}
                    />
                  </Group>

                  <Group
                    className={cx('iba-bullet-chart-value', {'align-right': alignRight})}
                    top={yMin + scaleHeight / 4}
                    left={xMin}
                  >
                    <rect
                      width={xScale(value) - xMin}
                      height={scaleHeight / 2}
                    />
                    <text
                      x={xScale(value) - xMin + (alignRight ? -4 : 4)}
                      y={scaleHeight / 4}
                    >
                      {shortTickNumber(value)}
                    </text>
                  </Group>
                </>
              );
            }
          }
        </Axes>
      </svg>
    );
  }
}
