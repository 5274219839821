import {memo, useContext, useEffect, useMemo, useRef} from 'react';
import {clamp, compact, forEach, isEmpty, map, max, min, size, transform, zipWith} from 'lodash';
import {brandColorNames, formatDateAsLocalDateTime, formatNumber, MultipleLineChart, parseTimestamp,
  TimelineGraphContainer, shortTickNumber} from 'apstra-ui-common';
import cx from 'classnames';
import {Button, Header, Popup, Divider} from 'semantic-ui-react';
import {scaleOrdinal} from 'd3';

import BarGroupChart from '../../../components/graphs/BarGroupChart';
import BoxplotChart from '../../../components/graphs/BoxplotChart';
import DonutChart from '../../../components/graphs/DonutChart';
import SunburstChart from '../../../components/graphs/SunburstChart';
import ReportTable from './ReportTable';
import {CHART_TYPES, REPORT_TYPES} from './const';
import ReportContext from './ReportContext';
import ChartLegend from '../../../components/graphs/ChartLegend';
import MarkdownFormatter from '../../../components/MarkdownFormatter';

import {ReactComponent as Chevron} from '../../../../styles/icons/iba/thin-chevron.svg';

const processPopupHeader = (value) => formatDateAsLocalDateTime(value);
const xFn = (d) => d.x;
const firstQuartileFn = (d) => d.q1;
const thirdQuartileFn = (d) => d.q3;
const upperFenceFn = (d) => d.upper_fence;
const lowerFenceFn = (d) => d.lower_fence;

const reportColors = [
  'green', 'blue', 'purple', 'orange', 'brown', 'red', 'olive', 'teal', 'violet', 'pink', 'grey', 'yellow'
];

const PRINTABLE_WIDTH = 1024;

const ReportEntity = memo(({
  type, title, text, data, x_axis: xAxis, y_axis: yAxis, items, path,
  tocLevels, lastSectionItem, dimensions, isPrinting
}) => {
  const sizeProps = isPrinting ? {width: PRINTABLE_WIDTH} : {};
  const xIsTimestamp = xAxis?.data_format === 'timestamp';

  const [samples, minValue, maxValue] = useMemo(() => {
    if (type === REPORT_TYPES.barChart || type === REPORT_TYPES.stackedBarChart) {
      // [Stacked] Bar Chart
      const {traces, x_values: xValues} = data;
      const {ticks, ticks_labels: ticksLabels} = xAxis;

      const xLabels = (xIsTimestamp ?
        map(data.x_values, (value) => parseTimestamp(value)) :
        (ticksLabels ? ticksLabels : (ticks ? ticks : xValues))) || [];

      const samples = map(xLabels, (x, index) => {
        return transform(traces, (result, {label, y_values: yValues}) => {
          result[label] = yValues[index];
        }, {x});
      });
      return [samples];
    } else if (type === REPORT_TYPES.boxChart) {
      // Box Chart
      const {lowerfence, q1, q2, q3, upperfence} = data;
      const values = data.values || [];
      const q0 = data.q0 || [];
      const q4 = data.q4 || [];
      const {ticks, ticks_labels: ticksLabels} = xAxis;
      const xValues = (xIsTimestamp ?
        map(data.x_values, (value) => parseTimestamp(value)) :
        ticksLabels ?
          ticksLabels :
          ticks ?
            ticks :
            data.x_values) || [];
      return [zipWith(
        xValues, lowerfence, q1, q2, q3, upperfence, values, q0, q4, (x, min, q1, median, q3, max, dots, q0, q4) => ({
          x, upper_fence: max, q3, median, q1, lower_fence: min, dots,
          outliers: compact([q0 !== min ? q0 : null, q4 !== max ? q4 : null]),
        })
      )];
    } else if (type === REPORT_TYPES.pieChart) {
      // Pie Chart
      const {values, labels, colors} = data;
      const colorNames = values?.length > reportColors.length ? brandColorNames : reportColors;
      let i = 0;
      return [zipWith(values, labels, colors, (value, label, color) => {
        const tooltip = `${label}: ${formatNumber(value, {short: true, withIndent: true})}`;
        return {
          value, label, tooltip, color: color || colorNames[i++ % colorNames.length], id: label,
        };
      })];
    } else if (type === REPORT_TYPES.lineChart) {
      // Line Chart
      const {x_values: xValues, traces} = data;
      let minValue, maxValue;
      return [
        transform(traces, (result, {y_values: yValues, x_values: xLineValues, mode, label}) => {
          minValue = min([minValue, min(yValues)]);
          maxValue = max([maxValue, max(yValues)]);
          const samples = zipWith(xLineValues || xValues, yValues, (timestamp, value) => {
            return {
              value,
              timestamp
            };
          });
          result.push({persisted_samples: samples, properties: {label}, lineMode: mode});
        }, []),
        minValue,
        maxValue
      ];
    } else if (type === REPORT_TYPES.sunburstChart) {
      // Sunburst Chart
      const {values, labels, parents, ids} = data;

      // Mapping items to IDs
      const itemById = transform(ids, (result, id, index) => {
        result[id] = {id, name: labels[index], size: values[index], children: [], parentId: parents[index]};
      }, {});

      // Building tree structure parent-children
      const tree = transform(parents, (acc, parentId, index) => {
        const item = itemById[ids[index]];
        if (parentId) {
          itemById[parentId]?.children.push(item);
        } else {
          acc.push(item);
        }
      }, []);

      // Substract nested values from top to bottom
      const substractChildValuesFrom = (id, value) => {
        const item = itemById[id];
        if (item) {
          item.size -= value;
          if (item.parentId) substractChildValuesFrom(item.parentId, value + item.size);
        }
      };
      // Traverse from the top levels to the bottom and substract values recursively
      forEach(itemById, ({children, parentId, size}) => {
        if (isEmpty(children)) substractChildValuesFrom(parentId, size);
      });

      // Assigning colors recursive function
      const assignColor = (item, color) => {
        item.color = color;
        forEach(item?.children, (child) => assignColor(child, color));
      };

      // Assigning colors
      const parentsCount = size(parents);
      const colorNames = parentsCount > reportColors.length ? brandColorNames : reportColors;
      forEach(tree, (parent, index) => {
        assignColor(parent, colorNames[index % colorNames.length]);
      });

      return [{
        name: '',
        children: tree
      }];
    }
    return [null];
  }, [type, data, xAxis, xIsTimestamp]);

  const colors = useMemo(() => {
    if (type === REPORT_TYPES.barChart || type === REPORT_TYPES.stackedBarChart || type === REPORT_TYPES.lineChart) {
      const {traces} = data;
      const colorNames = traces?.length > reportColors.length ? brandColorNames : reportColors;
      return map(traces, ({color}, i) => color || colorNames[i % colorNames.length]);
    }
    return [];
  }, [data, type]);

  const colorScale = useMemo(() => {
    if (type === REPORT_TYPES.lineChart) {
      const labels = map(samples, ({properties}) => properties?.label);
      const itemColors = colors.length === samples.length ?
        colors :
        map(labels, (_, i) => brandColorNames[i % brandColorNames.length]);
      return scaleOrdinal().domain(labels).range(itemColors);
    }
    return () => null;
  }, [colors, samples, type]);

  if (type === REPORT_TYPES.markdown) {
    return (
      <ReportMarkdown path={path} text={text} />
    );
  } else if (type === REPORT_TYPES.section) {
    return (
      <>
        <ReportHeader
          title={title}
          path={path}
          tocLevels={tocLevels}
          isPrinting={isPrinting}
        />
        <ReportSectionContainer className='report-section' path={path} isPrinting={isPrinting}>
          {map(items, (entity, i) => (
            <ReportEntity
              key={i}
              {...entity}
              isPrinting={isPrinting}
              lastSectionItem={items.length - 1 === i}
            />
          ))}
        </ReportSectionContainer>
        {!lastSectionItem && <Divider />}
      </>
    );
  } else {
    let chart = null;
    switch (type) {
    case REPORT_TYPES.barChart:
    case REPORT_TYPES.stackedBarChart:
      chart = (
        <BarGroupChart
          samples={samples}
          groupLabelKey='x'
          colors={!isEmpty(colors) ? colors : undefined}
          processPopupHeader={xIsTimestamp ? processPopupHeader : undefined}
          showZeroBars
          stackBars={type === REPORT_TYPES.stackedBarChart}
          axes={{
            x: {
              label: xAxis?.label,
              units: xAxis?.units,
              isLinear: xIsTimestamp,
              isTimestamp: xIsTimestamp,
              ticks: clamp(size(samples), 0, 20),
            },
            y: {
              label: yAxis?.label,
              units: yAxis?.units,
              unitsInline: true,
              isLinear: true,
              ticks: 10,
              showGrid: true
            }
          }}
        />
      );
      break;
    case REPORT_TYPES.boxChart:
      chart = (
        <BoxplotChart
          samples={samples}
          x={xFn}
          firstQuartile={firstQuartileFn}
          thirdQuartile={thirdQuartileFn}
          min={lowerFenceFn}
          max={upperFenceFn}
          axes={{
            x: {
              label: xAxis?.label,
              isLinear: xIsTimestamp,
              isTimestamp: xIsTimestamp,
              showGrid: true
            },
            y: {
              label: yAxis?.label,
              units: yAxis?.units,
              unitsInline: size(yAxis?.units) < 3,
              isLinear: true,
              showGrid: true,
              formatLabel: (value) => formatNumber(value, {short: (value <= -1 || value >= 1)})
            }
          }}
          {...sizeProps}
        />
      );
      break;
    case REPORT_TYPES.pieChart:
      chart = (
        <DonutChart
          width={350}
          thickness={10}
          values={samples}
          withLegend
          pie
        />
      );
      break;
    case REPORT_TYPES.lineChart:
      chart = (
        <>
          <TimelineGraphContainer
            axes={{
              x: {
                label: xAxis?.label,
                showGrid: true
              },
              y: {
                label: yAxis?.label,
                units: yAxis?.units,
                unitsInline: size(yAxis?.units) < 3,
                showGrid: true,
                formatLabel: shortTickNumber
              }
            }}
            items={samples}
            itemSamplesPath='persisted_samples'
            useCurrentTimeAsTimelineEnd={false}
            expanded
            {...sizeProps}
          >
            <MultipleLineChart
              popupContentItemKeys={['label']}
              minValue={minValue}
              maxValue={maxValue}
              valueKeyName='value'
              itemColors={colors.length === samples.length ? colors : undefined}
              dimensions={dimensions}
            />
          </TimelineGraphContainer>
          <ChartLegend className='print-only' ordinalColorScale={colorScale} horizontal />
        </>
      );
      break;
    case REPORT_TYPES.sunburstChart:
      chart = (
        <SunburstChart
          data={samples}
          width={350}
          withLegend
        />
      );
      break;
    case REPORT_TYPES.table:
      chart = (<ReportTable {...data} />);
      break;
    }

    return (
      <div className='report'>
        {title && (
          <div className='title'>
            {CHART_TYPES.has(type) && <ReportCollapseButton path={path} isPrinting={isPrinting} />}
            {title}
          </div>
        )}
        <ReportSectionContainer className='report-chart' path={path}>
          {chart}
        </ReportSectionContainer>
      </div>
    );
  }
});

ReportEntity.defaultProps = {
  dimensions: {
    compact: {
      height: 60,
      numTicksRows: 4
    },
    expanded: {
      height: 300,
      numTicksRows: 10
    },
  },
};

const ReportHeader = ({title, tocLevels, path, isPrinting}) => {
  const {collapsedSectionState, onSetSectionRef} = useContext(ReportContext);
  const collapsed = !isPrinting && collapsedSectionState[path];
  const headerRef = useRef();
  useEffect(
    () => {
      onSetSectionRef(path, headerRef);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [headerRef, path]
  );
  return (
    <Header
      id={`header.${path}`}
      className={cx('report-header', {expanded: !collapsed})}
      as={`h${Math.min(tocLevels.length, 6)}`}
    >
      <div ref={headerRef}>
        <ReportCollapseButton path={path} isPrinting={isPrinting} />
        {title}
      </div>
    </Header>
  );
};

const ReportCollapseButton = ({path, isPrinting}) => {
  const {collapsedSectionState, onCollapseToggle} = useContext(ReportContext);
  const collapsed = !isPrinting && collapsedSectionState[path];
  return (
    <Popup
      content={collapsed ? 'Expand' : 'Collapse'}
      offset={[-11, 0]}
      trigger={
        <Button
          basic
          circular
          size='tiny'
          icon={<Chevron className={cx('chevron-icon', {expanded: !collapsed})} />}
          onClick={() => onCollapseToggle(path)}
          aria-label='Toggle collapse/extend section'
        />
      }
    />
  );
};

const ReportSectionContainer = ({className, children, path, isPrinting}) => {
  const {collapsedSectionState} = useContext(ReportContext);
  const collapsed = !isPrinting && collapsedSectionState[path];
  return (
    <div className={cx(className, {'report-collapsed': collapsed})}>
      {children}
    </div>
  );
};

const ReportMarkdown = ({path, text}) => {
  const {collapsedSectionState} = useContext(ReportContext);
  return (
    <MarkdownFormatter
      className={cx({'report-collapsed': collapsedSectionState[path]})}
      markdown={text}
    />
  );
};

export default ReportEntity;
