import {observable, action, makeObservable, toJS, computed} from 'mobx';
import {filter, uniq, sortBy, transform, map, compact, some, values} from 'lodash';

import Port from './Port';
import {generateLocalId, getTopologicOrder} from '../../cablingMapEditor/utils';
import {ctrlNodeWidth, ctrlNodeHeight, ctrlIfcPositions, ctrlEntityTypes, ctrlGridStep,
  ctrlHalfNodeHeight} from '../../cablingMapEditor/const';
import {REDUNDANCY, NODE_ROLES, PEER_LINKS_TYPES, rolesOrder, ROLES_NODE_TYPES, NODE_TYPES_ROLES,
  PORT_ROLES} from '../const';
import {tryPlacing, stringFromSpeed, speedFromString, makePortChannelError} from '../utils';

const emptyNode = {
  id: null,
  label: '',
  role: NODE_ROLES.GENERIC,
  pairedWith: null,

  relativePosition: {x: null, y: null},
  _order: 0
};

class Node {
  @observable id = generateLocalId();
  @observable role;
  @observable _label;
  @observable name = generateLocalId();
  @observable tags;
  @observable _logicalDevice = null;
  @observable _redundancyProtocol = null;
  @observable.ref pairedWith = null;
  @observable ldPorts = [];

  @observable relativePosition;
  @observable _order;
  @observable isFirstInPair = false;
  @observable portChannelId = 0;
  @observable portChannelIdExt = 0;
  @observable mlagVlanId = 0;

  @observable spineLinksCount = 0;
  @observable spineLinksSpeed = null;

  @observable.ref zone = {};

  @observable stage;

  @observable.ref rackStore = null;

  @action
  setProperty(property, value, applyToPair) {
    this[property] = value;
    if (this.isPaired && applyToPair) {
      this.pairedWith.setProperty(property, value, false);
    }
  }

  @action
  setLogicalDevice(value) {
    this.setProperty('_logicalDevice', value, true);
    this.ldPorts = map(value?.ports, (port) => port.clone(true));
    this.checkLinksToSpines();
  }

  set logicalDevice(value) {
    this.setLogicalDevice(value);
  }

  @computed
  get logicalDevice() {
    return this._logicalDevice;
  }

  set label(value) {
    this.setProperty('_label', value, true);
  }

  @computed
  get label() {
    return `${this._label}${this.isPaired ? (this.isFirstInPair ? '_1' : '_2') : ''}`;
  }

  set redundancyProtocol(value) {
    if (this.isPaired) {
      this.setProperty('_redundancyProtocol', value, true);
    }
  }

  @computed
  get redundancyProtocol() {
    return this.pairedWith ? (
      this._redundancyProtocol || REDUNDANCY.NO_LAG
    ) : null;
  }

  get isPositioned() {
    return this.relativePosition.x !== null && this.relativePosition.y !== null;
  }

  get type() {
    return ctrlEntityTypes.NODE;
  }

  @computed
  get roleIndex() {
    return -rolesOrder.indexOf(this.role);
  }

  @computed
  get isOutsideItsZone() {
    const y = this.relativePosition?.y ?? 0;
    return (
      y < -ctrlHalfNodeHeight || y + ctrlHalfNodeHeight > this.zone.size
    );
  }

  @computed
  get isValid() {
    return !this.validationErrors?.length;
  }

  @computed
  get isPaired() {
    return !!this.pairedWith;
  }

  @computed
  get pairOrder() {
    return (this.isPaired && !this.isFirstInPair) ? 1 : 0;
  }

  @computed
  get isLeafPair() {
    return this.isLeaf && this.isPaired;
  }

  @computed
  get isMlagLeafPair() {
    return this.isLeafPair && this.redundancyProtocol === REDUNDANCY.MLAG;
  }

  @computed
  get isEsiLeafPair() {
    return this.isLeafPair && this.redundancyProtocol === REDUNDANCY.ESI;
  }

  @computed
  get firstInPair() {
    return (this.isPaired && !this.isFirstInPair) ? this.pairedWith : this;
  }

  @computed
  get secondInPair() {
    return this.firstInPair.pairedWith;
  }

  @computed
  get position() {
    return {
      x: this.relativePosition.x,
      y: this.relativePosition.y + (this.zone?.y ?? 0)
    };
  }

  @computed
  get isGeneric() {
    return this.role === NODE_ROLES.GENERIC;
  }

  @computed
  get isAccessSwitch() {
    return this.role === NODE_ROLES.ACCESS_SWITCH;
  }

  @computed
  get isLeaf() {
    return this.role === NODE_ROLES.LEAF;
  }

  @computed
  get idsAffected() {
    return this.isPaired ? [this.id, this.pairedWith.id] : [this.id];
  }

  @computed
  get nodesInvolved() {
    return this.isPaired ? (
      this.isFirstInPair ? [this, this.pairedWith] : [this.pairedWith, this]
    ) : [this];
  }

  @computed
  get topologicOrder() {
    return +getTopologicOrder(this.position) + (this.isFirstInPair ? 0 : ctrlNodeWidth + ctrlGridStep);
  }

  @computed.struct
  get myLinksByType() {
    return this.rackStore.nodeLinksGroups[this.id] ?? {};
  }

  @computed.struct
  get myLinksGroups() {
    return this.rackStore.linksGroupsByName[this.name] ?? [];
  }

  @computed.struct
  get usedPorts() {
    return this.rackStore.nodesUsedPorts[this.id] || [];
  }

  @computed
  get availablePorts() {
    const placement = tryPlacing(this.usedPorts, this.ldPorts);
    if (placement) {
      return filter(placement, {isTaken: false});
    } else {
      this.rackStore?.setError(
        'Unable to place links', <p><b>{this.label}</b>{' does not have enough free ports'}</p>
      );
      return [];
    }
  }

  // Makes sure that connection to provided linksGroup is possible (node has enough
  // available ports to host it)
  canConnectTo({speed, fromRole, toRole, count}, forcedCount) {
    // Identify which end of the link is applicable to this node
    const role = this.role === fromRole ? toRole : fromRole;
    // Adding links groups port to the list of taken
    return this.canConnectPorts(Port.generate(forcedCount || count, speed, [role]));
  }

  // Checks if provided amount of ports can fit into the capacity of this node
  canConnectPorts(ports) {
    // Get list of taken ports for this node
    const portsNeeded = [...this.usedPorts];
    // Adding links groups port to the list of taken
    portsNeeded.push(...ports);

    return tryPlacing(portsNeeded, this.ldPorts);
  }

  constructor(props = {}, rackStore) {
    if (!rackStore) throw new Error('No store provided upon node initialization!');
    this.rackStore = rackStore;
    makeObservable(this);
    this.init(props);
  }

  @action
  init(props) {
    this.fillWith({...emptyNode, ...{id: generateLocalId()}, ...props});
  }

  @action
  fillWith(data) {
    Object.assign(this, data);
  }

  @action
  resetPosition() {
    this.relativePosition = {...emptyNode.position};
  }

  @action
  pairWith(node) {
    this.pairedWith = node;
    node.pairedWith = this;
  }

  @action
  moveTo({x, y}) {
    this.relativePosition = {x, y};
  }

  @action
  moveBy({x, y}, notPropagate) {
    this.relativePosition = {x: +this.relativePosition.x + x, y: +this.relativePosition.y + y};
    if (!notPropagate) this.pairedWith?.moveBy({x, y}, true);
  }

  @action
  snapToGrid() {
    const x = ctrlGridStep * Math.round(this.relativePosition.x / ctrlGridStep) - this.relativePosition.x;
    const y = ctrlGridStep * Math.round(this.relativePosition.y / ctrlGridStep) - this.relativePosition.y;
    const moved = x || y;
    this.moveBy({x, y});
    return moved;
  }

  @computed
  get labelNotUnique() {
    return (this.rackStore?.usedLabels[this.label] > 1 || this.rackStore?.usedLabels[this._label] > 1);
  }

  @computed
  get validationErrors() {
    return compact([
      !this.logicalDevice && 'Logical Device is not set',
      this.isMlagLeafPair && !this.mlagVlanId && 'MLAG Keepalive VLAN ID is not set',
      this.labelNotUnique && 'Label must be unique',
      this.rackStore?.isL3Clos && this.spineLinksCount < 1 && this.isLeaf && 'Links per spine are not defined',
      ...this.linkingErrors,
      ...compact(values(this.portChannelIdsErrors))
    ]);
  }

  @computed
  get myPortChannelIdsDuplicates() {
    return this.rackStore?.portChannelsPools?.[this.name];
  }

  #isPortChannelIdDuplicated(id) {
    return id && this.myPortChannelIdsDuplicates?.includes(id);
  }

  @computed
  get portChannelIdsErrors() {
    if (this.isPaired && !this.isFirstInPair) {
      return this.pairedWith.portChannelIdsErrors;
    }
    // Generics and ESI access switch pairs have port channel id ranges
    if (this.isGeneric) {
      return this.#isPortChannelIdDuplicated(this.portChannelId) ? {
        portChannelId: makePortChannelError(this.portChannelId)
      } : {};
      // ESI access switch pairs have port channel ids defined for L3 peer links
    } else if (this.isPaired && this.redundancyProtocol === REDUNDANCY.ESI && this.isAccessSwitch) {
      return this.#isPortChannelIdDuplicated(this.portChannelId) ? {
        portChannelId: makePortChannelError(this.portChannelId, PEER_LINKS_TYPES.L3_PEER)
      } : {};
      // MLAG leaf pairs have port channel ids defined for L2 and L3 peer links
    } else if (this.isMlagLeafPair) {
      return (this.portChannelId && this.portChannelId === this.portChannelIdExt) ? {
        portChannelIdExt: makePortChannelError(this.portChannelId)
      } : {
        ...(this.#isPortChannelIdDuplicated(this.portChannelId) ?
          {portChannelId: makePortChannelError(this.portChannelId, PEER_LINKS_TYPES.L2_PEER)} : {}),
        ...(this.#isPortChannelIdDuplicated(this.portChannelIdExt) ?
          {portChannelIdExt: makePortChannelError(this.portChannelIdExt, PEER_LINKS_TYPES.L3_PEER)} : {})
      };
    }
    return {};
  }

  @computed
  get hasPortChannelErrors() {
    return this.portChannelId > 0 && some(this.portChannelIdsErrors);
  }

  @computed
  get linkingErrors() {
    const errors = [];

    // Generics and access switches must have link[s] to other node[s]
    if (!this.myLinksByType.null && !this.isLeaf) {
      errors.push('System must be connected with switch');
    }
    // If system is a switch pair ...
    if (this.isPaired) {
      if (this.isMlagLeafPair) {
        // ... of MLAG type ...
        // there must be at least 1 L2 peer link defined
        if (!this.myLinksByType[PEER_LINKS_TYPES.L2_PEER]) {
          errors.push('MLAG leaf pair must have at least one L2 peer link');
        }
      } else if (this.isAccessSwitch) {
        if (!this.myLinksByType[PEER_LINKS_TYPES.L3_PEER]) {
          // ... of ESI type ...
          // there must be at least 1 L3 peer link defined
          errors.push('ESI access switch pair must have at least one L3 peer link');
        }
        // ESI access switch pair can only be connected with ESI leaf pair
        if (some(
          this.myLinksGroups,
          ({toNode: {isPaired, isMlagLeafPair}}) => (!isPaired || isMlagLeafPair)
        )) {
          errors.push('ESI access switch pair can only be connected to ESI leaf pair');
        }
      }
    }
    return errors;
  }

  fallsWithin(start, end) {
    const {x, y} = this.position;
    const [startX, endX] = sortBy([start.x, end.x]);
    const [startY, endY] = sortBy([start.y, end.y]);

    return (x < endX) && (x + ctrlNodeWidth > startX) &&
      (y < endY) && (y + ctrlNodeHeight > startY);
  }

  clone() {
    return new Node(toJS({
      id: generateLocalId(),
      label: this.rackStore?.pickUniqueLabel(this._label, this.isPaired) ?? `${this.label}_${generateLocalId()}`,
      name: generateLocalId(),
      role: this.role,
      logicalDevice: this.logicalDevice,
      zone: this.zone,
      tags: this.tags,
      portChannelId: this.portChannelId,
      portChannelIdExt: this.portChannelIdExt,
      relativePosition: {...this.relativePosition},
      mlagVlanId: this.mlagVlanId,
      spineLinksCount: this.spineLinksCount,
      spineLinksSpeed: this.spineLinksSpeed
    }), this.rackStore);
  }

  @computed
  get interfacesPositions() {
    // Defines which side of the node bar their interfaces must be shown
    // depending on the position of the targets they are connected to
    return transform(
      // For the simple link define position based on the single target node position
      this.myLinksGroups,
      (acc, linksGroup) => {
        const {id, railIndex, fromName, fromNode, toNode, isPeer, isEmpty, isAttachedToFirst,
          isAttachedToSecond} = linksGroup;
        if (isEmpty && isPeer) return;

        const isSource = fromName === this.name;
        const node = isSource ? toNode : fromNode;
        // If link is one to pair in some cases target switches are not affected
        if (
          this.isPaired && !isSource && !node?.isPaired &&
            !(this.isFirstInPair ? isAttachedToFirst : isAttachedToSecond)
        ) return;

        // Peer links are always left to right
        const side = isPeer ? (
          this.isFirstInPair ?
            ctrlIfcPositions.RIGHT :
            ctrlIfcPositions.LEFT
        ) : (
          // Cross-zone links are always bottom to top
          isSource ?
            ctrlIfcPositions.TOP :
            ctrlIfcPositions.BOTTOM
          );

        acc[id] = {id, railIndex, side, node};
      },
      {}
    );
  }

  // Checks if the new LD is still capable of hosting previously defined links to spines.
  // If not, reset these settings.
  @action
  checkLinksToSpines() {
    if (!this.isLeaf) return;
    const spinePorts = Port.generate(this.spineLinksCount, speedFromString(this.spineLinksSpeed), PORT_ROLES.SPINE);
    if (!tryPlacing(spinePorts, this.ldPorts)) {
      this.setProperty('spineLinksCount', 0, true);
      this.setProperty('spineLinksSpeed', null, true);
    }
  }

  isPairedWith(nodeId) {
    return this.pairedWith?.id === nodeId;
  }

  getRelatedIds(linksGroups) {
    const {name} = this;
    const result = uniq(
      transform(linksGroups, (result, linksGroup) => {
        const oppositeName = linksGroup.oppositeTo(name);
        if (oppositeName) result.push(...this.nodesByName[oppositeName]);
      }, [])
    );
    return result;
  }

  static deserialize(jsonValue, rackStore) {
    const {devices = {}, nodes = {}, zones = {}} = rackStore;
    const {
      id,
      label,
      name,
      'node-type': nodeType,
      tags,
      redundancy_protocol: redundancyProtocol,
      logical_device: logicalDeviceId,
      pair_id: pairId,
      mlag_vlan_id: mlagVlanId,
      link_per_spine_count: spineLinksCount,
      link_per_spine_speed: spineLinksSpeed,
      port_channel_id_min: portChannelId,
      port_channel_id_max: portChannelIdExt,
      internals
    } = jsonValue;

    const role = NODE_TYPES_ROLES[nodeType];
    const pairedWith = pairId ? nodes[pairId] : null;

    const node = new Node({
      id,
      label,
      name,
      role,
      tags,
      zone: zones[role],
      redundancyProtocol,
      logicalDevice: devices[logicalDeviceId],
      pairedWith,
      mlagVlanId,
      spineLinksCount,
      spineLinksSpeed: stringFromSpeed(spineLinksSpeed),
      portChannelId,
      portChannelIdExt,
      ...(internals ? internals : {})
    }, rackStore);

    // Pairing consistency must be preserved on both ends
    if (pairedWith) {
      pairedWith.setProperty('pairedWith', node);
    }
    return node;
  }

  serialize(withInternals = true) {
    return {
      id: this.id,
      label: this._label,
      'node-type': ROLES_NODE_TYPES[this.role],
      tags: toJS(this.tags),
      redundancy_protocol: this.redundancyProtocol,
      logical_device: this.logicalDevice?.id ?? null,
      ...(this.isPaired ? {pair_id: this.pairedWith.id} : {}),
      ...(this.isLeaf ?
        {
          ...(this.isMlagLeafPair ? {mlag_vlan_id: this.mlagVlanId} : {}),
          ...(this.rackStore?.isL3Clos ?
            {
              link_per_spine_count: this.spineLinksCount,
              link_per_spine_speed: speedFromString(this.spineLinksSpeed)
            } : {}
          ),
          ...(this.isPaired ?
            {
              leaf_leaf_link_port_channel_id: 0,
              leaf_leaf_l3_link_port_channel_id: this.isLeaf ? 0 : this.portChannelIdExt
            } : {}
          ),
          ...(this.isMlagLeafPair ?
            {
              leaf_leaf_link_port_channel_id: this.portChannelId,
              leaf_leaf_l3_link_port_channel_id: this.portChannelIdExt
            } : {}
          )
        } :
        {}
      ),
      ...(this.isAccessSwitch ?
        {
          access_access_link_port_channel_id_min: this.portChannelId,
          access_access_link_port_channel_id_max: this.portChannelIdExt

        } :
        {}
      ),
      ...(this.isGeneric ?
        {
          port_channel_id_min: this.portChannelId,
          port_channel_id_max: this.portChannelIdExt
        } :
        {}
      ),
      ...(withInternals ? {
        internals: {
          name: this.name,
          relativePosition: toJS(this.relativePosition),
          isFirstInPair: this.isFirstInPair,
          portChannelId: this.portChannelId,
          portChannelIdExt: this.portChannelIdExt
        }
      } : {})
    };
  }
}

export default Node;
