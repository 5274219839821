import {useContext} from 'react';
import {Label} from 'semantic-ui-react';
import {map, get} from 'lodash';
import {ValueRenderer} from 'apstra-ui-common';

import IBAContext from './IBAContext';
import {NODE_ROLES} from './consts';

import {secondsRenderer, speedRenderer} from './commonRenderers';

function SystemInfo({systemId}) {
  const {systemIdMap, systemsHrefs} = useContext(IBAContext);
  const systemInfo = systemIdMap[systemId] ?? {};
  const {href, hostname, role} = systemInfo;
  const internalUrl = systemsHrefs ? systemsHrefs[systemId] : null;
  const systemIdHref = href ?? internalUrl;
  return [
    <div key='link'>
      {systemIdHref ?
        <a href={systemIdHref}>{systemId}</a> :
        systemId
      }
    </div>,
    hostname && <div key='hostname'>{hostname}</div>,
    role && <Label key='role' size='tiny'>{NODE_ROLES[role] ?? role}</Label>,
  ];
}

const systemIdRenderer = new ValueRenderer({
  condition: ({name}) => name === 'properties.system_id',
  Value: ({value: systemId}) => <SystemInfo systemId={systemId} />
});

const endpointRenderer = new ValueRenderer({
  condition: ({name}) => name === 'properties.endpoint',
  Value: ({value}) =>
    map((value || '').split('/'), (systemId, index) => <SystemInfo key={index} systemId={systemId} />)
});

const vniRenderer = new ValueRenderer({
  condition: ({name}) => name === 'properties.vni',
  Value: ({value}) => {
    const {generateVnLink, vnIdMap} = useContext(IBAContext);
    if (!value) return 'N/A';
    const vnName = vnIdMap[value];
    return (
      <>
        <div><a href={generateVnLink({'vn-id': [`${value}`]})}>{value}</a></div>
        {vnName &&
          <div>{vnName}</div>
        }
      </>
    );
  }
});

const vlanRenderer = new ValueRenderer({
  condition: ({name}) => name === 'properties.vlan',
  Value: ({value}) => {
    const {generateVnLink} = useContext(IBAContext);
    if (!value) return 'N/A';
    return (
      <a href={generateVnLink({'vlan-ids': [value]})}>{value}</a>
    );
  }
});

const subnetRenderer = new ValueRenderer({
  condition: ({name}) => name === 'properties.subnet',
  Value: ({value, item}) => {
    const {generateVnLink} = useContext(IBAContext);
    const addressFamily = get(item, ['properties', 'address_family']);
    const filter = {[`${addressFamily}-subnet`]: value};
    return (
      <a href={generateVnLink(filter)}>{value}</a>
    );
  }
});

export const stagePropertiesRenderers = [
  secondsRenderer,
  speedRenderer,
  systemIdRenderer,
  endpointRenderer,
  vniRenderer,
  vlanRenderer,
  subnetRenderer,
];
