import {observer} from 'mobx-react';
import cx from 'classnames';
import {filter, find, first, groupBy, keys, map, mapValues, some, transform, uniqBy, values} from 'lodash';
import {Fragment, useContext, useMemo} from 'react';
import {Label, List, ListItem, Message, Popup} from 'semantic-ui-react';

import IBAContext from '../IBAContext';
import {ReferenceDesignSchemaTooltip} from '../../graphExplorer/GraphReferenceDesignSchema';

import '../../graphExplorer/GraphPopup.less';
import {buildGraphNodesStyles} from '../../graphExplorer/utils';
import './NamedNodesView.less';

const ANY_NODE_LABEL = '-';

type NamedNode = {
  functionName: string,
  nodeType: string,
  nodeName: string
};

type NamedNodesViewProps = {
  namedNodes?: NamedNode[],
  errors?: any,
};

const rejectAlreadyNamedNodes = (nodes: NamedNode[] = []): NamedNode[] => values(
  transform(nodes, (acc, node) => {
    acc[node.nodeName] = acc[node.nodeName]?.nodeType ? acc[node.nodeName] : node;
  }, {})
);

export const NamedNodesView: React.FC<NamedNodesViewProps> = observer(({namedNodes, errors}) => {
  const {blueprintReferenceDesignSchema} = useContext(IBAContext);

  const nodesBySourceName = useMemo(
    () => mapValues(
      groupBy(
        rejectAlreadyNamedNodes(namedNodes),
        ({nodeType}) => nodeType ?? ANY_NODE_LABEL
      ),
      (nodes) => uniqBy(nodes, 'nodeName')
    ),
    [namedNodes]
  );

  const graphNodesStyles = useMemo(
    () => buildGraphNodesStyles(keys(blueprintReferenceDesignSchema?.nodes)),
    [blueprintReferenceDesignSchema]
  );

  const hasNodes = some(nodesBySourceName);
  return errors ? <Message error className='named-nodes-view-error' content='Query contains errors' /> : (
    <div className='named-nodes-view'>
      {hasNodes &&
        <>
          <div className='column-header'>{'Node Type'}</div>
          <div className='column-header'>{'Node Name'}</div>
        </>
      }
      {map(nodesBySourceName, (nodes, nodeType) => {
        const isNode = first(nodes)?.functionName === 'node';
        const hasTooltip = nodeType !== ANY_NODE_LABEL;
        const referenceDesignData = isNode ?
          blueprintReferenceDesignSchema?.nodes?.[nodeType] :
          find(blueprintReferenceDesignSchema?.relationships, {name: nodeType});
        const {color = null} = graphNodesStyles[nodeType] ?? {};
        return (
          <Fragment key={nodeType}>
            <div className='cell'>
              <Popup
                className='graph-popup'
                disabled={!hasTooltip}
                hoverable
                inverted={false}
                trigger={
                  <Label
                    className={cx('node-type', color ? `brand-palette-node-${color}` : 'grey')}
                    color='grey'
                  >
                    {nodeType}
                  </Label>
                }
              >
                {referenceDesignData ?
                  <ReferenceDesignSchemaTooltip
                    entity={{
                      id: nodeType,
                      nodeType,
                      sourceEntity: referenceDesignData
                    }}
                    highlightText=''
                  />
                :
                  <Message
                    warning
                    content={<><strong>{nodeType}</strong>{' was not found in reference design schema'}</>}
                  />
                }
              </Popup>
            </div>
            <div className='cell'>
              <List>
                {map(filter(nodes, 'nodeName'), (node) => (
                  <ListItem key={node.nodeName}>
                    <Label
                      className={cx('node-name', color ? `brand-palette-node-${color}` : null)}
                      color='grey'
                      title={node.nodeName}
                    >
                      {node.nodeName}
                    </Label>
                  </ListItem>
                ))}
              </List>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
});
