import {Text} from '@visx/text';
import {observer} from 'mobx-react';
import cx from 'classnames';

import Draggable from '../../eptBuilder/components/Draggable';

import './Zone.less';

const Zone = ({zone, x, y}) => {
  const {label, labelY, role, roleBased, topZone} = useZone(zone, y);
  const className = cx('rack-editor-zone', role);

  return (
    <g className={className}>
      {
        !zone.isLast &&
          <Draggable
            relative
            onMove={({y}) => (roleBased ? zone : topZone).resizeBy(y)}
          >
            <line
              className='outline'
              x1={-10000}
              x2={10000}
              y1={y}
              y2={y}
            />
            <line
              x1={-10000}
              x2={10000}
              y1={y}
              y2={y}
            />
          </Draggable>
      }
      {roleBased &&
        <Text
          x={20 - x}
          y={labelY}
        >
          {label}
        </Text>
      }
    </g>
  );
};

export default observer(Zone);

const useZone = (zone, y) => {
  const {isEmpty, isFirst, isLast, size, y: zy, label, nodesCount, role, roleBased, topZone} = zone;
  const labelY = (isEmpty || (!isFirst && !isLast)) ?
    (y + 5 - size / 2) :
    isLast ? zy + 30 : y - 30;

  const title = label + ((isEmpty || !roleBased) ? '' : ` (${nodesCount})`);

  return {label: title, labelY, role, roleBased, topZone};
};
