import {ActionsMenu, CopyButton, Markdown, ValidationErrors, onEnterKeyHandler} from 'apstra-ui-common';
import cx from 'classnames';
import {FC, useCallback, useMemo, useState} from 'react';
import {runInAction, set} from 'mobx';
import {observer} from 'mobx-react';
import {Popup, Header, Message, Label, Accordion, AccordionTitle, AccordionContent, Icon} from 'semantic-ui-react';
import {keys, findIndex, filter, forEach, pullAllWith, isMatch, values, map, find} from 'lodash';

import {Probe, ProbeProcessorType, ProcessorDefinition} from '../types';

import {ProbeDetailsProps} from './ProbeDetails/ProbeDetails';
import ProcessorInputEditor from './ProcessorInputEditor';
import ProcessorProperties from './ProcessorProperties';
import ProcessorModal from './ProcessorModal';
import ProcessorInputs from './ProcessorInputs';
import ProcessorIcon from './ProcessorIcon';
import {ScrollAnchor} from './ProbeDetails/ScrollContext';
import {ResourceModalMode} from './ProcessorModal/apstra-ui-common-types';

import './ProbeProcessor.less';

export function deleteProcessor({probe, processor, errors, setCurrentProcessorName}) {
  let previousProcessorName = null;
  forEach(probe.processors, ({name}, index) => {
    if (name === processor.name) {
      probe.processors.splice(index, 1);
      return false;
    }
    previousProcessorName = name;
  });
  pullAllWith(errors, [{processorName: processor.name}], isMatch);
  forEach(processor.outputs, (name) => {
    pullAllWith(probe.stages, [{name}], isMatch);
    pullAllWith(errors, [{stageName: name}], isMatch);
  });
  const removedStages = new Set(values(processor.outputs));
  for (const {inputs} of probe.processors) {
    for (const input of keys(inputs)) {
      if (removedStages.has(inputs[input]?.stage)) {
        set(inputs, input, {});
      }
    }
  }
  const nextProcessorName = previousProcessorName === null ?
    probe.processors.length ? probe.processors[0].name : null :
    previousProcessorName;
  setCurrentProcessorName(nextProcessorName);
}

type ProbeProcessorProps = {
  actionInProgress: boolean;
  active: boolean;
  editable?: boolean;
  errors?: any[];
  hasErrors: boolean;
  highlightStage: (stageName: string, highlight?: boolean) => void;
  probe: Probe;
  processor: ProbeProcessorType;
  processorDefinitionsByName: Record<string, ProcessorDefinition>;
  setCurrentProcessorName: (processorName: string | null) => void;
  telemetryServiceRegistryItems: ProbeDetailsProps['telemetryServiceRegistryItems'];
}

export const ProbeProcessor: FC<ProbeProcessorProps> = observer(({
  errors = [],
  actionInProgress,
  active,
  editable,
  hasErrors,
  highlightStage,
  probe,
  processor,
  processorDefinitionsByName,
  setCurrentProcessorName,
  telemetryServiceRegistryItems
}) => {
  const [processorModalMode, setProcessorModalMode] = useState<ResourceModalMode>();

  const processorIndex = useMemo(
    () => findIndex(probe.processors, {name: processor.name}),
    [probe.processors, processor.name]
  );

  const closeProcessorModalHandler = useCallback(() => {
    setProcessorModalMode(undefined);
  }, []);

  const deleteProcessorClickHandler = useCallback(
    () => runInAction(() => deleteProcessor({probe, processor, errors, setCurrentProcessorName})),
    [errors, probe, processor, setCurrentProcessorName]
  );

  const moveProcessorUpClickHandler = useCallback(() => runInAction(() => {
    [probe.processors[processorIndex - 1], probe.processors[processorIndex]] =
      [probe.processors[processorIndex], probe.processors[processorIndex - 1]];
  }), [probe.processors, processorIndex]);

  const moveProcessorDownClickHandler = useCallback(() => runInAction(() => {
    [probe.processors[processorIndex + 1], probe.processors[processorIndex]] =
      [probe.processors[processorIndex], probe.processors[processorIndex + 1]];
  }), [probe.processors, processorIndex]);

  const processorErrors = useMemo(
    () => filter(errors, {type: 'processor', processorName: processor.name}),
    [errors, processor.name]
  );

  const nameError = useMemo(
    () => find(errors, {type: 'processorName', processorName: processor.name}),
    [errors, processor.name]
  );

  const processorDefinition = processorDefinitionsByName[processor.name];
  const processorTypeLabel = (
    <Label className='processor-label' image>
      <ProcessorIcon processorType={processor.type} />
      {processorDefinition.label ?? processor.type}
      <CopyButton textToCopy={processorDefinition.label ?? processor.type} />
    </Label>
  );
  return (
    <div className='probe-processor'>
      <ScrollAnchor entityName='processor details' id={processor.name} />
      <div className='probe-processor-header-container'>
        {editable && [
          <ActionsMenu
            key='edit'
            size='mini'
            disabled={actionInProgress}
            items={[
              {
                icon: 'edit',
                title: 'Edit',
                onClick: () => setProcessorModalMode('update'),
              },
              {
                icon: 'clone',
                title: 'Clone',
                onClick: () => setProcessorModalMode('clone'),
              },
              {
                icon: 'trash',
                title: 'Delete',
                onClick: deleteProcessorClickHandler,
              },
            ]}
          />,
          <ActionsMenu
            key='reorder'
            size='mini'
            disabled={actionInProgress}
            items={[
              {
                icon: 'angle down',
                title: 'Move down',
                disabled: processorIndex === probe.processors.length - 1,
                onClick: moveProcessorDownClickHandler,
              },
              {
                icon: 'angle up',
                title: 'Move up',
                disabled: processorIndex === 0,
                onClick: moveProcessorUpClickHandler,
              },
            ]}
          />,
        ]}
        <Header className='probe-contents-header'>
          <span className='processor-title'>
            {'Processor:'}
            &nbsp;
            {processor.name}
            &nbsp;
          </span>
        </Header>
      </div>
      {nameError && <ValidationErrors className='error' errors={[nameError.message]} pointing='above' />}
      {editable && !!processorErrors.length &&
        <Message
          error
          icon='warning sign'
          header='Error'
          content={
            map(processorErrors, ({message}, index) =>
              <div key={index}>{message}</div>
            )
          }
        />
      }
      {!!processorModalMode && <ProcessorModal
        open
        onClose={closeProcessorModalHandler}
        mode={processorModalMode}
        probe={probe}
        processor={processor}
        errors={errors}
        onSuccess={({result: processorName}) => setCurrentProcessorName(processorName)}
      />}
      <Accordion fluid styled className={cx(hasErrors && 'has-errors')}>
        <AccordionTitle
          active={active}
          onClick={() => setCurrentProcessorName(active ? null : processor.name)}
          onKeyDown={onEnterKeyHandler(() => setCurrentProcessorName(active ? null : processor.name))}
          tabIndex={0}
        >
          <div className='item-title'>
            {processorDefinition.description ? (
              <Popup
                trigger={processorTypeLabel}
                content={<Markdown children={processorDefinition.description} />}
                position='top center'
                wide
              />
            ) : processorTypeLabel}
          </div>
          <Icon name={active ? 'angle down' : 'angle right'} size='large' />
        </AccordionTitle>
        {active &&
          <AccordionContent active>
            <div className='scrollable-content'>
              {editable &&
                <ProcessorInputEditor
                  probe={probe}
                  processor={processor}
                  highlightStage={highlightStage}
                  actionInProgress={actionInProgress}
                  errors={errors}
                  processorDefinitionsByName={processorDefinitionsByName}
                />
              }
              {!editable &&
                <ProcessorInputs
                  probe={probe}
                  processor={processor}
                  highlightStage={highlightStage}
                />
              }
              <ProcessorProperties
                probe={probe}
                processor={processor}
                processorDefinition={processorDefinition}
                editable={editable}
                actionInProgress={actionInProgress}
                highlightStage={highlightStage}
                errors={errors}
                telemetryServiceRegistryItems={telemetryServiceRegistryItems}
              />
            </div>
          </AccordionContent>
        }
      </Accordion>
    </div>
  );
});
