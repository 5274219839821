import {isFunction, isMatch, times} from 'lodash';

export default function matchTokenPattern({iterator, pattern, greedy = false}) {
  const tokens = [];
  pattern.reverse();
  do {
    for (const matcher of pattern) {
      let currentToken;
      do {
        currentToken = iterator.stepBackward();
        if (!currentToken) return null;
      } while (currentToken.type === 'text');
      if (isFunction(matcher) ? matcher(currentToken) : isMatch(currentToken, matcher)) {
        tokens.unshift(currentToken);
      } else if (!greedy) {
        return null;
      } else {
        times(tokens.length, () => iterator.stepForward());
        tokens.length = 0;
        break;
      }
    }
  } while (!tokens.length);
  return tokens;
}
