import {map, some, transform, uniqueId} from 'lodash';

import {ILink} from '../types';
import {ALL_NODE_ROLES, LINK_ROLES} from '../../../../roles';

function checkEndpointsInterfaceId(link: ILink): ILink {
  const updatedEndpoints = map(link.endpoints, (endpoint) => ({
    ...endpoint,
    if_id: endpoint.if_id ?? uniqueId('fake_id'),
    fakeInterface: !endpoint.if_id
  }));
  return {
    ...link,
    endpoints: updatedEndpoints,
    id: link.id ?? uniqueId('link_id_'),
  };
}

// filters out links for superspines
// checks that all interfaces has interface ids (generates new if there is no if_id)
export const processLinks = (links: ILink[], indexedNodes, topologySettings, selectedPlaneId): ILink[] => {
  return transform(links, (acc, link) => {
    const isGenericLink = link.role === LINK_ROLES.TO_GENERIC && !some(link.endpoints,
      (ep) => indexedNodes[ep.id]?.role === ALL_NODE_ROLES.EXTERNAL_GENERIC);
    if (!topologySettings.expandNodes && (isGenericLink || link.role === LINK_ROLES.LEAF_ACCESS)) {
      return;
    }

    if (!!selectedPlaneId || link.role !== LINK_ROLES.SPINE_SUPERSPINE) {
      const processedLink = checkEndpointsInterfaceId(link);
      acc.push(processedLink);
    }
  }, [] as ILink[]);
};
