import {some} from 'lodash';
import {Field, ValueRenderer} from 'apstra-ui-common';

import IntegerExpressionControl from './IntegerExpressionControl';

export default function IntegerExpressionInput({
  name, value, schema, required, disabled, errors, onChange, fieldProps, knownPythonExpressionVariables
}) {
  return (
    <Field
      label={schema?.title ?? name}
      description={schema?.description}
      required={required}
      disabled={disabled}
      errors={errors}
      {...fieldProps}
    >
      <IntegerExpressionControl
        disabled={disabled}
        value={value}
        onChange={onChange}
        knownPythonExpressionVariables={knownPythonExpressionVariables}
        type='expression'
      />
    </Field>
  );
}

export const integerExpressionRenderer = new ValueRenderer({
  condition: ({schema: {anyOf} = {}}) => anyOf && some(anyOf, {type: 'integer'}) && some(anyOf, {type: 'string'}),
  Value: ({value}) => value,
  ValueInput: IntegerExpressionInput
});
