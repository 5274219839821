import {Markdown} from 'apstra-ui-common';
import {FC} from 'react';

type MarkdownDescriptionProps = {
  description: string;
}

export const MarkdownDescription: FC<MarkdownDescriptionProps> = ({
  description
}) => {
  return <Markdown>{description}</Markdown>;
};
