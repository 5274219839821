import cx from 'classnames';
import {castArray, every, filter, map, size} from 'lodash';

import {Tags} from './ValueRenderers';
import {TooltipItem} from './TooltipItem';

import './LinkTooltipContent.less';

const NodeWithInterface = ({endpoint, isOnRight}) => {
  const endpoints = castArray(endpoint);
  const endpointsCount = endpoints.length;
  const classNames = cx('node-internals', {
    right: isOnRight
  });

  const firstSystem = endpoints[0];
  const isOnePointEndpoints = every(endpoints, (ep) => ep.system?.id === firstSystem.system?.id);

  return endpointsCount === 1 ? (
    <div className='node'>
      <div className={classNames}>
        <div className='interface-data'>
          <div>{firstSystem.system?.label ?? firstSystem.label}</div>
        </div>
        <div className='interface'>
          <i>{firstSystem.interface?.if_name ?? firstSystem.if_name}</i>
        </div>
      </div>
    </div>
  ) : (
    <div className='node'>
      <div className={classNames}>
        <div className='interface-data'>
          <div>{isOnePointEndpoints ? firstSystem.system?.label : endpointsCount}</div>
        </div>
      </div>
    </div>
  );
};

function adminStateName(operationState) {
  let adminState;
  switch (operationState) {
  case 'up': adminState = 'Up'; break;
  case 'admin_down': adminState = 'Admin Down'; break;
  case 'deduced_down': adminState = 'Deduced Down'; break;
  }
  return adminState;
}

const EndpointInfo = ({endpoint, index}) => {
  const label = endpoint.label || endpoint.system?.label;
  const interfaceName = endpoint.if_name || endpoint.interface?.if_name;
  const tags = endpoint.tags || endpoint.interface?.tags;
  const operationState = endpoint.operation_state;
  const isDown = endpoint.operation_state !== 'up';
  return (label || interfaceName) ? (
    <div className='tooltip-group'>
      <div><em>{`Endpoint ${index}`}</em></div>
      <TooltipItem label='Name' value={label} />
      <TooltipItem label='Interface' value={interfaceName} />
      {isDown && <TooltipItem label='Admin state' value={adminStateName(operationState)} />}
      {tags && <TooltipItem label='Tags' value={<Tags tags={tags} />} />}
    </div>
  ) : null;
};

export const LinkTooltipContent = ({data}) => {
  const aggregatedLinksCount = size(data?.member_link_ids);
  const isEndpointsPair = data?.endpoints?.length === 2;
  return (
    <>
      <div className='link-tooltip-label'>{data.label || 'Link'}</div>
      <div className='tp-link-tooltip'>
        <NodeWithInterface
          endpoint={isEndpointsPair ? data?.endpoints[0] : filter(data?.endpoints, {endpoint_group: 0})}
        />
        <div>
          {!!aggregatedLinksCount && <div className='lag-counter'>{aggregatedLinksCount}</div>}
          <div>
            <hr />
          </div>
        </div>
        <NodeWithInterface
          isOnRight
          endpoint={isEndpointsPair ? data?.endpoints[1] : filter(data?.endpoints, {endpoint_group: 1})}
        />
      </div>
      <TooltipItem label='Tags' value={<Tags tags={data.tags} />} />
      <TooltipItem hideEmpty label='Speed' value={data.speed} />
      <TooltipItem hideEmpty label='Rail Index' value={data.railIndex} />
      <TooltipItem hideEmpty label='Port Channel ID' value={data.port_channel_id} />
      <div className={cx('endpoints', {pair: isEndpointsPair})}>
        {map(data.endpoints, (endpoint, index) => <EndpointInfo key={index} index={index + 1} endpoint={endpoint} />)}
      </div>
    </>
  );
};
