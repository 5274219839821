import cx from 'classnames';
import {FC, useMemo, useState} from 'react';
import {Group} from '@visx/group';
import {bin, range, sum, format} from 'd3';
import {clamp, max, map, isUndefined, min, isEmpty, first, last, merge} from 'lodash';
import {useResizeDetector} from 'react-resize-detector';
import {Axes, AxisProps, AxesConsumerFn} from 'apstra-ui-common';

import ChartPopup from './ChartPopup';

import './HistogramChart.less';

type HistogramChartProps = {
  axes: Record<'x' | 'y', AxisProps>;
  data: [];
  value;
  y;
  processPopupContent;
  processPopupHeader;
  mode: 'compact' | 'expanded';
  dimensions: Record<HistogramChartProps['mode'], {height: number, numTicksRows: number}>;
  width?: number;
  className?: string;
  noDataMessage?: string;
  gap: number;
};

type bin = {
  x0: number;
  x1: number;
}

const HistogramChart: FC<HistogramChartProps> = ({
  axes, data, value, y, processPopupContent, processPopupHeader,
  mode, width: widthProp, className, dimensions, noDataMessage, gap
}) => {
  const [popupDescription, setPopupDescription] = useState<unknown>(null);
  const hidePopup = () => setPopupDescription(null);

  const {width: parentWidth = widthProp || 500, ref: containerRef} = useResizeDetector({handleHeight: false});
  const chartWidth = isUndefined(widthProp) ? parentWidth : widthProp;

  const {height: chartHeight, numTicksRows: chartNumTicksRows} = dimensions[mode];
  const isExpanded = mode === 'expanded';
  const showTicks = chartWidth > 0;

  const [bins, yAxis, chartNumTicksColumns] = useMemo(() => {
    const xAxis = map(data, value);
    const y0Axis = map(data, y);
    const xRange = range(xAxis.length);

    const setValues = new Set(xAxis);
    const xMaxValue = max(xAxis) ?? 0;
    const xMinValue = min(xAxis) ?? 0;
    const chartNumTicksColumns = clamp(
      max([xMaxValue - xMinValue, setValues.size]) as number,
      1,
      10
    );

    const bins: bin[] = bin()
      .thresholds(xMaxValue - xMinValue)
      .value((i) => xAxis[i])(xRange)
      .filter(({x0, x1}) => !isUndefined(x0) && !isUndefined(x1));

    return [
      bins,
      Array.from(bins, (i) => sum(i, (j) => y0Axis[j])),
      chartNumTicksColumns,
    ];
  }, [data, value, y]);

  const yMaxValue = max(yAxis) ?? 0;
  const numTicksColumns = showTicks && isExpanded ? chartNumTicksColumns : 0;
  const numTicksRows = showTicks ? clamp(Math.abs(yMaxValue), 1, chartNumTicksRows) : 0;

  if (isEmpty(data)) {
    return (
      <div className='histogram-no-data'>{noDataMessage}</div>
    );
  }

  const [firstBinX0, lastBinX0] = [first(bins)?.x0 ?? 0, last(bins)?.x0 ?? 0];
  const axesProps = {
    width: chartWidth,
    height: chartHeight,
    x: merge(
      {
        isLinear: true,
        axisOnZero: true,
        formatLabel: format('.0f')
      },
      axes?.x,
      {
        label: isExpanded ? axes?.x?.label : null,
        ticks: numTicksColumns,
        values: [firstBinX0 - 0.5, lastBinX0 + 0.5]
      }
    ),
    y: merge(
      {
        label: 'Frequency',
        isLinear: true
      },
      axes?.y,
      {
        ticks: numTicksRows,
        values: [0, yMaxValue]
      }
    )
  };

  return (
    <div
      ref={containerRef}
      className={cx('histogram-chart', 'graph-container', {expandable: !isExpanded})}
    >
      <svg className={cx('histogram-chart-layout', className)} width={chartWidth} height={chartHeight}>
        <Axes {...axesProps}>
          {
            ((xScale, yScale) => {
              const barWidth = Math.ceil(
                (xScale(lastBinX0) - xScale(firstBinX0)) / (lastBinX0 - firstBinX0)
              ) || xScale(firstBinX0);

              const xOffset = barWidth / 2.0;

              return (
                <Group className='bars' onMouseLeave={hidePopup}>
                  {map(bins, ({x0, x1, ...indexList}, index) => (
                    <rect
                      key={index}
                      x={xScale(x0) - xOffset}
                      width={barWidth - gap}
                      y={yScale(yAxis[index])}
                      height={yScale(0) - yScale(yAxis[index])}
                      onMouseEnter={(e) => setPopupDescription({
                        node: e.target,
                        header: processPopupHeader(x0, yAxis[index]),
                        content: processPopupContent(indexList, x0, x1),
                      })}
                    />
                  ))}
                </Group>
              );
            }) as AxesConsumerFn
          }
        </Axes>
        <ChartPopup popupDescription={popupDescription} />
      </svg>
    </div>
  );
};

HistogramChart.defaultProps = {
  mode: 'compact',
  dimensions: {
    compact: {
      height: 60,
      numTicksRows: 4
    },
    expanded: {
      height: 300,
      numTicksRows: 10
    },
  },
  y: () => 1,
  processPopupHeader: (x, y) => `${x}, ${y}`,
  processPopupContent: (indexList) => indexList,
  noDataMessage: 'No Data',
  gap: 0
};

export default HistogramChart;
