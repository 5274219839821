import {get, map, startCase} from 'lodash';
import {Fragment} from 'react';
import {Value} from 'apstra-ui-common';

export const TelemetryServiceStatus = (props) => {
  const {warning} = props;
  const warningType = warning ? warning.type : null;
  if (warningType === 'invalid_service') {
    return (
      <>
        <div><strong>{'Invalid service'}</strong></div>
        <div>{warning.message}</div>
      </>
    );
  } else if (warningType === 'conflict') {
    return (
      <>
        <div><strong>{'Conflict'}</strong></div>
        {map(['input', 'interval', 'execution_count'], (property) => {
          const expected = get(warning, ['expected', property], null);
          const actual = get(warning, ['actual', property], null);
          return expected !== actual ? (
            <Fragment key={property}>
              <div>{`Expected ${startCase(property)}: `}<b><Value value={expected} /></b></div>
              <div>{`Actual ${startCase(property)}: `}<b><Value value={actual} /></b></div>
            </Fragment>
          ) : null;
        })}
      </>
    );
  }
  return 'No warnings';
};
