import {Component} from 'react';
import {Icon, Label} from 'semantic-ui-react';
import {Field, ValueRenderer} from 'apstra-ui-common';

import TagsControl from './TagsControl';
import Tags from './Tags';

export default class TagsInput extends Component {
  static defaultProps = {
    allowNewTags: true,
  };

  renderLabel = ({text: tag}) => (
    <Label>
      <Icon name='tag' />
      {tag}
      <Icon link name='delete' onClick={() => this.removeTag(tag)} aria-label='Remove' />
    </Label>
  );

  render() {
    const {
      name, value, schema, required, disabled, errors, open, loading,
      onChange, placeholder, knownTags, allowNewTags, fieldProps,
    } = this.props;
    return (
      <Field
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
        errors={errors}
        {...fieldProps}
      >
        <TagsControl
          open={open}
          value={value}
          loading={loading}
          placeholder={placeholder}
          knownTags={knownTags}
          allowNewTags={allowNewTags}
          onChange={onChange}
        />
      </Field>
    );
  }
}

export const tagsRenderer = new ValueRenderer({
  condition: ({name}) => name === 'tags',
  Value: Tags,
  ValueInput: TagsInput,
});
