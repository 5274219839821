import {FC, useCallback, useMemo, useState} from 'react';
import cx from 'classnames';
import {keys, without, map, min, head, last, isUndefined, values, first, size, flatten, omit, groupBy,
  sum} from 'lodash';
import {Group} from '@visx/group';
import {BarGroup} from '@visx/shape';
import {AnyScaleBand, GroupKey} from '@visx/shape/lib/types';
import {useResizeDetector} from 'react-resize-detector';
import {easeExpOut, scaleBand, scaleOrdinal} from 'd3';
import {brandColorNames, formatNumber, Axes, AxisProps, AxesProps, AxesConsumer,
  AxesConsumerFn} from 'apstra-ui-common';
import {animated, Spring, SpringConfig} from '@react-spring/web';

import ChartPopup from './ChartPopup';
import ChartLegend from './ChartLegend';

import './BarGroupChart.less';

type BarGroupChartContentProps = Omit<BarGroupChartProps, 'mode' | 'dimensions'> & {
  groups: GroupKey[];
  valueKeys: number[] | string[];
  colorScale: (key: GroupKey, index: number) => string;
} & AxesConsumer;

const BarGroupChartContent: FC<BarGroupChartContentProps> = (props) => {
  const {
    axes, xScale, yScale, samples, groups, valueKeys, colorScale, padding, springConfig,
    groupLabelKey, processPopupHeader, processPopupContent, showZeroBars, stackBars
  } = props;

  const [popupDescription, setPopupDescription] = useState<unknown>(null);
  const hidePopup = () => setPopupDescription(null);

  const showPopup = useCallback(
    ({target}, barGroup) => {
      setPopupDescription({
        node: target.parentNode,
        header: processPopupHeader(groups[barGroup.index]),
        content: processPopupContent(barGroup.bars, axes?.y?.units),
      });
    },
    [axes?.y?.units, groups, processPopupContent, processPopupHeader]
  );

  const groupsCount = size(groups) || 1;
  const y0 = yScale(0);
  const [, yMax] = yScale.range();

  let boxSize = 0;
  if (!axes?.x?.isLinear) {
    boxSize = (xScale as AnyScaleBand).bandwidth();
  } else if (axes?.x?.isTimestamp) {
    const domain = xScale.domain();
    const startTime = head(domain);
    const endTime = last(domain);
    boxSize = (xScale(endTime) - xScale(startTime)) / groupsCount;
  }

  const barScale = scaleBand().domain(valueKeys).padding(padding).rangeRound([0, boxSize]);

  return (
    <>
      <BarGroup
        data={samples || []}
        keys={valueKeys}
        height={yMax}
        x0={(item) => item[groupLabelKey]}
        x0Scale={xScale as AnyScaleBand}
        x1Scale={barScale}
        yScale={yScale}
        color={colorScale}
      >
        {(barGroups) => {
          return map(barGroups, (barGroup) => {
            const stacks = {positive: 0, negative: 0};
            const firstBar = head(barGroup.bars) || {x: 0};
            const lastBar = last(barGroup.bars) || {x: 0, width: 0};
            const singleBarWidth = lastBar.x + lastBar.width - firstBar.x;
            return (
              <Group
                key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                left={barGroup.x0}
                onMouseEnter={(e) => showPopup(e, barGroup)}
                onMouseLeave={hidePopup}
              >
                {map(barGroup.bars, (bar) => {
                  let y: number, height: number;
                  if (bar.value > 0) {
                    // Positive bars
                    y = bar.y + (stackBars ? stacks.positive : 0);
                    height = bar.height - yMax + y0;
                    stacks.positive = y - y0;
                  } else {
                    // Negative bars
                    y = y0 + (stackBars ? stacks.negative : 0);
                    height = yMax - bar.height - y0;
                    stacks.negative = y + height - y0;
                  }

                  // Make zero-sized bars visible if showZeroBars is true
                  if (showZeroBars && height === 0) {
                    y -= 1;
                    height = 1;
                  }

                  return (
                    <Spring
                      key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                      from={{y: y0, height: 0}}
                      to={[{y, height}]}
                      config={springConfig}
                    >
                      {({y, height}) => (
                        <>
                          <animated.rect
                            className={cx('bar', bar.color)}
                            x={stackBars ? firstBar.x : bar.x}
                            y={y}
                            width={stackBars ? singleBarWidth : bar.width}
                            height={height}
                            rx={stackBars ? 0 : 4}
                          />
                          {!stackBars &&
                            <circle
                              className={cx('circle', bar.color)}
                              cx={bar.x + bar.width / 2}
                              cy={bar.y}
                              r={min([bar.width / 4, 7])}
                            />
                          }
                        </>
                      ) as JSX.Element}
                    </Spring>
                  );
                })}
              </Group>
            );
          });
        }}
      </BarGroup>
      <ChartPopup popupDescription={popupDescription} />
    </>
  );
};

type BarGroupChartProps = {
  mode: 'compact' | 'expanded';
  width?: number;
  dimensions: Record<BarGroupChartProps['mode'], {height: number}>;
  samples: any[];
  className?: string;
  groupLabelKey: string;
  colors: string[];
  stackBars?: boolean;
  processPopupHeader: (value: GroupKey) => string;
  processPopupContent: (items: any[], units?: string) => string | JSX.Element;
  axes: Record<'x' | 'y', AxisProps>;
  /* eslint-disable react/no-unused-prop-types */
  padding: number;
  springConfig: SpringConfig;
  showZeroBars?: boolean;
  /* eslint-enable */
}

const BarGroupChart: FC<BarGroupChartProps> = (props) => {
  const {axes, mode, samples, className, width: widthProp, dimensions, groupLabelKey, colors, stackBars,
    processPopupHeader, processPopupContent} = props;

  const {width: parentWidth = widthProp, ref: targetRef} = useResizeDetector({handleHeight: false});
  const chartWidth = isUndefined(widthProp) ? parentWidth : widthProp;
  const {height: chartHeight} = dimensions[mode];

  const groups = useMemo(() => map(samples, groupLabelKey), [groupLabelKey, samples]);

  const bars = useMemo(
    () => flatten(
      map(
        samples,
        (group) => {
          const bars = values(omit(group, groupLabelKey)) as number[];
          const upAndDown = groupBy(bars, (value) => value > 0);
          const [above, below] = [[0, ...(upAndDown?.true ?? [])], [0, ...(upAndDown?.false ?? [])]];
          return stackBars ? [sum([0, ...below]), sum([0, ...above])] : [...below, ...above];
        }
      )
    ),
    [groupLabelKey, samples, stackBars]
  );

  const valueKeys = useMemo(() => without(keys(first(samples)), groupLabelKey), [groupLabelKey, samples]);
  const colorScale = useMemo(() => scaleOrdinal().domain(valueKeys).range(colors), [colors, valueKeys]);

  const axesWithDefaults = {
    x: {
      ...axes?.x,
      axisOnZero: true,
      values: groups
    },
    y: {
      isLinear: true,
      ...axes?.y,
      values: bars
    }
  };

  const axesProps: Omit<AxesProps, 'children'> = {
    width: chartWidth,
    height: chartHeight,
    ...axesWithDefaults,
    clip: false
  };

  const extraProps = {
    groups, bars, valueKeys, colorScale, processPopupHeader, processPopupContent, axes: axesWithDefaults
  };

  const classNames = cx('bar-group-chart', 'graph-container', {
    expandable: mode !== 'expanded',
    stacked: stackBars,
    linear: axes?.x?.isLinear
  });

  return (
    <div
      ref={targetRef}
      className={classNames}
    >
      <svg className={cx('bar-group-chart-layout', className)} width={chartWidth} height={chartHeight}>
        {chartWidth as number > 0 &&
          <Axes {...axesProps}>
            {
              ((xScale, yScale) => <BarGroupChartContent {...props} {...extraProps} {...{xScale, yScale}} />) as
                AxesConsumerFn
            }
          </Axes>
        }
      </svg>
      <ChartLegend ordinalColorScale={colorScale} horizontal />
    </div>
  );
};

BarGroupChart.defaultProps = {
  mode: 'expanded',
  dimensions: {
    compact: {
      height: 100
    },
    expanded: {
      height: 400
    },
  },
  processPopupHeader: (groupName: GroupKey) => groupName as string,
  processPopupContent: (items, units) => <BarGroupPopupContent items={items} units={units} />,
  groupLabelKey: 'group',
  colors: brandColorNames,
  padding: 0.2,
  springConfig: {
    duration: 1000,
    easing: easeExpOut
  }
};

const BarGroupPopupContent = ({items, units}) => (
  <>
    {
      map(items, ({color, value, key}) => (
        <div key={key} className='bar-group-popup'>
          <div className={cx('legend', color)} />
          {key}
          {' '}
          {units ?
            `${formatNumber(value, {units, short: true})} (${value}${units})` :
            value
          }
        </div>
      ))
    }
  </>
);

export default BarGroupChart;
