import {Component, Fragment, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Form, Header, Segment} from 'semantic-ui-react';
import {observable, action, makeObservable, toJS, reaction, computed} from 'mobx';
import {observer} from 'mobx-react';
import moment from 'moment';
import {map, isNumber, range, findIndex, sortBy, forEach, slice, reverse, flatten, find, has, isNull, clamp,
  size} from 'lodash';
import {
  DurationInput, FetchData, FetchDataError, Loader, TimelineGraphContainer, shortTickNumber,
  request, MultipleLineChart, brandColorNames, StackedChart, DropdownControl, formatNumber, interpolateRoute,
} from 'apstra-ui-common';
import pluralize from 'pluralize';

import AggregationInput from './AggregationInput';
import DonutChart from './graphs/DonutChart';
import BarGroupChart from './graphs/BarGroupChart';
import humanizeString from '../humanizeString';

import './MetricQuery.less';

const routes = {
  metricQuery: '/api/qba/query',
  nodeDetail: '/api/cluster/nodes/<nodeId>',
};
const GRAPH_MODE = {
  LINEAR: 'linear',
  STACKED: 'stacked',
};
const VALUE_KEY = {
  CPU: 'cpu',
  MEMORY: 'memory',
};
const VALUE_UNITS = {
  [VALUE_KEY.CPU]: '%',
  [VALUE_KEY.MEMORY]: 'B',
};
const LEVEL = {
  AGENT: 'agent',
  CONTAINER: 'container',
  NODE: 'node',
};
const allOption = {key: 'all', text: 'All', value: 'all'};
const levelList = [LEVEL.NODE, LEVEL.CONTAINER, LEVEL.AGENT];
const getGroupKey = ({level, item, indexMap, nodes}) => {
  const {nodeIndex, containerIndex, agentIndex} = indexMap;
  const nodeLabel = getNodeLabel(item, nodeIndex, nodes);
  return level === LEVEL.AGENT ?
    `${item[containerIndex]} - ${item[agentIndex]}` :
    level === LEVEL.CONTAINER ?
      `${nodeLabel} - ${item[containerIndex]}` :
      `${nodeLabel}`;
};
const getLabelByLevel = (item, level, {agentIndex, containerIndex, nodeIndex}, nodes) => level === LEVEL.AGENT ?
  item[agentIndex] :
  level === LEVEL.CONTAINER ?
    item[containerIndex] :
    getNodeLabel(item, nodeIndex, nodes);
const transformFilters = (filters) => {
  const conditions = map(filters, (value, key) => ['=', key, value]);
  if (conditions.length > 1) return ['and', ...conditions];
  return flatten(conditions);
};
const getNodeLabel = (item, nodeIndex, nodes) => {
  const nodeId = item[nodeIndex];
  if (nodeId in nodes && nodes[nodeId].label) {
    return nodes[nodeId].label;
  }
  return nodeId;
};

const MINUTE_IN_SECONDS = 60;
const HOUR_IN_SECONDS = MINUTE_IN_SECONDS * 60;
const DAY_IN_SECONDS = HOUR_IN_SECONDS * 24;
const AGGREGATIONS = [
  {text: '1 Minute', value: MINUTE_IN_SECONDS},
  {text: '2 Minutes', value: MINUTE_IN_SECONDS * 2},
  {text: '5 Minutes', value: MINUTE_IN_SECONDS * 5},
  {text: '1 Hour', value: HOUR_IN_SECONDS},
  {text: '12 Hours', value: HOUR_IN_SECONDS * 12},
  {text: '1 Day', value: DAY_IN_SECONDS},
  {text: '7 Days', value: DAY_IN_SECONDS * 7},
];

@observer
export default class MetricQuery extends Component {
  static propTypes = {
    metricName: PropTypes.string.isRequired,
    valueKey: PropTypes.string,
    level: PropTypes.string,
    withLabels: PropTypes.bool,
  };

  static defaultProps = {
    level: LEVEL.AGENT,
  };

  constructor(props) {
    super(props);
    makeObservable(this);
    this.disposeValueKeyReaction = reaction(
      () => this.props.valueKey,
      () => {
        this.valueKey = this.props.valueKey || VALUE_KEY.MEMORY;
      },
      {fireImmediately: true}
    );
  }

  componentWillUnmount() {
    this.disposeValueKeyReaction();
  }

  static async fetchData({timeRange, aggregationInterval, valueKey, metricName, level, filters, signal, previousData}) {
    if (isNumber(timeRange)) {
      timeRange = {
        start: moment().add(-timeRange, 's').toISOString(),
        end: moment().toISOString()
      };
    }

    const selectedKeys = {
      [LEVEL.NODE]: LEVEL.NODE,
    };
    if (level === LEVEL.AGENT || level === LEVEL.CONTAINER) {
      selectedKeys[LEVEL.CONTAINER] = LEVEL.CONTAINER;
    }
    if (level === LEVEL.AGENT) {
      selectedKeys[LEVEL.AGENT] = LEVEL.AGENT;
    }

    const filter = transformFilters(filters);
    const querySource = {
      from: {
        source: 'query',
        locator: metricName,
      },
      select: {
        keys: selectedKeys,
        data: {
          trend: [
            'slr',
            valueKey,
          ],
          [valueKey]: valueKey,
        }
      },
    };
    if (filter.length) querySource.filter = filter;

    const body = [
      {
        from: {
          source: 'metricdb',
          between: {
            begin: timeRange.start,
            end: timeRange.end,
            aggregation_interval: aggregationInterval,
          },
          locator: [
            'cluster_health_info',
            level,
            'utilization',
          ]
        },
        name: metricName,
        select: {
          keys: selectedKeys,
          data: {
            [valueKey]: valueKey,
          }
        }
      },
      querySource,
    ];

    const requests = [
      request(
        routes.metricQuery,
        {method: 'POST', body: JSON.stringify(body), signal},
      )
    ];

    const nodeId = find(filters, (value, key) => key === LEVEL.NODE);
    if (has(previousData, ['containers', nodeId])) {
      requests.push(Promise.resolve(previousData.containers));
    } else if (nodeId) {
      requests.push(new Promise((resolve, reject) => {
        request(interpolateRoute(routes.nodeDetail, {nodeId}))
          .then((nodeDetail) => {
            const containers = previousData?.containers || {};
            containers[nodeId] = map(nodeDetail.containers, 'name');
            resolve(containers);
          })
          .catch((e) => reject(e));
      }));
    }

    const [data, containers] = await Promise.all(requests);
    return {...data, containers};
  }

  @observable timeRange = DAY_IN_SECONDS;
  @observable aggregationInterval = 3600;
  @observable lineGraphMode = GRAPH_MODE.LINEAR;
  @observable level = this.props.level;
  @observable filters = this.props.filters || {};
  @observable valueKey;

  @action
  setTimeRange = (newTimeRange) => {
    if (isNull(newTimeRange)) return;
    const startTime = isNumber(newTimeRange) ? moment().add(-newTimeRange, 's').toISOString() : newTimeRange.start;
    const endTime = !newTimeRange.end ? moment().toISOString() : newTimeRange.end;
    const timeInterval = (+new Date(endTime) - +new Date(startTime)) / 1000;
    if (this.aggregationInterval > timeInterval) {
      for (let i = AGGREGATIONS.length - 1; i >= 0; i--) {
        if (AGGREGATIONS[i].value <= timeInterval) {
          this.aggregationInterval = AGGREGATIONS[i].value;
          break;
        }
      }
    }
    this.timeRange = newTimeRange;
  };

  @action
  setAggregationInterval = (value) => {
    this.aggregationInterval = value;
  };

  @action
  setGraphMode = (mode) => {
    this.lineGraphMode = mode;
  };

  @action
  setLevel = (level) => {
    this.level = level;
    if (level === LEVEL.NODE) this.resetFilters();
  };

  @action
  setValueKey = (valueKey) => {
    this.valueKey = valueKey;
  };

  @action
  updateFilters = (value, level) => {
    const filters = {...this.filters};
    if (value === 'all') {
      delete filters[level];
      if (level === LEVEL.NODE) delete filters[LEVEL.CONTAINER];
    } else {
      filters[level] = value;
    }
    this.filters = filters;
  };

  @action
  resetFilters = () => {
    const {filters = {}} = this.props;
    this.filters = filters;
  };

  @computed
  get nodeOptions() {
    const {nodes} = this.props;
    return [
      allOption,
      ...map(nodes, ({id, label}) => ({
        key: id, value: id, text: label,
      })),
    ];
  }

  render() {
    const {
      timeRange, aggregationInterval, setAggregationInterval, setTimeRange, lineGraphMode,
      setGraphMode, level, setLevel, valueKey, setValueKey,
    } = this;
    const {metricName, filters: filtersProp = {}, nodes, withLabels} = this.props;
    const units = VALUE_UNITS[valueKey];

    return (
      <FetchData
        fetchData={MetricQuery.fetchData}
        fetchParams={{
          timeRange: toJS(timeRange),
          aggregationInterval,
          valueKey,
          metricName,
          level,
          filters: this.filters,
        }}
        pollingInterval={null}
        customLoader
      >
        {({items, status, containers, loaderVisible, fetchDataError}) =>
          <Fragment>
            <Form>
              {withLabels && (
                <Form.Group className='metric-query labels'>
                  {!this.props.valueKey && (
                    <Form.Field width={3} label='Select a metric' />
                  )}
                  <Form.Field width={6} label='Select a monitoring duration' />
                  <Form.Field width={this.props.valueKey ? 5 : 4} label='Select an aggregation period' />
                  <Form.Field width={this.props.valueKey ? 5 : 3} label='Select the hierarchy level' />
                </Form.Group>
              )}
              <Form.Group className='metric-query form-controls'>
                {!this.props.valueKey && (
                  <Form.Field width={3}>
                    <DropdownControl
                      selectedValueLabel='Metric: '
                      options={map(VALUE_KEY, (value) => ({
                        key: value, text: value, value,
                      }))}
                      value={valueKey}
                      onChange={setValueKey}
                      disabled={loaderVisible}
                    />
                  </Form.Field>
                )}
                <Form.Field width={6}>
                  <DurationInput
                    value={timeRange}
                    disabled={loaderVisible}
                    customValueType='dates'
                    onChange={setTimeRange}
                  />
                </Form.Field>
                <Form.Field width={this.props.valueKey ? 5 : 4}>
                  <AggregationInput
                    aggregations={AGGREGATIONS}
                    value={aggregationInterval}
                    selectedValueLabel='Aggregation: '
                    onChange={setAggregationInterval}
                    disabled={loaderVisible}
                  />
                </Form.Field>
                <Form.Field width={this.props.valueKey ? 5 : 3}>
                  <DropdownControl
                    selectedValueLabel='Level: '
                    options={map(levelList, (value) => ({
                      key: value, text: value, value,
                    }))}
                    value={level}
                    onChange={setLevel}
                    disabled={loaderVisible}
                  />
                </Form.Field>
              </Form.Group>
              {withLabels && (
                <Form.Group className='metric-query labels'>
                  {!filtersProp[LEVEL.NODE] &&
                    <Form.Field width={4} label='Select a node' />
                  }
                  {level !== LEVEL.NODE &&
                    <Form.Field width={4} label='Select a container' />
                  }
                </Form.Group>
              )}
              <Form.Group className='metric-query form-controls'>
                {!filtersProp[LEVEL.NODE] &&
                  <Form.Field width={4}>
                    <DropdownControl
                      selectedValueLabel='Node: '
                      options={this.nodeOptions}
                      value={this.filters[LEVEL.NODE] || allOption.key}
                      onChange={(value) => this.updateFilters(value, LEVEL.NODE)}
                      disabled={loaderVisible}
                    />
                  </Form.Field>
                }
                {level !== LEVEL.NODE &&
                  <Form.Field width={4}>
                    <DropdownControl
                      selectedValueLabel='Container: '
                      options={[
                        allOption,
                        ...(containers ?
                            map(containers[this.filters[LEVEL.NODE]], (value) => ({key: value, text: value, value})) :
                            []
                        )
                      ]}
                      value={this.filters[LEVEL.CONTAINER] || allOption.key}
                      onChange={(value) => this.updateFilters(value, LEVEL.CONTAINER)}
                      disabled={loaderVisible || !this.filters[LEVEL.NODE]}
                    />
                  </Form.Field>
                }
              </Form.Group>
            </Form>
            {loaderVisible ?
              <Loader />
              : fetchDataError ?
                <FetchDataError error={fetchDataError} />
                :
                <MetricQueryGraph
                  items={items}
                  status={status}
                  units={units}
                  valueKey={valueKey}
                  aggregationInterval={aggregationInterval}
                  mode={lineGraphMode}
                  setGraphMode={setGraphMode}
                  level={level}
                  useCurrentTimeAsTimelineEnd={!timeRange.end}
                  nodes={nodes}
                  filters={this.filters}
                />
            }
          </Fragment>
        }
      </FetchData>
    );
  }
}

const MetricQueryGraph = ({
  items, status, valueKey, units, aggregationInterval, itemSamplesPath, valueKeyName,
  topCount, mode, setGraphMode, level, useCurrentTimeAsTimelineEnd, nodes, filters,
}) => {
  const popupContentItemKeys = [level];
  const LineGraphComponent = mode === GRAPH_MODE.LINEAR ?
    MultipleLineChart :
    StackedChart;
  const header = filters[LEVEL.NODE] && level === LEVEL.NODE ?
    nodes[filters[LEVEL.NODE]].label || filters[LEVEL.NODE] :
    filters[LEVEL.CONTAINER] && level === LEVEL.CONTAINER ?
      filters[LEVEL.CONTAINER] :
        `Top ${topCount} ${pluralize(level, topCount)}`;

  const aggregationText = useMemo(() => {
    const aggregation = find(AGGREGATIONS, ({value}) => value === aggregationInterval);
    if (aggregation) {
      return aggregation.text;
    }
    return aggregationInterval;
  }, [aggregationInterval]);

  const modeOptions = useMemo(() => map(GRAPH_MODE, (value) => ({
    key: value, text: humanizeString(value), value,
  })), []);

  const indexMap = useMemo(() => {
    const {columns} = status;
    const dataIndex = findIndex(columns, ([key]) => key === valueKey);
    const nodeIndex = findIndex(columns, ([key]) => key === 'node');
    const containerIndex = findIndex(columns, ([key]) => key === 'container');
    const agentIndex = findIndex(columns, ([key]) => key === 'agent');
    const trendGradientIndex = findIndex(columns, ([key]) => key === 'trend|gradient');
    return {dataIndex, nodeIndex, containerIndex, agentIndex, trendGradientIndex};
  }, [status, valueKey]);

  const timestamps = useMemo(() => {
    const {begin_time: begin, end_time: end} = status;
    const endTime = useCurrentTimeAsTimelineEnd ?
      moment().utc().toISOString() :
      end;
    const count = (+new Date(endTime) - +new Date(begin)) / aggregationInterval / 1000;
    return map(range(count), (i) => {
      return moment(begin).add(i * aggregationInterval, 's').toString();
    });
  }, [status, aggregationInterval, useCurrentTimeAsTimelineEnd]);

  const sortedByLastValueItems = useMemo(() => {
    const {dataIndex} = indexMap;
    if (dataIndex < 0) return [];
    return reverse(sortBy(items, (item) => {
      const lastIndex = item[dataIndex]?.length - 1;
      return item[dataIndex][lastIndex];
    }));
  }, [items, indexMap]);

  const samples = useMemo(() => {
    const {dataIndex, nodeIndex, containerIndex, agentIndex} = indexMap;
    if (dataIndex < 0) return [];
    return map(slice(sortedByLastValueItems, 0, topCount), (item) => {
      return {
        [itemSamplesPath]: map(item[dataIndex], (value, i) => ({timestamp: timestamps[i], value})),
        properties: {
          node: getNodeLabel(item, nodeIndex, nodes),
          container: item[containerIndex],
          agent: item[agentIndex],
        },
      };
    });
  }, [sortedByLastValueItems, timestamps, itemSamplesPath, indexMap, topCount, nodes]);

  const donutSamples = useMemo(() => {
    const {dataIndex} = indexMap;
    const getTooltip = (label, value) => units ?
      `${label}: ${formatNumber(value, {units, short: true})}` :
      `${label}: ${value}`;
    const samples = map(slice(sortedByLastValueItems, 0, topCount), (item, i) => {
      const lastIndex = item[dataIndex]?.length - 1;
      const value = item[dataIndex][lastIndex];
      const label = getGroupKey({level, item, indexMap, nodes});
      return {
        id: label,
        value,
        label,
        tooltip: getTooltip(label, value),
        color: brandColorNames[i % brandColorNames.length],
        units
      };
    });

    let sumCountOthers = 0;
    for (let i = topCount; i < sortedByLastValueItems.length; i++) {
      const lastIndex = sortedByLastValueItems[i][dataIndex]?.length - 1;
      sumCountOthers += sortedByLastValueItems[i][dataIndex][lastIndex];
    }

    if (sumCountOthers > 0) {
      samples.push({
        id: 'Others',
        label: 'Others',
        value: sumCountOthers,
        tooltip: getTooltip('Others', sumCountOthers),
        color: brandColorNames[topCount % brandColorNames.length],
      });
    }
    return samples;
  }, [indexMap, sortedByLastValueItems, topCount, units, level, nodes]);

  const [barGroupSamples, barGroupTickFormatMap] = useMemo(() => {
    const {dataIndex} = indexMap;
    const timeBegin = timestamps[0];
    const timeEnd = timestamps[timestamps.length - 1];

    const barGroupSamples = [];
    const barGroupTickFormatMap = {};
    forEach(slice(sortedByLastValueItems, 0, topCount), (item) => {
      const lastIndex = item[dataIndex]?.length - 1;
      const groupKey = getGroupKey({level, item, indexMap, nodes});
      barGroupSamples.push({
        group: groupKey,
        [timeBegin]: item[dataIndex][0],
        [timeEnd]: item[dataIndex][lastIndex],
      });
      barGroupTickFormatMap[groupKey] = getLabelByLevel(item, level, indexMap, nodes);
    });
    return [barGroupSamples, barGroupTickFormatMap];
  }, [sortedByLastValueItems, indexMap, topCount, timestamps, level, nodes]);

  const extents = useMemo(() => {
    let minValue = 0;
    let maxValue = 0;
    forEach(samples, ({[itemSamplesPath]: sample}) => {
      forEach(sample, ({value}) => {
        if (value < minValue) minValue = value;
        if (value > maxValue) maxValue = value;
      });
    });
    return [minValue, maxValue];
  }, [samples, itemSamplesPath]);
  const [minValue, maxValue] = extents;

  const [trendGradientSamples, trendGradientTickFormatMap] = useMemo(() => {
    const trendGradientSamples = [];
    const trendGradientTickFormatMap = {};
    forEach(items, (item) => {
      const {trendGradientIndex} = indexMap;
      const groupKey = getGroupKey({level, item, indexMap, nodes});
      trendGradientSamples.push({
        value: item[trendGradientIndex],
        group: groupKey
      });
      trendGradientTickFormatMap[groupKey] = getLabelByLevel(item, level, indexMap, nodes);
    });
    return [
      slice(
        reverse(sortBy(trendGradientSamples, 'value')),
        0,
        topCount
      ),
      trendGradientTickFormatMap
    ];
  }, [items, indexMap, topCount, level, nodes]);

  const y = {
    units, unitsInline: true, unitsSpacer: '', showGrid: true, ticks: 10, isLinear: true, formatLabel: shortTickNumber
  };
  return (
    <>
      <Segment>
        <div className='metric-query header'>
          <Header content={`${header} ${valueKey} usage history`} />
          <DropdownControl
            aria-label='Mode'
            float='right'
            value={mode}
            onChange={setGraphMode}
            options={modeOptions}
          />
        </div>
        <TimelineGraphContainer
          axes={{x: {showGrid: true}, y}}
          items={samples}
          useCurrentTimeAsTimelineEnd={false}
          itemSamplesPath={itemSamplesPath}
          expanded
        >
          <LineGraphComponent
            popupContentItemKeys={popupContentItemKeys}
            itemSamplesPath={itemSamplesPath}
            minValue={minValue}
            maxValue={maxValue}
            units={units}
            valueKeyName={valueKeyName}
          />
        </TimelineGraphContainer>
      </Segment>
      <Segment>
        <Header content={`${header} ${valueKey} usage`} />
        <DonutChart
          width={350}
          thickness={10}
          values={donutSamples}
          withLegend
          pie
          showPieLabels={false}
        />
      </Segment>
      <Segment>
        <Header
          content={
            `${header} ${valueKey} usage at both ends of the duration window`
          }
        />
        <BarGroupChart
          samples={barGroupSamples}
          colors={['blue', 'orange']}
          axes={{
            x: {
              ticks: 10,
              formatLabel: (groupKey) => barGroupTickFormatMap[groupKey]
            },
            y
          }}
        />
      </Segment>
      <Segment>
        <Header
          content={
            `${header} ${valueKey} usage sloping factors. ` +
            `Increments every ${aggregationText} over the duration window`
          }
        />
        <BarGroupChart
          samples={trendGradientSamples}
          colors={['teal']}
          axes={{
            x: {
              ticks: clamp(size(trendGradientSamples), 0, 10),
              formatLabel: (groupKey) => trendGradientTickFormatMap[groupKey]
            },
            y
          }}
        />
      </Segment>
    </>
  );
};

MetricQueryGraph.defaultProps = {
  itemSamplesPath: 'items',
  valueKeyName: 'value',
  topCount: 12,
  nodes: {},
};
