import {get, max, min} from 'lodash';
import {TimelineGraphContainer} from 'apstra-ui-common';

import LineChart from '../../../components/graphs/LineChart';
import {anomalyColors} from '../../../graphColors';
import {getItemsSampleExtent} from './cache';

// Optical Transcievers
const AnomalousOpticalMetric = ({name, item, items, expanded, stageDataProps: {stage}, valueColumnName,
  patternDescription}) => {
  const warnColor = 'yellow';
  const alarmColor = 'red';

  const interfaceStatsStageName = get(patternDescription, ['fetchDataForRelatedStages', 0, 'stageName']);
  const sourceThresholdItem =
    (interfaceStatsStageName && get(item, ['preceding_items', interfaceStatsStageName, 0])) ?? item;

  const [lowWarn, highWarn] = [
    get(sourceThresholdItem, [`${name}_low_warn`]),
    get(sourceThresholdItem, [`${name}_high_warn`]),
  ];
  const [lowAlarm, highAlarm] = [
    get(sourceThresholdItem, [`${name}_low_alarm`]),
    get(sourceThresholdItem, [`${name}_high_alarm`]),
  ];
  const rangesByColor = {};
  rangesByColor[warnColor] = [
    {name: 'Warning High', borders: [highWarn, highAlarm], borderColor: warnColor, includeBorders: {min: true}},
    {name: 'Warning Low', borders: [lowAlarm, lowWarn], borderColor: warnColor, includeBorders: {max: true}},
  ];
  rangesByColor[alarmColor] = [
    {name: 'Alert High', borders: [highAlarm, null], borderColor: alarmColor, includeBorders: {min: true}},
    {name: 'Alert Low', borders: [null, lowAlarm], borderColor: alarmColor, includeBorders: {max: true}},
  ];

  const units = stage.units[name];
  const [originalMinValue, originalMaxValue] = getItemsSampleExtent(items, valueColumnName);
  const minValue = min([originalMinValue, lowAlarm]);
  const maxValue = max([originalMaxValue, highAlarm]);
  const axes = {
    x: {showGrid: true},
    y: {units, showGrid: true}
  };
  return (
    <TimelineGraphContainer
      axes={axes}
      samples={item.persisted_samples}
      useCurrentTimeAsTimelineEnd={false}
      expanded={expanded}
    >
      <LineChart
        minValue={minValue}
        maxValue={maxValue}
        rangesByColor={rangesByColor}
        colors={anomalyColors}
        valueKeyName={valueColumnName}
        showRangeInfoInPopup
      />
    </TimelineGraphContainer>
  );
};

export default AnomalousOpticalMetric;
