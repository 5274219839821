import React, {cloneElement, Component, isValidElement, useMemo} from 'react';
import PropTypes from 'prop-types';
import {scaleLinear} from 'd3';
import {Group} from '@visx/group';
import {Text} from '@visx/text';
import cx from 'classnames';
import {Spring, animated, to, easings} from '@react-spring/web';
import {clamp, forEach, inRange, isNil, isNumber, isUndefined, map, max, min, uniqueId} from 'lodash';
import {observer} from 'mobx-react';
import {computed, makeObservable} from 'mobx';
import {formatNumber} from 'apstra-ui-common';
import {Popup, Icon} from 'semantic-ui-react';

import {Filter3d} from './DonutChart';

import './Gauge.less';

const AnimatedArc = animated(({outerRadius, innerRadius, startAngle, endAngle, roundStart, roundEnd, ...props}) => {
  const d = useMemo(() => {
    const [soX, soY] = [outerRadius * Math.sin(startAngle), -outerRadius * Math.cos(startAngle)];
    const [eoX, eoY] = [outerRadius * Math.sin(endAngle), -outerRadius * Math.cos(endAngle)];
    const [siX, siY] = [innerRadius * Math.sin(startAngle), -innerRadius * Math.cos(startAngle)];
    const [eiX, eiY] = [innerRadius * Math.sin(endAngle), -innerRadius * Math.cos(endAngle)];
    const round = 0.5 * (outerRadius - innerRadius);

    const largeArc = +(endAngle - startAngle > Math.PI);
    return `M${soX},${soY}A${outerRadius},${outerRadius},0,${largeArc},1,${eoX},${eoY}` + // outer arc
      (roundEnd ? `A${round},${round},${Math.PI},1,1,${eiX},${eiY}` : `L${eiX},${eiY}`) + // arc end
      `A${innerRadius},${innerRadius},0,${largeArc},0,${siX},${siY}` + // inner arc
      (roundStart ? `A${round},${round},${Math.PI},1,1,${soX},${soY}` : 'Z'); // arc start
  }, [endAngle, innerRadius, outerRadius, roundEnd, roundStart, startAngle]);
  return <path d={d} {...props} />;
});

@observer
export default class Gauge extends Component {
  static propTypes = {
    value: PropTypes.number,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    rangeMin: PropTypes.number,
    rangeMax: PropTypes.number,
    radius: PropTypes.number,
    height: PropTypes.number,
    width: PropTypes.number,
    thickness: PropTypes.number,
    bgThickness: PropTypes.number,
    labelPadding: PropTypes.number,
    startAngle: PropTypes.number,
    endAngle: PropTypes.number,
    padding: PropTypes.number,
    top: PropTypes.number,
    units: PropTypes.string,
    hideLabels: PropTypes.bool,
    colors: PropTypes.arrayOf(PropTypes.string),
    valueArrowThickness: PropTypes.number,
    withValueArrow: PropTypes.bool,
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onClick: PropTypes.func,
    centerLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    showAltLabels: PropTypes.bool,
    showWireframes: PropTypes.bool
  };

  static defaultProps = {
    minValue: 0,
    maxValue: 0,
    thickness: 5,
    bgThickness: 12.5,
    labelPadding: 10,
    startAngle: -2 * Math.PI / 3,
    endAngle: 2 * Math.PI / 3,
    top: 0,
    units: '',
    hideLabels: false,
    colors: ['green', 'red'],
    valueArrowThickness: 1,
    withValueArrow: false,
    mode: 'compact',
    dimensions: {
      compact: {
        height: 120,
        width: 200
      },
      expanded: {
        height: 150,
        width: 250
      },
    },
    padding: 20,
    tooltip: ''
  };

  scale = scaleLinear().clamp(true);

  filter3dId = uniqueId('gauge-3d-filter-id-');

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get size() {
    const {width, height, mode, dimensions} = this.props;
    const {width: modeWidth, height: modeHeight} = dimensions[mode];
    return {
      width: isNumber(width) ? width : modeWidth,
      height: isNumber(height) ? height : modeHeight
    };
  }

  inRange(value) {
    const {startAngle, endAngle} = this.props;
    const Pi = Math.PI;
    return inRange(value, startAngle, endAngle) ||
      inRange(value + 2 * Pi, startAngle, endAngle) ||
      inRange(value - 2 * Pi, startAngle, endAngle);
  }

  @computed
  get criticalPoints() {
    const {startAngle, endAngle} = this.props;
    const Pi = Math.PI;
    const halfPi = Pi / 2;

    const x = [0];
    const y = [0];

    const criticalPoints = [
      [0, 0, -1], // Top
      [halfPi, 1, 0], // Right
      [Pi, 0, 1], // Bottom
      [-halfPi, -1, 0] // Left
    ];

    forEach(criticalPoints, ([value, dx, dy]) => {
      if (this.inRange(value)) {
        x.push(dx);
        y.push(dy);
      }
    });
    x.push(Math.sin(startAngle), Math.sin(endAngle));
    y.push(-Math.cos(startAngle), -Math.cos(endAngle));

    return {x, y};
  }

  @computed
  get graphBox() {
    const {criticalPoints: {x, y}} = this;
    const [xmin, xmax] = [min(x), max(x)];
    const [ymin, ymax] = [min(y), max(y)];
    return {xmin, ymin, xmax, ymax, width: xmax - xmin, height: ymax - ymin};
  }

  renderCriticalPoints() {
    const {radius, criticalPoints: {x, y}, graphBox} = this;
    const result = map(
      x,
      (xx, index) => (<circle key={index} cx={this.radius * xx} cy={this.radius * y[index]} r={10} />)
    );
    const {xmin, xmax, ymin, ymax} = graphBox;
    result.push(<rect
      key='rect'
      x={xmin * radius} y={ymin * radius}
      width={(xmax - xmin) * radius} height={(ymax - ymin) * radius}
    />);
    return (
      <g className='markup'>{result}</g>
    );
  }

  @computed get radius() {
    const {radius, padding} = this.props;
    const {width, height} = this.size;

    if (isNil(radius)) {
      const {width: graphWidth, height: graphHeight} = this.graphBox;
      return min([(width - 2 * padding) / graphWidth, (height - 2 * padding) / graphHeight]);
    } else {
      return radius;
    }
  }

  @computed get arcRadius() {
    const {radius} = this;
    const {bgThickness, thickness} = this.props;
    return radius - 0.5 * (bgThickness - thickness);
  }

  @computed get specialRangeColor() {
    return this.props.colors[1];
  }

  @computed get baseColor() {
    return this.props.colors[0];
  }

  @computed get baseArcClassName() {
    return `gauge-base-arc graph-color-${this.baseColor}`;
  }

  @computed get specialRangeArcClassName() {
    return `gauge-special-range-arc graph-color-${this.specialRangeColor}`;
  }

  renderLabel = ({name, value, angle}) => {
    const {labelPadding, units, showAltLabels, bgThickness} = this.props;
    const {radius} = this;

    const label = formatNumber(value, {units: showAltLabels ? '' : units, short: true});
    const textProps = showAltLabels ?
      {
        radius: radius - bgThickness / 2,
        textAnchor: () => 'middle',
        dx: () => 0,
        dy: bgThickness / 2 + 15
      } :
      {
        radius: radius + labelPadding,
        textAnchor: (angle) => angle < 0 ? 'end' : 'start',
        dx: (angle) => angle < 0 ? '-0.2em' : '0.2em',
        dy: '0.3em'
      };

    return (
      <Spring
        key={`label ${name}`}
        to={{angle}}
      >
        {({angle}) => {
          return (
            <animated.text
              className='gauge-label'
              transform={angle.to((angle) =>
                `translate(${textProps.radius * Math.sin(angle)}, ${-textProps.radius * Math.cos(angle)})`)
              }
              textAnchor={angle.to(textProps.textAnchor)}
              dx={angle.to(textProps.dx)}
              dy={textProps.dy}
            >
              {label}
            </animated.text>
          );
        }
        }
      </Spring>
    );
  };

  renderLineRange = ({name, angle}) => {
    const {thickness, labelPadding} = this.props;
    const {radius} = this;

    return (
      <line
        key={`range ${name}`}
        className='gauge-range'
        x1={(radius - thickness - labelPadding) * Math.sin(angle)}
        y1={-(radius - thickness - labelPadding) * Math.cos(angle)}
        x2={(radius + labelPadding) * Math.sin(angle)}
        y2={-(radius + labelPadding) * Math.cos(angle)}
      />
    );
  };

  renderPointer = ({name, value, angle}) => {
    const {labelPadding} = this.props;
    const {radius} = this;

    return (
      <Spring
        key={`pointer ${name}`}
        to={{angle}}
      >
        {({angle}) =>
          <animated.line
            key={`pointer ${value}`}
            className='gauge-pointer'
            x1={angle.to((angle) => radius * Math.sin(angle))}
            y1={angle.to((angle) => -radius * Math.cos(angle))}
            x2={angle.to((angle) => (radius + labelPadding) * Math.sin(angle))}
            y2={angle.to((angle) => -(radius + labelPadding) * Math.cos(angle))}
          />
        }
      </Spring>
    );
  };

  renderArc = ({name, startAngle, endAngle, className, highlighted, onClick, roundStart, roundEnd}) => {
    const {thickness} = this.props;
    const {filter3dId, arcRadius: radius} = this;

    return (
      <Spring
        key={`arc ${name}`}
        to={{startAngle, endAngle}}
      >
        {({startAngle, endAngle}) =>
          <AnimatedArc
            className={to([startAngle, endAngle], (startAngle, endAngle) =>
              cx('gauge-arc', className, {hidden: startAngle === endAngle, highlighted})
            )}
            outerRadius={radius}
            innerRadius={radius - thickness}
            roundStart={roundStart}
            roundEnd={roundEnd}
            startAngle={startAngle}
            endAngle={endAngle}
            onClick={onClick}
            filter={`url(#${filter3dId})`}
          />
        }
      </Spring>
    );
  };

  renderBackgroundArc = () => {
    const {startAngle: chartStartAngle, endAngle: chartEndAngle, bgThickness} = this.props;
    const {filter3dId, radius} = this;
    return <AnimatedArc
      roundStart
      roundEnd
      className='gauge-background-arc'
      startAngle={chartStartAngle}
      endAngle={chartEndAngle}
      filter={`url(#${filter3dId})`}
      innerRadius={radius - bgThickness}
      outerRadius={radius}
    />;
  };

  renderHand = () => {
    const {radius, getDegreeFromAngle, scale} = this;
    const {valueArrowThickness, value, startAngle, endAngle} = this.props;
    const angle = scale(value);
    const d = `M0,${-radius}L${-valueArrowThickness},0` +
      `A${valueArrowThickness},${valueArrowThickness},0,0,0,${valueArrowThickness},0Z`;

    return (
      <Spring
        key='value-arrow'
        to={{angle}}
        config={{
          duration: 2000,
          easing: easings.easeOutElastic
        }}
      >
        {({angle}) =>
          <animated.path
            className='gauge-value-arrow'
            transform={angle.to(
              (angle) => `rotate(${getDegreeFromAngle(clamp(angle, startAngle - 0.1, endAngle + 0.1))}, 0, 0)`
            )}
            d={d}
          />
        }
      </Spring>
    );
  };

  renderCenterLabel = () => {
    const {units, centerLabel} = this.props;
    const {radius} = this;

    const labelFontSize = radius / 2.5;

    return isValidElement(centerLabel) ?
      cloneElement(centerLabel) :
      (
        <>
          <Text
            textAnchor='middle'
            fontSize={labelFontSize}
            dy={-labelFontSize / 2}
            style={{fontWeight: 600}}
            className='label'
          >
            {centerLabel}
          </Text>
          <Text
            textAnchor='middle'
            fontSize={labelFontSize / 2.2}
            dy={6}
            style={{fontWeight: 400}}
            className='units'
          >
            {units}
          </Text>
        </>
      );
  };

  renderAltLabels = () => {
    const {renderLabel, props: {minValue, maxValue, startAngle, endAngle}} = this;
    return [
      renderLabel({name: 'min', value: minValue, angle: startAngle}),
      renderLabel({name: 'max', value: maxValue, angle: endAngle})
    ];
  };

  shouldRenderRangeInfo = (range) => {
    const {value, minValue, maxValue} = this.props;
    return isFinite(range) && range !== value && range > minValue && range < maxValue;
  };

  getDegreeFromAngle = (angle) => angle * 360 / (2 * Math.PI);

  calculatePopupArrowOffset = ({reference, popper}) => {
    const isEnoughSpace = window.innerWidth - reference.x >= popper.width;
    return [(isEnoughSpace ? -1 : 1) * reference.width, 0];
  };

  render() {
    const {
      className, value, minValue, rangeMin, rangeMax, top, withValueArrow,
      startAngle: chartStartAngle, endAngle: chartEndAngle, children, onClick,
      onSpecialRangeArcClick, onBaseArcClick, tooltip, centerLabel, showAltLabels, showWireframes
    } = this.props;
    const {width, height} = this.size;
    const {scale, renderArc, renderLabel, renderPointer, renderHand, shouldRenderRangeInfo,
      renderLineRange, baseArcClassName, specialRangeArcClassName, filter3dId,
      renderBackgroundArc, calculatePopupArrowOffset, renderCenterLabel, radius,
      graphBox: {xmin, ymin, width: graphWidth, height: graphHeight}} = this;
    let {maxValue, hideLabels} = this.props;

    const [offsetX, offsetY] = [(width - graphWidth * radius) / 2, (height - graphHeight * radius) / 2];
    const centerX = offsetX - radius * xmin;
    const centerY = (showAltLabels ? 0 : offsetY) - radius * ymin;

    if (minValue === maxValue) {
      hideLabels = true;
      maxValue++;
    }

    scale.domain([minValue, maxValue]).range([chartStartAngle, chartEndAngle]);
    const samples = [
      {
        name: 'minValue',
        value: minValue,
        hidden: hideLabels
      },
      {
        name: 'value',
        value,
        arcClassName: specialRangeArcClassName,
        highlighted: true,
        onClick: onSpecialRangeArcClick,
        hidden: hideLabels
      },
      {
        name: 'maxValue',
        value: maxValue,
        arcClassName: baseArcClassName,
        highlighted: value <= minValue,
        onClick: onBaseArcClick,
        hidden: hideLabels
      }
    ];
    const rangesDefined = isFinite(rangeMin) || isFinite(rangeMax);

    const arcs = [];
    const labels = [];
    const pointers = [];
    const lines = [];
    let previousEndAngle = chartStartAngle;

    samples.forEach(({name, value, arcClassName, hidden, highlighted, onClick}) => {
      const startAngle = previousEndAngle;
      const endAngle = scale(value);
      if (!hidden) {
        labels.push(renderLabel({name, value, angle: endAngle}));
        pointers.push(renderPointer({name, value, angle: endAngle}));
      }
      if (!rangesDefined) {
        arcs.unshift(renderArc({
          name,
          startAngle,
          endAngle,
          className: arcClassName,
          highlighted,
          onClick,
          roundStart: (startAngle === chartStartAngle),
          roundEnd: (endAngle === chartEndAngle)
        }));
      }
      previousEndAngle = endAngle;
    });

    if (rangesDefined) {
      const rangeMinAngle = isFinite(rangeMin) ? scale(rangeMin) : chartStartAngle;
      const rangeMaxAngle = isFinite(rangeMax) ? scale(rangeMax) : chartEndAngle;
      const valueAngle = scale(value);

      arcs.push(
        renderArc({
          name: 'minValue',
          startAngle: chartStartAngle,
          endAngle: rangeMinAngle,
          roundStart: true,
          highlighted: valueAngle >= chartStartAngle && valueAngle < rangeMinAngle,
          className: baseArcClassName,
          onClick: onBaseArcClick
        }),
        renderArc({
          name: 'value',
          startAngle: rangeMinAngle,
          endAngle: rangeMaxAngle,
          highlighted: valueAngle >= rangeMinAngle && valueAngle <= rangeMaxAngle,
          className: specialRangeArcClassName,
          onClick: onSpecialRangeArcClick
        }),
        renderArc({
          name: 'maxValue',
          startAngle: rangeMaxAngle,
          endAngle: chartEndAngle,
          roundEnd: true,
          highlighted: valueAngle > rangeMaxAngle && valueAngle <= chartEndAngle,
          className: baseArcClassName,
          onClick: onBaseArcClick
        })
      );

      if (shouldRenderRangeInfo(rangeMin)) {
        labels.push(renderLabel({name: 'min', value: rangeMin, angle: rangeMinAngle}));
        lines.push(renderLineRange({name: 'min', angle: rangeMinAngle}));
      }

      if (shouldRenderRangeInfo(rangeMax)) {
        labels.push(renderLabel({name: 'max', value: rangeMax, angle: rangeMaxAngle}));
        lines.push(renderLineRange({name: 'max', angle: rangeMaxAngle}));
      }
    }

    return (
      <div className='gauge-container'>
        <svg className={cx('gauge', className, {clickable: !!onClick})} width={width} height={height} onClick={onClick}>
          <Filter3d filterId={filter3dId} />
          <Group top={centerY + top} left={centerX}>
            {renderBackgroundArc()}
            {arcs}
            {pointers}
            {!showAltLabels && labels}
            {lines}
            {showAltLabels && this.renderAltLabels()}
            {!isUndefined(centerLabel) && renderCenterLabel()}
            {children}
            {withValueArrow && renderHand()}
            {showWireframes && this.renderCriticalPoints()}
          </Group>
        </svg>
        {tooltip &&
          <Popup
            content={tooltip}
            offset={calculatePopupArrowOffset}
            trigger={<Icon name='question circle' className='gauge-info-tooltip' />}
          />
        }
      </div>
    );
  }
}
