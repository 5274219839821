import {useContext} from 'react';
import {filter, isArray, isBoolean, isEmpty, isNil, transform} from 'lodash';
import {Form} from 'semantic-ui-react';
import {observer} from 'mobx-react';
import {CodeEditorControl, Field, FormFragment, StringListDropdownControl, ValueRenderer} from 'apstra-ui-common';

import {GRAPH_QUERY_KWARGS} from '../pythonExpression/consts';
import QueryBrick from './QueryBrick';
import {calculateRange} from './utils';
import QueryBuilderContext from './QueryBuilderContext';
import QueryDeleteButton from './QueryDeleteButton';

import './QueryProperty.less';

const QueryProperty = observer(({query, uniqNames, startRow, queryKeyPath}) => {
  const {deleteNode, replaceQueryValue, removeQueryKey, removePathQuery} = useContext(QueryBuilderContext);
  const formSchema = transform(
    filter(GRAPH_QUERY_KWARGS[query.name], ({name}) => name !== 'predicate' && name !== 'query'),
    (result, {name, ...schema}) => {
      result.push({name, schema});
    },
    []
  );
  const subQueryNodeRange = calculateRange(query.query);
  const subQueryStartRow = subQueryNodeRange <= 1 ? startRow : startRow + 1;
  const havingQueryPath = [...queryKeyPath, 'query'];
  const onEnsureDifferentChange = (value) => {
    replaceQueryValue(queryKeyPath, {uniqNames: value});
  };
  const onWhereChange = (value) => {
    replaceQueryValue(queryKeyPath, {predicate: value});
  };
  const onPropertyChange = (name, value) => {
    if ((isArray(value) && isEmpty(value)) || (isBoolean(value) && !value) || isNil(value)) {
      removeQueryKey(queryKeyPath, name);
    } else {
      replaceQueryValue(queryKeyPath, {[name]: value});
    }
  };
  return (
    <div className='query-brick query-property'>
      {query.name === 'where' && (
        <Form>
          <Field
            label='Filter Function'
            description={
              'Function to filter paths with names of path objects to pass to predicate. ' +
              'If None, it defaults to predicate function argument names'
            }
          >
            <CodeEditorControl
              mode='python-expression'
              enableCompletion
              value={query?.predicate ?? ''}
              onChange={onWhereChange}
            />
          </Field>
        </Form>
      )}
      {query.name === 'having' && (
        <QueryBrick
          query={query.query}
          canAddPath
          canBeRemoved
          deleteEl={
            <QueryDeleteButton
              onClick={() => deleteNode(query.query)}
            />
          }
          removePathAfterThisNode={() => removePathQuery(havingQueryPath)}
          startRow={subQueryStartRow}
          queryKeyPath={havingQueryPath}
        />
      )}
      <Form>
        {query.name === 'ensure_different' &&
          <Field>
            <StringListDropdownControl
              knownItems={uniqNames}
              value={query.uniqNames ?? []}
              onChange={onEnsureDifferentChange}
            />
          </Field>
        }
        <FormFragment
          schema={formSchema}
          values={query}
          onChange={onPropertyChange}
          renderers={[namesRenderer]}
          knownItems={uniqNames}
        />
      </Form>
    </div>
  );
});

const namesRenderer = new ValueRenderer({
  condition: ({name}) => name === 'names',
  ValueInput(props) {
    return (
      <Field label='names' description={props.schema.description}>
        <StringListDropdownControl allowAdditions {...props} />
      </Field>
    );
  },
});

export default QueryProperty;
