export type ProcessorDefinition = {
  label: string;
  name: ProcessorType;
  description: string;
  inputs: ProcessorDefinitionInputMap;
  outputs: ProcessorDefinitionOutputMap;
  schema: SchemaType;
  category: [string, string];
  examples?: ProcessorExampleType[];
  property_categories?: ProcessorPropertiesCategoryType;
};

export type StageDataSource = 'real_time' | 'time_series' | 'telemetry_service_warnings';

export type ProcessorType = string;
export type ProcessorPropertiesCategoryType = Record<string, ProcessorPropertyCategoryType>;
export type ProcessorPropertyCategoryType = 'graph' | 'telemetry' | 'processing';
export const PROCESSOR_PROPERTY_CATEGORIES: ProcessorPropertyCategoryType[] = ['graph', 'telemetry', 'processing'];

export type ProcessorDefinitionInputType = {
  types: ProcessorValueType[];
  required: boolean;
};
export type ProcessorDefinitionInputMap = Record<string, ProcessorDefinitionInputType>;

export type ProcessorDefinitionOutputType = {
  description: string;
  required: boolean;
  types: ProcessorValueType[];
};
export type ProcessorDefinitionOutputMap = Record<string, ProcessorDefinitionOutputType>;

export type ProcessorValueType = {
  dynamic: boolean;
  type: 'table' | 'string';
  keys: any;
  values: any;
};

export type SchemaTypeName = 'u8' | 'u16' | 'u32' | 'u64' | // unsigned integer
  's8' | 's16' | 's32' | 's64' | // signed integer
  'f32' | 'f64'; // float

export type SchemaType = {
  description?: string;
  name?: string;
  type: SchemaTypeName;
  properties: PropertyDescriptionMap;
  possible_values?: string[];
}

export type PropertyDescription = {
  title: string;
  description: string;
  anyOf?: any[];
  additionalProperties?: PropertyDescriptionMap;
  default?: any;
}

export type PropertyDescriptionMap = Record<string, PropertyDescription>;

export type Probe = {
  anomaly_count: number;
  description: string;
  disabled: boolean;
  host_node: string;
  iba_unit: string;
  id: string;
  label: string;
  last_error: any | null;
  predefined_probe: string;
  probe_state: any;
  processors: ProbeProcessorType[];
  referencing_dashboards: Array<{
    id: string;
    label: string;
    predefined_dashboard: string;
  }>;
  stages: ProbeStageType[];
  state: 'error' | 'operational';
  task_error: string;
  task_state: 'running';
}

export type ProbeProcessorType = {
  inputs: ProcessorInputMap;
  name: string;
  outputs: Record<string, string>,
  properties: Record<string, any>;
  task_error?: any;
  type: ProcessorType;
}

export type ProbeStageType = {
  anomaly_count: number;
  description: string;
  dynamic?: boolean;
  enable_metric_logging: boolean;
  graph_annotation_properties: any;
  name: string;
  retention_duration: number;
  units: any;
  values: Record<string, SchemaType>,
  warnings: number;
}

export type ProcessorInputType = {
  column?: string;
  stage?: string;
};

export type ProcessorInputMap = Record<string, ProcessorInputType>;

export type ProcessorCategory = {
  description: string;
  label: string;
  name: string;
  subcategories: Record<string, ProcessorSubcategory>;
};

export type ProcessorCategories = Record<string, ProcessorCategory>;

export type ProcessorSubcategory = {
  description: string;
  label: string;
  name: string;
};

export type ProcessorExampleType = {
  inputs: Record<string, ProcessorExampleInputType[]>;
  name: string;
  outputs: Record<string, ProcessorExampleOutputType[]>;
  properties: Record<string, any>;
}

export type ProcessorExampleInputType = {
  keys: any;
  values: any;
}

export type ProcessorExampleOutputType = {
  keys: any;
  values: any;
}

export type RenderingStrategy = {
  ValueRenderer?: any,
  SearchComponent?: any,
  StageDataRenderer?: any,
  aggregationType?: string,
  alwaysUseContext?: boolean,
  clearFilterOnContextTrigger?: boolean,
  defaultFilter?: string,
  extraColumns?: Array<any>,
  filterDeserializer?: any,
  filterSerializer?: any,
  filterTransformer?: any,
  getRenderer?: any,
  graphCombiningAvailable?: any,
  hiddenContextByDefault?: boolean,
  includePrecedingProcessorAnomalies?: boolean,
  noAnomalyHighlighting?: boolean,
  noCustomization?: boolean,
  noDataMessage?: string,
  noPagination?: boolean,
  noSpotlightMode?: boolean,
  shouldFetchData?: any,
  showTimestamp?: boolean,
  sortableByValue?: boolean,
  usePatternDescriptionAsValueDescription?: boolean,
};
