import {Component, useContext} from 'react';
import {Button} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {filter, map, merge} from 'lodash';
import {observer} from 'mobx-react';
import {computed, makeObservable} from 'mobx';
import {DurationInput, ValueRenderer, interpolateRoute, request} from 'apstra-ui-common';

import generateProbeURI from '../generateProbeURI';
import {interfacesRenderer} from './InterfacesInput';
import {environmentExpectationsRenderer} from './EnvironmentExpectationsInput';
import {discardThresholdRenderer} from './DiscardThresholdInput';
import {queueSpecificThresholdRenderer} from './QueueSpecificThresholdInput';
import PredefinedEntityModal from './PredefinedEntityModal';
import IBAContext from '../IBAContext';
import TagsInput from '../../components/TagsInput';

const durationRenderer = new ValueRenderer({
  condition: ({name, schema}) =>
    /(?:^|\w_)(?:period|duration|time_window|interval)$/.test(name) &&
    ['number', 'integer'].includes(schema.type),
  ValueInput: ({name, value, schema, required, disabled, errors, onChange, fieldProps}) =>
    <DurationInput
      name={name}
      value={value}
      schema={schema}
      required={required}
      disabled={disabled}
      errors={errors}
      customValueType='duration'
      textPrefix={null}
      onChange={onChange}
      fieldProps={fieldProps}
    />
});

const tagsRenderer = new ValueRenderer({
  condition: ({name, schema}) =>
    /(?:^|\w_)tags$/.test(name) && schema.type === 'array' && schema.items.type === 'string',
  ValueInput: ({name, value, schema, required, disabled, errors, onChange, fieldProps}) => {
    const {blueprintTags} = useContext(IBAContext);
    const knownTags = map(blueprintTags, ({label}) => label);
    return (
      <TagsInput
        name={name}
        value={value}
        schema={schema}
        required={required}
        disabled={disabled}
        errors={errors}
        onChange={onChange}
        knownTags={knownTags}
        fieldProps={fieldProps}
      />
    );
  }
});

@observer
export default class PredefinedProbeModal extends Component {
  static defaultProps = {
    mode: 'create'
  };

  static contextType = IBAContext;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get isCreateMode() {
    return this.props.mode === 'create';
  }

  @computed get entities() {
    const {predefinedProbes} = this.context;
    return this.isCreateMode ? filter(predefinedProbes, (probe) => !probe.experimental) : predefinedProbes;
  }

  generateResourceHref = ({blueprintId, entityId}) => {
    return generateProbeURI({blueprintId, probeId: entityId});
  };

  fetchPredefinedProbeParameters = async ({routes, blueprintId, entityName, entityId, signal}) => {
    const route = routes.predefinedProbeParameters;
    const result = await request(
      interpolateRoute(route, {blueprintId, predefinedProbe: entityName, probeId: entityId}),
      {signal}
    );
    return merge(
      result,
      this.props.mode === 'clone' && {label: `${result.label} copy`}
    );
  };

  render() {
    const {routes, blueprintId} = this.context;
    const {probe, mode, processorName, stageName, defaultPredefinedProbeName, helpPageId, ...props} = this.props;
    const {isCreateMode, entities, generateResourceHref} = this;

    return (
      <PredefinedEntityModal
        helpPageId={helpPageId}
        mode={mode}
        resourceName='Predefined Probe'
        entities={entities}
        entityName={defaultPredefinedProbeName ?
          defaultPredefinedProbeName :
          probe ?
            probe.predefined_probe :
            undefined
        }
        entityId={probe ? probe.id : undefined}
        fetchEntityParameters={probe ? this.fetchPredefinedProbeParameters : undefined}
        baseRoute={routes.predefinedProbeList}
        generateResourceHref={generateResourceHref}
        renderers={[
          interfacesRenderer,
          durationRenderer,
          tagsRenderer,
          environmentExpectationsRenderer,
          discardThresholdRenderer,
          queueSpecificThresholdRenderer,
        ]}
        extraActions={
          !isCreateMode &&
            <Button
              as={Link}
              to={generateProbeURI({blueprintId, probeId: probe.id, action: mode, processorName, stageName})}
              secondary
              icon='edit'
              size='large'
              content='Edit Processors'
            />
        }
        {...props}
      />
    );
  }
}
