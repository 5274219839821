import {Component, Fragment, createRef} from 'react';
import {Message, Grid, Table, Icon, Placeholder, Popup} from 'semantic-ui-react';
import {observable, computed, action, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {compact, filter, find, findIndex, forEach, has, head, includes, isEmpty, isFunction, isNumber, keys,
  map, merge, transform, xor, times} from 'lodash';
import cx from 'classnames';
import {COMBINE_GRAPHS_MODE, DEFAULT_COMBINE_GRAPHS_MODE, DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZES, Checkbox, DataTable,
  DataTableRowFragment, DateFromNow, DropdownControl, DurationInput, FetchDataError, LayoutContext, Pagination, Value,
  DataFilteringLayout, parseTimestamp, withRouter} from 'apstra-ui-common';

import {STAGE_DATA_SOURCE, DEFAULT_STAGE_DATA_SOURCE, DEFAULT_STAGE_TIME_SERIES_AGGREGATION,
  DEFAULT_STAGE_TIME_SERIES_DURATION} from '../consts';
import {
  processorHasRaiseAnomaliesFlag, processorCanRaiseAnomalies, getStageDataSchema, getInputStages,
  getProcessorByStageName, processorCanRaiseWarnings, getTelemetryServiceSchema,
  getDefaultAggregationType, getPossibleAggregationTypes, getPossibleValueColumnOptions
} from '../stageUtils';
import {withContext} from '../../withContext';
import {anomalyColors, discreteStateColors} from '../../graphColors';
import {stagePropertiesRenderers} from '../stagePropertiesRenderers';
import AnomalyValues from './AnomalyValues';
import checkForPatterns from '../checkForPatterns';
import AggregationInput, {
  aggregationIntervalOptions, aggregationIntervalOptionsWithOff
} from '../../components/AggregationInput';
import StageSearchBox, {getStageSearchSchema} from './StageSearchBox';
import {regexFilterRenderer, exactOrRegexFilterRenderer, rangeFilterRenderer} from '../filterRenderers';
import IBAContext from '../IBAContext';
import {ValueColumnNameInput} from './StageWidgetValueColumnNameInput';
import AggregationTypeInput from './AggregationTypeInput';
import {withScrollContext} from './ProbeDetails/ScrollContext';

import {PrimitiveValue} from './valueRenderers';

import {TelemetryServiceStatus} from './TelemetryServiceStatus';
import {SpotlightKeysTableRow} from './SpotlightKeysTableRow';
import {ColumnDescription} from './ColumnDescription';

import './StageData.less';

@withContext(LayoutContext)
@withRouter
@withScrollContext
@observer
export class StageData extends Component {
  static contextType = IBAContext;

  previousScrollPositionTop = null;
  spotlightViewRef = createRef();

  @observable highlightedColumn = null;

  @action highlightPropertyColumn = (column) => {
    if (column !== this.highlightedColumn) this.highlightedColumn = column;
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loaderVisible && !this.props.loaderVisible) {
      if (!this.props.filters.spotlightMode && this.previousScrollPositionTop) {
        this.props.mainContentElement?.scrollTo({top: this.previousScrollPositionTop, behavior: 'smooth'});
        this.previousScrollPositionTop = null;
      }
      if (this.props.filters.spotlightMode && this.spotlightViewRef.current) {
        this.spotlightViewRef.current.focus();
      }
    }
  }

  storeScrollPosition = () => {
    this.previousScrollPositionTop = this.props.mainContentElement?.scrollTop !== undefined ?
      this.props.mainContentElement?.scrollTop
      :
      (document.documentElement || document.body.parentNode || document.body).scrollTop;
  };

  @action
  switchToSpotlightMode = (itemId) => {
    this.storeScrollPosition();
    const {updatePagination, filters, activePage, pageSize, scrollContext, stage} = this.props;
    this.updateFilters({...filters, spotlightMode: true});
    const page = (activePage - 1) * pageSize + findIndex(this.props.stageItems, (item) => item.id === itemId) + 1;
    updatePagination({activePage: page, pageSize: 1});
    scrollContext?.scrollToElement('stage details', stage.name);
  };

  onSpotlightModeKeyDown = (event) => {
    if (event.key === 'Escape') {
      this.switchToListMode();
    }
  };

  @action
  switchToListMode = () => {
    const {updatePagination, filters, activePage, scrollContext, stage} = this.props;
    this.updateFilters({...filters, spotlightMode: false});
    const page = Math.floor(activePage / DEFAULT_PAGE_SIZE) + 1;
    updatePagination({activePage: page, pageSize: DEFAULT_PAGE_SIZE});
    scrollContext?.scrollToElement('stage details', stage.name);
  };

  @computed get anomaliesByItems() {
    const {stageItems, processor} = this.props;
    if (!processorCanRaiseAnomalies(processor)) {
      return {};
    }

    return transform(stageItems, (result, item) => {
      const {actual_value: actualValue, anomalous_value: anomalyValue,
        anomalous_value_min: valueMin, anomalous_value_max: valueMax, value} = item;
      const anomaly = {actual: {value: actualValue}, anomalous: {}};
      if (anomalyValue) anomaly.anomalous.value = anomalyValue;
      if (isNumber(valueMin)) anomaly.anomalous.value_min = valueMin;
      if (isNumber(valueMax)) anomaly.anomalous.value_max = valueMax;
      if (value === 'true') {
        result[item.id] = anomaly;
      }
    }, {});
  }

  @computed get stageHasPersistedData() {
    return this.props.stage.enable_metric_logging;
  }

  @computed get usePatternWithPersistedData() {
    return this.props.patternDescription?.shouldFetchPersistedStageData && this.showContextInfo;
  }

  @computed get usePatternWithRawPersistedData() {
    return this.props.patternDescription?.shouldFetchRawPersistedStageData && this.showContextInfo;
  }

  @computed get valueColumnName() {
    const {filters: {valueColumnName}, stage} = this.props;
    return valueColumnName || head(keys(stage.values));
  }

  @computed
  get rendererProps() {
    return {
      stageDataProps: this.props,
      valueSchemas: this.valueSchemas,
      valueColumnName: this.valueColumnName,
      defaultColors: this.stageGraphColors,
      patternDescription: this.props.patternDescription,
    };
  }

  @computed get tableSchema() {
    const {processorDefinitions} = this.context;
    const {stage, stageItems, processor, visibleColumns, filters, patternDescription, renderingStrategy} = this.props;
    const {valueColumnName, stageGraphColors, showContextInfo} = this;
    const isTelemetryServiceWarningsDataSource = filters.dataSource === STAGE_DATA_SOURCE.telemetry_service_warnings;
    const tableSchema = isTelemetryServiceWarningsDataSource ?
      getTelemetryServiceSchema(stageItems) :
      getStageDataSchema({
        stage,
        processor,
        renderingStrategy,
        processorDefinitions,
        showValue: !filters.spotlightMode && !isTelemetryServiceWarningsDataSource,
        valueColumnName: filters.dataSource === STAGE_DATA_SOURCE.time_series ? valueColumnName : null,
      });

    forEach(tableSchema, (column) => {
      column.valueSchema ??= stage.values[column.name] ?? null;
      if (column.name === 'anomaly') {
        column.value = ({item}) => item.id in this.anomaliesByItems;
        column.formatter = ({item}) => <AnomalyValues anomaly={this.anomaliesByItems[item.id]} />;
      } else if (column.name === 'warning') {
        column.formatter = ({value}) => <TelemetryServiceStatus warning={value} />;
      } else if (column.valueSchema) {
        const Renderer = renderingStrategy.getRenderer?.(column.valueSchema) ??
          renderingStrategy.ValueRenderer ??
          PrimitiveValue;
        column.formatter = (args) => <Renderer {...this.rendererProps} {...args} />;
      } else if (column.name === 'timestamp') {
        column.value = ({item}) => parseTimestamp(item.timestamp);
        column.formatter = ({value}) => <DateFromNow date={value} />;
      } else if (!column.formatter) {
        column.formatter = this.renderProperty;
      }
      // FIXME(vkramskikh): only used makePrecedingStageColumnDefinition to override item and name.
      // Formatters need to be reimplemented as function components accepting actual values.
      if (column.overrideFormatter) {
        column.formatter = column.overrideFormatter(column.formatter);
      }
      if (!column.description && column.type) {
        column.description = <ColumnDescription
          column={column}
          stage={stage}
          stageGraphColors={column.stageGraphColors ?? stageGraphColors}
          patternDescription={showContextInfo && patternDescription}
        />;
      }
    });

    if (isEmpty(visibleColumns)) {
      return tableSchema;
    } else {
      return compact(map(visibleColumns, (name) => find(tableSchema, {name})));
    }
  }

  @computed get valueSchemas() {
    return transform(this.tableSchema, (result, column) => {
      if (column.valueSchema) result[column.name] = column.valueSchema;
    }, transform(this.props.stage.values, (result, valueSchema, columnName) => {
      result[columnName] = valueSchema;
    }, {}));
  }

  @computed get stageGraphColors() {
    const {probe, stage} = this.props;
    let {processor} = this.props;

    let processorWithRaiseAnomalyFlag = null;
    const stages = [stage];
    do {
      processorWithRaiseAnomalyFlag = processorHasRaiseAnomaliesFlag(processor) ? processor : null;
      const inputStage = stages.pop();
      processor = getProcessorByStageName({probe, stageName: inputStage.name});
      stages.push(...getInputStages({probe, processor}));
    } while (!isEmpty(stages) && !processorWithRaiseAnomalyFlag);

    return processorWithRaiseAnomalyFlag && processorCanRaiseAnomalies(processorWithRaiseAnomalyFlag) ?
      anomalyColors : discreteStateColors;
  }

  renderProperty = ({name, value, item}) => (
    <Value
      name={name}
      value={value}
      item={item}
      renderers={stagePropertiesRenderers}
    />
  );

  @computed get renderChosenValueColumnAs() {
    const {props: {renderingStrategy}, valueColumnName} = this;
    const valueSchema = this.valueSchemas[valueColumnName];

    return renderingStrategy.getRenderer?.(valueSchema) ??
      renderingStrategy.ValueRenderer ??
      PrimitiveValue;
  }

  @computed get mayCombineGraphs() {
    const {spotlightMode} = this.props.filters;
    if (spotlightMode) return false;
    const {valueColumnName, props: {renderingStrategy, stage: {values}}} = this;
    const valueSchema = values[valueColumnName];
    return isFunction(renderingStrategy.graphCombiningAvailable) ?
      renderingStrategy.graphCombiningAvailable(valueSchema) :
      renderingStrategy.graphCombiningAvailable ?? false;
  }

  @computed get maySwitchSpotlightMode() {
    return !this.props.compact;
  }

  @computed get stageDataSourceIsTimeSeries() {
    return this.props.filters.dataSource === STAGE_DATA_SOURCE.time_series;
  }

  @computed get customRows() {
    const {spotlightMode} = this.props.filters;
    return transform(this.props.stageItems, (result, item) => {result[item.id] = spotlightMode;}, {});
  }

  @computed
  get showContextInfo() {
    return this.props.filters.showContextInfo || !!this.props.patternDescription?.renderingStrategy?.alwaysUseContext;
  }

  @computed get stageOptions() {
    const {stageDataSourceIsTimeSeries, props: {filters, processor, patternDescription}} = this;
    return [
      {
        name: 'Anomalies Only',
        isAvailable: processorCanRaiseAnomalies(processor) && !stageDataSourceIsTimeSeries,
        getUpdateOptions: (isSelected) => ({filters: {anomalousOnly: isSelected}}),
        isSelected: filters.anomalousOnly
      },
      {
        name: 'Show Context',
        description: patternDescription?.description ?? undefined,
        isAvailable: !!patternDescription && !patternDescription.renderingStrategy?.alwaysUseContext,
        getUpdateOptions: (isSelected) => {
          if (!isSelected && patternDescription?.renderingStrategy?.alwaysUseContext) return {};
          return {filters: {showContextInfo: isSelected}};
        },
        isSelected: filters.showContextInfo
      }
    ];
  }

  @computed get availableOptions() {
    return filter(this.stageOptions, {isAvailable: true});
  }

  @computed get availableOptionsControls() {
    return map(this.availableOptions, ({name, description}) => {
      const label = description ?
        <Popup
          key='popup'
          trigger={
            <span>
              {name}
              <Icon name='info circle' aria-label={description} role='img' />
            </span>
          }
          content={description}
          position='right center'
          wide
        /> :
        name;

      return (
        <Checkbox
          key={name}
          label={label}
          checked={includes(this.selectedOptions, name)}
          onChange={() => this.applyStageOptions(xor(this.selectedOptions, [name]))}
        />
      );
    });
  }

  @computed get selectedOptions() {
    return map(filter(this.stageOptions, {isSelected: true}), 'name');
  }

  @computed get aggregationTypeOptions() {
    const {stage, filters} = this.props;
    return map(getPossibleAggregationTypes(stage, filters.valueColumnName), (type) => ({
      key: type,
      text: type,
      value: type,
    }));
  }

  @action
  applyStageOptions = (value) => {
    const {props: {filters, updatePagination}, stageOptions, updateFilters} = this;
    const updateOptions = {};
    forEach(stageOptions, (option) => {
      if (option.isAvailable) {
        const isSelected = value.includes(option.name);
        if (isSelected !== option.isSelected) {
          merge(updateOptions, option.getUpdateOptions(isSelected));
        }
      } else if (option.isSelected) {
        merge(updateOptions, option.getUpdateOptions(false));
      }
    });
    if (updateOptions.filters) {
      const newFilters = {...filters, ...updateOptions.filters};
      updateFilters(newFilters);
    }
    if (updateOptions.pagination) {
      updatePagination(updateOptions.pagination);
    }
  };

  updateFilters = (newFilters) => {
    const {filters, patternDescription} = this.props;
    const processedFilters = {...newFilters};
    if (patternDescription?.renderingStrategy?.clearFilterOnContextTrigger &&
      has(newFilters, 'showContextInfo') &&
      filters?.showContextInfo !== newFilters?.showContextInfo
    ) {
      processedFilters.filter = {};
    }
    this.props.updateFilters(processedFilters);
  };

  @action
  onDataSourceChange = (value) => {
    const {props: {probe, stage, filters}, updateFilters} = this;
    const patternDescription = checkForPatterns({probe, stageName: stage.name, dataSource: value});
    const changes = {dataSource: value};
    if (value === STAGE_DATA_SOURCE.telemetry_service_warnings ||
      filters.dataSource === STAGE_DATA_SOURCE.telemetry_service_warnings) {
      if (filters.filter) changes.filter = {};
    }
    if (value === STAGE_DATA_SOURCE.time_series) {
      if (!filters.timeSeriesDuration) {
        changes.timeSeriesDuration = DEFAULT_STAGE_TIME_SERIES_DURATION;
      }
      if (filters.showContextInfo && !patternDescription?.renderingStrategy?.alwaysUseContext) {
        changes.showContextInfo = false;
      }
    } else if (value === STAGE_DATA_SOURCE.telemetry_service_warnings) {
      if (filters.spotlightMode) {
        changes.spotlightMode = false;
      }
    } else if (patternDescription) {
      changes.showContextInfo = !patternDescription.renderingStrategy?.hiddenContextByDefault;
    }
    updateFilters({...filters, ...changes});
  };

  @action
  onUpdateValueColumnName = (valueColumnName) => {
    const {props: {filters, stage, patternDescription}, updateFilters} = this;
    const aggregationType = getDefaultAggregationType(stage, valueColumnName, patternDescription);
    updateFilters({...filters, valueColumnName, aggregationType});
  };

  getDataTableCellProps = ({name, item}) => {
    const {renderingStrategy: {noSpotlightMode}, stage} = this.props;
    const valueCell = name in this.valueSchemas;
    const expandableValueCell = name in stage.values && !noSpotlightMode;
    return ({
      onClick: expandableValueCell && this.maySwitchSpotlightMode ? () => this.switchToSpotlightMode(item.id) : null,
      className: cx({
        'value-cell': valueCell,
        expandable: expandableValueCell,
        highlight: this.highlightedColumn === name,
      })
    });
  };

  getSpotlightModeDataTableProps = ({name, params}) => ({
    label: params.labels[name]
  });

  renderSpotlightItemValue = () => {
    const {props: {stageItems}, valueColumnName} = this;
    const Renderer = this.renderChosenValueColumnAs;
    const item = stageItems[0];
    return (
      <Renderer {...{
        item,
        name: valueColumnName,
        value: item[valueColumnName],
        index: 0,
        items: stageItems,
        expanded: true,
        ...this.rendererProps
      }}
      />
    );
  };

  renderCombinedGraphs = () => {
    const {stageItems} = this.props;
    const Renderer = this.renderChosenValueColumnAs;
    return (
      <Renderer {...{
        item: stageItems[0],
        index: 0,
        items: stageItems,
        expanded: true,
        ...this.rendererProps
      }}
      />
    );
  };

  renderStageDataTableFooterContent = (wrap, colSpan) => {
    const {tableSchema, props: {stageLink}} = this;
    if (!stageLink) return null;
    const footer = (
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell
            className={cx('stage-footer', {standalone: wrap})}
            colSpan={colSpan || tableSchema.length}
            textAlign='center'
          >
            {stageLink}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    );
    return wrap ? <Table size='small'>{footer}</Table> : footer;
  };

  wrapWithLoader(renderContent, noDataContent, alwaysShownContent = null) {
    const {loaderVisible, fetchDataError} = this.props;

    return loaderVisible ?
      <Placeholder className='stage-placeholder-background' fluid>
        {times(4, (index) =>
          <Placeholder.Paragraph key={index}>
            {times(10, (index) =>
              <Placeholder.Line key={index} />
            )}
          </Placeholder.Paragraph>
        )}
      </Placeholder>
      :
      <>
        {alwaysShownContent}
        {noDataContent || renderContent()}
        {fetchDataError && <FetchDataError error={fetchDataError} />}
      </>;
  }

  renderSpotlightMode(noDataContent) {
    const {
      activePage, pageSize, updatePagination, totalCount,
      probe, stage, stageItems, stageLink
    } = this.props;
    const {blueprintId} = this.context;

    const paginationProps = {
      activePage,
      pageSize,
      pageSizes: [1],
      totalCount,
      onChange: updatePagination,
    };

    const tableParams = {
      labels: transform(this.tableSchema, (result, schemaItem) => {
        result[schemaItem.name] = schemaItem.label;
      }, {}),
      blueprintId, probe, stage
    };

    return this.wrapWithLoader(
      () => (
        <div className='item-set-container'>
          <div // eslint-disable-line jsx-a11y/no-static-element-interactions
            ref={this.spotlightViewRef}
            tabIndex={-1}
            onKeyDown={this.onSpotlightModeKeyDown}
            className='spotlight-mode-container'
          >
            <div className='spotlight-mode-item item-set'>
              <div className='spotlight-mode-item-value'>
                {this.renderSpotlightItemValue()}
                {this.maySwitchSpotlightMode &&
                  <Icon link name='close' onClick={this.switchToListMode} />
                }
              </div>
              <Table celled size='small' className='schema-table'>
                <Table.Body>
                  <DataTableRowFragment
                    schema={this.tableSchema}
                    item={stageItems[0]}
                    params={tableParams}
                    getCellProps={this.getSpotlightModeDataTableProps}
                    CellComponent={SpotlightKeysTableRow}
                  />
                </Table.Body>
                {stageLink &&
                  this.renderStageDataTableFooterContent(false, 2)
                }
              </Table>
            </div>
            <div className='pagination-centered'>
              <Pagination separateButtons {...paginationProps} />
            </div>
          </div>
        </div>
      ),
      noDataContent
    );
  }

  @computed
  get searchSchema() {
    const {processor, stage, stageItems, dataSource} = this.props;
    const {processorDefinitions, isFreeform} = this.context;
    return getStageSearchSchema({processorDefinitions, processor, stage, stageItems, dataSource, isFreeform});
  }

  @computed get valueColumnOptions() {
    return getPossibleValueColumnOptions(this.props.stage);
  }

  @computed get dataSourceOptions() {
    const {stageHasPersistedData, props: {processor}} = this;
    return filter([
      {key: STAGE_DATA_SOURCE.real_time, value: STAGE_DATA_SOURCE.real_time, text: 'Real Time'},
      stageHasPersistedData ?
        {key: STAGE_DATA_SOURCE.time_series, value: STAGE_DATA_SOURCE.time_series, text: 'Time Series'} :
        null,
      processorCanRaiseWarnings(processor) ?
        {
          key: STAGE_DATA_SOURCE.telemetry_service_warnings,
          value: STAGE_DATA_SOURCE.telemetry_service_warnings,
          text: 'Telemetry Service Warnings'
        } :
        null
    ]);
  }

  @computed get isTimeSeriesControl() {
    const {stageDataSourceIsTimeSeries, usePatternWithPersistedData} = this;
    return (stageDataSourceIsTimeSeries || usePatternWithPersistedData);
  }

  @computed get showPersistedDataControl() {
    const {stageHasPersistedData, usePatternWithPersistedData, usePatternWithRawPersistedData, mayCombineGraphs,
      dataSourceOptions, isTimeSeriesControl, valueColumnOptions,
      props: {processor}} = this;
    return (
      stageHasPersistedData || usePatternWithPersistedData ||
      usePatternWithRawPersistedData || processorCanRaiseWarnings(processor)
    ) && (
      dataSourceOptions.length > 1 || mayCombineGraphs ||
        (isTimeSeriesControl && valueColumnOptions.length > 1) ||
        usePatternWithRawPersistedData
    );
  }

  getFilteringControls() {
    const {
      stageDataSourceIsTimeSeries, onDataSourceChange, usePatternWithPersistedData, usePatternWithRawPersistedData,
      onUpdateValueColumnName, valueColumnName, mayCombineGraphs, updateFilters, aggregationTypeOptions,
      dataSourceOptions, isTimeSeriesControl, valueColumnOptions, showPersistedDataControl,
      props: {compact, filters}
    } = this;

    return !compact &&
      <Fragment>
        {showPersistedDataControl &&
          <Grid.Row>
            <Grid.Column width={16}>
              <div className='stage-data-controls'>
                {dataSourceOptions.length > 1 &&
                  <DropdownControl
                    value={filters.dataSource || DEFAULT_STAGE_DATA_SOURCE}
                    selectedValueLabel='Data source: '
                    options={dataSourceOptions}
                    onChange={onDataSourceChange}
                    className='data-source'
                  />}
                {stageDataSourceIsTimeSeries && valueColumnOptions.length > 1 && (
                  <ValueColumnNameInput
                    options={valueColumnOptions}
                    value={valueColumnName}
                    onChange={(value) => onUpdateValueColumnName(value)}
                  />
                )}
                {mayCombineGraphs &&
                  <DropdownControl
                    value={filters.combineGraphs || DEFAULT_COMBINE_GRAPHS_MODE}
                    selectedValueLabel=' '
                    options={[
                      {key: COMBINE_GRAPHS_MODE.NONE, value: COMBINE_GRAPHS_MODE.NONE,
                        text: 'Separate graphs'},
                      {key: COMBINE_GRAPHS_MODE.LINEAR, value: COMBINE_GRAPHS_MODE.LINEAR,
                        text: 'Combine graphs: Linear'},
                      {key: COMBINE_GRAPHS_MODE.STACKED, value: COMBINE_GRAPHS_MODE.STACKED,
                        text: 'Combine graphs: Stacked'},
                    ]}
                    onChange={(value) => updateFilters({...filters, combineGraphs: value})}
                  />
                }
                {usePatternWithRawPersistedData && (
                  <>
                    {usePatternWithPersistedData && (
                      <AggregationInput
                        value={filters.timeSeriesAggregation}
                        disabled={filters.aggregationType === 'none'}
                        selectedValueLabel='Time Series Aggregation: '
                        onChange={(timeSeriesAggregation) => updateFilters(
                          {...filters, timeSeriesAggregation}, {resetActivePage: false})}
                      />
                    )}
                    <DurationInput
                      value={filters.timeSeriesDuration}
                      selectedValueLabel='Time Series Duration: '
                      customValueType='dates'
                      onChange={(timeSeriesDuration) => updateFilters(
                        {...filters, timeSeriesDuration}, {resetActivePage: false})}
                    />
                  </>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        }
        {isTimeSeriesControl && !usePatternWithRawPersistedData &&
          <Grid.Row>
            <Grid.Column width={16}>
              <div className='stage-aggregation-controls'>
                <AggregationTypeInput
                  selectedValueLabel='Aggregation Type: '
                  value={filters.aggregationType}
                  onChange={(aggregationType) => updateFilters(
                    {
                      ...filters,
                      aggregationType,
                      timeSeriesAggregation: aggregationType === 'none' ?
                        0 : filters.timeSeriesAggregation || DEFAULT_STAGE_TIME_SERIES_AGGREGATION
                    },
                    {resetActivePage: false}
                  )}
                  options={aggregationTypeOptions}
                  clearable
                />
                <AggregationInput
                  value={filters.timeSeriesAggregation}
                  disabled={filters.aggregationType === 'none'}
                  selectedValueLabel='Time Series Aggregation: '
                  aggregations={filters.aggregationType === 'none' ?
                    aggregationIntervalOptionsWithOff : aggregationIntervalOptions}
                  onChange={(timeSeriesAggregation) => updateFilters(
                    {...filters, timeSeriesAggregation}, {resetActivePage: false})}
                />
                <DurationInput
                  value={filters.timeSeriesDuration}
                  selectedValueLabel='Time Series Duration: '
                  customValueType='dates'
                  onChange={(timeSeriesDuration) => updateFilters(
                    {...filters, timeSeriesDuration}, {resetActivePage: false})}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        }
        {this.availableOptions.length > 0 &&
          <Grid.Row>
            <Grid.Column width={16}>
              <div className='options'>
                {this.availableOptionsControls}
              </div>
            </Grid.Column>
          </Grid.Row>
        }
      </Fragment>;
  }

  renderFilteredData(noDataContent) {
    const {
      tableSchema,
      anomaliesByItems,
      renderStageDataTableFooterContent,
      renderCombinedGraphs, mayCombineGraphs,
      updateFilters, highlightPropertyColumn, highlightedColumn,
      props: {
        compact, probe, stage,
        loaderVisible, fetchDataError,
        activePage, pageSize, updatePagination,
        filters, stageItems,
        sorting, updateSorting,
        totalCount, renderingStrategy
      },
      context: {blueprintId},
    } = this;
    const {spotlightMode} = filters;
    const pageSizes = spotlightMode ? [1] : DEFAULT_PAGE_SIZES;
    const {
      noAnomalyHighlighting, noSearch, noPagination = null,
      SearchComponent = StageSearchBox
    } = renderingStrategy;
    const tableParams = {highlightedColumn, blueprintId, probe, stage};
    const paginationProps = {
      activePage,
      pageSize,
      pageSizes,
      totalCount,
      onChange: updatePagination,
    };

    return (
      <DataFilteringLayout
        loaderVisible={loaderVisible}
        fetchDataError={fetchDataError}
        hideActionsAndPagination={compact || spotlightMode}
        paginationProps={(noPagination || spotlightMode) ? undefined : paginationProps}
        SearchComponent={SearchComponent}
        searchProps={{
          filters: filters.filter,
          schema: this.searchSchema,
          renderers: [regexFilterRenderer, exactOrRegexFilterRenderer, rangeFilterRenderer],
          disabled: noSearch,
          onChange: (filter) => updateFilters({...filters, filter}),
          highlightPropertyColumn,
          'aria-label': 'Stage filter query',
          asAccordion: false
        }}
        extraContent={{
          headerRow: this.getFilteringControls()
        }}
      >
        {
          spotlightMode ? this.renderSpotlightMode(noDataContent) : (
          noDataContent || (
            mayCombineGraphs && filters.combineGraphs !== COMBINE_GRAPHS_MODE.NONE ?
              <div className='combined-graphs'>
                {renderCombinedGraphs()}
                {renderStageDataTableFooterContent(true)}
              </div>
            :
              <DataTable
                className='item-set'
                size='small'
                items={stageItems}
                schema={tableSchema}
                sortable={!compact}
                sorting={sorting}
                updateSorting={updateSorting}
                getHeaderCellProps={({name}) => ({
                  collapsing: !(name in stage.values),
                  className: cx({highlight: name === highlightedColumn})
                })}
                getCellProps={this.getDataTableCellProps}
                getRowProps={({item}) => ({
                  warning: !isEmpty(item.warning),
                  error: !noAnomalyHighlighting && item.id in anomaliesByItems,
                })}
                getItemKey={({id}) => id}
                footer={
                  renderStageDataTableFooterContent(false)
                }
                params={tableParams}
              />
          )
          )
        }
      </DataFilteringLayout>
    );
  }

  render() {
    const {
      renderStageDataTableFooterContent,
      highlightPropertyColumn,
      updateFilters,
      props: {filters, stageItems, stage, processor, noSearch, renderingStrategy}
    } = this;
    const {StageDataRenderer, noDataMessage, SearchComponent = StageSearchBox} = renderingStrategy;

    const noDataContent = isEmpty(stageItems) ?
      <div className='no-data'>
        {
          filters.anomalousOnly ?
            <Message success icon='check circle' header='No anomalies!' />
          :
            <Message info icon='info circle' content={noDataMessage || 'No data'} />
        }
        {this.renderStageDataTableFooterContent(true)}
      </div> :
      null;

    const stageControls = (
      <Grid stackable className='data-filtering-layout'>
        {this.getFilteringControls()}
        <Grid.Row>
          <Grid.Column width={16}>
            <SearchComponent
              filters={filters.filter}
              stage={stage}
              stageItems={stageItems}
              processor={processor}
              dataSource={filters.dataSource}
              disabled={noSearch}
              onChange={(filter) => updateFilters({...filters, filter})}
              highlightPropertyColumn={highlightPropertyColumn}
              aria-label='Stage filter query'
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );

    const controls = StageDataRenderer ?
      [
        () => (
          <>
            <StageDataRenderer {...this.props} />
            {renderStageDataTableFooterContent(true)}
          </>
        ),
        noDataContent,
        stageControls
      ] :
      [
        () => (
          <div className='item-set-container'>
            {this.renderFilteredData(noDataContent)}
          </div>
        )
      ];

    return this.wrapWithLoader(...controls);
  }
}
