import {observer} from 'mobx-react';
import {useMemo} from 'react';
import cx from 'classnames';
import {first, isNil, last, map, mean} from 'lodash';

import {RAIL_MIN_OFFSET, STATE_CLASSES, STATUS_CLASSES} from './constants';

import {useLinkTooltip, useTooltip} from '../../GraphTooltips';

import './Rail.less';

const Rail = observer((props) => {
  const {curves, className} = useRail(props);
  return (
    <g className={className}>{curves}</g>
  );
});

export default Rail;

const gatherRailLinks = (links, fromLeft) => {
  const fn = fromLeft ? first : last;
  const points = map(links, ({coordinates}) => fn(coordinates));
  const middleY = mean(map(points, 'y'));
  return {points, middleY};
};

const useRail = ({links, railsOrder, linkSelection, hoveredLink, outline}) => {
  const {sharedTooltip} = useTooltip();
  const linkTooltipHandler = useLinkTooltip();

  const railIndex = first(links)?.sourceLink?.railIndex ?? null;
  const railOrder = railsOrder?.[railIndex] ?? 0;

  const [left, right] = useMemo(
    () => [gatherRailLinks(links, true), gatherRailLinks(links)],
    [links]
  );

  const curves = useMemo(() => {
    if (isNil(railIndex)) return null;

    const offset = RAIL_MIN_OFFSET + railOrder * 10;
    return map(
      left.points,
      (leftPoint, index) => {
        const link = links[index];
        const sourceLink = link?.sourceLink;
        const rightPoint = right.points[index];
        const middleX = (rightPoint.x - leftPoint.x) / 2 - offset;
        const oy = index * 2;
        const coordinates = [
          `M ${leftPoint.x} ${leftPoint.y}`,
          `q ${offset} 0 ${offset} ${left.middleY - leftPoint.y + oy}`,
          `c ${middleX} 0`,
          `${middleX} ${right.middleY - left.middleY}`,
          `${rightPoint.x - leftPoint.x - 2 * offset} ${right.middleY - left.middleY}`,
          `q 0 ${rightPoint.y - right.middleY - oy} ${offset} ${rightPoint.y - right.middleY - oy}`
        ];

        const selected = !!linkSelection?.[sourceLink?.id];
        const hovered = sourceLink?.id === hoveredLink?.sourceLink.id;
        const stateClass = STATE_CLASSES[link?.state];
        const statusClass = STATUS_CLASSES[link?.status];

        const classes = cx('link', stateClass, statusClass, {
          selected: hovered ? false : selected,
          hovered: hovered
        });

        return (
          <path
            key={index}
            className={classes}
            onMouseOver={linkTooltipHandler(sourceLink)}
            onMouseOut={sharedTooltip.hide}
            d={coordinates.join(' ')}
          />
        );
      }
    );
  }, [hoveredLink?.sourceLink.id, left.middleY, left.points, linkSelection, linkTooltipHandler, links, railIndex,
    railOrder, right.middleY, right.points, sharedTooltip.hide]);

  const className = cx('rail', `rail${railIndex}`, {outline, hovered: hoveredLink?.sourceLink.railIndex === railIndex});

  return {curves, railIndex, className};
};
