import ace from 'ace-builds';
import {syncSessionStorageItems, setDefaultHeadersProvider} from 'apstra-ui-common';

import 'ace-builds/src-min-noconflict/mode-json';
import 'ace-builds/src-min-noconflict/mode-yaml';

import './pythonExpression/ace-mode-python-expression';
import './pythonExpression/ace-mode-graph-query';
import './pythonExpression/ace-mode-custom-telemetry-collection-query';
import './cypher/ace-mode-cypher';
import './matcherFilterString/ace-mode-matcher-filter-string';
import './luceneApstra/ace-mode-lucene-apstra';
import './jinja/ace-mode-jinja';
import './telemetry/ace-mode-junos-cli';

import jsonWorkerUrl from 'file-loader?name=static/js/ace-worker-json.[hash:8].js!ace-builds/src-min-noconflict/worker-json'; // eslint-disable-line
import yamlWorkerUrl from 'file-loader?name=static/js/ace-worker-yaml.[hash:8].js!ace-builds/src-min-noconflict/worker-yaml'; // eslint-disable-line

import '../styles/index.less';

import captureErrors from './captureErrors';

import * as exports from './exports';

import './fonts/apstra-icons.font';
import './fonts/apstra-sidebar-icons.font';

import '../../target/app/static/cljs/core'; // eslint-disable-line import/no-unresolved

captureErrors();

Object.assign(window, exports);

ace.config.setModuleUrl('ace/mode/json_worker', jsonWorkerUrl);
ace.config.setModuleUrl('ace/mode/yaml_worker', yamlWorkerUrl);

syncSessionStorageItems(['aos_api_token', 'aos_user_id']);
setDefaultHeadersProvider(() => ({AuthToken: sessionStorage.getItem('aos_api_token')}));
