import {useRef} from 'react';
import {Icon} from 'semantic-ui-react';
import cx from 'classnames';
import {observer} from 'mobx-react';
import {Checkbox} from 'apstra-ui-common';

import {interfacePropTypes} from './shared-prop-types';
import {STATUS_CLASSES, STATE_ICONS} from './constants';
import {InterfaceTooltipContent, useTooltip} from '../../GraphTooltips';
import {PropertiesList} from '../../../../iba/components/graphs/HeadroomPopup';

import './Interface.less';

const Interface = observer((props) => {
  const viewRef = useRef();
  const {sharedTooltip} = useTooltip();
  const {marginBottom, marginTop, height, blueprintId, selected, highlighted, hovered, setHoveredIf,
    onSelect, nodeId} = props;
  const {className, label, state, status, operation_state: operationState,
    ipv4Addr, ipv6Addr, mapping, properties, propertiesSchema, id, CT, tags} = props;
  const statusClass = STATUS_CLASSES[status];
  const selectable = !!onSelect;
  const down = operationState === 'admin_down' || operationState === 'deduced_down';
  const classes =
    cx('interface',
      statusClass,
      className,
      {highlighted: hovered ? false : highlighted,
        hovered: hovered,
        down: down});
  const style = {
    lineHeight: `${height}px`,
    height,
    marginBottom,
    marginTop
  };

  const stateIcon = STATE_ICONS[state];

  const tooltipProps = {label, ipv4Addr, ipv6Addr, CT, tags, operation_state: operationState};

  const tooltip = (
    <>
      <InterfaceTooltipContent {...tooltipProps} />
      <PropertiesList properties={properties} schema={propertiesSchema} />
    </>
  );

  const showTooltip = (e) => {
    e.stopPropagation();
    sharedTooltip.show(tooltip);
  };

  const hideTooltip = (e) => {
    e.stopPropagation(e);
    sharedTooltip.hide();
  };

  const hover = () => {
    if (setHoveredIf && onSelect) {
      setHoveredIf(id);
    }
  };

  const unhover = () => {
    if (setHoveredIf && onSelect) {
      setHoveredIf(null);
    }
  };

  return (
    <div
      ref={viewRef}
      className={classes}
      style={style}
      onMouseOver={(e) => {
        e.preventDefault();
        showTooltip(e);
        hover();
      }}
      onMouseOut={(e) => {
        e.preventDefault();
        hideTooltip(e);
        unhover();
      }}
      onFocus={showTooltip}
      onBlur={hideTooltip}
    >
      {selectable &&
        <Checkbox
          checked={!!selected}
          aria-label='Select interface'
          onChange={() => onSelect(blueprintId, nodeId, label, id, mapping?.[0], viewRef)}
        />
      }
      <div className='label'>
        {stateIcon &&
          <Icon name={stateIcon} />
        }
        {label}
        <Icon name='warning sign' />
      </div>
    </div>
  );
});

Interface.propTypes = interfacePropTypes;

export {Interface};

