import {TimelineGraphContainer} from 'apstra-ui-common';

import DiscreteStateTimelineGraph from '../../../components/graphs/DiscreteStateTimeline';

const DiscreteStateTimeline = ({item, expanded = false, stageDataProps: {filters: {timeSeriesAggregation}},
  valueColumnName, stageGraphColors, valueSchemas}) => {
  const {possible_values: possibleValues} = valueSchemas[valueColumnName];
  const axes = {
    x: {showGrid: true},
    y: {showGrid: true}
  };
  return (
    <TimelineGraphContainer
      axes={axes}
      samples={item.persisted_samples}
      expanded={expanded}
      timeSeriesAggregation={timeSeriesAggregation}
      useCurrentTimeAsTimelineEnd={timeSeriesAggregation === 0}
    >
      <DiscreteStateTimelineGraph
        possibleValues={possibleValues}
        colors={stageGraphColors}
        valueKeyName={valueColumnName}
      />
    </TimelineGraphContainer>
  );
};

export default DiscreteStateTimeline;
