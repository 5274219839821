import {useState} from 'react';
import {min, minBy, isNil, isUndefined} from 'lodash';
import cx from 'classnames';
import bounds from 'binary-search-bounds';
import {CHART_VALUE_CIRCLE_RADIUS, GenericChartHoverHandler} from 'apstra-ui-common';

import './ChartHoverHandler.less';

const getColor = (highlightProps) => {
  if (!highlightProps?.sample) {
    return null;
  }
  return highlightProps.sample.color;
};

const ChartHoverHandler = (props) => {
  const [highlightProps, setHighlightProps] = useState(null);

  const highlighted = !isNil(highlightProps?.sampleX);

  const resetHighlight = () => {
    setHighlightProps(null);
    props.hidePopup();
  };

  const getRefreshHighlightFn = ({x, reset, popupAnchorRef}) => {
    return () => {
      const {samples, sampleTimes, xScale} = props;
      const xTimeStamp = xScale.invert(x);
      const geIndex = bounds.ge(sampleTimes, xTimeStamp);
      const leIndex = geIndex > 0 ? geIndex - 1 : geIndex;
      const sampleIndex = minBy(
        [geIndex, leIndex],
        (idx) => Math.abs(xScale(sampleTimes[idx]) - x)
      );
      const sampleProps = sampleIndex > -1 && !isUndefined(samples[sampleIndex]) ? {
        sample: samples[sampleIndex],
        sampleIndex,
        sampleX: xScale(sampleTimes[sampleIndex])
      } : null;

      if (sampleProps) {
        setHighlightProps({
          ...sampleProps,
          x,
        });
        const {generatePopupContentFn, showPopup, formatValue, valueKeyName, popupProps = {}} = props;
        const {sample} = sampleProps;
        showPopup({
          node: popupAnchorRef?.current,
          timestamp: sample.timestamp,
          header: formatValue(sample[valueKeyName]),
          additionalInfo: sample.popupExtraInfo,
          ...generatePopupContentFn && {
            custom: true,
            content: generatePopupContentFn(sample),
          },
          popupProps,
        });
      } else {
        reset();
      }
    };
  };

  const color = getColor(highlightProps);

  const {
    samples, width, height, yScale, yScaleMin, valueKeyName, selectedSampleId, children
  } = props;

  const highlightedSampleValue = yScale(highlightProps?.sample?.[valueKeyName]);

  return (
    <GenericChartHoverHandler
      width={width}
      height={height}
      values={samples}
      sampleX={highlightProps?.sampleX}
      lineHidden={!highlighted}
      lineClassName={{[`graph-color-${color}`]: color}}
      getHandlerFn={getRefreshHighlightFn}
      onReset={resetHighlight}
    >
      {children}
      {!isUndefined(highlightedSampleValue) && isFinite(highlightedSampleValue) &&
        <circle
          key='circle'
          className={cx(
            'chart-hover-handler-circle',
            {
              hidden: !highlighted,
              [`graph-color-${color}`]: color,
              selected: selectedSampleId === highlightProps?.sampleIndex
            })}
          cx={highlightProps?.sampleX}
          cy={min([highlightedSampleValue, yScaleMin])}
          r={CHART_VALUE_CIRCLE_RADIUS}
        />
      }
    </GenericChartHoverHandler>
  );
};

export default ChartHoverHandler;
