import {filter, find, forEach, groupBy, includes, invert, isMatch, keys, map, mapValues, pick,
  pullAllWith, set, sortBy, startsWith, transform} from 'lodash';
import {action, computed, makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';
import {Component} from 'react';
import {Button} from 'semantic-ui-react';

import IBAContext from '../../../IBAContext';
import ProcessorModal from '../../ProcessorModal';
import {deleteProcessor} from '../../ProbeProcessor';
import {generateNewInputName} from '../../../stageUtils';
import GraphProcessorView from './GraphProcessorView';
import TreeGraphCanvas from './TreeGraphCanvas';
import {checkConnectorsCompatibility, getProcessorSize} from './utils';
import GraphCanvasContext from './GraphCanvasContext';

import './TreeProbeGraph.less';

function orderLinks(links, processorDefinitions) {
  const linksByProcessor = groupBy(links, 'outputProcessor.name');

  const result = transform(linksByProcessor, (result, links) => {
    const processor = links[0].outputProcessor;
    const outputTypeByName = invert(processor.outputs);
    const {outputs} = find(processorDefinitions, {name: processor.type});
    const outputOrder = mapValues(invert(keys(outputs)), Number);
    const orderedLinks = sortBy(links, (link) => outputOrder[outputTypeByName[link.from]]);
    result.push(...orderedLinks);
  }, []);
  return result;
}

@observer
export default class TreeProbeGraph extends Component {
  static contextType = IBAContext;

  @observable processorModalOpen = false;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @action
  openProcessorModal = () => {
    this.processorModalOpen = true;
  };

  @action
  closeProcessorModal = () => {
    this.processorModalOpen = false;
  };

  @action
  onConnectLinks = (linkA, linkB) => {
    const [input, output] = linkA.type === 'output' ? [linkB, linkA] : [linkA, linkB];
    const value = {stage: output.stage};
    const inputName = input.type === 'add' ? generateNewInputName(input.processor) : input.id;
    set(input.processor.inputs, inputName, value);
    pullAllWith(this.props.errors, [
      {type: 'processors'},
      {type: 'processor', processorName: input.processor.name},
      {type: 'processorInput', processorName: input.processor.name, inputName},
    ], isMatch);
  };

  @computed get processorsGraph() {
    const {probe, highlights, editable, highlightStageAndRelatedProcessors,
      highlightCurrentEntity, highlightProcessorAndRelatedStages} = this.props;
    const {processorDefinitions} = this.context;
    const {processors} = probe;
    const graph = transform(processors, ({nodes, links}, processor) => {
      const connectors = GraphProcessorView.getConnectors(processor, processorDefinitions, editable);
      links.push(...filter(map(processor.inputs, ({stage}, inputName) => ({
        id: stage + processor.name + inputName,
        from: stage,
        to: processor.name + inputName,
        highlighted: highlights.stages[stage]?.highlightConnections ||
          highlights.processors[processor.name]?.highlightConnections,
        inputProcessor: processor,
        outputProcessor: find(processors, ({outputs}) => includes(outputs, stage)),
        inputName,
      })), 'from'));
      nodes[processor.name] = {
        content: <GraphProcessorView
          connectors={connectors}
          processor={processor}
          highlightStageAndRelatedProcessors={highlightStageAndRelatedProcessors}
          highlightCurrentEntity={highlightCurrentEntity}
          highlightProcessorAndRelatedStages={highlightProcessorAndRelatedStages}
          highlights={highlights}
        />,
        connectors,
        size: getProcessorSize(processor),
        processor,
        id: processor.name,
        isDraggable: true,
      };
    }, {nodes: {}, links: [], children: {}, parents: {}});
    graph.links = orderLinks(graph.links, this.context.processorDefinitions);
    const processorsByOut = transform(processors, (acc, processor) => {
      forEach(processor.outputs, (id) => { acc[id] = processor; });
    }, {});
    return transform(processors, ({children, parents}, processor) => {
      forEach(processor.inputs, ({stage: stageName}) => {
        const sourceProcessor = processorsByOut[stageName];
        if (!sourceProcessor) return;
        if (!children[sourceProcessor.name]) children[sourceProcessor.name] = [processor.name];
        else children[sourceProcessor.name].push(processor.name);
        if (!parents[processor.name]) parents[processor.name] = [sourceProcessor.name];
        else parents[processor.name].push(sourceProcessor.name);
      });
    }, graph);
  }

  @computed get processorsWithErrors() {
    return this.props.errors.reduce((result, error) => {
      if ('processorName' in error && startsWith(error.type, 'processor')) {
        result[error.processorName] = true;
      }
      return result;
    }, {});
  }

  @computed get processorStagesWithErrors() {
    return this.props.errors.reduce((result, error) => {
      if (error.type === 'stageProperty') set(result, [error.processorName, error.stageName], true);
      return result;
    }, {});
  }

  @action
  deleteProcessor = (processor) => {
    deleteProcessor({
      ...pick(this.props, ['probe', 'errors', 'setCurrentProcessorName']),
      processor
    });
  };

  @action
  deleteLink = (link) => {
    const {inputProcessor, inputName} = link;
    set(inputProcessor.inputs, inputName, {});
  };

  render() {
    const {closeProcessorModal, deleteLink, deleteProcessor, onConnectLinks, processorModalOpen,
      processorStagesWithErrors, processorsGraph, processorsWithErrors} = this;
    const {editable, probe, setCurrentProcessorName} = this.props;
    const {processorDefinitions} = this.context;
    return (
      <GraphCanvasContext.Provider
        value={{
          ...pick(this.props, [
            'currentProcessor', 'currentStageName', 'setCurrentProcessorName', 'setCurrentStageName',
            'highlightCurrentEntity', 'highlightProcessorAndRelatedStages', 'highlightStageAndRelatedProcessors',
            'highlights', 'warningCountByStageName', 'probe', 'editable'
          ]),
          connectorsCompatibilityFn: (c1, c2) => checkConnectorsCompatibility(c1, c2, {
            processorDefinitions,
          }),
          deleteProcessor,
          processorsWithErrors,
          processorStagesWithErrors,
        }}
      >
        {editable &&
          <div className='probe-graph__control-panel'>
            <Button
              basic
              icon='plus'
              content='Add processor'
              onClick={this.openProcessorModal}
            />
          </div>
        }
        <div className='probe-graph__scrollable-container'>
          <TreeGraphCanvas
            graph={processorsGraph}
            editable={editable}
            onConnectLinks={onConnectLinks}
            onLinkClose={deleteLink}
          />
        </div>
        <ProcessorModal
          key='processor-modal'
          open={processorModalOpen}
          onClose={closeProcessorModal}
          probe={probe}
          onSuccess={({result: processorName}) => setCurrentProcessorName(processorName)}
        />
      </GraphCanvasContext.Provider>
    );
  }
}
