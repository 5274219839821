import {scaleLinear} from 'd3';
import cx from 'classnames';
import {FormattedNumber} from 'apstra-ui-common';

import './SingleHorizontalBar.less';

const xMin = 0;
const xMax = 100;

export default function SingleHorizontalBar({
  value = 0, minValue = 0, maxValue = 0,
  units = '', extraContent,
}) {
  const domain = [minValue < 0 ? minValue : 0, maxValue];
  const isCollapsedDomain = domain[0] === domain[1];
  const xScale = scaleLinear().range([xMin, xMax]).domain(domain);
  const x = isCollapsedDomain ? 0 : xScale(value);
  const x0 = isCollapsedDomain ? 0 : xScale(0);

  return (
    <div className='single-horizontal-bar graph-container'>
      <div className='bar-value'>
        <FormattedNumber value={value} units={units} />
        {extraContent}
      </div>
      <div className='bar-container'>
        <div
          className={cx('horizontal-bar', {negative: value < 0})}
          style={{width: Math.abs(x - x0) + '%', left: (value < 0 ? x : x0) + '%'}}
        />
      </div>
    </div>
  );
}
