/* eslint-disable max-len */
import {
  castArray, every, includes, isEmpty, isEqual, isUndefined, keys, overEvery, size, some, values,
} from 'lodash';
import {FormattedNumber} from 'apstra-ui-common';

import {discreteStateColors} from '../graphColors';

import AnomalousMetrics from './components/AnomalousMetrics';
import TrafficSearchBox, {requestInterfacesTraffic} from './components/TrafficSearchBox';
import {OPTICAL_PROBE_METRICS} from './consts';
import makePrecedingStageColumnDefinition from './makePrecedingStageColumnDefinition';
import {convertValueType} from './stageUtils';
import {
  Probe, ProbeProcessorType, ProbeStageType, ProcessorType, RenderingStrategy, SchemaType, StageDataSource
} from './types';
import {AnomalousOpticalMetric, DeviceTraffic, DiscreteStateTimelineWithLegend,
  GaugeCount, GaugeCountWithRange, GaugePercent, GaugePercentWithRange,
  LineChartWithRange, MissingMacCount, PrimitiveValue, DiscreteStateTimelineWithSamples,
  RoutesStatus} from './components/valueRenderers';

export type ProbePatternMatcherContext = {
  dataSource: StageDataSource,
  probe: Probe,
  processor: ProbeProcessorType,
  stage: ProbeStageType
};

export type ProbePatternSchema = {
  description: string,
  graph: Array<StageSchema & {
    options?: StageSchemaOptions,
  }>,
  renderingStrategy: RenderingStrategy,
  fetchData?: any,
  dataSource: StageDataSource,
};

export type StageSchema = {
  inputName?: string,
  isMetricLoggingEnabled?: boolean,
  outputName?: string,
  processorType?: ProcessorType,
  values?: Record<string, StageSchemaValue>,
}

export type StageSchemaOptions = {
  fetchStageData?: boolean,
  fetchStageDataForOutputs?: string[],
  doNotUseAggregation?: boolean,
}

type StageSchemaValue = {
  type: string,
  hasPossibleValues?: boolean,
}

const accumulateExtraColumnFormatter = ({value}) => <FormattedNumber value={value} />;
const makeAccumulateExtraColumnDefinition = (types, columnDefinition) => ({stage}) => (
  size(stage.values) === 1 && stage.values.value?.type && types.includes(convertValueType(stage.values.value.type))
) ? columnDefinition : null;
const accumulateExtraColumns = [
  makeAccumulateExtraColumnDefinition(['string', 'number'], {
    name: 'count',
    label: 'Count',
    value: ['count'],
    formatter: accumulateExtraColumnFormatter,
    sortable: true,
    type: 'integer',
  }),
  makeAccumulateExtraColumnDefinition(['number'], {
    name: 'sum',
    label: 'Sum',
    value: ['sum'],
    formatter: accumulateExtraColumnFormatter,
    sortable: true,
    type: 'float',
  }),
  makeAccumulateExtraColumnDefinition(['number'], {
    name: 'avg',
    label: 'Average',
    value: ['avg'],
    formatter: accumulateExtraColumnFormatter,
    sortable: true,
    type: 'float',
  }),
  makeAccumulateExtraColumnDefinition(['number'], {
    name: 'std_dev',
    label: 'Standard Deviation',
    value: ['std_dev'],
    formatter: accumulateExtraColumnFormatter,
    sortable: true,
    type: 'float',
  }),
];

const opticalTransceiversExtraColumns = [
  makePrecedingStageColumnDefinition('media_type', 0),
  makePrecedingStageColumnDefinition('vendor_sn', 0),
  // FIXME(vkramskikh): instead of hardcoding stageGraphColors, they need to be derived from the graph,
  // utilizing the same logic used in StageData.stageGraphColors
  makePrecedingStageColumnDefinition('fiber_type', 0, {stageGraphColors: discreteStateColors}),
  makePrecedingStageColumnDefinition('vendor', 0),
  makePrecedingStageColumnDefinition('part_number', 0),
  AnomalousMetrics.columnDefinition,
];

export const probePatternSchemas: ProbePatternSchema[] = [
  // [N] {TS} → Range Check
  {
    description: 'Shows outliers of a time series numeric metric.',
    dataSource: 'real_time',
    graph: [
      {
        values: {value: {type: 'number'}},
        isMetricLoggingEnabled: true,
        options: {
          fetchStageData: true
        }
      },
      {processorType: 'range_check'},
    ],
    renderingStrategy: {
      ValueRenderer: LineChartWithRange,
      usePatternDescriptionAsValueDescription: true,
      noAnomalyHighlighting: true
    },
  },
  // [N] → Range Check {TS} → Time in State {TS}
  {
    description: 'Shows current state and history of sustained outliers of an instantaneous numeric metric.',
    dataSource: 'real_time',
    graph: [
      {
        values: {value: {type: 'number'}},
        isMetricLoggingEnabled: false,
        options: {
          fetchStageData: true
        }
      },
      {
        processorType: 'range_check',
        options: {
          fetchStageData: true
        },
      },
      {
        processorType: 'time_in_state_check',
        values: {value: {type: 'string', hasPossibleValues: true}},
        isMetricLoggingEnabled: true,
        options: {
          doNotUseAggregation: true,
          fetchStageData: true,
        }
      },
    ],
    renderingStrategy: {
      ValueRenderer: DiscreteStateTimelineWithLegend,
      usePatternDescriptionAsValueDescription: true,
      noAnomalyHighlighting: true
    },
  },
  // [N] {TS} → Range Check {TS} → Time in State {TS}
  {
    description: 'Shows current state and history of sustained outliers of a time series numeric metric.',
    dataSource: 'real_time',
    graph: [
      {
        values: {value: {type: 'number'}},
        isMetricLoggingEnabled: true,
        options: {
          fetchStageData: true
        }
      },
      {
        processorType: 'range_check',
        options: {
          fetchStageData: true
        },
      },
      {
        processorType: 'time_in_state_check',
        isMetricLoggingEnabled: true,
        options: {
          doNotUseAggregation: true,
          fetchStageData: true,
        },
      },
    ],
    renderingStrategy: {
      ValueRenderer: DiscreteStateTimelineWithSamples,
      usePatternDescriptionAsValueDescription: true,
      noAnomalyHighlighting: true
    },
  },
  // [DS] → Match Count
  {
    description: 'For each group of series show the number of series which instantaneous values are inside/outside of expected ranges.',
    dataSource: 'real_time',
    graph: [
      {values: {'*': {type: 'string', hasPossibleValues: true}}},
      {processorType: 'match_count'},
    ],
    renderingStrategy: {
      getRenderer: ({name}) => name === 'value' ? GaugeCount : PrimitiveValue,
      includePrecedingProcessorAnomalies: true,
    },
  },
  // [DS] → Match Count → Range Check
  {
    description: 'For each group of series calculate how many series have anomalous values. If this number exceeds a threshold, the whole group is considered anomalous.',
    dataSource: 'real_time',
    graph: [
      {values: {'*': {type: 'string', hasPossibleValues: true}}},
      {
        processorType: 'match_count',
        options: {
          fetchStageData: true
        },
      },
      {processorType: 'range_check'},
    ],
    renderingStrategy: {
      ValueRenderer: GaugeCountWithRange,
      usePatternDescriptionAsValueDescription: true,
    },
  },
  // [DS] → Match Percentage
  {
    description: 'For each group of series show the number of series which instantaneous values are inside/outside of expected ranges.',
    dataSource: 'real_time',
    graph: [
      {values: {'*': {type: 'string', hasPossibleValues: true}}},
      {processorType: 'match_perc'},
    ],
    renderingStrategy: {
      getRenderer: ({name}) => name === 'value' ? GaugePercent : PrimitiveValue,
      usePatternDescriptionAsValueDescription: true,
      includePrecedingProcessorAnomalies: true,
    },
  },
  // [DS] → Match Percentage → Range Check
  {
    description: 'For each group of series calculate how many series have anomalous values. If this number exceeds a threshold, the whole group is considered anomalous.',
    dataSource: 'real_time',
    graph: [
      {values: {'*': {type: 'string', hasPossibleValues: true}}},
      {
        processorType: 'match_perc',
        options: {
          fetchStageData: true
        },
      },
      {processorType: 'range_check'},
    ],
    renderingStrategy: {
      ValueRenderer: GaugePercentWithRange,
      usePatternDescriptionAsValueDescription: true,
    },
  },
  {
    description: 'Utilization Path',
    dataSource: 'real_time',
    graph: [
      {processorType: 'traffic_monitor'},
      {processorType: 'system_utilization', inputName: 'rx_bps'},
    ],
    renderingStrategy: {
      StageDataRenderer: DeviceTraffic,
      noPagination: true,
      noCustomization: true,
      SearchComponent: TrafficSearchBox,
      filterTransformer: TrafficSearchBox.filterTransformer,
      filterSerializer: TrafficSearchBox.filterSerializer,
      filterDeserializer: TrafficSearchBox.filterDeserializer,
      clearFilterOnContextTrigger: true,
      shouldFetchData: TrafficSearchBox.shouldFetchData,
      hiddenContextByDefault: true,
      noDataMessage: 'Select Source and Destination systems to visualize traffic between them',
    },
    fetchData: requestInterfacesTraffic,
  },
  {
    description: 'Accumulate: extra columns (always on)',
    dataSource: 'real_time',
    graph: [
      {processorType: 'accumulate', outputName: 'out'},
    ],
    renderingStrategy: {
      extraColumns: accumulateExtraColumns,
      alwaysUseContext: true,
    },
  },
  {
    description: 'Routes: custom route status renderer and default filter to hide unexpected routes (always on)',
    dataSource: 'real_time',
    graph: [
      {outputName: 'routes'},
    ],
    renderingStrategy: {
      defaultFilter: 'value != "Unexpected"',
      ValueRenderer: RoutesStatus,
      alwaysUseContext: true,
    },
  },
  {
    description: 'MAC Monitor: Missing MAC Counts stage link (always on)',
    dataSource: 'real_time',
    graph: [
      {processorType: 'mac', outputName: 'missing'},
    ],
    renderingStrategy: {
      ValueRenderer: MissingMacCount,
      alwaysUseContext: true,
      noSpotlightMode: true,
    },
  },
  {
    description: 'Optical Transceivers: Interface Stats & Lane Stats (always on)',
    dataSource: 'time_series',
    graph: [
      {
        processorType: 'optical_xcvr',
        options: {
          fetchStageDataForOutputs: ['interface'],
        }
      }
    ],
    renderingStrategy: {
      getRenderer: ({name}) => includes(OPTICAL_PROBE_METRICS, name) ? AnomalousOpticalMetric : null,
      graphCombiningAvailable: ({name}: any = {}) => !includes(OPTICAL_PROBE_METRICS, name),
      alwaysUseContext: true,
      noSpotlightMode: false,
    },
  },
  {
    description: 'Optical Transceivers: Anomalous Metrics for Summarized Interface Status (always on)',
    dataSource: 'real_time',
    graph: [
      {
        processorType: 'optical_xcvr',
        options: {
          fetchStageDataForOutputs: ['interface', 'lane']
        }
      },
      {processorType: 'optical_threshold', inputName: 'rx_power_has_high_alarm'},
    ],
    renderingStrategy: {
      extraColumns: opticalTransceiversExtraColumns,
      alwaysUseContext: true,
    },
  },
  {
    description: 'Optical Transceivers: Anomalous Metrics for Sustained Optical Threshold Anomaly (always on)',
    dataSource: 'real_time',
    graph: [
      {
        processorType: 'optical_xcvr',
        options: {
          fetchStageDataForOutputs: ['interface', 'lane']
        }
      },
      {processorType: 'optical_threshold', inputName: 'rx_power_has_high_alarm'},
      {processorType: 'time_in_state_check'},
    ],
    renderingStrategy: {
      extraColumns: opticalTransceiversExtraColumns,
      alwaysUseContext: true,
    },
  },
  {
    description: 'Environment Processor: Power Supply Count - default aggregation "last" (always on)',
    dataSource: 'real_time',
    graph: [
      {
        values: {
          operational_power_supply_count: {type: 'number'},
          total_power_supply_count: {type: 'number'},
        }
      },
    ],
    renderingStrategy: {
      aggregationType: 'last',
      alwaysUseContext: true,
    },
  },
  {
    description: 'Environment Processor: Total Fan Tray Count - default aggregation "last" (always on)',
    dataSource: 'real_time',
    graph: [
      {values: {total_fan_tray_count: {type: 'number'}}},
    ],
    renderingStrategy: {
      aggregationType: 'last',
      alwaysUseContext: true,
    },
  },
];

type MatcherFunction = (arg: {stageSchema: StageSchema, context: ProbePatternMatcherContext}) => boolean;

const matchers: Record<string, MatcherFunction> = {
  checkEnabledMetricLogging: ({stageSchema, context}) => isUndefined(stageSchema.isMetricLoggingEnabled) ||
  stageSchema.isMetricLoggingEnabled === context.stage.enable_metric_logging,

  checkOutputName: ({stageSchema, context}) => isUndefined(stageSchema.outputName) ||
    context.processor.outputs[stageSchema.outputName] === context.stage.name,

  checkProcessorType: ({stageSchema, context}) => isUndefined(stageSchema.processorType) ||
  stageSchema.processorType === context.processor.type,

  checkValues: ({stageSchema, context}) => {
    if (isUndefined(stageSchema.values)) return true;

    const valueKeys = keys(stageSchema.values);
    if ('*' in stageSchema.values) {
      const areAllValuesSuitable = every(
        castArray(stageSchema.values['*']),
        (patternValue) => some(context.stage.values, (value) => isStageValueSuitable(value, patternValue))
      );
      if (!areAllValuesSuitable) return false;
    } else if (!isEqual(valueKeys.sort(), keys(context.stage.values).sort())) {
      return false;
    }
    return every(stageSchema.values, (value, valueKey) => {
      if (valueKey === '*') return true;
      const stageValue = context.stage.values[valueKey];
      return stageValue && isStageValueSuitable(context.stage.values[valueKey], value);
    });
  }
};

export const checkIfPatternMatches = (stageSchema: StageSchema, context: ProbePatternMatcherContext) =>
  overEvery(values(matchers))({stageSchema, context});

function isStageValueSuitable(stageValue: SchemaType, schemaValue: StageSchemaValue) {
  const {possible_values: possibleValues, type: stageValueType} = stageValue;
  const type = convertValueType(stageValueType);
  const hasPossibleValues = !isEmpty(possibleValues);
  return type === schemaValue.type &&
    (isUndefined(schemaValue.hasPossibleValues) || schemaValue.hasPossibleValues === hasPossibleValues);
}
