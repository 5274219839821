import {Component, Fragment, useContext} from 'react';
import {useToggle} from 'react-use';
import {action, computed, set, remove, makeObservable, observable, reaction} from 'mobx';
import {observer} from 'mobx-react';
import {Popup, Table, Form, Icon} from 'semantic-ui-react';
import {
  map, filter, get, has, keys, includes, transform, pullAllWith, castArray,
  isMatch, isEmpty, find, forEach, some, flatMap, debounce
} from 'lodash';
import {
  FormFragment, ValidationErrors, Value, onEnterKeyHandler, withRouter
} from 'apstra-ui-common';

import {
  PROCESSOR_DYNAMIC_DATA_TYPES,
  PROCESSOR_STATIC_DATA_TYPES,
} from '../consts';
import IBAContext from '../IBAContext';
import humanizeString from '../../humanizeString';
import {isDisabledProperty, isHiddenProperty} from '../processorUtils';
import {PROCESSOR_PROPERTY_CATEGORIES} from '../types';
import {MarkdownDescription} from './MarkdownDescription';
import PythonExpressionParser from '../../pythonExpression/PythonExpressionParser';
import NamedNodeFinder from '../../pythonExpression/NamedNodeFinder';
import {ADDITIONAL_PROPERTIES_FIELD_NAME, processorPropertyRenderers} from '../processorPropertiesRenderers';
import {buildGraphNodesStyles} from '../../graphExplorer/utils';
import {NODE} from '../../pythonExpression/consts';

import './ProcessorProperties.less';

@withRouter
@observer
export default class ProcessorProperties extends Component {
  static contextType = IBAContext;

  constructor(props) {
    super(props);
    makeObservable(this);
    this.disposeNamedNodeFinder = reaction(
      () => this.props.processor.properties.graph_query,
      debounce(this.updateNamedNodes, 500)
    );
    this.updateNamedNodes();
  }

  @observable namedNodesInGraphQueries = [];

  @action
  updateNamedNodes = () => {
    this.namedNodesInGraphQueries = transform(
      castArray(this.props.processor.properties.graph_query),
      (result, graphQuery) => {
        if (graphQuery) {
          const {cst, lexErrors, parseErrors} = PythonExpressionParser.parse(graphQuery);
          if (!lexErrors.length && !parseErrors.length) {
            result.push({namedNodes: NamedNodeFinder.run(cst)});
          } else {
            result.push({errors: {lexErrors, parseErrors}});
          }
        } else {
          result.push({namedNodes: []});
        }
      },
      []
    );
  };

  @action
  onError = (propertyName, errors) => {
    pullAllWith(
      this.props.errors,
      [{
        type: 'processorProperty',
        processorName: this.props.processor.name,
        propertyName,
        isClientValidationError: true,
      }],
      isMatch
    );
    if (some(errors)) {
      this.props.errors.push(...map(errors, (error) => ({
        type: 'processorProperty',
        processorName: this.props.processor.name,
        propertyName,
        message: error,
        isClientValidationError: true,
      })));
    }
  };

  @computed get graphNodesStyles() {
    return buildGraphNodesStyles(keys(this.context.blueprintReferenceDesignSchema?.nodes));
  }

  @computed get knownPythonExpressionVariables() {
    const namedNodes = filter(flatMap(this.namedNodesInGraphQueries, 'namedNodes'), {functionName: NODE});
    const {blueprintReferenceDesignSchema} = this.context;
    const result = transform(namedNodes, (result, {nodeName, nodeType}) => {
      const {color = null, shape = null} = this.graphNodesStyles[nodeType] ?? {};
      forEach(blueprintReferenceDesignSchema?.nodes?.[nodeType]?.properties, (propertySchema, propertyName) => {
        result[nodeName + '.' + propertyName] = {nodeName, nodeType, propertyName, propertySchema, color, shape};
      });
    }, {});
    return result;
  }

  @computed get formSchemasByCategory() {
    const {processor, processorDefinition} = this.props;
    const propertyCategories = processorDefinition.property_categories;
    const formSchema = map(processorDefinition.schema.properties, (schema, name) => {
      const hidden = isHiddenProperty(name, processor);
      const disabled = isDisabledProperty(name, processor, processorDefinition);
      return {
        name, schema, hidden, disabled, required: includes(processorDefinition.schema.required, name)
      };
    });
    const additionalPropertiesSchema = processorDefinition.schema.additionalProperties;
    if (!isEmpty(additionalPropertiesSchema)) {
      const {title, description, ...additionalProperties} = additionalPropertiesSchema;
      formSchema.push({
        name: ADDITIONAL_PROPERTIES_FIELD_NAME,
        schema: {type: 'object', title, description, additionalProperties},
      });
    }
    return {
      ...transform(PROCESSOR_PROPERTY_CATEGORIES, (acc, category) => {
        acc[category] = filter(
          formSchema,
          ({name}) => propertyCategories?.[name] === category
        );
      }, {}),
      advanced: filter(
        formSchema,
        ({name}) => !PROCESSOR_PROPERTY_CATEGORIES.includes(propertyCategories?.[name])
      )
    };
  }

  @computed get service() {
    const {processor, telemetryServiceRegistryItems} = this.props;
    return find(telemetryServiceRegistryItems, {service_name: processor.properties.service_name});
  }

  @computed get serviceRegistryProperties() {
    return get(this.service, ['application_schema', 'properties', 'key', 'properties']);
  }

  @computed get propertyValues() {
    const {processor, processorDefinition} = this.props;
    const condition = processor.type === 'extensible_data_collector' ?
      (name) => !has(this.serviceRegistryProperties, [name]) &&
        !processorDefinition.schema.properties[name] && !includes(processor.properties.keys, name) :
      (name) => !processorDefinition.schema.properties[name];

    return transform({...processor.properties}, (result, value, name) => {
      if (condition(name)) {
        result = result[ADDITIONAL_PROPERTIES_FIELD_NAME];
      }
      result[name] = value;
    }, {[ADDITIONAL_PROPERTIES_FIELD_NAME]: {}});
  }

  @computed get errorMessagesByProperty() {
    const {errors, processor, processorDefinition} = this.props;
    const propertyErrors = filter(errors, {type: 'processorProperty', processorName: processor.name});
    return transform(propertyErrors, (result, {propertyName, message}) => {
      if (!processorDefinition.schema.properties[propertyName] &&
        !has(this.serviceRegistryProperties, [propertyName])) {
        result[ADDITIONAL_PROPERTIES_FIELD_NAME].push({[propertyName]: message});
      } else if (has(this.serviceRegistryProperties, [propertyName]) || propertyName === 'keys') {
        result.keys.push({[propertyName]: message});
      } else {
        if (!result[propertyName]) result[propertyName] = [];
        result[propertyName].push(...castArray(message));
      }
    }, {[ADDITIONAL_PROPERTIES_FIELD_NAME]: [], keys: []});
  }

  render() {
    const {
      probe, processor, processorDefinition, telemetryServiceRegistryItems,
      editable, actionInProgress, errors, highlightStage, processorDefinitionsByName,
      params: {probeId},
    } = this.props;
    const {
      formSchemasByCategory, propertyValues, serviceRegistryProperties,
      errorMessagesByProperty, namedNodesInGraphQueries, knownPythonExpressionVariables, graphNodesStyles,
      onError
    } = this;
    const props = {
      probe, probeId, processor, processorDefinition, propertyValues,
      formSchemasByCategory, actionInProgress, errors, errorMessagesByProperty,
      highlightStage, telemetryServiceRegistryItems, serviceRegistryProperties,
      namedNodesInGraphQueries, knownPythonExpressionVariables, graphNodesStyles,
      processorDefinitionsByName, onError,
    };
    const PropertiesComponent = editable ? PropertyEditor : PropertyList;
    return <PropertiesComponent {...props} />;
  }
}

export const PropertyList = observer(({
  formSchemasByCategory, propertyValues, probeId, probe, processor,
  highlightStage, errorMessagesByProperty, namedNodesInGraphQueries, knownPythonExpressionVariables,
}) => {
  const {blueprintId} = useContext(IBAContext);
  return (
    <Table definition size='small'>
      <Table.Body>
        {map(formSchemasByCategory, (formSchema, category) => some(formSchema) && (
          <Fragment key={category}>
            <Table.Row className='processor-properties-category-readonly'>
              <Table.Cell colSpan='2'>
                <PropertiesCategoryTitle category={category} className='property-category-title' />
              </Table.Cell>
            </Table.Row>
            {map(formSchema, ({name, schema}) => {
              const hasError = !isEmpty(errorMessagesByProperty[name]);
              return (
                <Table.Row key={name}>
                  <Table.Cell collapsing error={hasError}>
                    {schema.title ?? name}
                    {schema.description &&
                      <Popup
                        trigger={
                          <Icon role='img' name='info circle' color='grey' aria-label={schema.description} />
                        }
                        content={<MarkdownDescription description={schema.description} />}
                        position='right center'
                        wide
                      />
                    }
                  </Table.Cell>
                  <Table.Cell error={hasError}>
                    <Value
                      name={name}
                      value={propertyValues[name]}
                      values={propertyValues}
                      schema={schema}
                      renderers={processorPropertyRenderers}
                      blueprintId={blueprintId}
                      probeId={probeId}
                      processor={processor}
                      probe={probe}
                      highlightStage={highlightStage}
                      namedNodesInGraphQueries={namedNodesInGraphQueries}
                      knownPythonExpressionVariables={knownPythonExpressionVariables}
                    />
                    {hasError &&
                      <div>
                        <ValidationErrors errors={errorMessagesByProperty[name]} pointing={false} />
                      </div>
                    }
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Fragment>
        ))}
      </Table.Body>
    </Table>
  );
});

const PropertyEditor = observer(({
  formSchemasByCategory, propertyValues, actionInProgress, probeId, probe, processor,
  errors, highlightStage, errorMessagesByProperty, telemetryServiceRegistryItems,
  processorDefinition, serviceRegistryProperties,
  namedNodesInGraphQueries, knownPythonExpressionVariables, graphNodesStyles,
  onError,
}) => {
  const {blueprintId, blueprintTags, processorDefinitions} = useContext(IBAContext);

  const onPropertyChange = action((propertyName, value) => {
    const processorName = processor.name;
    if (propertyName === ADDITIONAL_PROPERTIES_FIELD_NAME) {
      const newAdditionalProperties = value;
      for (const propertyName of keys(processor.properties)) {
        if (
          !has(serviceRegistryProperties, [propertyName]) &&
          !processorDefinition.schema.properties[propertyName] &&
          !has(newAdditionalProperties, [propertyName])
        ) {
          remove(processor.properties, propertyName);
          pullAllWith(errors, [{type: 'processorProperty', processorName, propertyName}], isMatch);
        }
      }
      for (const propertyName of keys(newAdditionalProperties)) {
        if (processor.properties[propertyName] !== newAdditionalProperties[propertyName]) {
          set(processor.properties, propertyName, newAdditionalProperties[propertyName]);
          pullAllWith(errors, [{type: 'processorProperty', processorName, propertyName}], isMatch);
        }
      }
    } else if (processor.type === 'extensible_data_collector' && propertyName === 'keys') {
      const keysProperty = [...processor.properties.keys] ?? [];
      const newAdditionalProperties = value;
      for (const propertyName of keysProperty) {
        if (!has(newAdditionalProperties, [propertyName])) {
          remove(processor.properties, propertyName);
          pullAllWith(errors, [{type: 'processorProperty', processorName, propertyName}], isMatch);
        }
      }
      for (const propertyName of keys(newAdditionalProperties)) {
        if (processor.properties[propertyName] !== newAdditionalProperties[propertyName]) {
          set(processor.properties, propertyName, newAdditionalProperties[propertyName]);
          pullAllWith(errors, [{type: 'processorProperty', processorName, propertyName}], isMatch);
        }
      }
      processor.properties[propertyName] = keys(value);
      pullAllWith(errors, [{type: 'processorProperty', processorName, propertyName}], isMatch);
    } else if (propertyName === 'enable_telemetry_service') {
      const properties = ['service_input', 'execution_count', 'service_interval'];
      const pullAllWithValues = [];
      processor.properties[propertyName] = value;
      forEach(properties, (name) => {
        set(processor.properties, name, processorDefinition.schema.properties[name].default);
        pullAllWithValues.push({type: 'processorProperty', processorName, name});
      });
      pullAllWithValues.push({type: 'processorProperty', processorName, propertyName});
      pullAllWith(errors, pullAllWithValues, isMatch);
    } else {
      const pullAllWithValues = [];
      processor.properties[propertyName] = value;
      if (processor.type === 'extensible_data_collector' && propertyName === 'data_type') {
        if (PROCESSOR_DYNAMIC_DATA_TYPES.has(value)) {
          const keysProperty = [...processor.properties.keys] ?? [];
          for (const propertyName of keysProperty) {
            remove(processor.properties, propertyName);
            pullAllWithValues.push({type: 'processorProperty', processorName, propertyName});
          }
          set(processor.properties, 'keys', []);
          pullAllWithValues.push({type: 'processorProperty', processorName, propertyName: 'keys'});
        } else if (PROCESSOR_STATIC_DATA_TYPES.has(value)) {
          set(processor.properties, 'ingestion_filter', {});
          pullAllWithValues.push({type: 'processorProperty', processorName, propertyName: 'ingestion_filter'});
        }
      }
      pullAllWithValues.push({type: 'processorProperty', processorName, propertyName});
      pullAllWith(errors, pullAllWithValues, isMatch);
    }
    pullAllWith(errors, [{type: 'processors'}, {type: 'processor', processorName}], isMatch);
  });

  return (
    <>
      {map(formSchemasByCategory, (formSchema, category) => some(formSchema) && (
        <PropertyGroupAccordion
          key={category}
          collapsed={category === 'advanced'}
          title={<PropertiesCategoryTitle category={category} />}
        >
          <Form>
            <FormFragment
              schema={formSchema}
              values={propertyValues}
              renderers={processorPropertyRenderers}
              errors={errorMessagesByProperty}
              disabled={actionInProgress}
              onChange={onPropertyChange}
              blueprintId={blueprintId}
              blueprintTags={blueprintTags}
              probeId={probeId}
              probe={probe}
              processor={processor}
              processorDefinitions={processorDefinitions}
              highlightStage={highlightStage}
              telemetryServiceRegistryItems={telemetryServiceRegistryItems}
              namedNodesInGraphQueries={namedNodesInGraphQueries}
              knownPythonExpressionVariables={knownPythonExpressionVariables}
              graphNodesStyles={graphNodesStyles}
              fieldProps={{
                DescriptionComponent: MarkdownDescription
              }}
              processorDefinition={processorDefinition}
              serviceRegistryProperties={serviceRegistryProperties}
              onError={onError}
            />
          </Form>
        </PropertyGroupAccordion>
      ))}
    </>
  );
});

const PropertyGroupAccordion = ({children, collapsed, title}) => {
  const [expanded, toggleExpanded] = useToggle(!collapsed);
  return (
    <Table celled size='small'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            className='processor-properties-category-title'
            onClick={toggleExpanded}
            onKeyDown={onEnterKeyHandler(toggleExpanded)}
            tabIndex={0}
          >
            {title}
            <Icon name={expanded ? 'dropdown' : 'caret right'} />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      {expanded &&
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              {children}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      }
    </Table>
  );
};

const PropertiesCategoryTitle = ({
  category,
  className
}) => {
  const iconClass = {
    graph: 'icon apstra-icon apstra-icon-processor-category-graph',
    processing: 'icon apstra-icon apstra-icon-processor-category-processing',
    telemetry: 'icon apstra-icon apstra-icon-processor-category-telemetry',
    advanced: 'adjust icon',
  }[category];
  return (
    <div className={className}>
      {iconClass && <i className={iconClass} />}
      {humanizeString(category)}
    </div>
  );
};
