import {useContext} from 'react';
import {observer} from 'mobx-react';
import {forEach, includes, isEmpty, get} from 'lodash';

import PlainInputSearchBox from '../../components/PlainInputSearchBox';
import {
  convertPropertyType, convertValueType,
  getStageDataSchema, getTelemetryServiceSchema, getStageRenderingStrategy,
} from '../stageUtils';
import humanizeString from '../../humanizeString';
import IBAContext from '../IBAContext';

import {STAGE_DATA_SOURCE} from '../consts';

const StageSearchBox = (props) => {
  const {
    schema, filters, disabled, onChange, applyOnChange, highlightPropertyColumn, 'aria-label': ariaLabel,
    processor, stage, stageItems, dataSource, asAccordion = true
  } = props;
  const {processorDefinitions, isFreeform} = useContext(IBAContext);

  return (
    <PlainInputSearchBox
      filters={filters}
      schema={
        schema || getStageSearchSchema({processorDefinitions, processor, stage, stageItems, dataSource, isFreeform})
      }
      applyOnChange={applyOnChange}
      disabled={disabled}
      onChange={onChange}
      highlightPropertyColumn={highlightPropertyColumn}
      aria-label={ariaLabel}
      asAccordion={asAccordion}
      splitIntoColumns={2}
    />
  );
};

export default observer(StageSearchBox);

export const getStageSearchSchema = ({processorDefinitions, processor, stage, stageItems, dataSource, isFreeform}) => {
  const isTelemetryServiceWarningsDataSource = dataSource === STAGE_DATA_SOURCE.telemetry_service_warnings;
  const renderingStrategy = getStageRenderingStrategy({stage, dataSource});
  const stageDataSchema = (
    isTelemetryServiceWarningsDataSource ?
      getTelemetryServiceSchema(stageItems) :
      getStageDataSchema({stage, processor, renderingStrategy, processorDefinitions, showValue: false})
  )
    .filter(({name}) => !includes(['anomaly', 'warning', 'timestamp'], name))
    .reduce((result, {name, label, type: rawType}) => {
      const type = convertPropertyType(rawType);
      result.push({
        name,
        schema: {
          title: label,
          ...(type !== 'any' ? {type} : null),
        },
        ...(type === 'number' ? {match: 'range', strictMode: true} : null),
        ...(type === 'string' ? {match: 'exactOrRegex'} : null),
      });
      if (name === 'properties.system_id' && !isTelemetryServiceWarningsDataSource) {
        result.push({
          name: 'properties.system_hostname',
          schema: {type: 'string', title: 'System Hostname'},
          match: 'exactOrRegex',
        });
        if (!isFreeform) {
          result.push({
            name: 'properties.system_role',
            schema: {type: 'string', title: 'System Role'},
            match: 'exactOrRegex',
          });
        }
      }
      return result;
    }, []);

  if (!isTelemetryServiceWarningsDataSource) {
    forEach(stage?.values, ({name, title, type: rawType, possible_values: possibleValues, description}) => {
      if (rawType) {
        const type = convertValueType(rawType);
        const isDiscreteState = (type === 'string' && !isEmpty(possibleValues));
        stageDataSchema.push({
          name,
          schema: {
            title: title ?? humanizeString(name),
            type,
            description,
            ...(isDiscreteState ? {enum: possibleValues} : null),
          },
          ...(type === 'number' ? {match: 'range', strictMode: true} : null),
          ...(!isDiscreteState && type === 'string' ? {match: 'exactOrRegex'} : null),
          units: get(stage, ['units', name]),
        });
      }
    });
  }

  return stageDataSchema;
};
