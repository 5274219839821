import cx from 'classnames';

import {Value} from 'apstra-ui-common';

const PrimitiveValue = ({value, className}) => {
  const classNames = cx('value-container', className ?? 'primitive');
  return (
    <div className={classNames}>
      <div>
        <Value value={value} />
      </div>
    </div>
  );
};

export default PrimitiveValue;
