import {
  keys, map, transform, find, get, has, includes, head, flatMapDeep,
  sortBy, findKey, values, filter, isEmpty, isString, reduce, forEach,
  isNil,
  omit,
} from 'lodash';
import {stringifyState} from 'apstra-ui-common';

import {queryParamToSorting} from '../queryParamUtils';
import humanizeString from '../humanizeString';
import MatcherFilterStringToObjectConverter from '../matcherFilterString/MatcherFilterStringToObjectConverter';
import checkForPatterns from './checkForPatterns';
import {
  DEFAULT_STAGE_DATA_SOURCE,
  DEFAULT_AGGREGATION_TYPE_BY_VALUE_TYPE,
  STAGE_DATA_SOURCE,
  VALUE_NUMBER_TYPES,
  AGGREGATION_TYPE_BY_VALUE_TYPE,
} from './consts';
import {DiscreteStateTimeline, DiscreteStateValue, EventTimeline, HorizontalBar,
  LineChart, PrimitiveValue, TextValue} from './components/valueRenderers';
import {Probe, ProbeProcessorType, ProbeStageType, RenderingStrategy} from './types';

export function sortStagePropertyNames(propertyNames) {
  return sortBy(propertyNames, (propertyName) => propertyName === 'system_id' ? '' : propertyName);
}

export function getStageSorting({sortingOverrides, propertyNames}) {
  return transform(sortStagePropertyNames(propertyNames), (result, propertyName) => {
    const name = `properties.${propertyName}`;
    if (!has(sortingOverrides, [name])) result[name] = 'asc';
  }, {...sortingOverrides});
}

export function processorCanRaiseAnomalies(processor) {
  return processor.type === 'anomaly' || processor.properties.raise_anomaly;
}

export function processorHasRaiseAnomaliesFlag(processor) {
  return has(processor.properties, ['raise_anomaly']);
}

export function processorCanRaiseWarnings(processor) {
  const enableTelemetryService = get(processor, ['properties', 'enable_telemetry_service']);
  return includes(
    ['generic_data_collector', 'extensible_data_collector', 'evpn3', 'evpn5', 'vxlan_floodlist'],
    processor.type
  ) || enableTelemetryService;
}

export function convertPropertyType(type) {
  if (type === 'bool') {
    return 'boolean';
  } else if (type === 'float' || type === 'integer') {
    return 'number';
  }
  return 'string';
}

export function convertValueType(type) {
  if (type === 'bool') {
    return 'boolean';
  } else if (VALUE_NUMBER_TYPES.has(type)) {
    return 'number';
  }
  return 'string';
}

function getBaseRenderValueAs({type, possible_values: possibleValues}, dataSource) {
  const convertedType = convertValueType(type);
  if (convertedType === 'string') {
    if (possibleValues?.length) {
      if (dataSource === STAGE_DATA_SOURCE.real_time) return DiscreteStateValue;
      if (dataSource === STAGE_DATA_SOURCE.time_series) return DiscreteStateTimeline;
    } else {
      if (dataSource === STAGE_DATA_SOURCE.real_time) return TextValue;
      if (dataSource === STAGE_DATA_SOURCE.time_series) return EventTimeline;
    }
  } else if (convertedType === 'number') {
    if (dataSource === STAGE_DATA_SOURCE.real_time) return HorizontalBar;
    if (dataSource === STAGE_DATA_SOURCE.time_series) return LineChart;
  }
  return PrimitiveValue;
}

export function getBaseRenderingStrategy(stage, dataSource): RenderingStrategy {
  const values = keys(stage.values);
  if (values.length === 0) return {};
  return {
    sortableByValue: dataSource === STAGE_DATA_SOURCE.real_time,
    showTimestamp: dataSource === STAGE_DATA_SOURCE.real_time,
    noSpotlightMode: values.length > 1 && dataSource === STAGE_DATA_SOURCE.real_time,
    graphCombiningAvailable: dataSource === STAGE_DATA_SOURCE.real_time ?
      false :
      ({type}: any = {}) => VALUE_NUMBER_TYPES.has(type),
    getRenderer: (valueSchema) => getBaseRenderValueAs(valueSchema, dataSource),
  };
}

export function getStageRenderingStrategy({probe, stage, dataSource = DEFAULT_STAGE_DATA_SOURCE, showContext}) {
  if (!stage) return null;
  const stageRenderingStrategy = getBaseRenderingStrategy(stage, dataSource);
  if (!showContext) return stageRenderingStrategy;

  const patternDescription = checkForPatterns({probe, stageName: stage.name, dataSource});
  if (patternDescription) {
    const patternRenderingStrategy = patternDescription.renderingStrategy;
    const combinedRenderValueAs = (valueSchema) => {
      for (const {ValueRenderer, getRenderer} of [patternRenderingStrategy, stageRenderingStrategy]) {
        const renderer = getRenderer?.(valueSchema) ?? ValueRenderer;
        if (renderer) return renderer;
      }
      return null;
    };
    return omit(
      {
        ...stageRenderingStrategy,
        ...patternRenderingStrategy,
        getRenderer: combinedRenderValueAs
      },
      ['ValueRenderer']);
  } else {
    return stageRenderingStrategy;
  }
}

export function getTelemetryServiceSchema(items: any[]) {
  if (isEmpty(items)) return [];
  const properties = keys(head(items)?.properties);
  const schema: any[] = map(properties, (propertyName) => ({
    propertyName,
    label: humanizeString(propertyName),
    name: `properties.${propertyName}`,
    value: ['properties', propertyName],
    sortable: true,
  }));
  schema.push({
    name: 'warning',
    label: 'Telemetry Service Status',
    value: ['warning'],
  });
  return schema;
}

export function getStageDataSchema({
  stage, processor, renderingStrategy, processorDefinitions, showValue = true, valueColumnName,
}) {
  const {
    sortableByValue = false,
    showTimestamp = false,
    extraColumns = []
  } = renderingStrategy;
  const properties = map(sortStagePropertyNames(keys(stage.keys)), (propertyName) => {
    const propertyType = stage.keys[propertyName];
    let label: string | null = null;
    // FIXME(vkramskikh): why do we need to iterate over all processor definitions?
    for (const processorDefinition of processorDefinitions) {
      const humanizedPropertyName = get(processorDefinition, ['schema', 'properties', propertyName, 'title']);
      if (humanizedPropertyName) {
        label = humanizedPropertyName;
        break;
      }
    }
    if (label === null) label = humanizeString(propertyName);
    return {propertyName, name: `properties.${propertyName}`, label, propertyType};
  });
  const stageDataSchema: any[] = properties.map(({name, label, propertyName, propertyType}) => ({
    name,
    label,
    value: ['properties', propertyName],
    key: true,
    sortable: true,
    type: propertyType,
  }));
  if (!isEmpty(extraColumns)) {
    stageDataSchema.push(...extraColumns);
  }
  if (processorCanRaiseAnomalies(processor)) {
    stageDataSchema.push({
      name: 'anomaly',
      label: 'Anomaly',
    });
  }
  if (showValue) {
    values(stage.values)
      .filter((value) => !valueColumnName || valueColumnName === value.name)
      .forEach(({name, title, type}) => {
        stageDataSchema.push({
          name,
          label: title || humanizeString(name),
          value: [name],
          sortable: sortableByValue,
          type,
        });
      });
  }
  if (showTimestamp) {
    stageDataSchema.push({
      name: 'timestamp',
      label: 'Updated',
      sortable: true,
      value: ['timestamp'],
    });
  }
  return stageDataSchema;
}

export function getStageFormSchema(processorDefinition, stage?, stageDefinition?) {
  const stageFormSchema: any[] = [
    {
      name: 'enable_metric_logging',
      schema: {
        type: 'boolean',
        title: 'Enable Metric Logging',
        description: 'Save changes in this stage to MetricDB time series database.'
      }
    },
    {
      name: 'retention_duration',
      hidden: !stage?.enable_metric_logging, // eslint-disable-line camelcase
      schema: {
        type: 'number',
        default: 86400,
        title: 'Retention Duration',
        description: 'Retain data in MetricDB for specified duration.'
      }
    },
    {
      name: 'description',
      schema: {
        type: 'string',
        title: 'Description',
      }
    },
    {
      name: 'graph_annotation_properties',
      schema: {
        type: 'object',
        title: 'Graph Annotation Properties',
        description: 'Annotate graph nodes with properties associated with stage values.',
      }
    }
  ];
  const unitsProperties = stageDefinition ? reduce(stageDefinition.types, (result, {values}) => {
    forEach(values, ({title}, key) => {
      result[key] = {type: 'string', title};
    });
    return result;
  }, {}) : {};
  stageFormSchema.push({
    name: 'units',
    schema: {
      type: 'object',
      title: 'Units',
      description: 'Units for values.',
      properties: unitsProperties,
    }
  });
  return stageFormSchema;
}

export function castFilterConverter(filter) {
  if (isString(filter)) {
    const filterObject = MatcherFilterStringToObjectConverter.parseAndConvert(filter);
    return isEmpty(filterObject) && !isEmpty(filter) ? filter : filterObject;
  }
  return filter;
}

export function getStageQueryParamsFromWidget({
  widget, stage, alwaysUseContext, filterDeserializer = castFilterConverter
}) {
  const stageDataSourceIsTimeSeries = widget.data_source === STAGE_DATA_SOURCE.time_series;
  const filter = filterDeserializer(widget.filter);
  const filters: any = {
    dataSource: widget.data_source,
  };
  if (!isEmpty(filter)) {
    filters.filter = filter;
  }
  if (!stageDataSourceIsTimeSeries) {
    filters.anomalousOnly = widget.anomalous_only;
  }
  if (!stageDataSourceIsTimeSeries || alwaysUseContext) {
    filters.showContextInfo = widget.show_context;
  }
  if (stageDataSourceIsTimeSeries) {
    filters.valueColumnName = getValueColumnNameFromWidget({widget, stage});
  }
  if (!isNil(widget.time_series_duration)) {
    filters.timeSeriesDuration = widget.time_series_duration;
  }
  if (!isNil(widget.aggregation_period)) {
    filters.timeSeriesAggregation = widget.aggregation_period;
  }
  if (widget.combine_graphs) {
    filters.combineGraphs = widget.combine_graphs;
  }
  if (widget.aggregation_type) {
    filters.aggregationType = widget.aggregation_type;
  }

  const stageFilter: any = {};
  const sorting = queryParamToSorting(widget.orderby);
  if (!isEmpty(sorting)) {
    stageFilter.sorting = sorting;
  }
  if (!isEmpty(filters)) {
    stageFilter.filters = filters;
  }

  return isEmpty(stageFilter) ? null : `?stage-filter=${stringifyState(stageFilter)}`;
}

export function getValueColumnNameFromWidget({widget, stage}) {
  if (widget.value_column_name) return widget.value_column_name;
  // FIXME(vkramskikh): there is currently no value_column_name in the widget.
  // Instead, value_column_name is determined from visible_columns. This should be fixed
  // by introducing value_column_name support on the backend.
  if (!stage || widget.data_source === STAGE_DATA_SOURCE.real_time) return null;
  return find(widget.visible_columns, (name) => name in stage.values) ?? head(keys(stage.values)) ?? null;
}

export function getStageComponentPropsFromWidget({widget, probes}) {
  const {probe_id: probeId, stage_name: stageName} = widget;
  const probe = find(probes, {id: probeId});
  const processor = getProcessorByStageName({probe, stageName});
  const stage = getStageByName({probe, stageName});
  return {probe, processor, stage};
}

export function generateStageQueryParams(params) {
  if (params) {
    const filter = stringifyState({
      ...params.filters && {filters: {
        filter: params.filters.filter,
        anomalousOnly: params.filters.anomalousOnly,
        showContextInfo: params.filters.showContextInfo,
        dataSource: params.filters.dataSource,
        combineGraphs: params.filters.combineGraphs,
      }},
      ...params.sorting && {sorting: queryParamToSorting(params.sorting)},
    });
    return `?stage-filter=${filter}`;
  }
  return null;
}

export function getStageByName({probe, stageName}: {
  probe: Probe,
  stageName: string
}): ProbeStageType | null {
  if (!probe) return null;
  return find(probe.stages, {name: stageName}) || null;
}

export function getProcessorByStageName({probe, stageName}: {
  probe: Probe,
  stageName: string
}): ProbeProcessorType | null {
  if (!probe) return null;
  return find(
    probe.processors,
    ({outputs}) => !!find(outputs, (outputName) => outputName === stageName)
  ) || null;
}

export function getOutputNameByStageName({processor, stageName}) {
  if (!processor || !processor.outputs || !stageName) {
    return null;
  }
  return findKey(processor.outputs, (outputStageName) => outputStageName === stageName) || null;
}

export function getInputStages({probe, processor}) {
  const stageNames = getInputStageNames(processor);
  return filter(map(stageNames, (stageName) => getStageByName({probe, stageName})));
}

export function getInputStageNames(processor) {
  return map(processor.inputs, ({stage}) => stage);
}

export function getPossibleStageValues(processorDefinition, stagesKey, withTransformedType = true) {
  return transform(processorDefinition[stagesKey], (result, stageDefinition, stage) => {
    result[stage] = flatMapDeep(stageDefinition.types, ({dynamic, values}) => {
      return map(values, (valueArray, key) => map(valueArray, (value) => ({
        dynamic,
        name: key,
        possible_values: value.possible_values,
        type: withTransformedType ? convertValueType(value.type) : value.type,
      })));
    });
  }, {});
}

export function generateNewInputName(processor, from = 1) {
  const result = 'in' + from;
  if (!(result in processor.inputs)) return result;
  return generateNewInputName(processor, from + 1);
}

export function getPossibleValueColumnOptions(stage) {
  return values(stage.values).map(({name, title}) => ({
    key: name,
    value: name,
    text: title || humanizeString(name),
  }));
}

export function getPossibleAggregationTypes(stage, valueColumnName) {
  const type = get(stage, ['values', valueColumnName, 'type']);
  return transform(AGGREGATION_TYPE_BY_VALUE_TYPE, (result, types, aggregationType) => {
    if (types.has(type)) {
      result.push(aggregationType);
    }
  }, [] as string[]);
}

export function getDefaultAggregationType(stage, valueColumnName, patternDescription) {
  if (patternDescription?.renderingStrategy.aggregationType) {
    return patternDescription.renderingStrategy.aggregationType;
  }
  const type = convertValueType(get(stage, ['values', valueColumnName, 'type']));
  return DEFAULT_AGGREGATION_TYPE_BY_VALUE_TYPE[type];
}
