import {first, flatten, floor, keys, map, min, size, some, transform} from 'lodash';
import {useCallback, useMemo} from 'react';

import {HOMING_TYPES, draftLinksGroupId} from '../const';

import {useRackEditorStore} from './useRackEditorStore';
import Step from '../../cablingMapEditor/store/Step';

const useLinksGroupOptions = (linksGroup, freePorts) => {
  const {rackStore} = useRackEditorStore();
  const {nodesByName, changes} = rackStore;

  const {
    fromName, toName, speedString, isAttachedToFirst, isAttachedToSecond, isDualAttached,
    count, id
  } = linksGroup;

  const sourceNode = nodesByName[fromName][0];
  const destinationNode = nodesByName[toName][0];

  const fromPair = sourceNode.isPaired;
  const toPair = destinationNode.isPaired;
  const hasPairs = fromPair || toPair;
  const betweenPairs = fromPair && toPair && !sourceNode.isPairedWith(destinationNode.id);
  const isOneToPair = !fromPair && toPair;
  const isPairToOne = fromPair && !toPair;

  // Calculate ports intersection per speed
  const maxSizePerSpeed = useMemo(
    () => {
      // Now find ports intersection based on ports speed and possible configuration of links group
      const result = transform(
        // Iterating through ports speeds first source node has
        keys(freePorts[0][0]),
        (acc, speedString) => {
          const speedPorts = map(
            flatten(freePorts),
            (portsCounts) => portsCounts?.[speedString] ?? 0
          );

          let intersection = 0;
          if (isOneToPair) {
            // Only attached destination nodes must be taken into account
            intersection = min([
              // If dual attached source node needs twice more free ports
              isDualAttached ? floor(speedPorts[0] / 2) : speedPorts[0],
              ...(isAttachedToFirst ? [speedPorts[1]] : []),
              ...(isAttachedToSecond ? [speedPorts[2]] : [])
            ]);
          } else if (isPairToOne) {
            // Destination node needs twice more ports then any of sources
            intersection = min([speedPorts[0], speedPorts[1], floor(speedPorts[2] / 2)]);
          } else {
            // In other cases all nodes must have free ports available
            const commonCount = min(speedPorts);
            intersection = (betweenPairs && isDualAttached) ? floor(commonCount / 2) : commonCount;
          }

          acc[speedString] = intersection;
        },
        {}
      );
      return size(result) ? result : {[speedString]: 0};
    },
    [
      speedString, freePorts, isAttachedToFirst, isAttachedToSecond,
      isDualAttached, isOneToPair, isPairToOne, betweenPairs
    ]
  );

  const trackChanges = useCallback(
    (updates) => {
      let step;
      if (changes.currentCorrespondsTo(linksGroup.id)) {
        // If current change relates to this links group - reuse it
        step = first(changes.current);
      } else {
        // Create the new change and register it otherwise
        step = Step.modification(linksGroup, linksGroup);
        changes.register([step]);
      }

      // Update the links group
      linksGroup.fillWith(updates);
      // And the corresponding step
      step.setResult(linksGroup);
    },
    [changes, linksGroup]
  );

  const fromPorts0 = freePorts?.[0]?.[0]?.[speedString] || 0;
  const toPorts0 = freePorts?.[1]?.[0]?.[speedString] || 0;
  const toPorts1 = freePorts?.[1]?.[1]?.[speedString] || 0;

  // Only allow changing connection to the switch
  const toggleSwitchLink = useCallback((isFirst, canLinkBoth) => {
    const [primary, secondary] = [
      `isAttachedTo${isFirst ? 'First' : 'Second'}`,
      `isAttachedTo${isFirst ? 'Second' : 'First'}`
    ];
    const [primaryValue, secondaryValue] = [linksGroup[primary], linksGroup[secondary]];

    const update = {[primary]: !primaryValue};
    if (
      // both are true buth dual linking is impossible
      !primaryValue && secondaryValue && !canLinkBoth
    ) {
      // set second to FALSE
      update[secondary] = false;
    }
    if (
      // If both become FALSE, enable the secondary one
      primaryValue && !secondaryValue
    ) {
      // set second to TRUE
      update[secondary] = true;
    }
    trackChanges(update);
  }, [linksGroup, trackChanges]);

  // Only allow changing connection to the switch
  const toggleHoming = useCallback(() => {
    const state = !linksGroup.isDualAttached;
    trackChanges({
      isAttachedToFirst: state,
      isAttachedToSecond: state
    });
  }, [linksGroup, trackChanges]);

  const onPortClick = useCallback((speed) => {
    trackChanges({
      speed,
      count: 1,
      railIndex: null
    });
  }, [trackChanges]);

  // Identifies whether isAttachedToFirst can be changed
  const canLinkFirst = (
    // Only makes sense for one-to-pair configuration and
    isOneToPair && (
      (
        // if attached to the second and can be reattached to the first
        !isAttachedToFirst || toPorts1 >= count
      ) || isDualAttached
    )
  );

  // Identifies whether isAttachedToSecond can be changed
  const canLinkSecond = (
    // Only makes sense for one-to-pair configuration
    isOneToPair && (
      (
        // if attached to the first and can be reattached to the second
        !isAttachedToSecond || toPorts0 >= count
      ) || isDualAttached
    )
  );

  // Identifies whether dual-homing changing is allowed
  const canLinkBoth = (
    (
      // For pair-to-pair configuration
      betweenPairs &&
        (
          // If have cross-links already or
          isDualAttached ||
          // all nodes have enough free ports to create them
          maxSizePerSpeed[speedString] >= count
        )
    ) || (
      // For one-to-pair configuration
      isOneToPair &&
      // when source has enough free ports for dual-homing
      fromPorts0 >= count && (
        // and unset target has enough free ports either
        (!isAttachedToFirst && toPorts0 >= count) ||
        (!isAttachedToSecond && toPorts1 >= count)
      )
    )
  );

  const portsAvailable = maxSizePerSpeed[linksGroup.speedString] || 0;

  const canCreateNewLink = (id === draftLinksGroupId && count > 0) || some(
    keys(freePorts[0][0]),
    (speedString) => {
      const fromPorts0 = freePorts?.[0]?.[0]?.[speedString] || 0;
      const fromPorts1 = freePorts?.[0]?.[1]?.[speedString] || 0;
      const toPorts0 = freePorts?.[1]?.[0]?.[speedString] || 0;
      const toPorts1 = freePorts?.[1]?.[1]?.[speedString] || 0;

      return isOneToPair ?
        (fromPorts0 && (toPorts0 || toPorts1)) :
          isPairToOne ?
            (fromPorts0 > 1 && toPorts0 && toPorts1) :
              betweenPairs ?
                (fromPorts0 && fromPorts1 && toPorts0 && toPorts1) :
                (fromPorts0 && toPorts0);
    }
  );

  const homingType = toPair ? (
    fromPair ? HOMING_TYPES.DOUBLE_SINGLE : HOMING_TYPES.LEFT_RIGHT
  ) : null;

  return {
    maxSizePerSpeed, toggleHoming, toggleSwitchLink, onPortClick, portsAvailable, homingType,
    fromNodes: sourceNode.nodesInvolved, toNodes: destinationNode.nodesInvolved, fromPorts0, toPorts0, toPorts1,
    fromPair, toPair, hasPairs, betweenPairs, isOneToPair,
    canCreateNewLink, canLinkFirst, canLinkSecond, canLinkBoth, trackChanges
  };
};

export default useLinksGroupOptions;
