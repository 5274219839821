import {observer} from 'mobx-react';
import {Button, Form, Popup} from 'semantic-ui-react';
import {find} from 'lodash';
import {CodeEditorInput, FormFragment, ResourceModal, ValueRenderer, FetchDataError} from 'apstra-ui-common';

import {createOrUpdatePredefinedQuery} from './utils';

import './QueryEntityModal.less';

const formSchema = [
  {
    name: 'id',
    schema: {
      type: 'string',
    },
    required: true,
    hidden: true,
  },
  {
    name: 'label',
    schema: {
      type: 'string',
      title: 'Label',
    },
    required: true,
  },
  {
    name: 'query',
    schema: {
      type: 'string',
      title: 'Query',
    },
    required: true,
    disabled: true,
  },
  {
    name: 'predefined',
    schema: {
      type: 'boolean',
      default: false,
    },
    required: true,
    hidden: true,
  }
];

const QueryEntityModal = observer(({mode, trigger, onUpdate, onSuccess, query, queryProps, helpPageId}) => {
  const editableQuery = {...queryProps, query};
  const resetState = () => null;
  const submit = () => createOrUpdatePredefinedQuery(editableQuery);

  return (
    <ResourceModal
      helpPageId={helpPageId}
      mode={mode}
      resourceName='Query'
      titlesByMode={{
        create: 'Create',
        update: 'Edit',
      }}
      trigger={trigger}
      resetState={resetState}
      submit={submit}
      submitAvailable={editableQuery && !!editableQuery.label}
      onSuccess={({result}) => onSuccess(result)}
      showCreateAnother={false}
      closeIcon
    >
      {({actionInProgress, errors}) => {
        const httpError = find(errors, {type: 'http'});
        return (
          <Form>
            <FormFragment
              schema={formSchema}
              values={editableQuery}
              renderers={[codeEditorInputRenderer]}
              disabled={actionInProgress}
              onChange={(name, value) => onUpdate(name, value)}
            />
            {httpError &&
              <FetchDataError error={httpError.error} />
            }
          </Form>
        );
      }}
    </ResourceModal>
  );
});

const codeEditorInputRenderer = new ValueRenderer({
  condition: ({name}) => name === 'query',
  ValueInput(props) {
    return (
      <CodeEditorInput
        {...props}
        mode='graph-query'
        multiLine
      />
    );
  }
});

// https://github.com/Semantic-Org/Semantic-UI-React/issues/2804
export const QueryEntityModalWithPopup = observer(({
  query, queryProps, mode, onUpdate, onSuccess, submitAvailable
}) => submitAvailable ?
  <QueryEntityModal
    query={query}
    queryProps={queryProps}
    mode={mode}
    onUpdate={onUpdate}
    onSuccess={onSuccess}
    trigger={
      <Button
        primary
        icon='save'
        content='Save'
        floated='right'
      />
    }
  /> :
  <Popup
    content='Saving is available after a successful query execution'
    trigger={
      <div className='query-modal-trigger'>
        <Button
          primary
          icon='save'
          content='Save'
          disabled={!submitAvailable}
        />
      </div>
    }
  />
);

export default QueryEntityModal;
