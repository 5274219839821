import {isNil, map} from 'lodash';
import {FC} from 'react';

import {TOPOLOGY_CONFIG} from '../consts';
import {ALL_NODE_ROLES} from '../../../../roles';
import {ILinkEndpoint} from '../types';
import {InterfaceData} from '../utils';

type InterfacesProps = {
  nodeInterfaces: Record<string, InterfaceData>,
  showTooltipHandler: (ep: ILinkEndpoint) => (e: any) => void;
  hideTooltip: () => void;
};

export const Interfaces: FC<InterfacesProps> = ({nodeInterfaces, showTooltipHandler, hideTooltip}) => {
  return (
    <>
      <defs>
        <rect
          id='interface-port'
          x={-TOPOLOGY_CONFIG.interfaceSize / 2}
          y={-TOPOLOGY_CONFIG.interfaceSize / 2}
          rx={1}
          width={TOPOLOGY_CONFIG.interfaceSize}
          height={TOPOLOGY_CONFIG.interfaceSize}
        />
      </defs>
      {map(nodeInterfaces, ({railIndex, position, sourceInterface}, id) =>
        (!isNil(railIndex) && sourceInterface?.node?.role === ALL_NODE_ROLES.GENERIC) ? null :
        <use
          key={id}
          className='topology-interface'
          href='#interface-port'
          fill={sourceInterface?.operation_state === 'up' ? '#fff' : '#BABABA'}
          x={position.x}
          y={position.y}
          onMouseOut={hideTooltip}
          onBlur={hideTooltip}
          onMouseOver={showTooltipHandler?.(sourceInterface!)}
          onFocus={showTooltipHandler?.(sourceInterface!)}
        />
      )}
    </>
  );
};
