import {find, isEmpty, map, some, values} from 'lodash';

import {getPossibleStageValues} from '../../../stageUtils';
import {nodeWidth, processorStepHeight, stageStepHeight} from './consts';

export function getProcessorSize(processor) {
  const stageCount = values(processor.outputs).length;
  return {
    width: nodeWidth,
    height: processorStepHeight + stageCount * stageStepHeight,
  };
}

export function checkConnectorsCompatibility(c1, c2, {
  processorDefinitions
}) {
  if (c1.type === c2.type || c1.processor.name === c2.processor.name) return false;
  const [input, output] = c1.type === 'output' ? [c2, c1] : [c1, c2];
  const destinationProcessorDefinition = find(processorDefinitions, {name: input.processor.type});
  const sourceProcessorDefinition = find(processorDefinitions, {name: output.processor.type});
  const inputValues = getPossibleStageValues(destinationProcessorDefinition, 'inputs')[input.id];
  if (isEmpty(inputValues)) return true;
  const outputValues = getPossibleStageValues(sourceProcessorDefinition, 'outputs')[output.id];
  return some(map(inputValues), (input) => {
    const condition = input.name === '*' ?
      (inp, out) => inp.type === out.type :
      (inp, out) => inp.name === out.name && inp.type === out.type;
    return find(outputValues, (output) => condition(input, output)) ? input : null;
  });
}
