import dagre from '@dagrejs/dagre';
import {forEach, map, isEmpty, transform} from 'lodash';
import {computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {Component} from 'react';
import {withResizeDetector} from 'react-resize-detector';

import GraphCanvas from './GraphCanvas';
import {processorXPadding, processorYPadding} from './consts';

@withResizeDetector
@observer
export default class TreeGraphCanvas extends Component {
  static defaultProps = {
    width: 0,
    height: 0,
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed
  get graph() {
    const graph = new dagre.graphlib.Graph();
    graph.setGraph({
      marginx: processorXPadding,
      marginy: processorYPadding,
      ranker: 'longest-path',
    });
    graph.setDefaultEdgeLabel(() => ({}));
    forEach(
      this.props.graph.nodes,
      (node) => {
        graph.setNode(node.id, {
          id: node.id,
          width: node.size.width,
          height: node.size.height
        });
      }
    );
    forEach(
      this.props.graph.links,
      (link) => {
        graph.setEdge(link.outputProcessor.name, link.inputProcessor.name);
      }
    );
    dagre.layout(graph);

    return graph;
  }

  @computed
  get nodePositions() {
    return transform(this.graph.nodes(), (result, nodeId) => {
      result[nodeId] = this.graph.node(nodeId);
    }, {});
  }

  @computed
  get graphWidth() {
    return this.graph.graph().width ?? 0;
  }

  @computed
  get graphHeight() {
    return this.graph.graph().height ?? 0;
  }

  @computed
  get graphNodes() {
    return map(this.props.graph.nodes, ({content, connectors, id, isDraggable, size}) => (
      {
        content,
        connectors,
        id,
        size,
        isDraggable,
        x: this.nodePositions[id].x - size.width / 2,
        y: this.nodePositions[id].y - size.height / 2,
      }
    ), []);
  }

  render() {
    const {targetRef} = this.props;
    return !isEmpty(this.props.graph.nodes) &&
      <GraphCanvas
        nodes={this.graphNodes}
        links={this.props.graph.links}
        width={this.graphWidth}
        height={this.graphHeight}
        targetRef={targetRef}
        onConnectLinks={this.props.onConnectLinks}
        onLinkClose={this.props.onLinkClose}
        editable={this.props.editable}
      />;
  }
}
