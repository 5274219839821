/* eslint-disable sonarjs/no-duplicate-string */
import ace from 'ace-builds';
import {map, find, escape, includes, isEmpty} from 'lodash';

import buildPropertyDocHTMLFromSchema from '../pythonExpression/buildPropertyDocHTMLFromSchema';

const OPERATORS_BY_TYPE = {
  string: ['=', '!=', '~=', '?=', 'is', 'is not', 'in', 'not in'],
  number: ['=', '!=', '<', '>', '@=', 'is', 'is not', 'in', 'not in'],
  boolean: ['=', '!=', 'is', 'is not', 'in', 'not in'],
  any: ['=', '!=', '~=', '?=', '@=', '<', '>', 'is', 'is not', 'in', 'not in'],
};
const IS_NONE_OPERATOR_MAP = {
  is: ['not', 'none'],
  'is not': ['none'],
};
const BOOLEAN_OPERATORS_DESCRIPTION = [
  '"and" and "or" operators along with parentheses can be used to combine expressions',
  '(value1 = "a" or value2 = "b") and value3 = "c"'
];
const OPERATOR_DESCRIPTIONS = {
  and: BOOLEAN_OPERATORS_DESCRIPTION,
  or: BOOLEAN_OPERATORS_DESCRIPTION,
  '=': [
    'Checks if value is equal to argument',
    'value = "up"', 'value = 0', 'value = false'
  ],
  '!=': [
    'Checks if value is not equal to argument',
    'value != "down"', 'value != 0', 'value != true'
  ],
  '>': [
    'Checks if value is greater than argument',
    'value > 0', 'value > 6.9'
  ],
  '<': [
    'Checks if value is less than argument',
    'value < 0', 'value < 6.9'
  ],
  '~=': [
    'Checks if value matches regular expression',
    'value ~= "^eth.*"'
  ],
  '@=': [
    'Checks if value is in range',
    'value @= (-10, 90)'
  ],
  '?=': [
    'Checks if value is in IP address range',
    'value ?= ("192.168.0.1", "192.168.255.255")'
  ],
  is: [
    'Checks if value is none',
    'value is none'
  ],
  'is not': [
    'Checks if value is not none',
    'value is not none'
  ],
  in: [
    'Checks if value is in set',
    'value in [1, 2, 3]', 'value in ["a", "b", "c"]'
  ],
  'not in': [
    'Checks if value is not in set',
    'value not in [1, 2, 3]', 'value not in ["a", "b", "c"]'
  ],
};

function buildOperatorDescriptionDocHTML([description, ...examples]) {
  return [
    escape(description),
    ...(
      examples.length ?
        examples.length === 1 ?
        ['Example: ', escape(examples[0])] :
        ['Examples:', '<ul>' + map(examples, (example) => '<li>' + escape(example) + '</li>').join('') + '</ul>'] :
        []
    )
  ].map((chunk) => '<p>' + chunk + '</p>').join('');
}

ace.define(
  'ace/mode/matcher-filter-string',
  ['require', 'exports', 'module'],
  (require, exports) => {
    const {TextHighlightRules} = require('ace/mode/text_highlight_rules');
    const {Mode: TextMode} = require('ace/mode/text');
    const {TokenIterator} = require('ace/token_iterator');
    const {BaseCompleter} = require('ace/base_completer');

    class MatcherFilterStringCompleter extends BaseCompleter {
      getCustomCompletions(state, session, pos) {
        const token = session.getTokenAt(pos.row, pos.column);
        const {schema} = session.completerParams ?? {};
        const properties = this.getProperties(schema);
        const previousTokens = this.getPreviousTokens({session, pos});
        if (!token || !previousTokens) return properties;
        const {tokens: [firstToken]} = previousTokens;
        if (firstToken.type === 'keyword.logic') return properties;
        if (firstToken.type === 'property') {
          const propertyToken = this.getPropertyName({session, pos});
          const type = propertyToken ? this.getPropertyType(propertyToken.name, schema) : 'string';
          return this.getOperatorsCompletions(type && OPERATORS_BY_TYPE[type] || OPERATORS_BY_TYPE.any);
        }
        if (firstToken.type === 'keyword.operator') {
          if (IS_NONE_OPERATOR_MAP[firstToken.value]) {
            return map(IS_NONE_OPERATOR_MAP[firstToken.value], (name, index) => ({
              caption: name,
              snippet: name,
              score: 100 - index,
            }));
          }
        }
        if (firstToken.type === 'keyword.operator' || firstToken.type === 'paren.lparen') {
          const propertyToken = this.getPropertyName({session, pos});
          if (!propertyToken) return [];
          return this.getPossibleValues(propertyToken.name, schema);
        }
        if (includes(['string', 'constant.numeric', 'constant.language', 'paren.rparen'], firstToken.type)) {
          return this.getOperatorsCompletions(['and', 'or']);
        }
      }

      checkForCustomCharacterCompletions(editor) {
        const pos = editor.getCursorPosition();
        const line = editor.getSession().getLine(pos.row).substr(0, pos.column);
        if (/(['"]|\s*)$/.test(line)) {
          this.showCompletionsPopup(editor);
        }
      }

      getOperatorsCompletions(options) {
        return map(options, (operator, index) => ({
          caption: operator,
          snippet: operator,
          meta: 'operator',
          docHTML: OPERATOR_DESCRIPTIONS[operator] ?
            buildOperatorDescriptionDocHTML(OPERATOR_DESCRIPTIONS[operator]) : undefined,
          score: 100 - index,
        }));
      }

      getPropertyType(propertyName, schema) {
        return find(schema, {name: propertyName})?.schema?.type ?? null;
      }

      getPossibleValues(propertyName, schema) {
        const property = find(schema, {name: propertyName});
        if (!property?.schema) return [];
        const {schema: {type, enum: options}} = property;
        const schemaOptions = isEmpty(options) ? [] : options;
        if (type === 'boolean') {
          schemaOptions.push('true', 'false');
        }
        return map(schemaOptions, (option, index) => {
          option = type === 'string' ? `'${option}'` : option;
          const className = type === 'string' ? 'completion-string ace_' : null;
          return {
            caption: option,
            snippet: option,
            meta: 'possible value',
            className,
            score: 100 - index
          };
        });
      }

      getProperties(schema) {
        return map(schema, ({name, schema}, index) => ({
          caption: name,
          snippet: name,
          docHTML: buildPropertyDocHTMLFromSchema(schema),
          className: 'completion-keyword-argument ace_',
          meta: 'property',
          score: 1000 - index
        }));
      }

      getPropertyName({iterator, session, pos}) {
        if (!iterator) iterator = new TokenIterator(session, pos.row, pos.column);
        let currentToken;
        do {
          do {
            currentToken = iterator.stepBackward();
            if (!currentToken) return null;
          } while (currentToken.type === 'text');
          if (currentToken.type === 'property') {
            return {token: currentToken, name: currentToken.value};
          }
        } while (true); // eslint-disable-line no-constant-condition
      }

      getPreviousTokens({iterator, session, pos}) {
        if (!iterator) iterator = new TokenIterator(session, pos.row, pos.column);
        const tokens = [];
        let currentToken, nextToken;
        let parenNesting = false;
        do {
          do {
            currentToken = iterator.stepBackward();
            if (!currentToken) return null;
          } while (currentToken.type === 'text');
          tokens.unshift(currentToken);
          parenNesting = includes(['string', 'constant.numeric', 'constant.language'], currentToken.type);
          if (!parenNesting) {
            if (nextToken && tokens[0].type === 'keyword.operator') {
              return {iterator, tokens: [nextToken], name: nextToken.value};
            }
            return {iterator, tokens, name: currentToken.value};
          }
          nextToken = currentToken;
        } while (true); // eslint-disable-line no-constant-condition
      }
    }

    class MatcherFilterStringHighlightRules extends TextHighlightRules {
      constructor() {
        super();

        this.$rules = {
          start: [
            {
              token: 'string',
              regex: /'(:?[^\\\n\r']+|\\(:?[nr\\/']))*'/,
            },
            {
              token: 'string',
              regex: /"(:?[^\\\n\r"]+|\\(:?[nr\\/"]))*"/,
            },
            {
              token: 'keyword.logic',
              regex: /\b(?:or|and)\b/,
              caseInsensitive: true,
            },
            {
              token: 'keyword.operator',
              regex: /\b(?:is not|is|not in|in)\b/,
              caseInsensitive: true,
            },
            {
              token: 'keyword.operator',
              regex: /(?:=|!=|<|>|~=|\?=|@=)/,
            },
            {
              include: 'constants'
            },
            {
              token: 'property',
              regex: /[a-zA-Z_][.\w]*/,
            },
            {
              token: 'paren.lparen',
              regex: /[[(]/
            },
            {
              token: 'paren.rparen',
              regex: /[\])]/
            },
          ],
          constants: [
            {
              token: 'constant.numeric',
              regex: /[-+]?\d+(\.\d+)?/
            },
            {
              token: 'constant.language',
              regex: /(?:true|false|none)/,
              caseInsensitive: true,
            },
          ]
        };
        this.normalizeRules();
      }
    }

    class MatcherFilterStringMode extends TextMode {
      $id = 'ace/mode/matcher-filter-string';
      $behaviour = this.$defaultBehaviour;
      HighlightRules = MatcherFilterStringHighlightRules;
      completer = new MatcherFilterStringCompleter();
      getCurrentProperty = (session, {row, column}) => {
        const iterator = new TokenIterator(session, row, column);
        const valueTokens = ['constant.language', 'constant.numeric', 'paren.rparen', 'string'];
        let currentToken = session.getTokenAt(row, column);
        const isFirstTokenText = (currentToken?.type === 'text');
        if (isFirstTokenText) {
          const nextToken = iterator.stepForward();
          if (nextToken) {
            const nextTokenColumn = iterator.getCurrentTokenColumn();
            const nextTokenRow = iterator.getCurrentTokenRow();
            if (nextToken.type === 'property' && nextTokenRow === row && nextTokenColumn === column) {
              return nextToken;
            }
          }
        }
        do {
          if (!currentToken || currentToken.type === 'keyword.logic' ||
            (isFirstTokenText && includes(valueTokens, currentToken.type))) {
            return null;
          }
          if (currentToken.type === 'property') return currentToken;
          currentToken = iterator.stepBackward();
        } while (true); // eslint-disable-line no-constant-condition
      };
    }

    exports.Mode = MatcherFilterStringMode;
    exports.HighlightRules = MatcherFilterStringHighlightRules;
  }
);

ace.require(['ace/mode/matcher-filter-string']);
