import {head, isUndefined, map, transform} from 'lodash';

import {VALUE_NUMBER_TYPES} from '../../consts';

const correspondingStageItemMapping = new WeakMap();
const sampleExtentByItems = new WeakMap();
const valuesExtentByItems = new WeakMap();

const getCorrespondingStageItemForStage = ({item, stage}) => {
  return head(item.preceding_items[stage.name]) || null;
};

const getCorrespondingStageItemsForStages = ({item, stages}) => {
  return map(stages, (stage) => getCorrespondingStageItemForStage({item, stage}));
};

const getAllCorrespondingStageItemsForStages = ({items, stages}) => {
  if (correspondingStageItemMapping.has(items)) {
    return correspondingStageItemMapping.get(items);
  }

  const result = map(stages, (stage) =>
    map(items, (item) => getCorrespondingStageItemForStage({item, stage}))
  );
  correspondingStageItemMapping.set(items, result);
  return result;
};

const getItemsSampleExtent = (items, valueColumnName) => {
  if (sampleExtentByItems.has(items)) return sampleExtentByItems.get(items);
  let minValue;
  let maxValue = 0;
  for (const item of items) {
    if (item && item.persisted_samples) {
      for (const sample of item.persisted_samples) {
        const value = sample[valueColumnName];
        if (isUndefined(minValue) || value < minValue) minValue = value;
        if (value > maxValue) maxValue = value;
      }
    }
  }
  const result = [minValue, maxValue];
  sampleExtentByItems.set(items, result);
  return result;
};

const getItemsValuesExtent = (items, valueColumnName, valueSchemas) => {
  if (valuesExtentByItems.has(items)) return valuesExtentByItems.get(items)[valueColumnName];
  const result = transform(valueSchemas, (result, valueSchema, columnName) => {
    if (!VALUE_NUMBER_TYPES.has(valueSchema.type)) return;
    let minValue;
    let maxValue = 0;
    for (const item of items) {
      const value = item[columnName];
      if (isUndefined(minValue) || value < minValue) minValue = value;
      if (value > maxValue) maxValue = value;
    }
    result[columnName] = [minValue, maxValue];
  }, {});
  valuesExtentByItems.set(items, result);
  return result[valueColumnName];
};

export {getCorrespondingStageItemsForStages, getAllCorrespondingStageItemsForStages, getItemsSampleExtent,
  getItemsValuesExtent};
