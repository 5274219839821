import cx from 'classnames';
import {isNil, map} from 'lodash';
import {FC} from 'react';

import {LayerStatuses} from '../consts';
import {ALL_NODE_ROLES} from '../../../../roles';
import {ILinkEndpoint} from '../types';
import {InterfaceData} from '../utils';

import './GpuInterfaces.less';

type GpuInterfacesProps = {
  size?: number,
  gpuStatuses?: Record<string, LayerStatuses>,
  hideTooltip: () => void,
  nodeInterfaces: Record<string, InterfaceData>,
  showTooltipHandler: (ep: ILinkEndpoint, railIndex: number) => (e: any) => void,
}

export const GpuInterfaces: FC<GpuInterfacesProps> = ({
  size = 20,
  gpuStatuses,
  hideTooltip,
  nodeInterfaces,
  showTooltipHandler,
}) => {
  return (
    <>
      <defs>
        <path
          id='server-gpu-interface'
          d={
            `M${-size / 2},-4` +
            `h${size}` +
            `a${-size / 2},${-size / 2},0,1,1,${-size},0`
          }
        />
      </defs>
      {map(nodeInterfaces, ({renderRailIndex, railIndex, position, sourceInterface}, id) =>
        (!isNil(renderRailIndex) && !isNil(railIndex) && sourceInterface?.node?.role === ALL_NODE_ROLES.GENERIC) &&
          <use
            key={id}
            className={cx(
              'server-gpu-interface',
              `rail-${renderRailIndex + 1}`,
              !!gpuStatuses?.[sourceInterface.if_id] && `tp-status-${gpuStatuses[sourceInterface.if_id]}`
            )}
            href='#server-gpu-interface'
            x={position.x}
            y={position.y}
            onMouseOut={hideTooltip}
            onBlur={hideTooltip}
            onMouseOver={showTooltipHandler?.(sourceInterface, railIndex)}
            onFocus={showTooltipHandler?.(sourceInterface, railIndex)}
          />
      )}
    </>
  );
};
