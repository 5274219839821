import {Component, createRef} from 'react';
import {createPortal} from 'react-dom';
import {computed, observable, reaction, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {Ref, Table, Icon} from 'semantic-ui-react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import ResizeDetector from 'react-resize-detector';
import {map, flatMap, find, filter, transform, includes, without, isNil} from 'lodash';
import cx from 'classnames';
import {Checkbox, DropdownControl, Field} from 'apstra-ui-common';

import {getStageDataSchema, getStageRenderingStrategy} from '../stageUtils';
import IBAContext from '../IBAContext';
import {STAGE_DATA_SOURCE} from '../consts';

import './StageWidgetCustomizationInput.less';

const SORTING_NONE = 'none';
const SORTING_ASCENDING = 'asc';
const SORTING_DESCENDING = 'desc';

export default class StageWidgetCustomizationInput extends Component {
  static contextType = IBAContext;

  static defaultProps = {
    value: {visibleColumns: null, sorting: {}}
  };

  render() {
    const {
      value: {visibleColumns, sorting}, values,
      name, schema, required, disabled, onChange,
      selectedStageInfo, stageCustomizationAvailable,
    } = this.props;
    const {data_source: dataSource, value_column_name: valueColumnName, show_context: showContext} = values;
    return (
      <Field
        className='stage-widget-customization-input'
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
      >
        {!selectedStageInfo ?
          <div>{'Select a stage to see customization options.'}</div>
        : !stageCustomizationAvailable ?
          <div>{'This stage cannot be customized.'}</div>
        :
          <StageTableCustomizer
            visibleColumns={visibleColumns}
            sorting={sorting}
            selectedStageInfo={selectedStageInfo}
            dataSource={dataSource}
            valueColumnName={dataSource === STAGE_DATA_SOURCE.time_series ? valueColumnName : null}
            showContext={showContext}
            onChange={onChange}
          />
        }
      </Field>
    );
  }
}

@observer
export class StageTableCustomizer extends Component {
  static contextType = IBAContext;

  @observable valueColumnName = this.props.valueColumnName;

  disposeValueColumnNameReaction = reaction(
    () => this.props.valueColumnName,
    () => {
      if (this.valueColumnName !== this.props.valueColumnName) {
        this.onChange({visibleColumns: null});
        this.valueColumnName = this.props.valueColumnName;
      }
    },
    {fireImmediately: true}
  );

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get columns() {
    const {processorDefinitions} = this.context;
    const {selectedStageInfo: {processor, probe, stage}, dataSource, valueColumnName, showContext} = this.props;
    return getStageDataSchema({
      stage,
      processor,
      processorDefinitions,
      renderingStrategy: getStageRenderingStrategy({probe, stage, showContext, dataSource}),
      valueColumnName: dataSource === STAGE_DATA_SOURCE.time_series ? valueColumnName : null,
    });
  }

  @computed get normalizedVisibleColumns() {
    const {columns, props: {visibleColumns}} = this;
    const columnNames = map(columns, 'name');
    return isNil(visibleColumns) ? columnNames : filter(visibleColumns, (name) => includes(columnNames, name));
  }

  @computed get normalizedSorting() {
    const {columns, props: {sorting}} = this;
    return transform(columns, (result, {name}) => {
      if (sorting[name]) result[name] = sorting[name];
    }, {});
  }

  @computed get columnsGroupedByVisibility() {
    const {columns, normalizedVisibleColumns: visibleColumns} = this;
    return {
      true: map(visibleColumns, (name) => find(columns, {name})),
      false: filter(columns, (column) => !includes(visibleColumns, column.name)),
    };
  }

  onChange({visibleColumns = this.normalizedVisibleColumns, sorting = this.normalizedSorting}) {
    this.props.onChange({visibleColumns, sorting});
  }

  toggleColumnVisibility = (name) => {
    const {normalizedVisibleColumns: visibleColumns, normalizedSorting: sorting} = this;
    const currentlyVisible = includes(visibleColumns, name);
    const newVisibleColumns = currentlyVisible ? without(visibleColumns, name) : [...visibleColumns, name];
    const newSorting = {...sorting};
    if (currentlyVisible) delete newSorting[name];
    this.onChange({visibleColumns: newVisibleColumns, sorting: newSorting});
  };

  setColumnSorting = (name, value) => {
    const newSorting = {...this.normalizedSorting};
    if (value === SORTING_NONE) {
      delete newSorting[name];
    } else {
      newSorting[name] = value;
    }
    this.onChange({sorting: newSorting});
  };

  updateTableWidth = () => {
    this.tableWidth = this.tableRef.current.offsetWidth;
  };

  onColumnDragEnd = ({source, destination}) => {
    if (!destination || source.index === destination.index) return;
    const newVisibleColumns = [...this.normalizedVisibleColumns];
    const [removed] = newVisibleColumns.splice(source.index, 1);
    newVisibleColumns.splice(destination.index, 0, removed);
    this.onChange({visibleColumns: newVisibleColumns});
  };

  componentDidMount() {
    // mount point for draggable row
    const dndTable = document.createElement('table');
    dndTable.classList.add(...this.tableClass.split(' '));
    const dndTableBody = document.createElement('tbody');
    dndTable.appendChild(dndTableBody);
    document.body.appendChild(dndTable);
    this.dndTable = dndTable;
    this.dndTableBody = dndTableBody;
  }

  componentWillUnmount() {
    document.body.removeChild(this.dndTable);
    this.disposeValueColumnNameReaction();
  }

  sortingOptions = [
    {key: SORTING_NONE, value: SORTING_NONE, text: 'None', icon: 'arrows alternate vertical'},
    {key: SORTING_ASCENDING, value: SORTING_ASCENDING, text: 'Ascending', icon: 'long arrow alternate up'},
    {key: SORTING_DESCENDING, value: SORTING_DESCENDING, text: 'Descending', icon: 'long arrow alternate down'},
  ];

  tableRef = createRef();
  tableWidth = null;
  tableClass = 'ui small definition very basic table stage-widget-customization-table';

  render() {
    const {true: visibleColumns = [], false: invisibleColumns = []} = this.columnsGroupedByVisibility;
    return (
      <DragDropContext onDragEnd={this.onColumnDragEnd}>
        <ResizeDetector handleWidth onResize={this.updateTableWidth} targetRef={this.tableRef} />
        <Ref innerRef={this.tableRef}>
          <Table className={this.tableClass}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={6} />
                <Table.HeaderCell width={6} textAlign='center'>{'Visible'}</Table.HeaderCell>
                <Table.HeaderCell width={5} textAlign='center'>{'Sorting'}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {flatMap([[visibleColumns, true], [invisibleColumns, false]], ([columns, visible]) =>
              !!columns.length &&
                <Droppable
                  key={String(visible)}
                  droppableId={String(visible)}
                  isDropDisabled={!visible}
                >
                  {({innerRef, placeholder, droppableProps}) =>
                    <Ref innerRef={innerRef}>
                      <Table.Body {...droppableProps}>
                        {map(columns, ({name, label, sortable}, columnIndex) => {
                          const sorting = this.normalizedSorting[name] ?? SORTING_NONE;
                          return (
                            <Draggable
                              key={name}
                              draggableId={name}
                              index={columnIndex}
                              isDragDisabled={!visible}
                            >
                              {({innerRef, draggableProps, dragHandleProps}, {isDragging}) => {
                                if (isDragging && draggableProps.style && this.tableWidth) {
                                  draggableProps.style = {...draggableProps.style, width: this.tableWidth};
                                }
                                const element = (
                                  <Ref innerRef={innerRef}>
                                    <Table.Row
                                      {...draggableProps}
                                      className={cx({dragging: isDragging, invisible: !visible})}
                                    >
                                      <Table.Cell {...dragHandleProps}>
                                        <Icon name='bars' />
                                        {label}
                                      </Table.Cell>
                                      <Table.Cell textAlign='center'>
                                        <Checkbox
                                          aria-label={`'${label}' field visibility toggle`}
                                          checked={visible}
                                          disabled={this.valueColumnName === name}
                                          onChange={() => this.toggleColumnVisibility(name)}
                                        />
                                      </Table.Cell>
                                      <Table.Cell textAlign='center'>
                                        {sorting !== SORTING_NONE &&
                                          <Icon name={`long arrow alternate ${{asc: 'up', desc: 'down'}[sorting]}`} />
                                        }
                                        <DropdownControl
                                          inline
                                          selection={false}
                                          disabled={!sortable || !visible}
                                          value={sorting}
                                          options={this.sortingOptions}
                                          aria-label={`'${label}' field sorting toggle`}
                                          onChange={(value) => this.setColumnSorting(name, value)}
                                        />
                                      </Table.Cell>
                                    </Table.Row>
                                  </Ref>
                                );
                                return isDragging ? createPortal(element, this.dndTableBody) : element;
                              }}
                            </Draggable>
                          );
                        })}
                        {placeholder}
                      </Table.Body>
                    </Ref>
                  }
                </Droppable>
            )}
          </Table>
        </Ref>
      </DragDropContext>
    );
  }
}
