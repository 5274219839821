import {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Form} from 'semantic-ui-react';
import {observable, computed, action, makeObservable, reaction} from 'mobx';
import {observer} from 'mobx-react';
import {isNil, isPlainObject, castArray, flatten, map, transform, includes, isUndefined, isEmpty} from 'lodash';
import {DropdownInput, Field} from 'apstra-ui-common';

import IntegerExpressionControl from './IntegerExpressionControl';

export const RANGE_TYPE = {
  equals: 'EQUALS',
  strictMode: 'STRICT_MODE',
  nonStrictMode: 'NON_STRICT_MODE',
};

const nonStrictModes = ['LESS_OR_EQUAL', 'MORE_OR_EQUAL', 'IN_RANGE'];
const strictModes = ['LESS', 'MORE', 'IN_RANGE'];
const equalsMode = ['EQUALS'];
const [LESS_OR_EQUAL, MORE_OR_EQUAL, IN_RANGE] = nonStrictModes;
const [LESS, MORE] = strictModes;
const [EQUALS] = equalsMode;

const modeLabels = {
  [LESS_OR_EQUAL]: 'Less than or equal to',
  [MORE_OR_EQUAL]: 'More than or equal to',
  [IN_RANGE]: 'In range',
  [LESS]: 'Less than',
  [MORE]: 'More than',
  [EQUALS]: 'Equal to',
};

const optionsRangeType = {
  [RANGE_TYPE.equals]: equalsMode,
  [RANGE_TYPE.strictMode]: strictModes,
  [RANGE_TYPE.nonStrictMode]: nonStrictModes,
};

@observer
export default class RangeControl extends Component {
  static propTypes = {
    inputType: PropTypes.oneOf(['number', 'expression']),
    rangeTypes: PropTypes.arrayOf(PropTypes.string),
    placeholder: PropTypes.string,
    minInputFieldWidth: PropTypes.number,
    maxInputFieldWidth: PropTypes.number,
  };

  static defaultProps = {
    inputType: 'number',
    rangeTypes: [RANGE_TYPE.nonStrictMode],
    placeholder: 'Select range type',
    minInputFieldWidth: 6,
    maxInputFieldWidth: 12,
  };

  @observable mode = this.guessMode();

  constructor(props) {
    super(props);
    makeObservable(this);

    this.disposeValue = reaction(
      () => this.props.value,
      (value) => {
        if (isEmpty(value)) this.mode = this.guessMode();
      }
    );
  }

  componentWillUnmount() {
    this.disposeValue();
  }

  guessMode() {
    const {value, rangeTypes} = this.props;
    if (!isNil(value.min) && !isNil(value.max)) {
      return IN_RANGE;
    } else if (!isNil(value.equals)) {
      return EQUALS;
    } else if (!isNil(value.min) && isNil(value.max)) {
      return includes(rangeTypes, RANGE_TYPE.strictMode) ? MORE : MORE_OR_EQUAL;
    } else if (isNil(value.min) && !isNil(value.max)) {
      return includes(rangeTypes, RANGE_TYPE.strictMode) ? LESS : LESS_OR_EQUAL;
    } else {
      return null;
    }
  }

  @action
  setMode = (mode) => {
    if (includes([MORE, MORE_OR_EQUAL], this.mode) && includes([LESS, LESS_OR_EQUAL], mode)) {
      this.setValue({max: this.props.value.min || null});
    } else if (includes([LESS, LESS_OR_EQUAL], this.mode) && includes([MORE, MORE_OR_EQUAL], mode)) {
      this.setValue({min: this.props.value.max || null});
    } else if (mode === MORE_OR_EQUAL || mode === MORE) {
      this.setValue({min: this.props.value.min || null});
    } else if (mode === LESS_OR_EQUAL || mode === LESS) {
      this.setValue({max: this.props.value.max || null});
    } else if (mode === EQUALS) {
      this.setValue({equals: this.props.value.equals || null});
    } else if (mode === IN_RANGE) {
      this.setValue({min: this.props.value.min || null, max: this.props.value.max || null});
    }

    this.mode = mode;
  };

  @action
  setValue = ({min, equals, max}) => {
    const newValue = {};
    if (!isUndefined(min)) newValue.min = min;
    if (!isUndefined(equals)) newValue.equals = equals;
    if (!isUndefined(max)) newValue.max = max;
    this.props.onChange(newValue);
  };

  @computed get errors() {
    return transform(this.props.errors, (result, error) => {
      if (isPlainObject(error)) {
        if ('min' in error) result.min.push(...castArray(error.min));
        if ('max' in error) result.max.push(...castArray(error.max));
      } else {
        result.generic.push(...castArray(error));
      }
    }, {min: [], max: [], generic: []});
  }

  onInputChange(name, value) {
    const newValue = value !== '' ? value : null;
    if (this.mode === IN_RANGE) {
      const oppositeName = (name === 'min' ? 'max' : 'min');
      this.setValue({[name]: newValue, [oppositeName]: this.props.value[oppositeName]});
      return;
    }
    this.setValue({[name]: newValue});
  }

  renderInput(name) {
    const {
      inputType, value, disabled, inputFieldProps,
      minInputFieldWidth, maxInputFieldWidth, knownPythonExpressionVariables
    } = this.props;
    const errors = this.errors[name];
    const inRange = this.mode === IN_RANGE;
    return (
      <Field
        key={name}
        width={inRange ? minInputFieldWidth : maxInputFieldWidth}
        disabled={disabled}
        errors={errors}
        {...inputFieldProps}
      >
        <IntegerExpressionControl
          type={inputType}
          step={inputType === 'number' ? 'any' : undefined}
          disabled={disabled}
          knownPythonExpressionVariables={knownPythonExpressionVariables}
          value={!isNil(value[name]) ? value[name] : ''}
          onChange={(value) => this.onInputChange(name, value)}
        />
      </Field>
    );
  }

  render() {
    const {disabled, fieldProps, placeholder, rangeTypes} = this.props;
    const options = flatten(map(rangeTypes, (type) => optionsRangeType[type]));

    return (
      <Fragment>
        <DropdownInput
          fieldProps={{width: 4, ...fieldProps}}
          disabled={disabled}
          errors={this.errors.generic}
          options={options.map((mode) => ({key: mode, value: mode, text: modeLabels[mode]}))}
          value={this.mode}
          onChange={this.setMode}
          placeholder={placeholder}
          aria-label={modeLabels[this.mode] ?? placeholder}
        />
        {(this.mode === LESS_OR_EQUAL || this.mode === LESS) && this.renderInput('max')}
        {(this.mode === MORE_OR_EQUAL || this.mode === MORE) && this.renderInput('min')}
        {this.mode === EQUALS && this.renderInput('equals')}
        {this.mode === IN_RANGE && [
          this.renderInput('min'),
          <Form.Field key='dash' className='no-input-field'>{'—'}</Form.Field>,
          this.renderInput('max')
        ]}
      </Fragment>
    );
  }
}
