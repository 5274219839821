import {useCallback, useMemo} from 'react';
import {Form} from 'semantic-ui-react';
import {Field, ListInput, ValueInput, ValueRenderer} from 'apstra-ui-common';
import {compact, castArray, omit} from 'lodash';

const generateNewEntry = () => ({queue_id: 0, discard_threshold: 0, buffer_utilization_threshold: 0});
const isItemRemovable = (item) => item.queue_id !== 'default';

export default function QueueSpecificThresholdInput({
  name, value = [], schema, required, disabled, errors, onChange, fieldProps
}) {
  const onFieldChange = useCallback((index, field, newRowInputValue) => {
    const newInputValue = [...value];
    newInputValue[index][field] = newRowInputValue;
    onChange(newInputValue);
  }, [value, onChange]);

  const errorsByItems = useMemo(() => ListInput.getErrorByItems(errors), [errors]);

  return (
    <>
      <ListInput
        name={name}
        value={value}
        schema={schema}
        required={required}
        disabled={disabled}
        errors={errorsByItems.generic}
        onChange={onChange}
        generateNewEntry={generateNewEntry}
        isItemRemovable={isItemRemovable}
        minItems={1}
        header={<QueueSpecificThresholdHeader />}
        fieldProps={fieldProps}
      >
        {({value, disabled, index}) =>
          <>
            <ValueInput
              value={value.queue_id}
              schema={
                value.queue_id === 'default' ?
                  {oneOf: [{const: 'default', title: 'default'}]}
                :
                  {type: 'integer', minimum: 0}
              }
              disabled={disabled || value.queue_id === 'default'}
              fieldProps={{width: 5}}
              errors={compact(castArray(errorsByItems.byIndex[index]?.queue_id))}
              onChange={(rowInputValue) => onFieldChange(index, 'queue_id', rowInputValue)}
            />
            <ValueInput
              value={value.discard_threshold}
              schema={omit(schema.items.properties.discard_threshold, ['title'])}
              disabled={disabled}
              fieldProps={{width: 5}}
              errors={compact(castArray(errorsByItems.byIndex[index]?.discard_threshold))}
              onChange={(rowInputValue) => onFieldChange(index, 'discard_threshold', rowInputValue)}
            />
            <ValueInput
              value={value.buffer_utilization_threshold}
              schema={omit(schema.items.properties.buffer_utilization_threshold, ['title'])}
              disabled={disabled}
              fieldProps={{width: 5}}
              errors={compact(castArray(errorsByItems.byIndex[index]?.buffer_utilization_threshold))}
              onChange={(rowInputValue) => onFieldChange(index, 'buffer_utilization_threshold', rowInputValue)}
            />
          </>
        }
      </ListInput>
    </>
  );
}

const QueueSpecificThresholdHeader = () => (
  <Field className='field-subheader'>
    <Form.Group>
      <Field width={5} label='Queue ID' />
      <Field width={5} label='Discard Threshold' />
      <Field width={5} label='Buffer Utilization Threshold' />
    </Form.Group>
  </Field>
);

export const queueSpecificThresholdRenderer = new ValueRenderer({
  condition: ({name, schema}) =>
    name === 'threshold' &&
      schema.type === 'array' &&
      schema.items.type === 'object' &&
      schema.items.properties.queue_id &&
      schema.items.properties.discard_threshold &&
      schema.items.properties.buffer_utilization_threshold,
  Value: () => null,
  ValueInput: QueueSpecificThresholdInput,
});
