import {useCallback, useMemo} from 'react';
import {Form} from 'semantic-ui-react';
import {Field, ListInput, ValueInput, ValueRenderer} from 'apstra-ui-common';
import {compact, castArray} from 'lodash';

const generateNewEntry = () => ({queue_id: 0, threshold: 0});
const isItemRemovable = (item) => item.queue_id !== 'default';

export default function DiscardThresholdInput({
  name, value = [], schema, required, disabled, errors, onChange, fieldProps
}) {
  const onFieldChange = useCallback((index, field, newRowInputValue) => {
    const newInputValue = [...value];
    newInputValue[index][field] = newRowInputValue;
    onChange(newInputValue);
  }, [value, onChange]);

  const errorsByItems = useMemo(() => ListInput.getErrorByItems(errors), [errors]);

  return (
    <>
      <ListInput
        name={name}
        value={value}
        schema={schema}
        required={required}
        disabled={disabled}
        errors={errorsByItems.generic}
        onChange={onChange}
        generateNewEntry={generateNewEntry}
        isItemRemovable={isItemRemovable}
        minItems={1}
        header={<DiscardThresholdInputHeader />}
        fieldProps={fieldProps}
      >
        {({value, disabled, index}) =>
          <>
            <ValueInput
              value={value.queue_id}
              schema={
                value.queue_id === 'default' ?
                  {oneOf: [{const: 'default', title: 'default'}]}
                :
                  {type: 'integer', minimum: 0}
              }
              disabled={disabled || value.queue_id === 'default'}
              fieldProps={{width: 7}}
              errors={compact(castArray(errorsByItems.byIndex[index]?.queue_id))}
              onChange={(rowInputValue) => onFieldChange(index, 'queue_id', rowInputValue)}
            />
            <ValueInput
              value={value.threshold}
              schema={schema.items.properties.threshold}
              disabled={disabled}
              fieldProps={{width: 7}}
              errors={compact(castArray(errorsByItems.byIndex[index]?.threshold))}
              onChange={(rowInputValue) => onFieldChange(index, 'threshold', rowInputValue)}
            />
          </>
        }
      </ListInput>
    </>
  );
}

const DiscardThresholdInputHeader = () => (
  <Field className='field-subheader'>
    <Form.Group>
      <Field width={7} label='Queue ID' />
      <Field width={7} label='Threshold' />
    </Form.Group>
  </Field>
);

export const discardThresholdRenderer = new ValueRenderer({
  condition: ({name, schema}) =>
    name === 'discard_threshold' && schema.type === 'array' && schema.items.type === 'object',
  Value: () => null,
  ValueInput: DiscardThresholdInput,
});
