import {castArray, isEmpty, map} from 'lodash';

import DiscreteStateLegend from './graphs/DiscreteStateLegend';

export const ColumnDescription = ({column, stage, stageGraphColors, patternDescription}) => {
  const isContextValue = column.valueSchema && !(column.name in stage.values);

  const kind =
    column.key ? 'key' :
    isContextValue ? 'context value' :
    stage.values[column.name] ? 'value' : null;
  const showPatternDescription = kind === 'value' &&
    patternDescription?.description &&
    patternDescription?.renderingStrategy?.usePatternDescriptionAsValueDescription;

  const descriptionSchema = showPatternDescription ?
    {
      'Context Data Representation': patternDescription.description
    } :
    {
      Name: column.name,
      Kind: kind,
      Type: column.type,
      Units: column.valueSchema && stage.units[column.name],
      Description: column.valueSchema?.description && castArray(column.valueSchema.description).join(', '),
      'Possible Values': !isEmpty(column.valueSchema?.possible_values) &&
        <DiscreteStateLegend
          colors={stageGraphColors}
          possibleValues={column.valueSchema.possible_values}
        />
    };

  return map(descriptionSchema, (value, key) =>
    value ?
      <div key={key}>
        <strong>{key}{': '}</strong>
        {value}
      </div> :
    null
  );
};
