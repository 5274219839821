import {filter, includes, isEmpty, keys, max, min} from 'lodash';
import {COMBINE_GRAPHS_MODE, MultipleLineChart, StackedChart, TimelineGraphContainer,
  formatNumber} from 'apstra-ui-common';

import {rangeProcessorUtils} from '../../processorUtils';
import {sortStagePropertyNames} from '../../stageUtils';
import {secondsRenderer, speedRenderer} from '../../commonRenderers';
import chartPopupSystemIdLabelRenderer from '../../chartPopupSystemIdLabelRenderer';
import LineChartGraph from '../../../components/graphs/LineChart';
import {getAllCorrespondingStageItemsForStages, getItemsSampleExtent} from './cache';

export const LineChart = ({item, items, rangeMin, rangeMax, expanded = false, stageDataProps: {stage,
  filters: {combineGraphs}, visibleColumns}, valueColumnName, defaultColors}) => {
  const units = stage.units[valueColumnName];

  const stageKeys = isEmpty(visibleColumns) ?
    keys(stage.keys) :
    filter(keys(stage.keys), (key) =>
      (includes(visibleColumns, key) || includes(visibleColumns, `properties.${key}`)));
  const sortedKeys = sortStagePropertyNames(stageKeys);

  const [originalMinValue, originalMaxValue] = getItemsSampleExtent(items, valueColumnName);

  const minValue = min([originalMinValue, rangeMin, rangeMax]);
  const maxValue = max([originalMaxValue, rangeMin, rangeMax]);

  const axes = {
    x: {
      showGrid: true
    },
    y: {
      units,
      showGrid: true,
      formatLabel: (value) => formatNumber(value, {short: Math.abs(value) >= 1})
    }
  };
  const popupRenderers = [secondsRenderer, speedRenderer, chartPopupSystemIdLabelRenderer];

  if (combineGraphs !== COMBINE_GRAPHS_MODE.NONE) {
    return (
      <TimelineGraphContainer
        axes={axes}
        items={items}
        itemSamplesPath='persisted_samples'
        useCurrentTimeAsTimelineEnd={false}
        expanded={expanded}
      >
        {combineGraphs === COMBINE_GRAPHS_MODE.LINEAR ? (
          <MultipleLineChart
            popupContentItemKeys={sortedKeys}
            minValue={minValue}
            maxValue={maxValue}
            units={units}
            valueKeyName={valueColumnName}
            popupRenderers={popupRenderers}
          />
        ) : combineGraphs === COMBINE_GRAPHS_MODE.STACKED ? (
          <StackedChart
            popupContentItemKeys={sortedKeys}
            valueKeyName={valueColumnName}
            popupRenderers={popupRenderers}
          />
        ) : null}
      </TimelineGraphContainer>
    );
  }

  return (
    <TimelineGraphContainer
      axes={axes}
      samples={item.persisted_samples}
      useCurrentTimeAsTimelineEnd={false}
      expanded={expanded}
    >
      <LineChartGraph
        minValue={minValue}
        maxValue={maxValue}
        rangeMin={rangeMin}
        rangeMax={rangeMax}
        colors={defaultColors}
        valueKeyName={valueColumnName}
      />
    </TimelineGraphContainer>
  );
};

// NTS/NSTS + Range
export const LineChartWithRange = (props) => {
  const {item, index, items, expanded = false, patternDescription: {relatedStages},
    stageDataProps: {processor}} = props;
  const [nsSourceItems] = getAllCorrespondingStageItemsForStages({items, stages: relatedStages});
  const nsSourceItem = nsSourceItems[index];
  const {min: rangeMin, max: rangeMax} = rangeProcessorUtils.getMinMax(processor, item);

  return nsSourceItem &&
    <LineChart {...{
      ...props,
      item: nsSourceItem,
      items: nsSourceItems,
      rowKey: item.id,
      rangeMin,
      rangeMax,
      index,
      expanded,
      processor,
    }}
    />;
};
