import {map, flattenDeep, forEach, groupBy, min, max, clamp, some, values} from 'lodash';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {action} from 'mobx';
import {observer} from 'mobx-react';
import {Popup} from 'semantic-ui-react';
import {useResizeDetector} from 'react-resize-detector';
import cx from 'classnames';
import {select, zoom} from 'd3';

import Node from './Node';
import Grid from '../../cablingMapEditor/components/Grid';
import Selection from '../../cablingMapEditor/components/Selection';
import {ZoomArea, zoomConstrain} from '../../cablingMapEditor/components/ZoomArea';
import {GraphPreview} from '../../cablingMapEditor/components/GraphPreview';
import Draggable from '../../eptBuilder/components/Draggable';
import {useRackEditorStore} from '../hooks/useRackEditorStore';
import {ctrlGridStep, ctrlNodeHeight, ctrlNodeWidth} from '../../cablingMapEditor/const';
import {ReactComponent as Definitions} from '../../../styles/icons/cabling-map-editor/definitions.svg';
import Zone from './Zone';
import NodeProperties from './NodeProperties';
import LinksGroup from './LinksGroup';

import './Canvas.less';
import {pickBatchChangeFor} from '../utils';

const Canvas = ({readonly, snapped, fullscreen, zoomNode,
  minZoom = 0.2, maxZoom = 1, panPadding = 100, margin = 10, zoomStep = 1.1}) => {
  const canvasRef = useRef();
  const zoomGroupRef = useRef();

  const {rackStore, selection} = useRackEditorStore();
  const [zoomTransition, setZoomTransition] = useState(false);
  const [shiftIsPressed, trackShift] = useState(false);
  const [searchTransition, setSearchTransition] = useState(false);
  const searchTransitionTimeout = useRef();

  const [d3Transform, setTransform] = useState({});

  const height = 800;
  const {width = 800, ref} = useResizeDetector();

  const screenToPoint = useCallback((x, y) => {
    const pt = canvasRef?.current?.createSVGPoint();
    if (!pt) return {x: 0, y: 0};
    pt.x = x; pt.y = y;
    const transformed = pt.matrixTransform(zoomGroupRef.current.getScreenCTM().inverse());
    return {x: transformed.x, y: transformed.y};
  }, []);

  const pointToContainer = useCallback((x, y) => {
    const pt = canvasRef.current.createSVGPoint();
    pt.x = x; pt.y = y;
    const transformed = pt.matrixTransform(zoomGroupRef.current.getCTM());
    return {x: transformed.x, y: transformed.y};
  }, []);

  const shiftPress = useCallback((event) => trackShift(event?.shiftKey), []);

  useEffect(() => {
    window.addEventListener('keydown', shiftPress);
    window.addEventListener('keyup', shiftPress);
    return () => {
      window.removeEventListener('keydown', shiftPress);
      window.removeEventListener('keyup', shiftPress);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    positioner: {minX, minY, maxX, maxY}, editedNode, nodes, nodesByName, linksGroups
  } = rackStore;

  const panConstraints = useMemo(() => [
    [min([minX, 0]) - panPadding, min([minY, 0]) - panPadding],
    [max([maxX, minX + width]) + panPadding, max([maxY + panPadding, minY + height - margin])],
  ], [height, margin, maxX, maxY, minX, minY, panPadding, width]);

  // Node clicking in readonly mode navigates to the neighbors view
  // In editing mode - toggles node selection state
  const handleNodeClick = (event, node) => {
    selection.toggleNodeSelection(event, node);
  };

  // Mouse events handling and dotted background are exceeding in readonly mode
  // and must be removed
  const interactionProps = {
    onMouseDown: (event) => {
      selection.start(event, screenToPoint);
      selection.change(event, nodes, screenToPoint, true);

      rackStore.toggleOptions(true);
      rackStore.editNode();
      rackStore.toggleLinker();

      if (selection.selectedNodesIds.length === 1) {
        const id = selection.selectedNodesIds[0];
        selection.end();
        // node is remounted after selection.
        // this hack is needed to initialize dragging on newly selected
        // remounted node
        setTimeout(() => {
          const node = document.getElementById(id);
          node?.dispatchEvent(new MouseEvent('mousedown', event));
        }, 20);
      }
    },
    onMouseMove: (event) => selection.change(event, nodes, screenToPoint),
    onMouseUp: () => selection.end()
  };

  const classNames = cx(
    'rack-editor-canvas',
    {
      readonly,
      'zoom-transition': zoomTransition,
      pannable: shiftIsPressed || readonly,
      'search-transition': searchTransition
    }
  );

  const canvasZoom = useMemo(() => zoom(), []);

  useEffect(() => {
    canvasZoom
      .scaleExtent([minZoom, maxZoom])
      .translateExtent(panConstraints)
      .constrain(zoomConstrain)
      .filter((event) => {
        return readonly ? true : event?.shiftKey;
      })
      .on('zoom', (event) => {
        select(zoomGroupRef.current)
          .attr('transform', event.transform);
      });

    select(canvasRef.current)
      .call(canvasZoom)
      // force to apply constrains
      .call(canvasZoom.translateBy, 0, 0);
  }, [canvasRef, readonly, minZoom, maxZoom, panConstraints, canvasZoom]);

  useEffect(() => {
    canvasZoom.on('zoom.canvas', (e) => setTransform(e.transform));
    return () => canvasZoom.on('zoom.canvas', null);
  }, [canvasZoom]);

  const resetZoom = useCallback(() => {
    const horizontalZoom = width / (maxX - minX);
    const verticalZoom = height / (maxY - minY);
    const zoom = readonly ? clamp(max([horizontalZoom, verticalZoom]), minZoom, maxZoom) : 1;
    select(canvasRef.current)
      .call(canvasZoom.scaleTo, zoom)
      .call(
        canvasZoom.translateTo,
        0.5 * (panConstraints[0][0] + panConstraints[1][0]),
        0.5 * (panConstraints[0][1] + panConstraints[1][1])
      );
  }, [canvasZoom, height, maxX, maxY, maxZoom, minX, minY, minZoom, panConstraints, readonly, width]);

  const zoomIn = useCallback(() => {
    select(canvasRef.current).call(canvasZoom.scaleBy, zoomStep);
  }, [canvasZoom, zoomStep]);

  const zoomOut = useCallback(() => {
    select(canvasRef.current).call(canvasZoom.scaleBy, 1 / zoomStep);
  }, [canvasZoom, zoomStep]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(resetZoom, []);

  const onTransformChangeHandler = useCallback((transform) => {
    select(canvasRef.current)
      .call(canvasZoom.transform, transform);
  }, [canvasZoom]);

  // Handle links group clicking
  const onLinksGroupClick = useCallback(({id, fromName, toName, isPeer}) => {
    selection.select(nodesByName?.[fromName][0].id, nodesByName?.[toName][isPeer ? 1 : 0].id);
    rackStore.toggleLinker(id, selection);
  }, [rackStore, selection, nodesByName]);

  useEffect(() => {
    if (zoomNode) {
      setSearchTransition(true);
      setTimeout(() => {
        select(canvasRef.current)
          .call(
            canvasZoom.translateTo,
            zoomNode.position.x,
            zoomNode.position.y
          );
      }, 0);
      const timeout = searchTransitionTimeout.current = setTimeout(() => setSearchTransition(false), 1000);
      return () => clearTimeout(timeout);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomNode]);

  const gridSize = max(map(flattenDeep(panConstraints), Math.abs)) / minZoom;

  const editedNodeScreenPosition = editedNode && pointToContainer(editedNode.position.x, editedNode.position.y);
  return (
    <div ref={ref} className={cx('rack-editor-canvas-container', {fullscreen})}>
      <ZoomArea
        zoom={canvasZoom}
        minX={panConstraints[0][0]}
        minY={panConstraints[0][1]}
        maxX={panConstraints[1][0]}
        maxY={panConstraints[1][1]}
        width={width}
        height={height}
        onTransformChange={onTransformChangeHandler}
        setZoomTransition={setZoomTransition}
        parentRef={ref}
        fullscreen={fullscreen}
        resetZoom={resetZoom}
        zoomIn={zoomIn}
        zoomOut={zoomOut}
      >
        <GraphPreview
          nodes={nodes}
          minX={panConstraints[0][0]}
          minY={panConstraints[0][1]}
          maxX={panConstraints[1][0]}
          maxY={panConstraints[1][1]}
        />
      </ZoomArea>
      <svg
        ref={canvasRef}
        id='ct-canvas'
        className={classNames}
        xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'
        version='1.2'
        baseProfile='full'
        width={width}
        height={height - margin}
        {...interactionProps}
      >
        <g ref={zoomGroupRef}>
          {!readonly && <Grid className='background-grid' step={ctrlGridStep} size={gridSize} />}
          <Definitions />

          <Zones xOffset={d3Transform.x / d3Transform.k} />

          <Links
            linksGroups={linksGroups}
            onClick={readonly ? undefined : onLinksGroupClick}
            readonly={readonly}
          />

          <Nodes snapped={snapped} onClick={handleNodeClick} readonly={readonly} />

          <Selection selection={selection} />
        </g>
      </svg>
      {editedNode &&
        <Popup
          mountNode={ref?.current}
          context={canvasRef.current}
          style={{width: ctrlNodeWidth}}
          offset={[
            editedNodeScreenPosition.x,
            editedNodeScreenPosition.y + ctrlNodeHeight - height
          ]}
          size='tiny'
          position='bottom left'
          inverted={false}
          pinned
          open
          basic
        >
          <NodeProperties nodes={editedNode} />
        </Popup>
      }
    </div>
  );
};

// Render zones
const Zones = observer(({xOffset}) => {
  const {rackStore: {zones}} = useRackEditorStore();

  let y = 0;
  return map(zones, (zone) => {
    y += zone.size;
    return <Zone key={zone.label} {...{zone, x: xOffset, y}} />;
  });
});

// Renders a list of nodes
const renderNodesList = (nodes, isSelected, onClick, readonly) => (
  map(
    nodes,
    (node) => (
      <Node
        key={node.id}
        {...{node, isSelected, onClick, readonly}}
      />
    )
  )
);

// When selected nodes group is being moved each of the nodes must be moved accordingly
const handleMoving = action((offset, selectedNodes, selectedNodesIds) => {
  // If any of the nodes gets outside of the zone's top border - cancel movement
  if (some(selectedNodes, ({relativePosition: {y}}) => (y + offset.y < ctrlGridStep))) return;

  // Move each of selected nodes
  forEach(selectedNodes, (node) => {
    // Avoid double moving of switch pairs
    node.moveBy(offset, selectedNodesIds.includes(node?.pairedWith?.id));
  });
});

const dropNode = (node, snapped) => {
  let moved = false;
  if (node.position.y < ctrlGridStep) {
    node.moveTo({x: node.position.x, y: ctrlGridStep});
    moved = true;
  }
  if (snapped && (!node.isPaired || node.isFirstInPair)) {
    moved = node.snapToGrid() || moved;
  }
  return moved;
};

// When dropped, ...
const handleDropping = action((selection, snapped, selectedNodes, rackStore) => {
  let moved = false;
  forEach(selectedNodes, (node) => {
    moved = dropNode(node, snapped) || moved;
    if (node.pairedWith) {
      moved = dropNode(node.pairedWith, snapped) || moved;
    }
  });
  if (moved) {
    // Register changes with duplication checking
    rackStore.changes.register(selection.fixFinalState(rackStore.nodes), false, true);
    // Update zones maxes
    rackStore.calculateZonesSizes();
    // and the positions
    rackStore.updateArea();
  }
});

// Render nodes
const Nodes = observer(({readonly, snapped, onClick}) => {
  const {rackStore, selection} = useRackEditorStore();
  const {selectedNodesIds} = selection;

  // All nodes are split into selected/not selected groups
  // Selected get wrapped into Draggable and rendered on top of the others
  const nodesGroups = readonly ?
    {false: values(rackStore.nodes)} :
    groupBy(
      rackStore.nodes,
      ({id}) => selectedNodesIds.includes(id)
    );

  // Render both ...
  return map(
    // ... selected and not selected nodes groups
    [false, true],
    (isSelected) => {
      const nodesChunk = nodesGroups[isSelected];
      const nodesRendered = renderNodesList(nodesChunk, isSelected, onClick, readonly);

      const onStartDragging = () => {
        rackStore.editNode();
        selection.setBatchChanges(pickBatchChangeFor(nodesChunk, rackStore, false));
      };

      const onMove = (offset) => handleMoving(offset, nodesChunk, selectedNodesIds);
      const onDrop = () => handleDropping(selection, snapped, nodesChunk, rackStore);

      return isSelected ? (
        <Draggable
          key='selection'
          relative
          {...{onStartDragging, onMove, onDrop}}
        >
          {nodesRendered}
        </Draggable>
      ) : nodesRendered;
    });
});

// Renders links groups
const Links = observer(({readonly, onClick, linksGroups}) => {
  return map(linksGroups, (linksGroup) => (
    <LinksGroup
      key={linksGroup.id}
      linksGroup={linksGroup}
      readonly={readonly}
      onClick={onClick}
    />
  ));
});

export default observer(Canvas);
