import {Component, Fragment} from 'react';
import {scaleLinear} from 'd3';
import {assign, forEach, get, groupBy, isFinite, merge, transform} from 'lodash';
import {observable, action, computed, toJS, makeObservable} from 'mobx';
import {observer} from 'mobx-react';

import {NODE_ROLES} from '../../consts';
import HeadroomPopup from './HeadroomPopup';
import TrafficDiagram from './TrafficDiagram';
import {getMaxLinkSpeed, getNodeLinkMap, getLinkProperties, getNodeProperties,
  getTrafficDataFromSource} from './trafficUtils';

@observer
export default class HeadroomDiagram extends Component {
  @observable.ref popupDescription = null;

  static defaultProps = {
    ranges: [0, 0.35, 1],
    colors: ['#e43d2f', '#ffb71b', '#84b135'],
    minLinkValue: 20,
    labelKey: 'label',
    speedSchema: {
      speed: {
        title: 'Speed',
        units: 'bps',
      }
    },
  };

  scaleLinkColor = scaleLinear().domain(this.props.ranges)
    .range(this.props.colors).clamp(true);

  @action
  showPopup = (
    {clientX: left, clientY: top},
    {active, headroomValue, properties, propertiesSchema, speed, units, popupHeader, popupDescription}
  ) => {
    const {colors, ranges} = this.props;
    this.popupDescription = {
      colors,
      ranges,
      coordinates: {left, top},
      active,
      headroomValue,
      speed,
      header: popupHeader,
      description: popupDescription,
      properties,
      propertiesSchema,
      axes: {x: {units}}
    };
  };

  @action
  hidePopup = () => {
    this.popupDescription = null;
  };

  getLinkProps = (link, minValueForActiveLink) => {
    const {
      props: {interfaceValuesSchema, minLinkValue, speedSchema},
      hidePopup, nodeInterfaces, scaleLinkColor, showPopup
    } = this;
    const headroom = link.speed - link.traffic;
    const active = isFinite(link.traffic);
    return {
      active,
      units: 'bps',
      headroomValue: headroom,
      value: active ? Math.max(minValueForActiveLink, headroom)
        : Math.max(link.speed / 40, minLinkValue),
      properties: getLinkProperties(link, nodeInterfaces),
      propertiesSchema: merge(interfaceValuesSchema, speedSchema),
      popupHeader: `${link.sourceName} - ${link.targetName}`,
      popupDescription: `${link.sourceIntf} - ${link.targetIntf}`,
      props: {
        onMouseEnter: (e) => showPopup(e, link),
        onMouseLeave: hidePopup,
        stroke: active ? scaleLinkColor(headroom / link.speed) : undefined,
      }
    };
  };

  getNodeProps = (node) => {
    const {
      props: {systemValuesSchema, speedSchema},
      hidePopup, nodeInterfaces, scaleLinkColor, showPopup
    } = this;
    return {
      active: isFinite(node.utilization),
      properties: getNodeProperties({node, nodeInterfaces}),
      propertiesSchema: merge(systemValuesSchema, speedSchema),
      popupHeader: node.name,
      popupDescription: NODE_ROLES[node.role] ?? node.role,
      props: {
        onMouseEnter: (e) => showPopup(e, node),
        onMouseLeave: hidePopup,
        fill: isFinite(node.utilization) ? scaleLinkColor(1 - node.utilization / 100) : undefined
      }
    };
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed.struct
  get nodeInterfaces() {
    const {
      props: {interfaceItems, systemItems, systemIdMap, dataSource},
    } = this;
    const indexedInterfaces = groupBy(interfaceItems, (i) => i.properties.system_id);
    return transform(systemItems, (acc, system) => {
      const systemId = get(system, ['properties', 'system_id']);
      const id = systemIdMap[systemId]?.id;
      if (!id) return;
      acc[id] = {
        ...getTrafficDataFromSource(system, dataSource),
        interfaces: transform(indexedInterfaces[systemId], (acc, intf) => {
          acc[intf.properties.interface] = {
            ...getTrafficDataFromSource(intf, dataSource),
            speed: intf.properties.speed
          };
        }, {})
      };
    }, {});
  }

  @computed.struct
  get nodeLinkTraffic() {
    const nodeLinkMap = getNodeLinkMap(this.props.paths, this.nodeInterfaces, this.getSystemNameById);
    const maxSpeed = getMaxLinkSpeed(nodeLinkMap);
    const minValueForActiveLink = maxSpeed / 20;
    forEach(nodeLinkMap, (node) => {
      forEach([...node.inputs, ...node.outputs],
        (link) => assign(link, this.getLinkProps(link, minValueForActiveLink)));
      assign(node, this.getNodeProps(node));
    });
    return nodeLinkMap;
  }

  getSystemNameById = (id) => {
    const {labelKey, systemIdMap} = this.props;
    return get(systemIdMap, [id, labelKey]);
  };

  render() {
    const {
      popupDescription, props, nodeLinkTraffic
    } = this;
    const popupDescriptionJS = toJS(popupDescription);
    return (
      <Fragment>
        <TrafficDiagram
          nodeLinkTraffic={nodeLinkTraffic}
          {...props}
        />
        {popupDescriptionJS &&
          <HeadroomPopup {...popupDescriptionJS} />
        }
      </Fragment>
    );
  }
}
