import {find, last} from 'lodash';
import {TimelineGraphContainer, Value} from 'apstra-ui-common';
import {Fragment, useContext} from 'react';

import DiscreteStateTimeline from '../../../components/graphs/DiscreteStateTimeline';
import AnomalyHistoryGraphLegend from '../graphs/AnomalyHistoryGraphLegend';
import IBAContext from '../../IBAContext';
import {getCorrespondingStageItemsForStages} from './cache';

const DiscreteStateTimelineWithLegend = ({item, name, expanded, patternDescription: {relatedStages, relatedProcessors},
  defaultColors, valueSchemas}) => {
  const {processorDefinitions} = useContext(IBAContext);
  const {possible_values: possibleValues} = valueSchemas[name];

  const [nsProcessor, rangeProcessor, timeInStateProcessor] = relatedProcessors;

  const [nsSourceItem, rangeSourceItem] =
    getCorrespondingStageItemsForStages({item, stages: relatedStages});

  const processorDefinition = find(processorDefinitions, {name: nsProcessor.type});
  const counterTypeSchema = processorDefinition.schema.properties.counter_type;
  const counterTypeName = nsProcessor.properties.counter_type;

  let timeLineSamples = item.persisted_samples;
  if (!timeLineSamples && item.value) {
    timeLineSamples = [{
      timestamp: item.timestamp,
      value: item.value,
    }];
  }

  const numericValue = nsSourceItem?.value;
  const lastSample = last(timeLineSamples);
  const sustainedAnomalyValue = lastSample?.value;
  const instantaneousAnomalyValue = rangeSourceItem?.value;
  const axes = {
    x: {showGrid: true},
    y: {showGrid: true}
  };
  return (
    <TimelineGraphContainer
      axes={axes}
      samples={timeLineSamples}
      expanded={!!expanded}
      popupIntervalPrefix='Time in State: '
    >
      {(childProps) => (
        <Fragment>
          <DiscreteStateTimeline {...childProps} possibleValues={possibleValues} colors={defaultColors} />
          <AnomalyHistoryGraphLegend
            counterType={counterTypeName ? <Value value={counterTypeName} schema={counterTypeSchema} /> : null}
            lastSampleTime={last(timeLineSamples)?.timestamp}
            numericValue={numericValue}
            sustainedAnomalyValue={sustainedAnomalyValue}
            instantaneousAnomalyValue={instantaneousAnomalyValue}
            possibleValues={possibleValues}
            relatedProcessors={{rangeProcessor, timeInStateProcessor}}
            relatedItems={{rangeSourceItem, timeInStateSourceItem: item}}
            colors={defaultColors}
          />
        </Fragment>
      )}
    </TimelineGraphContainer>
  );
};

export default DiscreteStateTimelineWithLegend;
