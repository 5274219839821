import {Component, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {action, computed, makeObservable, observable} from 'mobx';
import {observer} from 'mobx-react';

import {find, filter, map, isNil, reject, sortBy, groupBy, keys, transform, uniq, size} from 'lodash';
import {Checkbox, DropdownControl} from 'apstra-ui-common';

import {linkPropTypes, nodePropTypes, aggregateLinkPropTypes} from './shared-prop-types';
import {PORT_ROLE_LABELS} from '../../../../portConsts';

import Link from './Link';
import Node from './Node';
import Rail from './Rail';
import AggregateLink from './AggregateLink';
import {TooltipPopup, TooltipProvider} from '../../GraphTooltips';

import './NeighborsView.less';
import DropdownWithCheckboxes from '../../../DropdownWithCheckboxes';

const DEFAULT_NEIGHBORS_ROLE_FILTER = 'all';

@observer
export class NeighborsView extends Component {
  static propTypes = {
    blueprintId: PropTypes.string,
    styles: PropTypes.object,
    chartWidth: PropTypes.number.isRequired,
    linksWidth: PropTypes.number.isRequired,
    nodeWidth: PropTypes.number.isRequired,

    links: PropTypes.arrayOf(
      PropTypes.shape(linkPropTypes)
    ).isRequired,

    node: PropTypes.shape(nodePropTypes),

    neighbors: PropTypes.arrayOf(
      PropTypes.shape(nodePropTypes)
    ).isRequired,

    aggregateLinks: PropTypes.arrayOf(
      PropTypes.shape(aggregateLinkPropTypes)
    ),
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get neighborsRoleOptions() {
    return [{key: DEFAULT_NEIGHBORS_ROLE_FILTER, value: DEFAULT_NEIGHBORS_ROLE_FILTER, text: 'All Neighbors'}]
      .concat(
        this.props.neighborsRoles?.sort()
          .map((role) => ({key: role, value: role, text: PORT_ROLE_LABELS[role] ?? role}))
      );
  }

  @computed
  get railsIndexes() {
    return sortBy(
      uniq(
        map(
          filter(this.props?.links, ({sourceLink: {railIndex}}) => !isNil(railIndex)),
          'sourceLink.railIndex'
        )
      )
    );
  }

  @computed get railOptions() {
    return map(this.railsIndexes, (role) => ({key: role, value: role, text: PORT_ROLE_LABELS[role] ?? role}));
  }

  @observable selectedRails = this.railsIndexes;

  @action
  setSelectedRails = (value) => {
    this.selectedRails = value;
  };

  renderRailsLabel() {

  }

  render() {
    const {
      chartWidth,
      showUnusedPorts, onShowUnusedPortsChange, showUnusedPortsAvailable,
      showAggregateLinks, onShowAggregateLinksChange, showAggregateLinksAvailable,
      neighborsRoleFilter, onNeighborsRoleFilterChange, className
    } = this.props;

    const railsCount = size(this.railsIndexes);
    const rolesFilter = !!onNeighborsRoleFilterChange;

    return (
      <div className={className}>
        <div style={{width: chartWidth}} className='neighbors-view-filter'>
          {showAggregateLinksAvailable &&
            <div>
              <Checkbox
                label='Show Aggregate Links'
                checked={showAggregateLinks}
                onChange={() => onShowAggregateLinksChange(!showAggregateLinks)}
              />
            </div>
          }
          {showUnusedPortsAvailable &&
            <div>
              <Checkbox
                label='Show Unused Ports'
                checked={showUnusedPorts}
                onChange={() => onShowUnusedPortsChange(!showUnusedPorts)}
              />
            </div>
          }
          {railsCount > 0 &&
            <div>
              <DropdownWithCheckboxes
                addControlButtons
                className='rail-filter'
                dropdownLabel='GPU Rails'
                currentSelection={this.selectedRails}
                onApplySelection={this.setSelectedRails}
                items={this.railOptions}
              />
            </div>
          }
          {rolesFilter &&
            <div>
              {'Show '}
              <DropdownControl
                inline
                aria-label='Filter neighbors by role'
                selection={false}
                options={this.neighborsRoleOptions}
                value={neighborsRoleFilter ?? DEFAULT_NEIGHBORS_ROLE_FILTER}
                onChange={(value) => onNeighborsRoleFilterChange(
                  value === DEFAULT_NEIGHBORS_ROLE_FILTER ? null : value
                )}
              />
            </div>
          }
        </div>
        <TooltipProvider layer=''>
          <NeighborsViewContent {...this.props} selectedRails={this.selectedRails} />
        </TooltipProvider>
      </div>
    );
  }
}

const NeighborsViewContent = observer((props) => {
  const {hoveredIf, setHoveredIf, hoveredPC, setHoveredPC, chartStyle, linksStyle, nodeContainerStyle, selectAggregate,
    hoveredLink, links, railsLinks, railsOrder, railsRenderOrder} = useNeighborsViewContent(props);

  const {
    blueprintId, node, neighbors, aggregateLinks, showAggregateLinks, interfaceSelection,
    onSelectInterface, linkSelection, highlightedInterfaces, onSelectNode, nodeSelected, onSelectAggregate,
    nodeMenuOpen, unusedInterfaceSelection
  } = props;

  return (
    <div
      className='neighbors-view clear'
      style={chartStyle}
    >
      <TooltipPopup />
      <div className='main-node-container' style={nodeContainerStyle}>
        <Node
          blueprintId={blueprintId}
          {...node}
          interfaceSelection={interfaceSelection}
          unusedInterfaceSelection={unusedInterfaceSelection}
          onSelectInterface={onSelectInterface}
          onSelectNode={onSelectNode}
          nodeSelected={nodeSelected}
          setHoveredIf={setHoveredIf}
          nodeMenuOpen={nodeMenuOpen}
          hoveredIf={hoveredIf}
        />
      </div>

      <svg className='links' style={linksStyle}>
        <g>
          {map(links, (link, i) => {
            const selected = !!linkSelection?.[link.sourceLink.id];
            const hovered = link.sourceLink.id === hoveredLink?.sourceLink.id;
            return (
              <Link
                key={i}
                selected={selected}
                hovered={hovered}
                {...link}
              />);
          })}
        </g>

        {showAggregateLinks &&
          <g>
            {map(aggregateLinks, (aggregateLink, i) => {
              return (
                <AggregateLink
                  key={i}
                  {...aggregateLink}
                  aggregateLink={aggregateLink}
                  hovered={aggregateLink.id === hoveredPC?.id}
                  onSelect={onSelectAggregate ? selectAggregate : null}
                  onHover={setHoveredPC}
                />
              );
            })}
          </g>}
        <g className='rails'>
          {
            map([true, false], (outline) =>
              map(railsRenderOrder, (railIndex) => {
                const railLinks = railsLinks[railIndex];
                return (
                  <Rail
                    key={railIndex}
                    links={railLinks}
                    railsOrder={railsOrder}
                    linkSelection={linkSelection}
                    hoveredLink={hoveredLink}
                    outline={outline}
                  />);
              })
            )
          }
        </g>
      </svg>

      <div className='neighbors-list' style={nodeContainerStyle}>
        {neighbors.map((node) => {
          const highlighted = highlightedInterfaces?.[node.id];
          const ifc = hoveredLink?.neighborInterfaceInfo;
          const hovered = node.id === ifc?.nodeId ? ifc?.interfaceId : null;
          return (
            <Node
              key={node.id}
              blueprintId={blueprintId}
              highlightedInterfaces={highlighted}
              hoveredIf={hovered}
              {...node}
            />
          );
        })}
      </div>

    </div>
  );
});

const railFinder = ({sourceLink: {railIndex}}) => isNil(railIndex);

const useNeighborsViewContent = ({chartWidth, linksWidth, nodeWidth, styles, blueprintId, node, links, aggregateLinks,
  onSelectAggregate, selectedRails}) => {
  const [hoveredIf, setHoveredIf] = useState(null);
  const [hoveredPC, setHoveredPC] = useState(null);

  const pureLinks = useMemo(() => filter(links, railFinder), [links]);
  const rails = useMemo(() => reject(links, railFinder), [links]);

  const railsLinks = groupBy(rails, 'sourceLink.railIndex');
  const railsOrder = transform(sortBy(keys(railsLinks)), (acc, railIndex, index) => {acc[railIndex] = index;}, {});

  const chartStyle = {...styles, width: chartWidth};
  const linksStyle = {width: linksWidth};
  const nodeContainerStyle = {width: nodeWidth};

  const linkContainsInterface = (ifId, link) => {
    const nodeIf = link.nodeInterfaceInfo.interfaceId;
    return nodeIf === ifId;
  };

  const selectAggregate = (aggregateId, ref) => {
    const aggregate = find(aggregateLinks, ['id', aggregateId]);
    const linkIds = aggregate?.linkIds;
    const aggLinks = filter(links, (l) => linkIds.indexOf(l.sourceLink.id) !== -1);
    const interfaces = map(aggLinks, (l) => l.nodeInterfaceInfo.interfaceId);
    onSelectAggregate(blueprintId, node.id, interfaces, ref);
  };

  const hoveredLink = find(links, (l) => linkContainsInterface(hoveredIf, l));
  const hoveredRailIndex = hoveredLink && hoveredLink?.sourceLink.railIndex;
  const railsRenderOrder = sortBy(selectedRails, (index) => +index === hoveredRailIndex ? 1 : -1);

  return {hoveredIf, setHoveredIf, hoveredPC, setHoveredPC, chartStyle, linksStyle, nodeContainerStyle, selectAggregate,
    hoveredLink, links: pureLinks, railsLinks, railsOrder, railsRenderOrder};
};
