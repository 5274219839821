import {FC, ReactNode, useCallback, useMemo, useState} from 'react';
import {useResizeDetector} from 'react-resize-detector';
import cx from 'classnames';
import {transform, map, max as _max, min as _min, omit, head, last, isUndefined, clamp, size, first,
  random} from 'lodash';
import {BoxPlot} from '@visx/stats';
import {Group} from '@visx/group';
import {formatNumber, formatDateAsLocalDateTime, AxisProps, AxesConsumer, AxesConsumerFn, Axes,
  AxesProps} from 'apstra-ui-common';
import moment, {MomentInput} from 'moment';

import {makePrintable} from './utils';
import ChartPopup from './ChartPopup';

import './BoxplotChart.less';

export const DATA_FORMAT = {
  none: 'none',
  timestamp: 'timestamp',
};

const MIN_BOX_SIZE = 3;
const HIDDEN_FIELDS = ['x', 'outliers', 'dots', 'points'];
const DOTS_BAR_GAP = 5;

type DotsCenters = {cx: number, cy: number}[];
type PopupDescriptionObject = {
  node: Element;
  header: string;
  content: ReactNode | (() => ReactNode | string);
} | null;

type PropGetter<T extends keyof BoxData> = (value: BoxData) => BoxData[T];

type BoxplotChartContentProps = {
  axes: Record<'x' | 'y', AxisProps>;
  samples: any[];
  x: PropGetter<'x'>;
  min: PropGetter<'min'>;
  max: PropGetter<'max'>;
  outliers: PropGetter<'outliers'>;
  dots?: PropGetter<'dots'>;
  /* eslint-disable react/no-unused-prop-types */
  // Not used in BoxplotChart component
  median: PropGetter<'median'>;
  firstQuartile: PropGetter<'firstQuartile'>;
  thirdQuartile: PropGetter<'thirdQuartile'>;
  gap: number;
  dotRadius: number;
  maxBarWidth: number;
  combineDotsWithBars: boolean;
  /* eslint-enable */
};

const BoxplotChartContent: FC<BoxplotChartContentProps & AxesConsumer> = ({
  xScale, yScale, axes, samples,
  x, min, max, median, firstQuartile, thirdQuartile, outliers, dots, dotRadius, gap, maxBarWidth, combineDotsWithBars
}) => {
  const [popupDescription, setPopupDescription] = useState<PopupDescriptionObject>(null);
  const hidePopup = () => setPopupDescription(null);

  const showDots = !isUndefined(dots);
  const formatIsTimestamp = axes.x?.isTimestamp;
  const groups = axes.x?.values ?? [];
  const units = axes.y?.units;
  const samplesCount = size(samples);

  // Making paddings on the X axis
  if (formatIsTimestamp) {
    let timeDomain: (string | number | Date)[] = groups;
    // For the timestamp format emulate paddings by adding the offsets to the beginning
    // and the end of the scale
    let [start, end] = [moment(head<MomentInput>(groups)), moment(last<MomentInput>(groups))];
    // Handling proper date/timestamp format parsing
    if (!start.isValid() || !end.isValid()) {
      [start, end] = [moment().subtract(1, 'd'), moment().add(1, 'd')];
    }
    // Simulate offsets on the time scale
    const timeOffset = moment.duration(end.diff(start)).asMilliseconds() / (samplesCount + 1);
    timeDomain = [
      start.subtract(timeOffset, 'ms').toDate(),
      end.add(timeOffset, 'ms').toDate()
    ];
    xScale.domain(timeDomain);
  } else if (!combineDotsWithBars && showDots && samplesCount > 3) {
    // Make outer padding and align to the right to fit the dots on the left edge.
    // It is only visible when there are many samples (e.g. > 3)
    xScale.paddingOuter?.(0.2).align?.(1);
  }

  // Maximal possible size of the single figure
  const domain = xScale.domain();
  const bandWidth = xScale.bandwidth ?
    xScale.bandwidth() :
    (samplesCount === 1 ?
      maxBarWidth :
      (xScale(last(domain)) - xScale(first(domain))) / (samplesCount || 1));

  // Calculate visible bar's width + gap size
  const gapDelta = maxBarWidth - bandWidth + gap;
  let visibleBoxWidth = _min([bandWidth, maxBarWidth]) - clamp(gapDelta, 0, gap);
  if (showDots && !combineDotsWithBars) {
    visibleBoxWidth = (visibleBoxWidth - DOTS_BAR_GAP) / 2;
  }
  visibleBoxWidth = clamp(visibleBoxWidth, MIN_BOX_SIZE, maxBarWidth);

  // Calculate left offset for the given figure
  const calculateBoxLeft = useCallback(
    (d) => {
      const result = xScale(x(d)) - visibleBoxWidth / 2 + (formatIsTimestamp ? 0 : bandWidth / 2);
      return (isNaN(result) ? 0 : result);
    },
    [bandWidth, formatIsTimestamp, visibleBoxWidth, x, xScale]
  );

  // Box always stays on the same position only its size changes. But the dots might either be
  // displayed over the bar or aside
  const dotsOffset = (!showDots || combineDotsWithBars) ? 0 : -(visibleBoxWidth + DOTS_BAR_GAP);

  const dotsData = useMemo(() => {
    return transform(
      samples,
      (result: DotsCenters[], d) => {
        const middleX = calculateBoxLeft(d);
        const dotsCoords = transform(
          dots?.(d) ?? [],
          (acc: DotsCenters, dotY) => {
            const [cx, cy] = [middleX + random(visibleBoxWidth), yScale(dotY)];
            if (!isNaN(cx) && !isNaN(cy)) acc.push({cx, cy});
          },
          []
        );
        result.push(dotsCoords);
      },
      []
    );
  }, [samples, calculateBoxLeft, dots, visibleBoxWidth, yScale]);

  const popupEvents = useCallback(
    (d, popupProps) => ({
      onMouseOver: (e) => setPopupDescription({
        node: e.target,
        header: formatIsTimestamp ? formatDateAsLocalDateTime(x(d)) : x(d),
        content: () => <BoxplotPopupContent {...popupProps} />
      }),
      onMouseLeave: hidePopup,
    }),
    [formatIsTimestamp, x]
  );

  return (
    <>
      <Group>
        {
          map(samples, (d, index) => {
            const popupFullData = {items: omit(d, HIDDEN_FIELDS), units, outliers: outliers(d)};
            return (
              <g key={`item-${index}`} className='boxplot-points'>
                {showDots && (
                  <Group className='boxplot-dots' left={dotsOffset}>
                    {map(dotsData[index], ({cx, cy}, dotIndex) => (
                      <circle key={`dot-${dotIndex}`} r={dotRadius} cx={cx} cy={cy} />
                    ))}
                  </Group>
                )}
                <BoxPlot
                  className='boxplot-summary'
                  min={min(d)}
                  max={max(d)}
                  left={calculateBoxLeft(d)}
                  firstQuartile={firstQuartile(d)}
                  thirdQuartile={thirdQuartile(d)}
                  median={median(d)}
                  boxWidth={visibleBoxWidth}
                  valueScale={yScale}
                  outliers={outliers(d)}
                  rx={0}
                  ry={0}
                  minProps={popupEvents(
                    d, min(d) === max(d) ? popupFullData : {items: {lower_fence: min(d)}, units}
                  )}
                  maxProps={popupEvents(d, {items: {upper_fence: max(d)}, units})}
                  boxProps={popupEvents(d, popupFullData)}
                />
              </g>
            );
          })
        }
      </Group>
      <ChartPopup popupDescription={popupDescription} />
    </>
  );
};

type BoxData = {
  x: number;
  min: number;
  median: number;
  max: number;
  firstQuartile: number;
  thirdQuartile: number;
  outliers?: number[];
  dots?: number[];
}

type BoxplotChartProps = {
  mode: 'compact' | 'expanded';
  width?: number;
  dimensions: Record<BoxplotChartProps['mode'], {height: number}>;
  className?: string;
} & BoxplotChartContentProps;

const BoxplotChart: FC<BoxplotChartProps> = (props) => {
  const {axes, mode, width: widthProp, dimensions, samples, className, x, min, max, outliers, dots} = props;

  const {width: parentWidth = widthProp, ref: targetRef} = useResizeDetector({handleHeight: false});

  const chartWidth = (isUndefined(widthProp) ? parentWidth : widthProp) || 0;
  const {height: chartHeight} = dimensions[mode];

  const groups = map<any[], number | string>(samples, x) as (number[] | string[]);

  const [minYValue, maxYValue] = useMemo(() => {
    const values = transform(samples, (result: (number | undefined)[], d: BoxData) => {
      result.push(min(d), max(d), _min(outliers(d)), _max(outliers(d)));
      if (dots?.(d)) {
        result.push(_min(dots(d)), _max(dots(d)));
      }
    }, []);
    const minYValue = _min(values) || 0;
    const maxYValue = _max(values) || 0;

    return [minYValue, maxYValue];
  }, [samples, max, min, outliers, dots]);

  const axesWithDefaults = {
    x: {
      ...axes?.x,
      values: groups
    },
    y: {
      isLinear: true,
      ...axes?.y,
      values: [minYValue, maxYValue]
    }
  };

  const axesProps: Omit<AxesProps, 'children'> = {
    width: chartWidth,
    height: chartHeight,
    ...axesWithDefaults,
    clip: false
  };

  return (
    <div
      ref={targetRef}
      className={cx('boxplot-chart', 'graph-container', {expandable: mode !== 'expanded'})}
    >
      {
        chartWidth > 0 &&
          <svg className={cx('boxplot-chart-layout', className)} width={chartWidth} height={chartHeight}>
            <Axes {...axesProps}>
              {
                ((xScale, yScale) => <BoxplotChartContent
                  {...props} axes={axesWithDefaults} {...{xScale, yScale}}
                />) as AxesConsumerFn
              }
            </Axes>
          </svg>
      }
    </div>
  );
};

BoxplotChart.defaultProps = {
  mode: 'expanded',
  dimensions: {
    compact: {
      height: 150
    },
    expanded: {
      height: 400
    },
  },
  x: (d: BoxData) => d.x,
  min: (d: BoxData) => d.min,
  max: (d: BoxData) => d.max,
  median: (d: BoxData) => d.median,
  firstQuartile: (d: BoxData) => d.firstQuartile,
  thirdQuartile: (d: BoxData) => d.thirdQuartile,
  outliers: (d: BoxData) => d.outliers,
  dotRadius: 2,
  gap: 5,
  maxBarWidth: 40,
  combineDotsWithBars: false
};

const BoxplotPopupContent = ({items, outliers, units}) => {
  const data = useMemo(() => {
    let filteredItems = {...items};
    const values = map(filteredItems, (value) => value);
    const minOutlier = _min(outliers) || 0;
    const maxOutlier = _max(outliers) || 0;
    if (maxOutlier > _max(values)) filteredItems = {max: maxOutlier, ...filteredItems};
    if (minOutlier < _min(values)) filteredItems.min = minOutlier;
    return filteredItems;
  }, [items, outliers]);

  return (
    <div className='ui bulleted list'>
      {map(data, (value, key) => (
        <div key={key} className='item'>
          <b>{makePrintable(key)}</b>
          {': '}
          {formatNumber(value, {units, short: true, withIndent: true})}
        </div>
      ))}
    </div>
  );
};

export default BoxplotChart;

