import {PureComponent} from 'react';
import {map, merge} from 'lodash';
import {Axes} from 'apstra-ui-common';

import './EventTimeline.less';

export default class EventTimeline extends PureComponent {
  static defaultProps = {
    mode: 'compact',
    eventCircleRadius: 4,
    maxHeaderLength: 140,
    dimensions: {
      compact: {
        height: 40
      },
      expanded: {
        height: 100
      }
    }
  };

  formatValue = (value) => {
    const {maxHeaderLength} = this.props;
    return value.length > maxHeaderLength ? value.slice(0, maxHeaderLength) + '…' : value;
  };

  render() {
    const {
      props: {
        axes, mode, dimensions,
        samples, sampleTimes,
        width, eventCircleRadius,
        showPopup, hidePopup,
        timelineStartTime, timelineEndTime,
        valueKeyName
      }
    } = this;

    const {height} = dimensions[mode];
    const isExpanded = mode === 'expanded';
    const axesProps = {
      width,
      height,
      x: merge(
        {},
        axes?.x,
        {
          isLinear: true,
          isTimestamp: true,
          ticks: isExpanded ? axes?.x?.ticks : 0,
          values: [timelineStartTime, timelineEndTime]
        }
      ),
      y: {
        hide: true,
        isLinear: true,
        values: [-1, 1]
      },
      clip: false
    };

    return (
      <svg className='event-timeline' width={width} height={height}>
        <Axes {...axesProps}>
          {
            (xScale, yScale) => {
              const y0 = yScale(0);
              const [xMin, xMax] = xScale.range();
              return (
                <>
                  <line x1={xMin} y1={y0} x2={xMax} y2={y0} />
                  {
                    map(samples, (sample, sampleIndex) => {
                      const x = xScale(sampleTimes[sampleIndex]);
                      return (
                        <circle
                          key={sample.timestamp}
                          r={eventCircleRadius}
                          cx={x}
                          cy={y0}
                          onMouseEnter={(e) => showPopup?.({
                            node: e.target,
                            timestamp: sample.timestamp,
                            header: this.formatValue(sample[valueKeyName]),
                          })}
                          onMouseLeave={hidePopup}
                        />
                      );
                    })
                  }
                </>
              );
            }
          }
        </Axes>
      </svg>
    );
  }
}
