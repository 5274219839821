import {Component} from 'react';
import {observable, action, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {Group} from '@visx/group';
import {scaleOrdinal} from 'd3';
import {map, transform, merge} from 'lodash';
import {formatDateAsLocalTime, Axes} from 'apstra-ui-common';

import './DiscreteStateTimeline.less';

@observer
export default class DiscreteStateTimeline extends Component {
  static defaultProps = {
    mode: 'compact',
    possibleValues: [],
    dimensions: {
      compact: {
        height: 60
      },
      expanded: {
        height: 110
      },
    },
    colors: ['green', 'red'],
    valueKeyName: 'value'
  };

  colorScale = scaleOrdinal(this.props.colors);

  @observable.ref possibleValuesTextLengths = null;

  @action
  computeTextLengths = (node) => {
    if (node && !this.possibleValuesTextLengths) {
      this.possibleValuesTextLengths = transform(this.props.possibleValues, (result, value) => {
        node.textContent = value;
        result[value] = Math.ceil(node.getComputedTextLength());
      }, {});
    }
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  render() {
    const {
      colorScale, possibleValuesTextLengths, computeTextLengths,
      props: {
        axes: axesProp, mode, dimensions, width,
        samples, sampleTimes, possibleValues,
        numTicksColumns, showPopup, hidePopup,
        timelineStartTime, timelineEndTime, valueKeyName
      }
    } = this;

    const {height} = dimensions[mode];
    colorScale.domain(possibleValues);

    const axes = {
      width,
      height,
      x: merge(
        {
          hide: mode !== 'expanded',
          ticks: numTicksColumns,
          formatLabel: formatDateAsLocalTime,
          values: [timelineStartTime, timelineEndTime]
        },
        axesProp?.x,
        {
          showGrid: false,
          isLinear: true,
          isTimestamp: true
        }),
      y: merge(
        {
          isLinear: true,
          hide: true,
          ticks: 5,
          values: [0, 1]
        },
        axesProp?.y,
        {
          showGrid: false
        }
      ),
      clip: true
    };

    return (
      <svg className='discrete-state-timeline' width={width} height={height}>
        <Axes {...axes}>
          {
            (xScale, yScale) => {
              const [x0, x1] = xScale.range();
              const xMax = x1 - x0;

              const [y1, y0] = yScale.range();
              const barHeight = y1 - y0;

              return width > 0 && map(samples, (sample, sampleIndex) => {
                const sampleTime = sampleTimes[sampleIndex];
                const nextSampleTime = sampleTimes[sampleIndex + 1] ?? null;
                const x = xScale(sampleTime);
                const barWidth = (nextSampleTime ? xScale(nextSampleTime) : xMax) - x;
                return barWidth ? (
                  <Group
                    key={sample.timestamp}
                    className={`graph-color-${colorScale(sample[valueKeyName])}`}
                    left={x}
                  >
                    <rect
                      x={0}
                      y={y0}
                      width={barWidth}
                      height={barHeight}
                      strokeDasharray={nextSampleTime ?
                        `${barWidth} ${barHeight} ${barWidth + barHeight}` :
                        undefined
                      }
                      onMouseEnter={(e) => showPopup({
                        node: e.target,
                        timestamp: sample.timestamp,
                        header: sample[valueKeyName],
                      })}
                      onMouseLeave={hidePopup}
                    />
                    {possibleValuesTextLengths && possibleValuesTextLengths[sample[valueKeyName]] < barWidth &&
                      <text
                        x={barWidth / 2}
                        y={barHeight / 2 + 5}
                      >
                        {sample[valueKeyName]}
                      </text>
                    }
                  </Group>
                ) : null;
              });
            }
          }
        </Axes>
        {!possibleValuesTextLengths &&
          <text ref={computeTextLengths} />
        }
      </svg>
    );
  }
}
