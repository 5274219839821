import {BaseSyntheticEvent, FC, useMemo} from 'react';
import cx from 'classnames';

import {GPU_BUS_SPACE} from '../consts';
import {RailLinkData} from '../utils';

import './RailLink.less';

type RailLinkProps = RailLinkData & {
  roundRadius?: number,
  onClick: () => void;
  onMouseOut?: (e: BaseSyntheticEvent) => void;
  onBlur?: (e: BaseSyntheticEvent) => void;
  onMouseOver?: (e: BaseSyntheticEvent) => void;
  onFocus?: (e: BaseSyntheticEvent) => void;
};

const getLeafVOffset = (gpuRail: number, railCount: number) => {
  return Math.round((1 + Math.abs((railCount - 1) / 2 - gpuRail)) * GPU_BUS_SPACE);
};

const getRailBusXPos = (gpuRail: number, railCount: number, xCenter: number) => {
  return Math.round(xCenter + (gpuRail - 0.5 * (railCount - 1)) * GPU_BUS_SPACE);
};

export const RailLink: FC<RailLinkProps> = ({
  roundRadius = 6,
  gpuPoint, leafPoint, renderRailIndex, rackXCenter, railCount,
  onClick, onMouseOut, onBlur, onMouseOver, onFocus
}) => {
  const curve = useMemo(
    () => {
      if (!leafPoint || !gpuPoint) return null;
      const railBusX = getRailBusXPos(renderRailIndex, railCount, rackXCenter);
      const serverVOffset = gpuPoint.y - (railCount - renderRailIndex + 1) * GPU_BUS_SPACE;
      const leafVOffset = getLeafVOffset(renderRailIndex, railCount);
      const isLeftLeaf = leafPoint.x < rackXCenter;
      const isLeftServer = gpuPoint.x < rackXCenter;
      /* eslint-disable max-len */
      return (
        `M${leafPoint.x},${leafPoint.y}` +
        `v${leafVOffset - roundRadius}` +
        `a${roundRadius},${roundRadius},0,0,${isLeftLeaf ? 0 : 1},${isLeftLeaf ? roundRadius : -roundRadius},${roundRadius}` +
        `H${railBusX + roundRadius * (isLeftLeaf ? -1 : 1)}` +
        `a${roundRadius},${roundRadius},0,0,${isLeftLeaf ? 1 : 0},${isLeftLeaf ? roundRadius : -roundRadius},${roundRadius}` +
        `V${serverVOffset - roundRadius}` +
        `a${roundRadius},${roundRadius},0,0,${isLeftServer ? 1 : 0},${isLeftServer ? -roundRadius : roundRadius},${roundRadius}` +
        `H${gpuPoint.x + roundRadius * (isLeftServer ? 1 : -1)}` +
        `a${roundRadius},${roundRadius},0,0,${isLeftServer ? 0 : 1},${isLeftServer ? -roundRadius : roundRadius},${roundRadius}` +
        `V${gpuPoint.y}`
      );
      /* eslint-enable max-len */
    },
    [leafPoint, gpuPoint, renderRailIndex, railCount, rackXCenter, roundRadius]
  );

  return curve ? (
    <g
      className={cx('rail-link', `rail-${renderRailIndex + 1}`)}
      onClick={onClick}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      onBlur={onBlur}
      onFocus={onFocus}
    >
      <path
        className='rail-link-hover-area'
        d={curve}
      />
      <path
        className='rail-link-curve'
        d={curve}
      />
    </g>
  ) : null;
};
