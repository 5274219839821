import {Component} from 'react';
import {Placeholder} from 'semantic-ui-react';
import {times} from 'lodash';
import {FetchData, FetchDataError} from 'apstra-ui-common';

import StageWidget from './StageWidget';
import {WIDGET_TYPE_STAGE} from '../consts';
import IBAContext from '../IBAContext';

import './Widget.less';

export const widgetComponentsByType = {
  [WIDGET_TYPE_STAGE]: StageWidget,
};

export default class Widget extends Component {
  static contextType = IBAContext;

  static async fetchData({widgetType, ...params}) {
    const WidgetComponent = widgetComponentsByType[widgetType];
    const widgetData = WidgetComponent.fetchData ? await WidgetComponent.fetchData(params) : null;
    return {widgetData};
  }

  render() {
    const {widget, widgetData} = this.props;
    const WidgetComponent = widgetComponentsByType[widget.type];
    if (!WidgetComponent) return null;
    if (widgetData !== undefined) {
      return (
        <WidgetComponent {...this.props} />
      );
    } else {
      const {blueprintId, isFreefrom, processorDefinitions, routes} = this.context;
      return (
        <FetchData
          customLoader
          fetchData={Widget.fetchData}
          fetchParams={{widgetType: widget.type, blueprintId, isFreefrom, processorDefinitions, widget, routes}}
          pollingInterval={WidgetComponent.pollingInterval}
        >
          {({widgetData, loaderVisible, fetchDataError}) =>
            loaderVisible ?
              <WidgetLoader />
            : fetchDataError ?
              <FetchDataError error={fetchDataError} />
            :
              <WidgetComponent widgetData={widgetData} {...this.props} />
          }
        </FetchData>
      );
    }
  }
}

export function WidgetLoader() {
  return (
    <div className='widget-loader'>
      <Placeholder>
        {times(6, (index) => <Placeholder.Line key={index} />)}
      </Placeholder>
    </div>
  );
}
