import {FC, useMemo} from 'react';
import {map} from 'lodash';

import {TopologyViewModel} from '../utils';

import {RailLink} from './RailLink';

type RailLinksProps = {
  hideTooltip?: () => void;
  onClickLink?: (id: string) => void,
  railLinks: TopologyViewModel['railLinks'],
  railsToShow?: number[],
  showTooltipHandler?: (link) => any;
}

export const RailLinks: FC<RailLinksProps> = ({
  hideTooltip,
  onClickLink,
  railLinks,
  railsToShow,
  showTooltipHandler,
}) => {
  const railsToShowSet = useMemo(
    () => new Set(railsToShow),
    [railsToShow]
  );
  return (
    <>
      {map(railLinks, (railLink, linkId) => (
        (!railsToShow || railsToShowSet.has(railLink.railIndex)) ?
          <RailLink
            key={linkId}
            {...railLink}
            onClick={() => onClickLink?.(linkId)}
            onMouseOut={hideTooltip}
            onBlur={hideTooltip}
            onMouseOver={showTooltipHandler?.(railLink.sourceLink)}
            onFocus={showTooltipHandler?.(railLink.sourceLink)}
          /> : null
      ))}
    </>
  );
};
