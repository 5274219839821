import {
  map, flatMap, find, transform, split, castArray, isString, isNumber, isBoolean,
  isPlainObject, isNil, isArray, capitalize, isFinite,
} from 'lodash';

const valueConverters = [
  {condition: isString, convert: convertStringValue},
  {condition: isBoolean, convert: convertBooleanValue},
  {condition: isArray, convert: convertArrayValue},
];

function convertBooleanValue(value) {
  return capitalize(String(value));
}

function convertStringValue(value) {
  return `"${value.replace(/["\\]/g, (c) => '\\' + c)}"`;
}

function convertArrayValue(value) {
  return `[${value.map((value) => convertValue(value)).join(',')}]`;
}

function convertValue(value) {
  for (const {condition, convert} of valueConverters) if (condition(value)) return convert(value);
  return String(value);
}

const filterConverters = [
  {condition: isRegexFilter, convert: convertRegexFilter},
  {condition: isPrimitiveValue, convert: convertPrimitiveFilter},
  {condition: isArray, convert: convertArrayFilter},
  {condition: isExactOrRegexFilter, convert: convertExactOrRegexFilter},
  {condition: isTimeFilter, convert: convertTimeFilter},
  {condition: isRangeFilter, convert: convertRangeFilter},
];

function convertFilter(name, value, schema) {
  const filterSchema = find(schema, {name}) ?? {};
  for (const {condition, convert} of filterConverters) {
    if (condition(value, filterSchema)) {
      return convert(name, value, filterSchema);
    }
  }
  return [];
}

function isRegexFilter(value, schema) {
  return schema.match === 'regex' && isString(value);
}
function convertRegexFilter(name, value) {
  return `${name} ~= ${convertValue(value)}`;
}

function isPrimitiveValue(value) {
  return isString(value) || isNumber(value) || isBoolean(value);
}
function convertPrimitiveFilter(name, value) {
  return `${name} = ${convertValue(value)}`;
}

function isExactOrRegexFilter(value, schema) {
  return schema.match === 'exactOrRegex' &&
    isPlainObject(value) && !isNil(value.value) && ['regex', 'text'].includes(value.type);
}
function convertExactOrRegexFilter(name, {type, value}) {
  const operator = type === 'regex' ? '~=' : '=';
  return `${name} ${operator} ${convertValue(value)}`;
}

function isRangeFilter(value, schema) {
  return schema.match === 'range' &&
    isPlainObject(value) && (!isNil(value.min) || !isNil(value.max) || !isNil(value.equals));
}
function convertRangeFilter(name, {min, equals, max}, {strictMode}) {
  const result = [];
  if (isFinite(min)) result.push(`${name}${strictMode ? ' > ' : ' >= '}${min}`);
  if (isFinite(max)) result.push(`${name}${strictMode ? ' < ' : ' <= '}${max}`);
  if (isFinite(equals)) result.push(`${name} = ${equals}`);
  return result;
}

function isTimeFilter(value) {
  return isPlainObject(value) && (!isNil(value.start) || !isNil(value.end));
}
function convertTimeFilter(name, {start, end}) {
  const result = [];
  if (start) result.push(`${name}>=${start}`);
  if (end) result.push(`${name}<=${end}`);
  return result;
}

function convertArrayFilter(name, value) {
  return value.length ? `${name} in ${convertArrayValue(value)}` : [];
}

export function filtersToQueryParam(filters, schema) {
  if (isString(filters)) return filters;
  return flatMap(filters, (value, name) => castArray(convertFilter(name, value, schema))).join(' and ');
}

export function sortingToQueryParam(sorting) {
  return map(sorting, (direction, name) => `${name}:${direction.toUpperCase()}`).join(';');
}

export function queryParamToSorting(stringifiedSorting) {
  return transform(split(stringifiedSorting, ';'), (result, stringifiedColumnSorting) => {
    const match = stringifiedColumnSorting.match(/^(.*?):(ASC|DESC)$/);
    if (match) {
      const [, columnName, direction] = match;
      result[columnName] = direction.toLowerCase();
    }
  }, {});
}
