import {Fragment} from 'react';
import {Popup, Input, Icon, Divider, List} from 'semantic-ui-react';
import {
  map, flatMap, get, keys, includes, transform,
  isArray, isPlainObject, isEmpty, isNumber, head, isNil, some, isUndefined,
  flatten, every,
} from 'lodash';
import isNumeric from 'is-number';
import {
  CodeEditorInput, CodeEditorControl, DurationInput, Field, ValueRenderer, ValueInput, ListInput, RadioGroupInput,
  StringListDropdownControl, formatSeconds
} from 'apstra-ui-common';

import {
  SPECIAL_RENDERED_PROPERTIES,
  SPECIAL_RENDERED_INPUT_PROPERTIES,
  PROCESSOR_STATIC_DATA_TYPES,
  SPECIAL_NUMBER_PROPERTIES,
} from './consts';
import FormattedPythonExpression from '../pythonExpression/components/FormattedPythonExpression';
import RangeValue from '../components/RangeValue';
import {integerExpressionRenderer} from '../components/IntegerExpressionInput';
import {environmentExpectationsRenderer} from './components/EnvironmentExpectationsInput';
import {AdditionalPropertiesEditor} from './components/AdditionalPropertiesEditor';
import OptionalExpressionInputWrapper from './components/OptionalExpressionInputWrapper';
import PythonExpressionSuggestions from './components/PythonExpressionSuggestions';
import ValueMapInput from './components/ValueMapInput';
import ValueMap from './components/ValueMap';
import MultiValueMap from './components/MultiValueMap';
import MultiValueMapInput from './components/MultiValueMapInput';
import RangeInput from './components/RangeInput';
import StateRangeInput from './components/StateRangeInput';
import ServiceNameInput from './components/ServiceNameInput';
import {stageRenderer} from './components/StageInput';
import IngestionFilterInput from './components/IngestionFilterInput';
import IngestionFilter from './components/IngestionFilter';
import QueryGroupByInput from './components/QueryGroupByInput';
import IBAContext from './IBAContext';
import GraphExplorerNewWindowButton from '../graphExplorer/GraphExplorerNewWindowButton';
import QueryExpansionInput from './components/QueryExpansionInput';
import QueryTagFilterInput, {QueryTagFilter} from './components/QueryTagFilterInput';
import Tags from '../components/Tags';
import TagsInput from '../components/TagsInput';
import KeysInput from './components/KeysInput';
import SavedGraphQueryButton from '../queryBuilder/SavedGraphQueryButton';
import GroupByInput from './components/GroupByInput';
import {NamedNodesView} from './components/NamedNodesView';

export const ADDITIONAL_PROPERTIES_FIELD_NAME = 'additionalProperties';

const groupByRenderer = new ValueRenderer({
  condition: ({name, schema}) => name === 'group_by' && schema?.type === 'array' && schema.items?.type === 'string',
  Value({value}) {
    if (value.length) {
      return <List bulleted horizontal items={value} />;
    } else {
      return [
        'All',
        ' ',
        <Popup
          key='popup'
          trigger={<Icon circular name='info' aria-label='Aggregate all items into one group' />}
          content='Aggregate all items into one group'
          position='right center'
          wide
        />
      ];
    }
  },
  ValueInput: GroupByInput,
});

const significantKeysRenderer = new ValueRenderer({
  condition: ({name, schema, processor}) =>
    (name === 'significant_keys' || name === 'keys' && processor?.type !== 'extensible_data_collector') &&
    schema?.type === 'array' && schema.items?.type === 'string',
  Value: ({value}) => <List bulleted horizontal items={value} />,
  ValueInput: ({name, value, schema, required, disabled, errors, onChange, fieldProps}) => {
    const castFlattenErrors = (errors) => flatten(map(errors, (error) => {
      const keysError = get(error, ['keys']) || get(error, ['significant_keys']) || error;
      return isPlainObject(keysError) ?
        map(keysError, (error, key) => `${key}: ${error}`) : keysError;
    }));
    return (
      <Field
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
        errors={castFlattenErrors(errors)}
        {...fieldProps}
      >
        <StringListDropdownControl
          allowAdditions
          noResultsMessage='Start typing to add a new key'
          placeholder='No keys specified'
          value={value}
          onChange={onChange}
        />
      </Field>
    );
  }
});

const keysRenderer = new ValueRenderer({
  condition: ({name, schema, processor}) =>
    name === 'keys' && processor?.type === 'extensible_data_collector' &&
    schema?.type === 'array' && schema.items?.type === 'string',
  Value: ({value, values}) =>
    <ValueMap
      value={transform(value, (result, key) => {
        if (!isUndefined(values[key])) result[key] = values[key];
      }, {})}
    />,
  ValueInput: KeysInput,
});

const pythonExpressionRenderer = new ValueRenderer({
  condition: ({name}) => SPECIAL_RENDERED_INPUT_PROPERTIES.PYTHON_EXPRESSION.includes(name),
  Value: ({name, value}) => {
    if (SPECIAL_RENDERED_PROPERTIES.SECONDS_OR_EXPRESSION.includes(name) && isNumeric(value)) {
      return <secondsOrExpressionRenderer.Value value={Number(value)} />;
    } else {
      return (
        <FormattedPythonExpression
          key={`python-expression-${name}`}
          value={String(value ?? '')}
        />
      );
    }
  },
  ValueInput: ({
    name, value, schema, required, disabled, errors, onChange, fieldProps, knownPythonExpressionVariables
  }) => (
    <Field
      label={schema?.title ?? name}
      description={schema?.description}
      required={required}
      disabled={disabled}
      errors={errors}
      {...fieldProps}
    >
      <CodeEditorControl
        mode='python-expression'
        enableCompletion
        completerParams={{knownVariables: knownPythonExpressionVariables}}
        name={name}
        value={String(value ?? '')}
        schema={schema}
        required={required}
        disabled={disabled}
        errors={errors}
        onChange={onChange}
      />
      {SPECIAL_RENDERED_INPUT_PROPERTIES.PYTHON_EXPRESSION_SUGGESTIONS[name] &&
        <PythonExpressionSuggestions
          propertyName={name}
          setPropertyValue={onChange}
          knownPythonExpressionVariables={knownPythonExpressionVariables}
        />
      }
    </Field>
  )
});

const graphQueryRenderer = new ValueRenderer({
  condition: ({name}) => name === 'graph_query',
  Value({value}) {
    if (isEmpty(value)) {
      return 'Empty';
    } else {
      return (
        <IBAContext.Consumer>
          {({blueprintId, blueprintDesign}) =>
            flatMap(isArray(value) ? value : [value], (graphQuery, index) => [
              !!index && <Divider key={`divider-${index}`} />,
              <Fragment key={`gq-${index}`}>
                <FormattedPythonExpression
                  value={graphQuery}
                  multiLine
                />
                <GraphExplorerNewWindowButton
                  floated
                  defaultQuery={graphQuery}
                  explorerProps={{
                    blueprints: [{id: blueprintId, design: blueprintDesign}],
                    explorerMode: 'view-query'
                  }}
                />
              </Fragment>
            ])
          }
        </IBAContext.Consumer>
      );
    }
  },
  ValueInput: ({
    name, value, schema, required, disabled, errors, onChange, fieldProps,
    namedNodesInGraphQueries, graphNodesStyles,
  }) => {
    const valueAsArray = isArray(value) ? value : [value];
    return (
      <IBAContext.Consumer>
        {({blueprintId, blueprintDesign}) =>
          <ListInput
            name={name}
            value={!required && value === '' ? [] : valueAsArray}
            schema={schema}
            required={required}
            disabled={disabled}
            errors={errors}
            onChange={(value) => onChange(required && value.length === 1 ? value[0] : value)}
            minItems={required ? 1 : 0}
            buttonText='Add Graph Query'
            fieldProps={fieldProps}
          >
            {({value, onChange, index}) =>
              <div className='processor-property-graph-query-input'>
                <CodeEditorInput
                  value={value}
                  onChange={onChange}
                  fieldProps={{width: 16}}
                  mode='graph-query'
                  enableCompletion
                  completerParams={{blueprintDesign, graphNodesStyles}}
                  actions={[
                    <GraphExplorerNewWindowButton
                      key='GraphExplorerNewWindowButton'
                      explorerProps={{
                        blueprints: [{id: blueprintId, design: blueprintDesign}],
                        explorerMode: 'edit-query'
                      }}
                      defaultQuery={value}
                      saveQuery={onChange}
                    />,
                    <SavedGraphQueryButton
                      key='SavedGraphQueryButton'
                      resourceName='Graph Query'
                      onChange={onChange}
                    />
                  ]}
                  multiLine
                />
                <NamedNodesView {...namedNodesInGraphQueries[index]} />
              </div>
            }
          </ListInput>
        }
      </IBAContext.Consumer>
    );
  }
});

const serviceNameRenderer = new ValueRenderer({
  condition: ({name, schema}) => name === 'service_name' && !schema.enum,
  Value: ({value}) =>
    <>
      <a href={`/#/analytics/telemetry-services/${value}`}>{value}</a>
      {' ('}<a href={`/#/analytics/telemetry-service-registry/${value}`}>{'schema'}</a>{')'}
    </>,
  ValueInput: ServiceNameInput,
});

const secondsOrExpressionRenderer = new ValueRenderer({
  condition: ({name}) => SPECIAL_RENDERED_PROPERTIES.SECONDS_OR_EXPRESSION.includes(name),
  Value: ({value}) => isNumber(value) ? formatSeconds(value) : <FormattedPythonExpression value={value} />,
  ValueInput: ({
    name, value, schema, required, disabled, errors, onChange, fieldProps, knownPythonExpressionVariables
  }) => {
    const {type, anyOf} = schema;
    const switchModeEnabled = (!!anyOf && some(anyOf, {type: 'integer'}) && some(anyOf, {type: 'string'})) ||
      (type === 'string');
    const props = {
      value,
      name,
      type,
      schema,
      required,
      disabled,
      errors,
      onChange: (value) => {
        if (includes(SPECIAL_NUMBER_PROPERTIES, name)) {
          onChange(value);
        } else {
          onChange(switchModeEnabled && !!value ? `${value}` : value);
        }
      },
      fieldProps,
    };
    return (
      <OptionalExpressionInputWrapper
        switchModeEnabled={switchModeEnabled}
        knownPythonExpressionVariables={knownPythonExpressionVariables}
        {...props}
      >
        {() =>
          <DurationInput
            {...props}
            customValueType='duration'
            textPrefix=''
            value={isNumeric(value) ? Number(value) : value}
          />
        }
      </OptionalExpressionInputWrapper>
    );
  }
});

// special handling for data_type dropdown for Extensible Service Data Collector:
// in case there are nested anyOf's inside the root anyOf, consider only the first one for the dropdown options
const dataTypeRenderer = new ValueRenderer({
  condition: ({name, schema}) =>
    name === 'data_type' &&
    isArray(schema?.anyOf) &&
    every(schema.anyOf, ({anyOf}) => isArray(anyOf)),
  ValueInput: ({name, value, schema, required, disabled, errors, onChange, fieldProps}) =>
    <ValueInput
      name={name}
      value={value}
      schema={{...schema, anyOf: schema.anyOf[0].anyOf}}
      required={required}
      disabled={disabled}
      errors={errors}
      onChange={onChange}
      fieldProps={fieldProps}
    />
});

const valueMapRenderer = new ValueRenderer({
  condition: ({name, schema}) => name === 'value_map' && schema?.type === 'object',
  Value: ValueMap,
  ValueInput: ValueMapInput,
});

const multiValueMapRenderer = new ValueRenderer({
  condition: ({name, schema}) => name === 'value_map' && isArray(schema?.anyOf),
  Value: MultiValueMap,
  ValueInput: MultiValueMapInput,
});

const rangeRenderer = new ValueRenderer({
  condition: ({name, value}) => name === 'range' && isPlainObject(value),
  Value: ({value}) => <RangeValue min={value.min} max={value.max} />,
  ValueInput: RangeInput,
});

const queryExpansionRenderer = new ValueRenderer({
  condition: ({name}) => name === 'query_expansion',
  Value: ({value}) => {
    return (
      map(value, (keyValue, key) => {
        return (
          <div key={key}>
            <b>{key}</b>
            {keyValue.type && ` (${keyValue.type})`}
            {': '}
            <FormattedPythonExpression value={keyValue.generator} />
          </div>
        );
      })
    );
  },
  ValueInput: QueryExpansionInput
});

const stateRangeRenderer = new ValueRenderer({
  condition: ({name, value}) => name === 'state_range' && isPlainObject(value),
  Value({value}) {
    const stateKey = head(keys(value));
    const range = head(value[stateKey]);
    const formatValue = (value) => isNil(value) ? null :
      isNumber(value) ? formatSeconds(value) : <FormattedPythonExpression value={value} />;

    return (
      <div>
        {'State '}<b>{stateKey}</b>{': '}
        <RangeValue min={formatValue(range.min)} max={formatValue(range.max)} />
      </div>
    );
  },
  ValueInput: StateRangeInput
});

const ingestionFilterRenderer = new ValueRenderer({
  condition: ({name}) => name === 'ingestion_filter',
  Value: ({value, values}) =>
    PROCESSOR_STATIC_DATA_TYPES.has(values.data_type) ? 'N/A' : <IngestionFilter value={value} />,
  ValueInput: ({
    name, value, values, schema, required, disabled, errors, onChange,
    telemetryServiceRegistryItems, knownPythonExpressionVariables, fieldProps
  }) =>
      PROCESSOR_STATIC_DATA_TYPES.has(values.data_type) ?
        null :
        <IngestionFilterInput
          name={name}
          value={value}
          values={values}
          schema={schema}
          required={required}
          disabled={disabled}
          errors={errors}
          onChange={onChange}
          telemetryServiceRegistryItems={telemetryServiceRegistryItems}
          knownPythonExpressionVariables={knownPythonExpressionVariables}
          fieldProps={fieldProps}
        />
});

const queryTagFilterRenderer = new ValueRenderer({
  condition: ({name}) => name === 'query_tag_filter',
  Value: QueryTagFilter,
  ValueInput: QueryTagFilterInput,
});

const anomalyInterfaceTagsRenderer = new ValueRenderer({
  condition: ({name}) => [
    'alarm_anomaly_interface_tags',
    'no_anomaly_interface_tags',
    'warn_anomaly_interface_tags',
  ].includes(name),
  Value: Tags,
  ValueInput: ({name, value, schema, required, disabled, errors, onChange, fieldProps, blueprintTags}) =>
    <TagsInput
      name={name}
      value={value}
      schema={schema}
      required={required}
      disabled={disabled}
      errors={errors}
      onChange={onChange}
      fieldProps={fieldProps}
      knownTags={map(blueprintTags, ({label}) => label)}
    />
});

const queryGroupByRenderer = new ValueRenderer({
  condition: ({name}) => name === 'query_group_by',
  ValueInput: QueryGroupByInput
});

const executionCountRenderer = new ValueRenderer({
  condition: ({name}) => name === 'execution_count',
  Value: ({value}) => value === '-1' ? 'Run continuously' : value,
  ValueInput: ({name, value, schema, required, disabled, errors, onChange, fieldProps}) => (
    <Field
      label={schema?.title ?? name}
      description={schema?.description}
      required={required}
      disabled={disabled}
      errors={errors}
      {...fieldProps}
    >
      <RadioGroupInput
        name={name}
        onChange={(v) => onChange(v ? '-1' : '0')}
        schema={{
          type: 'boolean',
          oneOf: [
            {const: true, title: 'Run continuously'},
            {const: false, title: 'Specify execution count'},
          ],
        }}
        value={value === '-1'}
      />
      {value !== '-1' &&
        <Input
          min={0}
          onChange={(e) => onChange(e.target.value.toString())}
          type='number'
          value={+value}
        />
      }
    </Field>
  )
});

const extraPythonExpressionKeysRenderer = new ValueRenderer({
  condition: ({name, schema}) => (
    name === ADDITIONAL_PROPERTIES_FIELD_NAME &&
    get(schema, ['additionalProperties', 'type']) === 'string'
  ),
  Value: ValueMap,
  ValueInput: ({
    name, value, schema, required, disabled, errors, onChange, fieldProps, knownPythonExpressionVariables,
    processorDefinition, serviceRegistryProperties, onError,
  }) => (
    <AdditionalPropertiesEditor
      name={name}
      value={value}
      schema={schema}
      required={required}
      disabled={disabled}
      errors={errors}
      onChange={onChange}
      buttonText='Add Key'
      noItemsMessage='No extra keys for graph query defined.'
      fieldProps={fieldProps}
      processorDefinition={processorDefinition}
      serviceRegistryProperties={serviceRegistryProperties}
      knownPythonExpressionVariables={knownPythonExpressionVariables}
      onError={onError}
    />
  )
});

export const processorPropertyRenderers = [
  pythonExpressionRenderer,
  secondsOrExpressionRenderer,
  rangeRenderer,
  stateRangeRenderer,
  groupByRenderer,
  significantKeysRenderer,
  keysRenderer,
  valueMapRenderer,
  multiValueMapRenderer,
  dataTypeRenderer,
  serviceNameRenderer,
  extraPythonExpressionKeysRenderer,
  stageRenderer,
  integerExpressionRenderer,
  environmentExpectationsRenderer,
  ingestionFilterRenderer,
  queryTagFilterRenderer,
  anomalyInterfaceTagsRenderer,
  queryGroupByRenderer,
  graphQueryRenderer,
  queryExpansionRenderer,
  executionCountRenderer,
];
