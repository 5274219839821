import {Component, Fragment} from 'react';
import {observable, action, computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {find, last, min, max} from 'lodash';
import moment from 'moment';
import {StateToggle, TimelineGraphContainer, Value,
  formatDateAsLocalDateTimeMS, formatInterval} from 'apstra-ui-common';

import {rangeProcessorUtils, timeInStateProcessorUtils} from '../../processorUtils';
import DiscreteStateTimeline from '../../../components/graphs/DiscreteStateTimeline';
import LineChart from '../../../components/graphs/LineChart';
import AnomalyHistoryGraphLegend from './AnomalyHistoryGraphLegend';

@observer
export default class DiscreteStateTimelineWithSamples extends Component {
  views = {
    sustainedAnomalyHistory: 'Sustained Anomaly History',
    sampleHistory: 'Sample History'
  };

  @observable view = this.views.sustainedAnomalyHistory;

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed
  get isSustainedAnomalyHistory() {
    return this.view === this.views.sustainedAnomalyHistory;
  }

  @action
  toggleView = (value, event) => {
    event.stopPropagation();
    this.view = value;
  };

  renderLineChart = ({childProps}) => {
    const {maxValue: originalMaxValue, minValue: originalMinValue, units, item, relatedProcessors, colors} = this.props;
    const [, rangeProcessor, timeInStateProcessor] = relatedProcessors;

    const period = timeInStateProcessorUtils.getTimeWindow(timeInStateProcessor, item);
    const {min: rangeMin, max: rangeMax} = rangeProcessorUtils.getMinMax(rangeProcessor, item);
    const minValue = min([originalMinValue, rangeMin, rangeMax]);
    const maxValue = max([originalMaxValue, rangeMin, rangeMax]);

    const lineChartProps = {minValue, maxValue, rangeMin, rangeMax, units};
    const timestamp = moment(childProps.timelineEndTime).add(-period, 'seconds').toDate();
    const interval = childProps.timelineEndTime - timestamp;

    return (
      <LineChart
        {...childProps}
        {...lineChartProps}
        colors={colors}
        timeIndicators={[
          {
            timestamp,
            popup: {
              header: 'Last Period',
              content: [
                formatDateAsLocalDateTimeMS(timestamp),
                formatInterval(interval),
              ]
            }
          }
        ]}
      />
    );
  };

  render() {
    const {toggleView, views, view, isSustainedAnomalyHistory, renderLineChart} = this;
    const {axes, correspondingSourceStageItems, expanded, index, item, timeSeriesAggregation,
      possibleValues, processorDefinitions, relatedProcessors, colors} = this.props;

    const [nsSourceItems, rangeSourceItems] = correspondingSourceStageItems;
    const [nsProcessor, rangeProcessor, timeInStateProcessor] = relatedProcessors;

    const processorDefinition = find(processorDefinitions, {name: nsProcessor.type});
    const counterTypeSchema = processorDefinition.schema.properties.counter_type;
    const counterTypeName = nsProcessor.properties.counter_type;

    let sustainedAnomalyValue = last(item.persisted_samples)?.value;
    if (!sustainedAnomalyValue && item?.value) sustainedAnomalyValue = item.value;

    const instantaneousAnomalyValue = rangeSourceItems[index].value;

    const nsSourceItem = nsSourceItems ? nsSourceItems[index] : null;
    const rangeSourceItem = rangeSourceItems ? rangeSourceItems[index] : null;
    const numericValue = nsSourceItem ? nsSourceItem.value : null;

    let timeLineSamples = isSustainedAnomalyHistory ? item.persisted_samples : nsSourceItems[index].persisted_samples;
    if (!timeLineSamples && item?.value) {
      timeLineSamples = [{
        timestamp: item.timestamp,
        value: item.value,
      }];
    }

    return (
      <TimelineGraphContainer
        axes={axes}
        samples={timeLineSamples}
        expanded={expanded}
        timeSeriesAggregation={timeSeriesAggregation}
        useCurrentTimeAsTimelineEnd
        popupIntervalPrefix='Time in State: '
      >
        {(childProps) => (
          <Fragment>
            <StateToggle
              style={{marginBottom: 15}}
              value={view}
              leftOption={{value: views.sustainedAnomalyHistory, text: views.sustainedAnomalyHistory}}
              rightOption={{value: views.sampleHistory, text: views.sampleHistory}}
              onChange={toggleView}
            />

            {isSustainedAnomalyHistory &&
              <DiscreteStateTimeline {...childProps} possibleValues={possibleValues} colors={colors} />
            }
            {!isSustainedAnomalyHistory && renderLineChart({childProps})}

            <AnomalyHistoryGraphLegend
              counterType={counterTypeName ? <Value value={counterTypeName} schema={counterTypeSchema} /> : null}
              lastSampleTime={last(timeLineSamples).timestamp}
              numericValue={numericValue}
              sustainedAnomalyValue={sustainedAnomalyValue}
              instantaneousAnomalyValue={instantaneousAnomalyValue}
              possibleValues={possibleValues}
              relatedProcessors={{rangeProcessor, timeInStateProcessor}}
              relatedItems={{rangeSourceItem, timeInStateSourceItem: item}}
              colors={colors}
            />
          </Fragment>
        )}
      </TimelineGraphContainer>
    );
  }
}
