import {useMemo} from 'react';
import {isString, isNil, isFinite, isPlainObject} from 'lodash';
import {Field, ValueRenderer} from 'apstra-ui-common';
import {Dropdown, Input} from 'semantic-ui-react';

import RangeControl, {RANGE_TYPE} from '../components/RangeControl';
import RangeValue from '../components/RangeValue';

import './filterRenderers.less';

export const regexFilterRenderer = new ValueRenderer({
  condition: ({match}) => match === 'regex',
  getQueryPartData: ({value}) => {
    return {value: `/${value}/`, matcher: '~'};
  },
});

const regexInputOptions = [
  {key: 'text', text: '=', value: 'text', description: 'exact match'},
  {key: 'regex', text: '~', value: 'regex', description: 'regex match'},
];

const normalizeExactOrRegexFilterValue = (value) =>
  isString(value) ? {value, type: 'text'} :
  isPlainObject(value) ? value : {value: '', type: 'text'};

export const exactOrRegexFilterRenderer = new ValueRenderer({
  condition: ({match}) => match === 'exactOrRegex',
  Value: ({value: originalValue}) => {
    const {value, type} = normalizeExactOrRegexFilterValue(originalValue);
    return type === 'regex' ? `/${value}/` : value;
  },
  ValueInput: ({value: sourceValue, name, schema, required, disabled, errors, onChange, placeholder, fieldProps}) => {
    const {value, type} = normalizeExactOrRegexFilterValue(sourceValue);
    return (
      <Field
        className='string-filter'
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
        errors={errors}
        {...fieldProps}
      >
        <Input
          type='text'
          label={
            <Dropdown
              basic
              options={regexInputOptions}
              value={type ?? 'text'}
              onChange={(_, data) => onChange({value, type: data.value})}
            />
          }
          value={value ?? ''}
          placeholder={placeholder}
          onChange={(e) => onChange({type, value: e.target.value})}
        />
      </Field>
    );
  },
  getQueryPartData: ({value: sourceValue}) => {
    const {value, type} = normalizeExactOrRegexFilterValue(sourceValue);
    return type === 'regex' ? {value: `/${value}/`, matcher: '~'} : {value: `"${value}"`, matcher: '='};
  },
});

const normalizeRangeFilterValue = (value) =>
  isFinite(value) ? {equals: value} :
  isPlainObject(value) ? value : {};

export const rangeFilterRenderer = new ValueRenderer({
  condition: ({match}) => match === 'range',
  Value: ({value, strictMode}) => <RangeValue strictMode={strictMode} {...normalizeRangeFilterValue(value)} />,
  ValueInput: ({
    value: sourceValue, name, schema, required, disabled, errors, fieldProps, onChange, knownPythonExpressionVariables
  }) => {
    const value = useMemo(() => normalizeRangeFilterValue(sourceValue), [sourceValue]);
    return (
      <Field
        className='number-filter'
        label={schema?.title ?? name}
        description={schema?.description}
      >
        <div className='fields'>
          <RangeControl
            value={value}
            errors={errors}
            onChange={onChange}
            placeholder='Select type'
            rangeTypes={[RANGE_TYPE.strictMode, RANGE_TYPE.equals]}
            knownPythonExpressionVariables={knownPythonExpressionVariables}
            strictMode
            fieldProps={{
              width: 8,
              required,
              disabled,
              ...fieldProps,
            }}
            minInputFieldWidth={4}
            maxInputFieldWidth={8}
          />
        </div>
      </Field>
    );
  },
  getQueryPartData: ({value, strictMode}) => {
    const {min, equals, max} = normalizeRangeFilterValue(value);
    const hasMin = !isNil(min);
    const hasEquals = !isNil(equals);
    const hasMax = !isNil(max);

    if (hasMin && hasMax) {
      return {value: `[${min}…${max}]`, matcher: 'in range'};
    } else if (hasMin && !hasMax) {
      return {value: min, matcher: strictMode ? '>' : '≥'};
    } else if (!hasMin && hasMax) {
      return {value: max, matcher: strictMode ? '<' : '≤'};
    } else if (hasEquals) {
      return {value: equals, matcher: '='};
    } else {
      return null;
    }
  }
});
