import {indexOf, map, size} from 'lodash';
import {observer} from 'mobx-react';
import cx from 'classnames';
import {useMemo} from 'react';

import {getLinkPath} from '../../cablingMapEditor/utils';
import {useRackEditorStore} from '../hooks/useRackEditorStore';
import {useLinkTooltip, useTooltip} from '../../components/graphs/GraphTooltips';
import {draftLinksGroupId, railGap} from '../const';

import './LinksGroup.less';

const LinksGroup = ({linksGroup, onClick, readonly}) => {
  const {paths} = useLinksGroup(linksGroup, onClick, readonly);
  return paths;
};

export default observer(LinksGroup);

const useLinksGroup = (linksGroup, onClick, readonly) => {
  const {
    id, fromName, toName, isAttachedToFirst, isAttachedToSecond, count, isPeer, isDualAttached,
    fromNode, toNode, isInRail: linkIsInRail, railIndex
  } = linksGroup;

  const {rackStore} = useRackEditorStore();
  const {nodesByName, linksGroups, openedLinksGroupId, usedRailIndices, railsZone, supportsRails} = rackStore;
  const isInRail = supportsRails && linkIsInRail;

  const {sharedTooltip} = useTooltip();
  const linkTooltipHandler = useLinkTooltip();

  const onClickHandler = useMemo(
    () => onClick ? () => onClick(linksGroup) : undefined,
    [linksGroup, onClick]
  );

  const tooltipData = useMemo(
    () => {
      const endpoints = [
        {
          system: {label: fromNode?._label},
          interface: {}
        },
        {
          system: {label: toNode?._label},
          interface: {}
        }
      ];
      return {
        ...linksGroup,
        speed: linksGroup.speedString,
        endpoints,
        member_link_ids: new Array(linksGroup.count)
      };
    },
    [linksGroup, fromNode?._label, toNode?._label]
  );

  const eventsHandlers = useMemo(
    () => {
      return readonly ?
        {
          onMouseOver: linkTooltipHandler(tooltipData),
          onMouseOut: sharedTooltip.hide,
        } : {
          onClick: onClickHandler
        };
    },
    [readonly, linkTooltipHandler, onClickHandler, sharedTooltip.hide, tooltipData]
  );

  if (!count) return {paths: null};

  const selectedLinksGroup = linksGroups[openedLinksGroupId];

  const classNames = cx(
    're-links-group',
    `size-${count > 5 ? 5 : count}`,
    {
      selected: id === openedLinksGroupId || selectedLinksGroup?.isSamePeerWith(linksGroup),
      draft: id === draftLinksGroupId,
      interactive: !!onClick,
      rail: isInRail,
      [`rail${railIndex}`]: isInRail
    }
  );

  const fromNameNodes = nodesByName[fromName];
  const fromNodes = isPeer ? [fromNameNodes?.[0]] : fromNameNodes;
  const toNodes = isPeer ? [fromNameNodes?.[1]] : nodesByName[toName];

  const isBetweenPairs = size(fromNodes) === 2 && size(toNodes) === 2;

  const paths = (
    <g {...eventsHandlers}>
      {
        map(fromNodes, (fromNode) => {
          return map(toNodes, (toNode, index) => {
            if (!toNode) return null;

            // Link is shown if target is not paired
            const isShown = !toNode.isPaired ||
              (isBetweenPairs ?
                // ... when both source and destination are pairs, link must either be
                // double-attached or have the same order in their pairs (direct links)
                (isDualAttached || fromNode.isFirstInPair === toNode.isFirstInPair) :
                // For single attached - only the node of the corresponding order
                (toNode.isFirstInPair ? isAttachedToFirst : isAttachedToSecond)
              );
            if (!isShown) return null;
            const startPoint = linksGroup.portsPositions[fromNode.id];
            const endPoint = linksGroup.portsPositions[toNode.id];
            if (isInRail) {
              endPoint.railOffset = indexOf(usedRailIndices, railIndex) * railGap;
            }
            const path = getLinkPath(startPoint, endPoint, undefined, isInRail ? railsZone : undefined);

            return [
              <path
                key={`${index}_outline`}
                className={`${classNames} outline`}
                d={path}
              />,
              <path
                key={index}
                className={classNames}
                d={path}
              />
            ];
          });
        })
      }
    </g>
  );

  return {paths};
};
