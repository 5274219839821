import {
  transform, sortBy, filter, map, isUndefined, includes, compact, keys, isString, get, last, cloneDeep, first,
} from 'lodash';
import moment from 'moment';
import {LINE_MODE, brandColorNames} from 'apstra-ui-common';

import MatcherFilterStringToObjectConverter from './matcherFilterString/MatcherFilterStringToObjectConverter';

export const mergeValues = (expectedValues, actualValues) => {
  const actualCopy = {...actualValues};
  const result = transform(expectedValues, (acc, expectedValue, expectedKey) => {
    const actualValue = actualCopy[expectedKey];
    acc.push({
      name: expectedKey,
      expected: expectedValue,
      actual: actualValue || null,
    });
    if (!isUndefined(actualValue)) {
      delete actualCopy[expectedKey];
    }
  }, []);
  if (Object.keys(actualCopy).length > 0) {
    map(actualCopy, (actualValue, actualKey) => {
      result.push({
        name: actualKey,
        expected: null,
        actual: actualValue,
      });
    });
  }
  return result;
};

// use excludedTypes here because `allSamples` doesn't always contains all possible
// anomaly types which affects the color schema (colors can change after auto refetch
// if a new anomaly type appears for the very first time)
export const getMLCValues = (allSamples, valueKey, excludedTypes = [], allAnomalyTypes, range) => {
  let minValue = 0;
  let maxValue = 0;
  const colors = compact(
    map(
      allAnomalyTypes,
      (type, id) => !includes(excludedTypes, type) && includes(keys(allSamples), type) && brandColorNames[id]
    )
  );
  return {
    data: sortBy(
      transform(allSamples, (acc, anomalySamples, anomalyType) => {
        // TODO (vkniazeva): temp keep this "if" due to API for current state doesn't support filters
        if (!includes(excludedTypes, anomalyType)) {
          acc.push({
            items: sortBy(
              anomalySamples,
              (sample) => {
                if (sample[valueKey] < minValue) {
                  minValue = sample[valueKey];
                }
                if (sample[valueKey] > maxValue) {
                  maxValue = sample[valueKey];
                }
                return moment(sample.timestamp);
              }),
            data: {anomalyType: anomalyType},
            lineMode: LINE_MODE.LINES,
          });

          const artificialStart = cloneDeep(first(last(acc).items));
          const artificialEnd = cloneDeep(last(last(acc).items));
          artificialStart.timestamp = range.start;
          artificialStart[valueKey] = null;
          artificialEnd.timestamp = range.end;
          last(acc).items.unshift(artificialStart);
          last(acc).items.push(artificialEnd);
        }
      }, []),
      ({data: {anomalyType}}) => anomalyType
    ),
    colors,
    minValue,
    maxValue,
  };
};

// NOTE: this filter fn is able to process only basic filtering (string or string/regexp)
// as it is made as a temporary solution for the lack of filters in API request.
// As soon as the BE adds filters, this should be deleted
export const filterCurrentState = (filters, items) => {
  if (items.length === 0) {
    return [];
  }
  if (!filters) {
    return items;
  }

  return filter(items, (item) => {
    let searchFilters = filters;
    if (isString(filters)) {
      searchFilters = MatcherFilterStringToObjectConverter.parseAndConvert(filters) || {};
    }
    const results = map(searchFilters, (filterData, path) => {
      if (isString(filterData)) {
        return item[path] === filterData;
      } else if (filterData.type === 'text') {
        return get(item, path) === filterData.value;
      } else if (filterData.type === 'regex') {
        try {
          const regExp = new RegExp(filterData.value);
          return regExp.test(get(item, path));
        } catch (error) {
          return false;
        }
      } else {
        return false;
      }
    });
    return compact(results).length === keys(searchFilters).length;
  });
};
