import {Markdown} from 'apstra-ui-common';
import {FC} from 'react';
import {Icon, Popup} from 'semantic-ui-react';

type DescriptionTooltipProps = {
  className?: string;
  description?: string;
};

export const DescriptionTooltip: FC<DescriptionTooltipProps> = ({
  className,
  description
}) => {
  if (!description) return null;
  return (
    <Popup
      content={
        <Markdown>
          {description}
        </Markdown>
      }
      offset={[-11, 0]}
      trigger={<Icon className={className} name='question circle' />}
    />
  );
};
