import {toJS} from 'mobx';
import {find} from 'lodash';

import {TRAFFIC_PROBE_STAGE} from '../graphs/trafficUtils';
import TrafficDiagramContainer from '../graphs/TrafficDiagramContainer';

const DeviceTraffic = ({filters, stage, stageItems, patternData, probe}) => {
  const averageInterfaceCountersStage = find(
    probe?.stages,
    {name: TRAFFIC_PROBE_STAGE.averageInterfaceCounters}
  );
  return (
    <TrafficDiagramContainer
      stage={stage}
      stageItems={stageItems}
      patternData={patternData}
      sourceNodeId={filters?.filter?.sourceId}
      destinationNodeId={filters?.filter?.targetId}
      systemValuesSchema={toJS(stage?.values)}
      interfaceValuesSchema={toJS(averageInterfaceCountersStage?.values)}
    />
  );
};

export default DeviceTraffic;
