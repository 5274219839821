import ace from 'ace-builds';
import {map, compact, escape, startsWith} from 'lodash';

import matchTokenPattern from './matchTokenPattern';
import {PYTHON_EXPRESSION_KEYWORDS} from './consts';

const BUILTIN_FUNCTIONS = [
  {
    name: 'int',
    args: 'arg[, base=0]',
    description: 'Converts arg to integer.'
  },
  {
    name: 'str',
    args: 'arg',
    description: 'Converts arg to string.'
  },
  {
    name: 'len',
    args: 'arg',
    description: 'Returns length of the argument arg.'
  },
  {
    name: 'flag',
    args: 'arg',
    description: 'Returns 0 if arg is None or 1 if not.'
  },
  {
    name: 're_match',
    args: 'pattern, str',
    description: 'Returns string result of pattern regular expression matching the beginning of str, ' +
        'or empty string if no match.'
  },
  {
    name: 're_search',
    args: 'pattern, str',
    description: 'Search through str and return the string result at the first location of pattern match, ' +
        'or empty string if no match could be found.'
  },
];

const BUILTIN_STRING_METHODS = [
  {
    name: 'startswith',
    args: 'prefix',
    description: 'Returns True if string starts with the specified prefix. prefix argument can be a list' +
        ' of prefixes to look for.'
  },
  {
    name: 'endswith',
    args: 'suffix',
    description: 'Returns True if string ends with the specified suffix. suffix argument can be a list of' +
        ' suffixes to look for.'
  },
  {
    name: 'find',
    args: 'arg[, start[, end]]',
    description: 'Returns the lowest index in the str where substring arg can be found, within the optional' +
        ' range [start : end]. Returns -1 if arg could not be found.'
  },
  {
    name: 'rfind',
    args: 'arg[, start[, end]]',
    description: 'Returns the highest index in the str where substring arg can be found, within the optional' +
        ' range [start, end]. Returns -1 if arg could not be found.'
  },
  {
    name: 'count',
    args: 'arg[, start[, end]]',
    description: 'Returns number of occurrences of arg in the optional range [start, end]'
  },
  {
    name: 'lower',
    description: 'Returns a copy of the string in lower case.'
  },
  {
    name: 'upper',
    description: 'Returns a copy of the string in upper case.'
  },
  {
    name: 'replace',
    args: 'old, new[, count]',
    description: 'Returns a copy of the string with all or count occurrences of substring old replaced by new.'
  },
  {
    name: 'split',
    args: '[sep][, maxsplit]',
    description: 'If sep is given, then consecutive delimiters will not be groupd together. If maxsplit is given,' +
        ' than at most maxsplit splits are done.'
  },
  {
    name: 'strip',
    args: '[chars]',
    description: 'Returns a copy of the str with the leading and trailing chars or whitespace removed.'
  },
];

function buildFunctionDocHTML(name, args, description) {
  return compact([
    '<b>', escape(name), '(</b>', args ? '<i>' + args + '</i>' : null, '<b>)</b>',
    description ? '<p>' + escape(description) + '</p>' : null
  ]).join('');
}

ace.define(
  'ace/mode/custom-telemetry-collection-query',
  ['require', 'exports', 'module'],
  (require, exports) => {
    const {
      Mode: PythonExpressionMode,
      Completer: PythonExpressionCompleter,
    } = require('ace/mode/python-expression');
    const {TokenIterator} = require('ace/token_iterator');

    const KEYWORDS_COMPLETIONS = map(PYTHON_EXPRESSION_KEYWORDS, (name, index) => ({
      caption: name,
      snippet: name,
      meta: 'keyword',
      score: 100 - index,
    }));

    const BUILTIN_FUNCTIONS_COMPLETIONS = map(
      BUILTIN_FUNCTIONS,
      ({name, args, description, meta}, index) => ({
        caption: name,
        snippet: name + '($0)',
        meta: meta ?? 'function',
        className: 'completion-function ace_',
        docHTML: buildFunctionDocHTML(name, args, description),
        score: 100 - index,
      })
    );

    const BUILTIN_STRING_METHODS_COMPLETIONS = map(
      BUILTIN_STRING_METHODS,
      ({name, args, description, meta}, index) => ({
        caption: name,
        snippet: name + '($0)',
        meta: meta ?? 'method',
        className: 'completion-function ace_',
        docHTML: buildFunctionDocHTML(name, args, description),
        score: 100 - index,
      })
    );

    class CustomTelemetryCollectionQueryCompleter extends PythonExpressionCompleter {
      getMethodCompletions(session, pos, methodStarted) {
        if (
          matchTokenPattern({
            iterator: new TokenIterator(session, pos.row, pos.column),
            pattern: [
              ({type}) => type === 'string' || type === 'identifier',
            ].concat(methodStarted ? [{type: 'punctuation', value: '.'}] : [])
          })
        ) return BUILTIN_STRING_METHODS_COMPLETIONS;
      }

      checkForCustomCharacterCompletions(editor) {
        const pos = editor.getCursorPosition();
        const line = editor.getSession().getLine(pos.row).substr(0, pos.column);
        if (/(['"\w]\.\s*)$/.test(line)) {
          this.showCompletionsPopup(editor);
        }
      }

      async getCustomCompletions(state, session, pos, prefix) {
        const token = session.getTokenAt(pos.row, pos.column);
        const result = [];

        if (token.type === 'string' || token.type === 'constant.language.escape') return [];

        if (token.type === 'punctuation' && token.value === '.') {
          return this.getMethodCompletions(session, pos) ?? [];
        }

        if (token.type === 'identifier') {
          if (BUILTIN_STRING_METHODS.some(({name}) => startsWith(name, token.value))) {
            const result = this.getMethodCompletions(session, pos, true);
            if (result) return result;
          }

          result.push(
            ...KEYWORDS_COMPLETIONS,
            ...BUILTIN_FUNCTIONS_COMPLETIONS,
            ...this.getKnownVariablesCompletions(session.completerParams?.knownVariables),
            ...await this.getTextCompletions(state, session, pos, prefix)
          );
        }
        return result;
      }
    }

    class CustomTelemetryCollectionQueryMode extends PythonExpressionMode {
      $id = 'ace/mode/custom-telemetry-collection-query';
      completer = new CustomTelemetryCollectionQueryCompleter();
    }

    exports.Mode = CustomTelemetryCollectionQueryMode;
  }
);

ace.require(['ace/mode/custom-telemetry-collection-query']);
