import {useContext} from 'react';
import {compact, get, head, join, map, max, min, transform} from 'lodash';

import {filtersToQueryParam} from '../../../queryParamUtils';
import generateProbeURI from '../../generateProbeURI';
import PrimitiveValue from './PrimitiveValue';
import {rangeProcessorUtils} from '../../processorUtils';
import Gauge from '../graphs/Gauge';
import IBAContext from '../../IBAContext';
import {getCorrespondingStageItemsForStages} from './cache';

const StageGauge = ({item, groupBy, patternDescription: {relatedStages}, defaultColors, stageDataProps, ...params}) => {
  const {blueprintId} = useContext(IBAContext);

  const {probe, processor, navigate, filters} = stageDataProps;
  const referenceState = get(processor, ['properties', 'reference_state'], 'true');
  const inputColumn = head(map(processor?.inputs, ({column}) => column));

  const sourceStage = relatedStages[0];

  const redirectToSourceStage = (e, operator) => {
    e.stopPropagation(); // to prevent opening spotlight view
    const properties = transform(groupBy, (acc, key) => {acc[key] = item.properties[key];}, {});
    const filter = join(
      compact([`${inputColumn}${operator}"${referenceState}"`, filtersToQueryParam(properties)]),
      ' and '
    );
    const queryParams = {filters: {filter}};
    navigate(generateProbeURI(
      {blueprintId, probeId: probe.id, stageName: sourceStage.name, queryParams}
    ));
  };

  const {rangeMin, rangeMax, minValue: originalMinValue = 0, maxValue: originalMaxValue, value} = params;
  const minValue = min([originalMinValue, rangeMin, rangeMax]);
  const maxValue = max([originalMaxValue, rangeMin, rangeMax, value]);

  return (
    <Gauge
      colors={defaultColors}
      onBaseArcClick={(e) => redirectToSourceStage(e, '!=')}
      onSpecialRangeArcClick={(e) => redirectToSourceStage(e, '=')}
      withValueArrow
      {...params}
      minValue={minValue}
      maxValue={maxValue}
      mode={filters.spotlightMode ? 'expanded' : 'compact'}
    />
  );
};

// N/NS (N out of M)
export const GaugeCount = (props) => {
  const {item, name, value, patternDescription: {relatedProcessors}, stageDataProps: {stage}} = props;
  if (!isFinite(value)) return <PrimitiveValue value={value} />;

  const {[name]: units} = stage.units;
  const [, matchProcessor] = relatedProcessors;
  const groupBy = matchProcessor.properties.group_by;
  const maxValue = item.total_count;

  return <StageGauge {...{...props, item, groupBy, value, maxValue, units}} />;
};

// N/NS (N out of M) + Range
export const GaugeCountWithRange = (props) => {
  const {item, name, patternDescription: {relatedStages, relatedProcessors}, stageDataProps: {processor}} = props;
  const [, matchProcessor] = relatedProcessors;
  const groupBy = matchProcessor.properties.group_by;
  const [, matchCountStage] = relatedStages;
  const [, matchCountStageItem] = getCorrespondingStageItemsForStages({item, stages: relatedStages});
  const {[name]: units} = matchCountStage.units;

  if (!isFinite(matchCountStageItem.value)) {
    return <PrimitiveValue value={matchCountStageItem.value} />;
  }
  const maxValue = matchCountStageItem.total_count;
  const {min: rangeMin, max: rangeMax} = rangeProcessorUtils.getMinMax(processor, item);

  return (
    <StageGauge
      {...{...props, item, groupBy, value: matchCountStageItem.value, maxValue, rangeMin, rangeMax, units}}
    />
  );
};

// N/NS (N out of M percent)
export const GaugePercent = (props) => {
  const {item, name, value, patternDescription: {relatedProcessors}, stageDataProps: {stage}} = props;
  if (!isFinite(value)) return <PrimitiveValue value={value} />;

  const {[name]: units} = stage.units;
  const [, matchProcessor] = relatedProcessors;
  const groupBy = matchProcessor.properties.group_by;

  return <StageGauge {...{...props, item, groupBy, value, maxValue: 100, units}} />;
};

// N/NS (N out of M percent) + Range
export const GaugePercentWithRange = (props) => {
  const {item, name, patternDescription: {relatedProcessors, relatedStages}, stageDataProps: {processor}} = props;
  const [, matchProcessor] = relatedProcessors;
  const groupBy = matchProcessor.properties.group_by;
  const [, matchPercentStage] = relatedStages;
  const [, matchPercentStageItem] = getCorrespondingStageItemsForStages({item, stages: relatedStages});
  const {[name]: units} = matchPercentStage.units;

  if (!isFinite(matchPercentStageItem.value)) {
    return <PrimitiveValue value={matchPercentStageItem.value} />;
  }

  const {min: rangeMin, max: rangeMax} = rangeProcessorUtils.getMinMax(processor, item);

  return (
    <StageGauge
      {...{...props, item, groupBy, value: matchPercentStageItem.value, maxValue: 100, rangeMin, rangeMax, units}}
    />
  );
};
