import {Component} from 'react';
import PropTypes from 'prop-types';
import {Form, Grid, Message} from 'semantic-ui-react';
import {action, computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {
  map, find, filter, some, get, pullAllWith, isMatch, isString, mapValues, keyBy,
  isUndefined, castArray,
} from 'lodash';
import {DropdownInput, FetchDataError, FormFragment, ValueRenderer} from 'apstra-ui-common';

import MultiParagraphText from '../../components/MultiParagraphText';
import {castObjectValuesToArray} from '../../processErrors';
import {MarkdownDescription} from './MarkdownDescription';

@observer
export default class PredefinedEntityForm extends Component {
  static propTypes = {
    entityName: PropTypes.string,
    label: PropTypes.string.isRequired,
    actionInProgress: PropTypes.bool,
    parameters: PropTypes.object,
    entity: PropTypes.object,
    entitiesByName: PropTypes.object,
    errors: PropTypes.array,
    renderers: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.instanceOf(ValueRenderer)])),
    setEntityName: PropTypes.func.isRequired,
    setParameter: PropTypes.func.isRequired,
    columnWidths: PropTypes.arrayOf(PropTypes.number),
  };

  static defaultProps = {
    columnWidths: [8, 8]
  };

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  @computed get genericErrors() {
    return filter(this.props.errors, {type: 'generic'});
  }

  @computed get propertyErrorsByKey() {
    return mapValues(
      keyBy(filter(this.props.errors, {type: 'property'}), 'propertyName'),
      (error) => isUndefined(error.message) ?
        castObjectValuesToArray(error.propertyErrors) :
        castArray(error.message)
    );
  }

  @computed get httpError() {
    return find(this.props.errors, {type: 'http'});
  }

  @computed get formSchema() {
    const {entity} = this.props;
    return entity ? map(get(entity, ['schema', 'properties'], {}), (schema, name) => ({
      schema, name,
      required:
        name === 'label' ||
        some(get(entity, ['schema', 'required'], []), (propertyName) => propertyName === name)
    })) : [];
  }

  @action
  setEntityName = (entityName) => {
    this.props.setEntityName(entityName);
    this.props.errors.length = 0;
  };

  @action
  setParameter = (name, value) => {
    this.props.setParameter(name, value);
    pullAllWith(this.props.errors, [{type: 'property', propertyName: name}, {type: 'generic'}], isMatch);
  };

  render() {
    const {
      mode, label, entityName, parameters, entity, entitiesByName, actionInProgress, renderers,
      columnWidths: [leftColumnWidth, rightColumnWidth]
    } = this.props;
    const {formSchema, genericErrors, httpError, propertyErrorsByKey} = this;
    return (
      <Grid divided>
        <Grid.Column width={leftColumnWidth}>
          <Form>
            <DropdownInput
              search
              schema={{title: label}}
              errors={propertyErrorsByKey.id}
              disabled={mode !== 'create' || actionInProgress}
              required
              value={entityName}
              options={map(entitiesByName, (entity, entityName) => ({
                key: entityName,
                value: entityName,
                text: get(entity, ['schema', 'properties', 'label', 'default'], entityName),
              }))}
              onChange={this.setEntityName}
            />
            <FormFragment
              schema={formSchema}
              values={parameters}
              errors={propertyErrorsByKey}
              renderers={renderers}
              disabled={actionInProgress}
              onChange={(name, value) => this.setParameter(name, value)}
              fieldProps={{
                DescriptionComponent: MarkdownDescription
              }}
            />
          </Form>
        </Grid.Column>
        <Grid.Column width={rightColumnWidth}>
          {entity && entity.description && (
            isString(entity.description) ?
              <MultiParagraphText asMarkdown text={entity.description} />
            :
              entity.description
          )}
        </Grid.Column>
        {!!genericErrors.length &&
          <Grid.Column width={16}>
            <Message
              error
              icon='warning sign'
              header='Error'
              content={
                <ul>
                  {genericErrors.map(({message}, index) =>
                    <li key={index}>{message}</li>
                  )}
                </ul>
              }
            />
          </Grid.Column>
        }
        {httpError &&
          <Grid.Column width={16}>
            <FetchDataError error={httpError.error} />
          </Grid.Column>
        }
      </Grid>
    );
  }
}
