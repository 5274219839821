import cx from 'classnames';
import {Value} from 'apstra-ui-common';

const RoutesStatus = ({item}) => {
  const color = item.value === 'Missing' ?
  'red' :
  (item.value === 'Expected' ? 'green' : null);
  return (
    <div className={cx('value-container', {[`graph-color-${color}`]: !!color})}>
      <div>
        <Value value={item.value} />
      </div>
    </div>
  );
};

export default RoutesStatus;
