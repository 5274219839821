import {Base64} from 'js-base64';
import {noop} from 'lodash';
import {ElementType, FC, PropsWithChildren, createContext,
  useContext, useEffect, useMemo, useRef, useState} from 'react';

export interface ProcessorContextValue {
  registerElement: (entityName: string, id: string, node: HTMLElement) => void;
  unregisterElement: (entityName: string, id: string) => void;
  scrollToElement: (entityName: string, id: string, scrollBehavior?: ScrollBehavior) => void;
}

const ProcessorContext = createContext<ProcessorContextValue>({
  registerElement: noop,
  unregisterElement: noop,
  scrollToElement: noop,
});

export const useScrollContext = () => useContext(ProcessorContext);

export const withScrollContext = (Source: ElementType): FC<any> => (props) => {
  const scrollContext = useScrollContext();
  return <Source scrollContext={scrollContext} {...props} />;
};

export const withScrollProvider = <T extends object>(Component: FC<T>): FC<T> => (props: T) => (
  <ScrollContextProvider>
    <Component {...props} />
  </ScrollContextProvider>
);

function buildId(entityName: string, id: string): string {
  return Base64.encode(entityName) + '@' + Base64.encode(id);
}

type ScrollAnchorProps = {
  entityName: string;
  id: string;
  topOffset?: number;
};

export const ScrollAnchor: FC<ScrollAnchorProps> = ({
  topOffset = 20,
  entityName, id
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const {registerElement, unregisterElement} = useScrollContext();
  useEffect(() => {
    registerElement(entityName, id, ref.current!);
    return () => unregisterElement(entityName, id);
  }, [entityName, id, registerElement, unregisterElement]);
  return <div ref={ref} style={{marginTop: `-${topOffset}px`, paddingTop: `${topOffset}px`}} />;
};

export function ScrollContextProvider({children}: PropsWithChildren) {
  const [elementById] = useState<Map<string, HTMLElement>>(() => new Map());

  const contextValue = useMemo<ProcessorContextValue>(
    () => {
      return {
        registerElement: (entityName: string, id: string, node: HTMLElement) => {
          elementById.set(buildId(entityName, id), node);
        },
        unregisterElement: (entityName: string, id: string) => {
          elementById.delete(buildId(entityName, id));
        },
        scrollToElement: (entityName: string, id: string) => {
          elementById.get(buildId(entityName, id))?.scrollIntoView({behavior: 'auto'});
        },
      };
    },
    [elementById]
  );

  return (
    <ProcessorContext.Provider value={contextValue}>{children}</ProcessorContext.Provider>
  );
}
