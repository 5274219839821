import {Component, Fragment} from 'react';
import {Table, Loader, Label} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {computed, makeObservable} from 'mobx';
import {observer} from 'mobx-react';
import {transform, find, filter, some, values, sortBy, isNil, isPlainObject, castArray, concat} from 'lodash';
import {DropdownInput, FetchData, Field, ValueRenderer, interpolateRoute, request} from 'apstra-ui-common';

import generateProbeURI from '../generateProbeURI';

import './StageInput.less';

@observer
export default class StageInput extends Component {
  constructor(props) {
    super(props);
    makeObservable(this);
  }

  static async fetchAllProbes({blueprintId, routes, signal}) {
    const {items: probes} = await request(interpolateRoute(routes.probeList, {blueprintId}), {signal});
    return {probes};
  }

  static async fetchProbe({blueprintId, probeId, routes, signal}) {
    const probe = await request(interpolateRoute(routes.probeDetails, {blueprintId, probeId}), {signal});
    return {probe};
  }

  @computed get errors() {
    return transform(this.props.errors, (result, error) => {
      if (isPlainObject(error)) {
        for (const [errorPropertyName, resultPropertyName] of [['probe_id', 'probe'], ['stage_name', 'stage']]) {
          if (error[errorPropertyName]) {
            result[resultPropertyName] = castArray(error[errorPropertyName]);
          }
        }
      } else {
        result.generic.push(...castArray(error));
      }
    }, {probe: [], stage: [], generic: []});
  }

  @computed get value() {
    const {value} = this.props;
    return isNil(value) ? {probe_id: null, stage_name: null} : value;
  }

  @computed get probes() {
    const {probes, probeId} = this.props;
    return sortBy(filter(probes, ({id}) => id !== probeId), 'label');
  }

  @computed get isSelectedProbeOperational() {
    const {probe_id: probeId} = this.value;
    return find(this.probes, {id: probeId})?.state === 'operational';
  }

  @computed get stagesNames() {
    const result = [];
    const {probe_id: probeId} = this.value;
    const probe = find(this.probes, {id: probeId});
    if (probe) {
      for (const processor of probe.processors) result.push(...values(processor.outputs));
    }
    return result;
  }

  @computed get isProbeIdValid() {
    const {probe_id: probeId} = this.value;
    return some(this.props.probes, {id: probeId});
  }

  onProbeChange = (probeId) => {
    return this.props.onChange({probe_id: probeId, stage_name: null});
  };

  onStageChange = (stageName) => {
    return this.props.onChange({...this.value, stage_name: stageName});
  };

  render() {
    const {name, schema, required, disabled, loaderVisible} = this.props;
    const {
      probes, stagesNames, errors, isProbeIdValid, isSelectedProbeOperational,
      value: {probe_id: probeId = null, stage_name: stageName = null}
    } = this;
    const probeErrors = concat(errors.probe, isProbeIdValid && !isSelectedProbeOperational ?
      ['Probe should be operational'] : []);
    return (
      <Field
        className='stage-input'
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
        errors={errors.generic}
      >
        <Table definition size='small'>
          <Table.Body>
            <Table.Row>
              <Table.Cell collapsing>{'Probe'}</Table.Cell>
              <Table.Cell>
                <DropdownInput
                  clearable
                  search
                  errors={probeErrors}
                  value={loaderVisible ? null : probeId}
                  disabled={disabled || loaderVisible}
                  loading={loaderVisible}
                  placeholder={loaderVisible ? '' : 'Select probe'}
                  options={probes.map(({id, label}) => ({key: id, text: label, value: id}))}
                  onChange={(value) => this.onProbeChange(value)}
                />
              </Table.Cell>
            </Table.Row>
            {isSelectedProbeOperational &&
              <Table.Row>
                <Table.Cell collapsing>{'Stage'}</Table.Cell>
                <Table.Cell>
                  <DropdownInput
                    clearable
                    search
                    errors={errors.stage}
                    value={loaderVisible ? null : isProbeIdValid ? stageName : null}
                    disabled={disabled || loaderVisible || !isProbeIdValid}
                    loading={loaderVisible}
                    placeholder={loaderVisible ? '' : 'Select stage'}
                    options={stagesNames.map((stagesName) => ({
                      key: stagesName,
                      text: stagesName,
                      value: stagesName,
                    }))}
                    onChange={(value) => this.onStageChange(value)}
                  />
                </Table.Cell>
              </Table.Row>
            }
          </Table.Body>
        </Table>
      </Field>
    );
  }
}

export const stageRenderer = new ValueRenderer({
  condition: ({name}) => name === 'stage',
  Value({value, routes, blueprintId}) {
    if (!value) value = {probe_id: null, stage_name: null};
    const {probe_id: probeId = null, stage_name: stageName = null} = value;
    return (
      <FetchData
        customLoader
        fetchData={StageInput.fetchProbe}
        fetchParams={{routes, blueprintId, probeId}}
        pollingInterval={null}
      >
        {({loaderVisible, probe}) => {
          if (loaderVisible) {
            return (
              <Loader active inline size='mini' />
            );
          }
          if (probe) {
            const stage = find(probe.stages, {name: stageName});
            if (stage) {
              return (
                <Fragment>
                  <Link to={generateProbeURI({blueprintId, probeId})}>{probe.label}</Link>
                  {' / '}
                  <Link to={generateProbeURI({blueprintId, probeId, stageName})}>{stageName}</Link>
                </Fragment>
              );
            }
          }
          return (
            <Label basic color='red' icon='warning sign' content='Invalid stage' />
          );
        }}
      </FetchData>
    );
  },
  ValueInput({name, value, schema, required, disabled, errors, onChange, routes, blueprintId, probeId}) {
    return (
      <FetchData
        customLoader
        fetchData={StageInput.fetchAllProbes}
        fetchParams={{routes, blueprintId}}
        pollingInterval={null}
      >
        <StageInput
          name={name}
          value={value}
          schema={schema}
          required={required}
          disabled={disabled}
          errors={errors}
          onChange={onChange}
          probeId={probeId}
        />
      </FetchData>
    );
  }
});
