import {isNumber} from 'lodash';
import {FormattedNumber, ValueRenderer, formatSeconds, withPrefix} from 'apstra-ui-common';

import {SPECIAL_RENDERED_PROPERTIES} from './consts';

export const secondsRenderer = new ValueRenderer({
  condition: ({name, value}) =>
    withPrefix(SPECIAL_RENDERED_PROPERTIES.SECONDS_OR_EXPRESSION, 'properties.').includes(name) &&
    isNumber(value),
  Value: ({value}) => formatSeconds(value)
});

export const speedRenderer = new ValueRenderer({
  condition: ({name, value}) =>
    withPrefix(SPECIAL_RENDERED_PROPERTIES.SPEED, 'properties.').includes(name) && isNumber(value),
  Value: ({value}) => <FormattedNumber value={value} units='bps' />
});
