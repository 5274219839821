import SingleHorizontalBar from '../graphs/SingleHorizontalBar';
import {getItemsValuesExtent} from './cache';
import PrimitiveValue from './PrimitiveValue';

const HorizontalBar = ({name, value, items, extraContent, valueSchemas, stageDataProps: {stage}}) => {
  if (!isFinite(value)) return <PrimitiveValue value={value} />;

  const {[name]: units} = stage.units;
  const [minValue, maxValue] = getItemsValuesExtent(items, name, valueSchemas);
  return (
    <SingleHorizontalBar
      value={value}
      minValue={minValue}
      maxValue={maxValue}
      units={units}
      extraContent={extraContent}
    />
  );
};

export default HorizontalBar;
