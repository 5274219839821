import {Component, Fragment} from 'react';
import {Header, Label, Message, Grid, Button, Popup} from 'semantic-ui-react';
import {observable, action, makeObservable, runInAction} from 'mobx';
import {observer} from 'mobx-react';
import {filter, get, head, includes, isEmpty, isFunction, isNumber, keys, map, transform} from 'lodash';
import moment from 'moment';
import {DEFAULT_COMBINE_GRAPHS_MODE, ActionsMenu,
  DataFilteringContainerWithRouter as DataFilteringContainer, FetchData,
  hasBlueprintPermissions, interpolateRoute, notifier,
  refetchExcludeParametersComparer, request, withRouter} from 'apstra-ui-common';

import {
  DEFAULT_STAGE_DATA_SOURCE,
  WIDGET_TYPE_STAGE, STAGE_DATA_SOURCE,
  DEFAULT_STAGE_TIME_SERIES_DURATION,
  DEFAULT_STAGE_TIME_SERIES_AGGREGATION,
  STAGE_DYNAMIC_POPUP_MESSAGE,
} from '../consts';
import {sortingToQueryParam, filtersToQueryParam} from '../../queryParamUtils';
import {
  getStageSorting, processorCanRaiseAnomalies,
  getStageRenderingStrategy, processorCanRaiseWarnings, getDefaultAggregationType,
} from '../stageUtils';
import {descriptionRenderer} from '../descriptionRenderer';
import {StageData} from './StageData';
import {getStageSearchSchema} from './StageSearchBox';
import checkForPatterns from '../checkForPatterns';
import DashboardChoiceModal from './DashboardChoiceModal';
import IBAContext from '../IBAContext';
import PersistedLabel from './PersistedLabel';
import userStore from '../../userStore';
import {StageEditor} from './StageEditor';

import {ReactComponent as StageDynamic} from '../../../styles/icons/iba/stage-dynamic.svg';

import './ProbeStage.less';

@observer
export default class ProbeStage extends Component {
  static contextType = IBAContext;

  static defaultProps = {
    spotlightMode: false,
  };

  static pollingInterval = 10000;
  static userStoreKey = 'probeStage';

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  static get excludeNonImportantParameters() {
    return ['probe.anomaly_count', 'probe.stages', 'probe.probe_state', 'stage.anomaly_count'];
  }

  static async fetchData(args) {
    const {
      editable,
      blueprintId, isFreeform, processorDefinitions, probe, processor, stage,
      activePage, pageSize, filters, sorting,
      routes, signal
    } = args;
    if (editable || probe.disabled || probe.state === 'maintenance') return {};

    const patternDescription = checkForPatterns({probe, stageName: stage.name, dataSource: filters.dataSource});
    const usePattern = !!patternDescription &&
      (filters.showContextInfo || patternDescription.renderingStrategy?.alwaysUseContext);
    const stageFilter = filtersToQueryParam(
      (usePattern && isFunction(patternDescription.renderingStrategy.filterTransformer)) ?
      patternDescription.renderingStrategy.filterTransformer(filters.filter) : filters.filter,
      getStageSearchSchema({
        processorDefinitions, processor, stage, stageItems, dataSource: filters.dataSource, isFreeform
      })
    );

    if (usePattern && isFunction(patternDescription.renderingStrategy?.shouldFetchData) &&
      !patternDescription.renderingStrategy.shouldFetchData(args)) {
      return {};
    }

    const body = {stage: stage.name};

    const queryParams = new URLSearchParams();
    if (activePage && pageSize && !(usePattern && patternDescription.renderingStrategy.noPagination)) {
      queryParams.set('page', activePage);
      queryParams.set('per_page', pageSize);
    }
    const sortingAsString = sortingToQueryParam(
      getStageSorting({sortingOverrides: sorting, propertyNames: keys(stage.keys)})
    );

    if (sortingAsString.length) body.order_by = sortingAsString;

    if (stageFilter) body.filter = stageFilter;
    if (usePattern && !isEmpty(patternDescription.fetchDataForRelatedStages)) {
      body.include_stage = map(filter(
        patternDescription.fetchDataForRelatedStages, ({stageName}) => stageName !== stage.name
      ), 'stageName');
    }
    if (filters.anomalousOnly) body.anomalous_only = true;

    const now = moment();
    const {timeSeriesDuration} = filters;

    if (patternDescription?.shouldFetchPersistedStageData && usePattern ||
      (patternDescription?.shouldFetchRawPersistedStageData && usePattern) ||
      filters.dataSource === STAGE_DATA_SOURCE.time_series) {
      body.begin_time =
        isNumber(timeSeriesDuration) ?
          now.clone().add(-timeSeriesDuration, 's').toISOString() : timeSeriesDuration?.start;
      body.end_time =
        (isNumber(timeSeriesDuration) ? null : timeSeriesDuration?.end) ?? now.toISOString();

      if (filters.dataSource === STAGE_DATA_SOURCE.time_series && filters.timeSeriesAggregation) {
        // default behavior for time series
        const stageAggregation = {
          period: filters.timeSeriesAggregation,
          metrics: filters.aggregationType === 'unset' ? {} : {[filters.valueColumnName]: filters.aggregationType},
        };
        body.aggregation = {
          [stage.name]: stageAggregation
        };
      }

      if (usePattern && patternDescription?.shouldFetchPersistedStageData) {
        const aggregation = transform(
          patternDescription?.fetchDataForRelatedStages,
          (acc, {doNotUseAggregation, hasPersistedData, stageName, values}) => {
            if (!hasPersistedData || doNotUseAggregation) return;
            acc[stageName] = {
              period: filters.timeSeriesAggregation,
              metrics: isEmpty(values) || filters.aggregationType === 'unset' ? {} :
                transform(values, (acc, value) => {acc[value] = filters.aggregationType;}, {})
            };
            return acc;
          },
          {}
        );
        if (!isEmpty(aggregation)) {
          body.aggregation = aggregation;
        }
      }
    }

    if (processorCanRaiseAnomalies(processor)) {
      body.anomaly_context = true;
    }
    if (filters.dataSource === STAGE_DATA_SOURCE.telemetry_service_warnings) {
      if (body.order_by) queryParams.set('orderby', body.order_by);
      if (body.filter) queryParams.set('filter', body.filter);
    }

    const [{items: stageItems, total_count: totalCount},
      patternData, diskUsage] = (await Promise.allSettled([
      filters.dataSource === STAGE_DATA_SOURCE.telemetry_service_warnings ?
        request(
          interpolateRoute(routes.telemetryServiceWarnings, {blueprintId, probeId: probe.id, stageName: stage.name}),
          {signal, queryParams, method: 'GET'}
        ) :
        request(
          interpolateRoute(routes.probeStage, {blueprintId, probeId: probe.id}),
          {signal, queryParams, method: 'POST', body: JSON.stringify(body)}
        ),
      usePattern && patternDescription.fetchData ? patternDescription.fetchData({
        blueprintId, probe, processor, stage, routes, signal, filters
      }) : null,
      stage.enable_metric_logging ? request(interpolateRoute(routes.diskSpaceUsageForStage, {
        blueprintId, probeId: probe.id, stageName: stage.name
      })) : null,
    ])).map((response, index) => {
      if (response.status === 'fulfilled') {
        return response.value;
      }
      // diskUsage
      if (index === 2) {
        return null;
      }
      throw response.reason;
    });

    return {stageItems, totalCount, patternData, diskUsage};
  }

  @observable.ref dashboardChoiceModalProps = null;

  @action
  setDashboardChoiceModalProps = (props) => {
    this.dashboardChoiceModalProps = props;
  };

  render() {
    const {blueprintId, isFreeform, processorDefinitions, routes, blueprintPermissions} = this.context;
    const telemetryServiceWarnings = get(this.props.stage, ['warnings'], 0);
    const defaultDataSource = processorCanRaiseWarnings(this.props.processor) && telemetryServiceWarnings > 0 ?
      STAGE_DATA_SOURCE.telemetry_service_warnings : DEFAULT_STAGE_DATA_SOURCE;
    const {
      editable, compact,
      probe, processor, stage, stageData,
      actionInProgress, errors,
      visibleColumns,
      filter,
      anomalousOnly = false,
      spotlightMode = false,
      dataSource = defaultDataSource,
      showContextInfo: showContextInfoFromProps,
      stageLink,
      timeSeriesDuration = DEFAULT_STAGE_TIME_SERIES_DURATION,
      timeSeriesAggregation = DEFAULT_STAGE_TIME_SERIES_AGGREGATION,
      combineGraphs = DEFAULT_COMBINE_GRAPHS_MODE,
      valueColumnName = head(keys(stage.values)),
      processorDefinition,
    } = this.props;
    const isProbeOperational = probe?.state === 'operational';
    const defaultPageSize = userStore.getStoreValue([ProbeStage.userStoreKey, 'pageSize']);
    const setUserStoreProps = (value) => {
      if (value?.pageSize > 1) {
        userStore.setStoreValueFn(ProbeStage.userStoreKey)(value);
      }
    };

    return (
      <DataFilteringContainer
        stateQueryParam={compact ? null : 'stage-filter'}
        defaultFilters={{
          filter, dataSource, anomalousOnly, spotlightMode,
          timeSeriesDuration, timeSeriesAggregation, valueColumnName, combineGraphs,
        }}
        defaultPageSize={spotlightMode ? 1 : defaultPageSize}
        setUserStoreProps={setUserStoreProps}
      >
        {({activePage, pageSize, updatePagination, filters: currentFilters, updateFilters, sorting, updateSorting}) => {
          const patternDescription = checkForPatterns({probe, stageName: stage.name,
            dataSource: currentFilters.dataSource});
          const defaultShowContextInfo = showContextInfoFromProps ??
            (!!patternDescription && !patternDescription.renderingStrategy?.hiddenContextByDefault);
          const defaultAggregationType = getDefaultAggregationType(stage, valueColumnName, patternDescription);
          const defaultFilter = patternDescription?.renderingStrategy.defaultFilter ?? null;

          const filters = {
            ...currentFilters,
            showContextInfo: currentFilters.showContextInfo ?? defaultShowContextInfo,
            aggregationType: currentFilters.aggregationType ?? defaultAggregationType,
            filter: currentFilters.filter ?? defaultFilter,
          };

          const showContext = filters.showContextInfo || patternDescription?.renderingStrategy?.alwaysUseContext;
          const renderingStrategy = getStageRenderingStrategy({
            stage, probe,
            dataSource: filters.dataSource,
            showContext,
          });
          const isTelemetryServiceWarningsDataSource =
            filters.dataSource === STAGE_DATA_SOURCE.telemetry_service_warnings;
          return (
            <FetchData
              customLoader
              pollingInterval={ProbeStage.pollingInterval}
              providedData={stageData}
              fetchData={ProbeStage.fetchData}
              fetchParams={{
                stageData,
                editable,
                blueprintId, isFreeform, processorDefinitions, probe, processor, stage,
                activePage, pageSize, filters, sorting,
                routes
              }}
              refetchComparer={refetchExcludeParametersComparer(ProbeStage.excludeNonImportantParameters)}
            >
              {({
                stageItems = [],
                totalCount = null,
                patternData,
                loaderVisible,
                fetchDataError,
                diskUsage,
              }) =>
                <Grid className='probe-stage'>
                  {!compact &&
                    <Fragment>
                      <Grid.Row>
                        <Grid.Column width={13}>
                          <Header className='probe-contents-header'>
                            {stage.dynamic &&
                              <Fragment>
                                <Popup
                                  trigger={
                                    <Label
                                      content='Dynamic'
                                      icon={<StageDynamic className='stage-icon' />}
                                    />
                                  }
                                  content={STAGE_DYNAMIC_POPUP_MESSAGE}
                                  wide
                                />
                                &nbsp;
                              </Fragment>
                            }
                            {!editable &&
                              <Fragment>
                                {stage.enable_metric_logging &&
                                  <Fragment>
                                    <PersistedLabel duration={stage.retention_duration} diskUsage={diskUsage} />
                                    &nbsp;
                                  </Fragment>
                                }
                                {patternDescription?.shouldFetchPersistedStageData &&
                                  patternDescription?.shouldFetchRawPersistedStageData &&
                                  showContext &&
                                    <Label content='Aggregation affects only sample history' />
                                }
                              </Fragment>
                            }
                          </Header>
                        </Grid.Column>
                        {!editable && isProbeOperational && !isTelemetryServiceWarningsDataSource &&
                          <Grid.Column width={3} textAlign='right' className='create-button'>
                            <ActionsMenu
                              size='small'
                              items={[{
                                icon: 'apstra-icon apstra-icon-widget',
                                title: 'Create dashboard widget',
                                hasPermissions:
                                  hasBlueprintPermissions({blueprintPermissions, action: 'edit'}),
                                notPermittedMessage: 'You do not have permission to create',
                                onClick: () => this.setDashboardChoiceModalProps({
                                  open: true,
                                  widget: {
                                    type: WIDGET_TYPE_STAGE,
                                    label: stage.name,
                                    probe_id: probe.id,
                                    stage_name: stage.name,
                                    data_source: filters.dataSource,
                                    filter:
                                      (renderingStrategy?.filterSerializer || filtersToQueryParam)(
                                        filters.filter,
                                        getStageSearchSchema({
                                          processorDefinitions, processor, stage, stageItems,
                                          dataSource: filters.dataSource, isFreeform
                                        })
                                      ),
                                    anomalous_only: filters.anomalousOnly,
                                    show_context: filters.showContextInfo,
                                    spotlight_mode: filters.spotlightMode,
                                    orderby: sortingToQueryParam(sorting),
                                    aggregation_type: filters.aggregationType,
                                    aggregation_period: filters.timeSeriesAggregation,
                                    ...(isNumber(filters.timeSeriesDuration) &&
                                      {time_series_duration: filters.timeSeriesDuration}
                                    ),
                                    value_column_name: filters.valueColumnName,
                                    combine_graphs: filters.combineGraphs,
                                  }
                                }),
                              }]}
                            />
                            {this.dashboardChoiceModalProps?.open &&
                              <DashboardChoiceModal
                                open={false}
                                onClose={() => this.setDashboardChoiceModalProps(null)}
                                {...this.dashboardChoiceModalProps}
                              />
                            }
                          </Grid.Column>
                        }
                      </Grid.Row>
                      {!editable && stage.description &&
                        <Grid.Row>
                          <Grid.Column width={16}>
                            <descriptionRenderer.Value value={stage.description} />
                          </Grid.Column>
                        </Grid.Row>
                      }
                    </Fragment>
                  }
                  {!editable && VSphereAnomalyRemediator.anomalyRemediationPossible({
                    stage, routes, blueprintPermissions
                  }) &&
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <VSphereAnomalyRemediator
                          probe={probe}
                          stage={stage}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  }
                  <Grid.Row>
                    <Grid.Column width={16}>
                      {editable ?
                        <StageEditor
                          stage={stage}
                          processor={processor}
                          actionInProgress={actionInProgress}
                          errors={errors}
                          processorDefinition={processorDefinition}
                        />
                      : probe.disabled ?
                        <Message
                          info
                          icon='info circle'
                          content='This probe is disabled.'
                        />
                      : probe.state === 'configuring' ?
                        <Message
                          info
                          icon='hourglass half'
                          content='This probe is configuring.'
                        />
                      : probe.state === 'maintenance' ?
                        <Message
                          info
                          icon='wrench'
                          content='This probe is in maintenance mode.'
                        />
                      : probe.state === 'error' &&
                        // checking the probe state to determine if we need to show telemetry warnings
                        probe.probe_state?.processors_configuration?.state !== 'operational' &&
                        probe.probe_state?.pipeline?.state !== 'operational'
                      ?
                        <Message
                          error
                          icon='warning sign'
                          content='This probe is in error state.'
                        />
                      :
                        <StageData
                          stage={stage}
                          stageItems={stageItems}
                          visibleColumns={visibleColumns}
                          probe={probe}
                          processor={processor}
                          compact={compact}
                          totalCount={totalCount}
                          activePage={activePage}
                          pageSize={pageSize}
                          filters={filters}
                          sorting={sorting}
                          updatePagination={updatePagination}
                          updateFilters={updateFilters}
                          updateSorting={updateSorting}
                          patternData={patternData}
                          stageLink={stageLink}
                          loaderVisible={loaderVisible}
                          fetchDataError={fetchDataError}
                          renderingStrategy={renderingStrategy}
                          patternDescription={patternDescription}
                        />
                      }
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              }
            </FetchData>
          );
        }
        }
      </DataFilteringContainer>
    );
  }
}

@withRouter
@observer
class VSphereAnomalyRemediator extends Component {
  static contextType = IBAContext;

  static stageNames = [
    'Fabric missing VLAN configs anomaly',
    'Hypervisor missing VLAN configs anomaly',
  ];

  constructor(props) {
    super(props);
    makeObservable(this);
  }

  static anomalyRemediationPossible({stage, blueprintPermissions}) {
    return (
      hasBlueprintPermissions({blueprintPermissions, action: 'edit'}) &&
      includes(VSphereAnomalyRemediator.stageNames, stage.name) &&
      !!stage.anomaly_count
    );
  }

  @observable actionInProgress = false;

  @action
  resolveAnomalies = async () => {
    const {blueprintId, routes, anomalyRemediationUrl} = this.context;
    const {probe, stage} = this.props;
    let error = null;
    this.actionInProgress = true;
    try {
      await request(
        interpolateRoute(routes.vShpereAnomalyResolver, {blueprintId}),
        {method: 'POST', body: JSON.stringify({probe_id: probe.id, stage_name: stage.name})}
      );
    } catch (e) {
      error = e;
    }
    if (!error) {
      notifier.notify({message: 'Anomalies were remediated successfully'});
      window.location.href = `/#${anomalyRemediationUrl}`;
    } else {
      notifier.showError(error);
      runInAction(() => {
        this.actionInProgress = false;
      });
    }
  };

  render() {
    return (
      <Message info>
        <Message.Content>
          <Message.Header>{'Anomaly Remediation'}</Message.Header>
          <p>{'It is possible to automatically fix the anomalies.'}</p>
          <Button
            primary
            icon='wrench'
            labelPosition='left'
            content='Remediate Anomalies'
            disabled={this.actionInProgress}
            onClick={this.resolveAnomalies}
          />
        </Message.Content>
      </Message>
    );
  }
}
