import {Message} from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import {Field, Markdown, ValueRenderer} from 'apstra-ui-common';

import './descriptionRenderer.less';

export const descriptionRenderer = new ValueRenderer({
  condition: ({name}) => name === 'description',
  Value({value}) {
    return value && (
      <Message className='description-message'>
        <Markdown children={value} />
      </Message>
    );
  },
  ValueInput({name, value, schema, required, disabled, errors, onChange}) {
    return (
      <Field
        className='description-field'
        label={schema?.title ?? name}
        description={schema?.description}
        required={required}
        disabled={disabled}
        errors={errors}
      >
        <TextareaAutosize
          cacheMeasurements
          value={value}
          rows={1}
          minRows={1}
          maxRows={10}
          disabled={disabled}
          onChange={(e) => onChange(e.target.value)}
        />
      </Field>
    );
  }
});
