import {forEach, forEachRight, isFunction, isNil, keys, map, some, compact} from 'lodash';

import {
  ProbePatternMatcherContext, ProbePatternSchema, checkIfPatternMatches, probePatternSchemas
} from './probePatterns';
import {getProcessorByStageName, getStageByName} from './stageUtils';
import {Probe, ProbeProcessorType, ProbeStageType, RenderingStrategy, StageDataSource} from './types';

type CheckPatternResult = {
  renderingStrategy: RenderingStrategy;
  shouldFetchPersistedStageData: boolean;
  shouldFetchRawPersistedStageData: boolean;
  relatedProcessors: ProbeProcessorType[];
  relatedStages: ProbeStageType[];
  fetchData: any;
  fetchDataForRelatedStages: Array<{
    stageName: string;
    hasPersistedData: boolean | null;
    doNotUseAggregation?: boolean;
    values?: string[] | null;
  }>;
  description: string;
  dataSource: StageDataSource | undefined;
};

export function checkForPatternBySchema(
  probeSchema: ProbePatternSchema,
  matcherContext: ProbePatternMatcherContext
): CheckPatternResult | null {
  const relatedStages: ProbeStageType[] = [];
  const relatedProcessors: ProbeProcessorType[] = [];
  const fetchDataForRelatedStages = [] as CheckPatternResult['fetchDataForRelatedStages'];
  let matched = false;
  let {processor: currentProcessor, stage: currentStage} = matcherContext;
  if (probeSchema.dataSource !== matcherContext.dataSource) return null;
  forEachRight(probeSchema.graph, ({options, ...stageSchema}, index) => {
    if (!checkIfPatternMatches(stageSchema,
      {...matcherContext, processor: currentProcessor, stage: currentStage})) return false;

    relatedProcessors.unshift(currentProcessor);
    relatedStages.unshift(currentStage);

    if (options?.fetchStageData) {
      fetchDataForRelatedStages.push({
        stageName: currentStage.name,
        hasPersistedData: stageSchema?.isMetricLoggingEnabled ?? null,
        doNotUseAggregation: options?.doNotUseAggregation,
        values: stageSchema.values ? keys(stageSchema.values) : null,
      });
      // TODO: need to check if this stage is connected to some other processor
      // if it is, we need to take that processor and see what inputs it is connected to
      // and add those columns to values
    }
    forEach(options?.fetchStageDataForOutputs, (outputName) => {
      const stageName = currentProcessor.outputs[outputName];
      if (stageName) {
        fetchDataForRelatedStages.push({
          stageName,
          hasPersistedData: stageSchema?.isMetricLoggingEnabled ?? null,
          doNotUseAggregation: true,
          // all cases in patterns with fetchStageDataForOutputs are fetched without aggregation
          // so we can safely set this to true, but we can extend fetchStageDataForOutputs
          // with an options object to modify this behavior in future
        });
      }
    });
    if (!index) {
      matched = true;
      return false;
    }
    const sourceStageName = currentProcessor.inputs[stageSchema.inputName ?? 'in']?.stage ?? null;
    if (isNil(sourceStageName)) return false;
    currentStage = getStageByName({probe: matcherContext.probe, stageName: sourceStageName})!;
    currentProcessor = getProcessorByStageName({probe: matcherContext.probe, stageName: sourceStageName})!;
  });
  if (!matched) return null;
  const renderingStrategyWithExpandedExtraColumns = {
    ...probeSchema.renderingStrategy,
    extraColumns: compact(map(probeSchema.renderingStrategy.extraColumns, (columnDefinition) =>
      isFunction(columnDefinition) ?
        columnDefinition({probe: matcherContext.probe, processor: currentProcessor, stage: currentStage,
          relatedProcessors, relatedStages, fetchDataForRelatedStages})
      :
        columnDefinition
    ))
  };
  return {
    renderingStrategy: renderingStrategyWithExpandedExtraColumns,
    shouldFetchPersistedStageData: some(probeSchema.graph,
      ({isMetricLoggingEnabled, options}) => isMetricLoggingEnabled && !options?.doNotUseAggregation),
    shouldFetchRawPersistedStageData: some(probeSchema.graph,
      ({isMetricLoggingEnabled, options}) => isMetricLoggingEnabled && options?.doNotUseAggregation),
    relatedProcessors, relatedStages,
    fetchData: probeSchema.fetchData, fetchDataForRelatedStages, description: probeSchema.description,
    dataSource: probeSchema.dataSource,
  };
}

export default function checkForPatterns({probe, stageName, dataSource}: {
  probe: Probe,
  stageName: string,
  dataSource: StageDataSource,
}): CheckPatternResult | null {
  const stage = getStageByName({probe, stageName});
  const processor = getProcessorByStageName({probe, stageName});
  if (!stage || !processor) return null;

  const matcherContext: ProbePatternMatcherContext = {probe, processor, stage, dataSource};
  for (const probeSchema of probePatternSchemas) {
    const result = checkForPatternBySchema(probeSchema, matcherContext);
    if (result) return result;
  }
  return null;
}
