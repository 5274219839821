import {releaseProxy, wrap} from 'comlink';
import {action, autorun, computed, makeObservable, observable} from 'mobx';
import {find, map} from 'lodash';

class NodeArrangerStore {
  @observable.ref arrangedPositions = null;
  @observable arrangeType = 'user-defined';
  @observable isCalculating = false;
  @observable readonly = true;

  @action
  setIsCalculating = (isCalculating) => {
    this.isCalculating = isCalculating;
  };

  @action
  setReadonly = (readonly) => {
    this.readonly = readonly;
  };

  @action
  setArrangeType = (arrangeType) => {
    this.arrangeType = arrangeType;
  };

  @action
  setArrangedPositions = (arrangedPositions) => {
    this.arrangedPositions = arrangedPositions;
  };

  @computed
  get positions() {
    if (this.isCalculating || this.arrangeType === 'user-defined') {
      return null;
    }
    return this.arrangedPositions;
  }

  constructor(rootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
    this.updateReactionDisposer = autorun(this.arrange);

    this.graphArrangerWorker = new Worker(
      new URL('GraphArranger', import.meta.url) /* webpackChunkName: 'graph-aligner-worker' */
    );
    this.graphArranger = wrap(this.graphArrangerWorker);
  }

  dispose = () => {
    (this.activeArrangePromise ?? Promise.resolve())
      .finally(this.disposeGraphArranger);
    this.updateReactionDisposer();
  };

  disposeGraphArranger = () => {
    this.graphArranger[releaseProxy]();
    this.graphArrangerWorker.terminate();
  };

  arrange = () => {
    const elkAlgorithm = this.arrangeType;
    if (!find(algorithms, {id: elkAlgorithm})) {
      this.setArrangeType('user-defined');
      return;
    }
    if (elkAlgorithm === 'user-defined' || !this.readonly) {
      return;
    }
    const rankdir = elkAlgorithm === 'dagreTB' ? 'TB' : 'LR';

    this.setIsCalculating(true);
    this.activeArrangePromise = this.graphArranger.align(
      map(this.rootStore.cablingMap.nodes,
        ({id}) => ({id})
      ),
      map(this.rootStore.cablingMap.links,
        ({endpoint1, endpoint2}) => ({source: endpoint1.nodeId, target: endpoint2.nodeId})
      ),
      {
        marginx: 0,
        marginy: 0,
        ranker: 'longest-path',
        rankdir,
        nodesep: 25,
        ranksep: 25,
      }
    );
    this.activeArrangePromise
      .then(this.setArrangedPositions)
      .finally(() => {
        this.setIsCalculating(false);
        this.activeArrangePromise = null;
      });
  };
}

export default NodeArrangerStore;

export const algorithms = [
  {
    id: 'dagreTB',
    name: 'Top-bottom',
  },
  {
    id: 'dagreLR',
    name: 'Left-right',
  }
];
