import {useContext} from 'react';

import DiscreteStateTimelineWithSamplesGraph from '../graphs/DiscreteStateTimelineWithSamples';
import IBAContext from '../../IBAContext';
import {getAllCorrespondingStageItemsForStages, getItemsSampleExtent} from './cache';

const DiscreteStateTimelineWithSamples = (
  {item, name, index, items, expanded = false, rowKey = item.id, patternDescription: {relatedStages, relatedProcessors},
    defaultColors, valueSchemas, valueColumnName, stageDataProps: {stage}}) => {
  const {processorDefinitions} = useContext(IBAContext);
  const {possible_values: possibleValues} = valueSchemas[name];
  const units = stage.units[name];

  const correspondingSourceStageItems = getAllCorrespondingStageItemsForStages(
    {items, stages: relatedStages}
  );
  const [nsSourceItems] = correspondingSourceStageItems;
  const [minValue, maxValue] = getItemsSampleExtent(nsSourceItems, valueColumnName);

  const graphProps = {correspondingSourceStageItems, expanded, index, item, maxValue, minValue,
    possibleValues, processorDefinitions, relatedProcessors, rowKey,
    units, colors: defaultColors};

  return (
    <DiscreteStateTimelineWithSamplesGraph {...graphProps} />
  );
};

export default DiscreteStateTimelineWithSamples;
